.table-default {
    width:100%;
    thead {
      > tr {
        > th {
          text-transform:uppercase;
          position:relative;
          &:not(:first-of-type){
            border-left:1px solid #eee;
          }
          &:not(:last-of-type) {
            border-right:1px solid #eee;
          }
        }
      }
    }
    tbody {
      > tr {
        > td {
          vertical-align:top;
        }
      }
    }
    &.table-striped tbody tr:nth-of-type(odd) {
      background:rgba(black,0.025);
    }
    @media screen and (max-width: 767px) {
      border: 0;
      thead {display:none;}
      tr {
        margin-bottom: 10px;
        display: block;
        border:1px solid #eee;
        background:white;
      }
      td {
        text-align: right;
  
        border-bottom: 1px solid #eee;
        border-top:0 !important;
        min-height: 43px;
        &:last-child {
          border-bottom: 0;
        }
        &:before {
          content: attr(data-label);
          float: left;
          text-transform: uppercase;
          font-weight: bold;
        }
        .grade-block {
          margin-left: auto;
        }
      }
      tbody td, tbody th {
        display:block !important
      }
      .block-column {
        text-align:left;
        &:before {
          float:none;
          display:block;
          margin-bottom:$gap-xs;
        }
      }
    }
  }
  