//skitties
.palette_skittles{
    .grade-block{
        background-color: #CA0020;
        color: #000000;
    }
    display: flex; 
    flex-wrap: wrap;
}
  
  //palette
@mixin graded( $color )
{
  &.grade-block {
    color: white;
    background-color: $color;
  }

  &.grade {
    color: $color;
  }

  fill: $color;
  stroke: darken( $color, 10% );
  stroke-width: 1px;

  &.historic-grade-block
  {
    background-color: fade( $color, 60% );
  }

  &.historic-grade
  {
    color: fade( $color, 60% );
  }
}

@mixin palette_sequential($good, $neutral, $bad)
{

  .grade-Ap               { @include graded(mix($good, $neutral, 100%)); }
  .grade-A                { @include graded(mix($good, $neutral, 95%)); }
  .grade-Am               { @include graded(mix($good, $neutral, 85%)); }

  .grade-Bp               { @include graded(mix($good, $neutral, 75%)); }
  .grade-B                { @include graded(mix($good, $neutral, 60%)); }
  .grade-Bm               { @include graded(mix($good, $neutral, 55%)); }

  .grade-Cp               { @include graded(mix($good, $neutral, 10%)); }
  .grade-C                { @include graded($neutral); }
  .grade-Cm               { @include graded(mix($bad, $neutral, 30%)); }

  .grade-Dp               { @include graded(mix($bad, $neutral, 58%)); }
  .grade-D                { @include graded(mix($bad, $neutral, 80%)); }
  .grade-Dm               { @include graded(mix($bad, $neutral, 90%)); }

  .grade-F                { @include graded(mix($bad, $neutral, 100%)); }

  .grade-NA               { @include graded(#999); font-size: 10px; }
}

.palette_teal_to_brown   { @include palette_sequential( #018571, #CCCCCC, #A6611A ); display: flex; flex-wrap: wrap; };
.palette_blue_to_red     { @include palette_sequential( #0571B0, #CCCCCC, #CA0020 ); display: flex; flex-wrap: wrap; };
.palette_green_to_purple { @include palette_sequential( #008837, #CCCCCC, #7B3294 ); display: flex; flex-wrap: wrap; };
.palette_green_to_red    { @include palette_sequential( #2EB263, #D8D800, #B2302E ); display: block; flex-wrap: wrap; };
.palette_brown_to_teal   { @include palette_sequential( #A6611A, #CCCCCC, #018571 ); display: flex; flex-wrap: wrap; };
.palette_red_to_blue     { @include palette_sequential( #CA0020, #CCCCCC, #0571B0 ); display: flex; flex-wrap: wrap; };
.palette_purple_to_green { @include palette_sequential( #7B3294, #CCCCCC, #008837 ); display: flex; flex-wrap: wrap; };
.palette_red_to_green    { @include palette_sequential( #D63A36, #CCCCCC, #008837 ); display: flex; flex-wrap: wrap; };

.palette_default { @extend .palette_green_to_red; }
