@import "../PageTitle/pagetitle-styles.scss";
@import "../../styles/variables.scss";
.loading-template {
    .side-panel {
        min-height: 200px;
        background-color: #ffffff;
        padding: 0!important;
    }
    .right-panel {
        left: 3%;
        padding: 20px;
        min-height: 400px;
        margin-left: inherit;
        padding-left: 0 !important;
        table {
            width: 100% !important;
        }
        @media (max-width: 992px) {
            top: 0%;
        }
    }
    .non-transparent {
        background-color: #ffffff;
    }
    .transparent-bg {
        background-color: none !important;
    }
    .left-0 {
        left: 0 !important;
    }
    table.grid-template {
        tr {
            display: flex !important;
            flex-direction: row !important;
            width: 100%;
            td {
                margin: 5px !important;
            }
        }
    }
}
.sidebar-skeleton,
.rightpanel-skeleton {
    &,
    *,
    ::after,
    ::before {
        box-sizing: border-box;
    }

    direction: ltr;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    // padding: $ph-gutter ($ph-gutter / 2) ($ph-gutter - $ph-spacer) ($ph-gutter / 2);
    overflow: hidden;
    // margin-bottom: $ph-gutter;

    &::before {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        z-index: 1;
        width: 500%;
        margin-left: -250%;
        animation: phAnimation $ph-animation-duration linear infinite;
        background: linear-gradient(to right, rgba($ph-bg, 0) 46%, rgba($ph-bg, 0.35) 50%, rgba($ph-bg, 0) 54%) 50% 50%;
    }

    > * {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column;
        padding-right: ($ph-gutter / 2);
        padding-left: ($ph-gutter / 2);
    }

    .card-header {
        max-height: 50px;
    }
    .normal-lines-shortest {
        height: 20px;
        width: 50%;
        border-radius: 2px;
        background-color: $ph-color;
        margin-bottom: $ph-spacer;
        border-radius: 0.25rem;
    }
    .normal-lines-short {
        height: 20px;
        width: 80%;
        border-radius: 2px;
        background-color: $ph-color;
        margin-bottom: $ph-spacer;
        border-radius: 0.25rem;
    }
    .normal-lines {
        height: 20px;
        border-radius: 2px;
        background-color: $ph-color;
        margin-bottom: $ph-spacer;
        border-radius: 0.25rem;
    }
}
