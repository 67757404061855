.warning-div {
    position: fixed;
    top: 30%;
    left: 34%;
    background-color: #fff;
    padding: 0px;
    z-index: 1000000000;
    width: 35%;
    min-height: 35%;
    text-align: center;
    border-radius: 4px;
    &.popup-dark-theme{
      .header{
        background-color: #2f2f2f;
      }
      hr{
        height: 0.5px;
        background-color: #5b5b5b !important;
      }
    }
    &.popup-light-theme{
      hr{
        height: 0.5px;
        background-color: #5b5b5b !important;
      }
      border: 1px solid gray;
      
    }
    .header{
      padding: 10px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      img{
        height: auto;
      }
    }
    .warning-body{
      padding: 20px;
    }
    img {
        height: 15%;
    }
    hr{
        margin: 20px 0;
    }

    .drawer-cancel{
      background-color: white;
      color: black;
      border: 1px solid #dadada;
    }
    .width-30 {
      width: 30px;
    }
    @media (max-width: 1024px) {
      top: 3%;
      left: 22%;
      width: 59%;
    }
    @media (max-width: 720px) {
      top: 0%;
      left: 0%;
      width: 100%;
    }

    .height-20{
        height: 20%;
    }

    .height-40{
        height: 40%;
    }

    .height-60{
        height: 60%;
    }

    .height-80{
        height: 80%;
    }

  }

.drawer-warning, .warning-div {
  &.popup-dark-theme{
    border: 0.5px solid #8080809e;
    border-radius: 4px;
    .drawer-ok{
      background-color: var(--btn-bg);
      color: #fff;
      // border-color: #3397C9;
      height: 31.5px;
      width: 61.7px;
    }
    .drawer-ok:hover {
      color: #ffffff;
      background: var(--btn-bg-h);
    }
  }
  &.popup-light-theme{
    border: 0.5px solid #80808073;
    border-radius: 4px;
    .drawer-ok{
      background-color: var(--btn-bg);
      color: #fff;
      // border-color: #3397C9;
      height: 31.5px;
      width: 61.7px;
    }
    .drawer-ok:hover {
      color: #ffffff;
      background: var(--btn-bg-h);
    }
  }
}  