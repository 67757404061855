@keyframes icon-blip {
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(1.125);
  }
}

@keyframes icon-blip-down {
  0% {
    transform:scale(1.125);
  }
  100% {
    transform:scale(1);
  }
}

.hero {
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  position:relative;
  position:relative;
  z-index:999;
  min-height:100vh;
  // background:$hero-gradient2;
    background:$blue2 url(../../../assets/images/analytics-background-crystal.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    @media (max-width:$screen-xl) {
      background-position: bottom;
    }  
  // background-position: bottom;
  &.beta{
    background-color: #1F526B;
  }
  .logo {
    text-align:center;
    align-self:center;
    //set margin to auto and remove all media queries for margin if HA doesn't require in IE
    margin:auto 0;
    // margin: 7%;
    position:relative;
    z-index:10;
    width:100%;
    // @media (max-width:$screen-xl) {
    //   margin: 10%;
    // }
    // @media (max-width:$screen-sm-ls) {
    //   margin-top: 15%;
    //   margin-bottom: 15%;
    // }
    img {
      max-width:60%;
      @media (min-width:$screen-sm) {
        max-width:50vh;
      }
    }
  }
  &.brand{
    z-index: 10;
  }
  &.vendor {
    background-image:url(../../../assets/images/analytics-background-other.svg);
    background-color:white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
    z-index: 10;
  @media (max-width:$screen-xl) {
        background-position: bottom;
      }   
    .logo {
      padding:$gap-lg 0;
      img {
        max-width:500px;
        max-height:300px;
        @media (max-width:$screen-xs) {
          max-width:100%;
          max-height:25vh
        }
      }
    }
  }
  &:not(.vendor){
    .logo img {
      @include drop-shadow(0 2px 2px rgba($blue-dk,0.5));
    }
  }
  .nav-trigger {
    position:absolute;
    bottom:0;
    width:100%;
    height:$primary-nav-height;
  }
}

// @supports (-ms-ime-align:auto) { //this applies only in edge
 
// }


.vendor .quick-links {
  a {
    color:$blue-dk !important;
    @media (min-width:$screen-sm-ls) {
      &:not(:first-of-type) {
        border-left:1px solid rgba($text-color,0.1);
      }
    }
  }
}

.quick-links {
  display:flex;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
  padding:0;
  width:100%;
  margin:(-$gap-md) auto auto;
  @media (min-width:$screen-sm) {
    // padding-left:$gap-lg;
    // padding-right:$gap-lg;
    margin-top:$gap-lg;
  }
  @media (max-width:$screen-sm-ls) {
    max-width:600px;
    margin-top:24px;
  }
  .link-item {
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
    opacity:0.7;
    color:white !important;
    @media (max-width:$screen-sm-ls) {
      margin:$gap 0;
      width:50%;
    }
    @media (max-width:$screen-xs) {
      svg,.splash-spinner {
        width:60px;
        height:60px;
      }
    }
    @media (min-width:$screen-sm-ls) {
      &:hover,&:focus {
        svg,.splash-spinner {
          animation: icon-blip 0.25s forwards;
        }
      }
      &:not(:first-of-type) {
        border-left:1px solid rgba(white,0.1);
      }
    }
    &:hover,&:focus,&:active {
      opacity:1;
      text-decoration:none;
    }
    &.disabled {
      cursor: not-allowed;
      svg,.splash-spinner,.title {
        opacity: 0.2;
      }
      svg,.splash-spinner {
        animation: none;
      }
      &:hover,&:focus {

      }
    }
    svg{
      #pharmaceutical{
        g{
          path{
            stroke-width: 0px !important;
          }
        }
      }
    }
  }
  svg,.splash-spinner {
    animation: icon-blip-down 0.1s forwards;
    height:100px;
    margin:0 ($gap-lg * 1.5);
    transform:scale(1);
    transition:all 0.5s;
    width:100px;
    @media (max-width:$screen-sm-ls) {
      margin:0 auto;
    }
    @include screen-tv {
      width:140px;
      height:140px;
    }
  }
  .splash-spinner>i{
    font-size: 50px !important;
    position: relative;
    top: 30px;
    color: white;
  }
  p.splash-spinner-black>i{
    color: #103756;
  }

  .title {
    margin:$gap 0 0;
    @include font-semibold;
    text-transform:uppercase;
  }
}
// .quick-links .vendor{
//   a {
//     color:$blue-dk;
//     @media (min-width:$screen-sm-ls) {
//       &:not(:first-of-type) {
//         border-left:1px solid rgba($text-color,0.1);
//       }
//     }
//   }
// }