.loader1 {
  display: inline-block;
  position: fixed;
  width: 64px;
  height: 64px;
  left: 42%;
  top: 35%;
  z-index: 100000;
  display: block;
}

@media (max-width: 576px) {
  .loader1 {
    display: inline-block;
    position: fixed;
    width: 64px;
    height: 64px;
    left: 22%;
    top: 37%;
    z-index: 10000;
  }
}

.loader1:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); 
}

@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;500&display=swap");

.progressApp {
  display: inline-block;
  position: relative;
}

.circle-svg {
  display: block;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  /* stroke: #FFFFFF; */
  stroke: #FFFFFF;
}

.circle-progress {
  stroke: #286090;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 3em;
  font-weight: bold;
  fill: #FFFFFF;
}