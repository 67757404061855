// @import 'palettes';

//-- Scaffolding
@import url(https://fonts.googleapis.com/css?family=Sarala);
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans);

@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
// @import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap);
// @import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap);
@import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);
@import "../../../assets/Analytics-style/base/_colors.scss",
  "../../../assets/Analytics-style/base/_settings.scss",
  "../../../assets/Analytics-style/base/_mixins.scss",
  "../../../assets/Analytics-style/base/_selectors.scss",
  "../../../assets/Analytics-style/base/_functions.scss",
  "../../../assets/Analytics-style/base/_helpers.scss",
  "../../../assets/Analytics-style/base/_typography.scss", "./rickshaw.min.css",
  "../../../styles/sarala.css";

//-- layout
@import "../../../assets/Analytics-style/layout/_header.scss",
  "../../../assets/Analytics-style/layout/_footer.scss",
  "../../../assets/Analytics-style/layout/_page.scss";

@import "../../../assets/Analytics-style/modules/_buttons.scss",
  "../../../assets/Analytics-style/modules/_documents.scss",
  "../../../assets/Analytics-style/modules/_sidebar.scss",
  "../../../assets/Analytics-style/modules/_mobile-nav.scss",
  "../../../assets/Analytics-style/modules/_forms.scss",
  "../../../assets/Analytics-style/modules/_icons.scss",
  "../../../assets/Analytics-style/modules/_cards.scss",
  "../../../assets/Analytics-style/modules/_lists.scss",
  "../../../assets/Analytics-style/modules/_hero.scss",
  "../../../assets/Analytics-style/modules/_patient-info.scss",
  "../../../assets/Analytics-style/modules/_drawer.scss",
  "../../../assets/Analytics-style/modules/_biomarkers.scss",
  "../../../assets/Analytics-style/modules/_grades.scss",
  "../../../assets/Analytics-style/modules/_your-health.scss",
  "../../../assets/Analytics-style/modules/_recommendations.scss",
  "../../../assets/Analytics-style/modules/_downloadable.scss",
  "../../../assets/Analytics-style/modules/table",
  "../../../assets/Analytics-style/modules/_loading.scss";

@import "../../../assets/Analytics-style/state/_animation.scss",
  "../../../assets/Analytics-style/state/_accessibility.scss",
  "../../../assets/Analytics-style/state/_debug.scss",
  "../../../assets/Analytics-style/state/_scrolled.scss";

@import "../../../assets/Analytics-style/themes/_vendor.scss";
@import "../../Data/components/palettes.scss";
// body{
//   $font-family-sans-serif: $font-regular, sans-serif;
// }
.analytics-screen {
  //font-family: sans-serif !important;
  font-family:'Noto Sans' !important;
  // font-weight: 400;
}
.recommendation-review .content {
  background-image: url("../../../assets/images/analytics-background-spring.jpg");
}
.loader-fafa {
  color: white;
}
.success {
  color: #24a54e !important;
}
.danger {
  color: #d9534f !important;
}
.analytics-blue{
  color: #2980B9 !important;
}
// .warning{
//   background:$warning;
// }
.drawer-body .recommendation-content .content {
  width: 100%;
  padding: 24px 0;
}
.alert .icon {
  margin-right: 12px;
  opacity: 0.4;
  fill: #8a6d3b;
}
.history-mobile{
  display: none;
}
@media(max-width:1024px){
  .history-mobile {
    display: initial;
    font-size: 1.35rem;
    color: #828181;
    //padding-left: 1rem;
  }
}
@supports (-ms-ime-align: auto) {
  .drawer {
    .drawer-heading {
      position: fixed;
    }
    .comments {
      margin-top: 150px;
    }
  }
}
.icon.fill {
  fill: currentColor;
}
//recommendations
.recommendation-content .item {
  background: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  padding-bottom: 12px;
  padding-top: 12px;
  border: 1px solid #eee;
  padding: 12px;
  word-break: break-all;
}
.recommendation-content .item .icon-group {
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-align: right;
  -ms-flex-align: right;
  align-items: right;
  margin-left: auto;
  opacity: 0.5;
  color: rgb(156, 147, 147);
  .icon:hover {
     opacity: 1;
  }
}
.content .item .icon-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.content .item .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
  border: 1px solid #eee !important;
  margin-bottom: 12px;
}
.content .item .provider-photo {
  width: 50px;
  height: 40px;
  margin-right: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.content .item .patient-view-rec {
  width: 100px;
  height: 40px;
  margin-right: 12px;
}
.details .summary {
  font-size: 1.4rem;
  color: #444;
}
.details .name {
  color: #444;
}
.open-drawer.drawer-shade:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  width: 100vw;
  height: 100vh;
  animation: fade-in 0.5s forwards;
}
body.open-drawer {
  overflow: hidden;
}
.recommendation-pdf {
  display: none;
}
blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}
.fa-spinner {
  color: #103756;
  font-size: 35px !important;
}
.recommendation-group-name-pdf {
  color: #103756;
  font-size: 25px;
}
.indicator-pdf .result {
  margin-bottom: 30px;
}
.indicator-pdf .detail .title {
  margin-top: 0;
}
h3 .header-visit-pdf {
  margin-bottom: 40px;
}
.powered-by-physioage {
  padding: 0.8rem 1.8rem;
  border-radius: 2rem;
  border: 1px solid lightgrey;
  font-size: 1.5rem;
  font-family: "Sarala";
}
.meta-edit-style{
  color: #2980B9;
  padding: 3.4px 6.5px;
  border: 1px solid #2980B9;
  border-radius: 50%;
}
.meta-edit-style:hover{
  background-color: #2980B9;
  color: white;
}
.meta-recommend-style{
  color: #2980B9;
  padding: 3px 3px;
  border: 1px solid #2980B9;
  border-radius: 50%;
  font-size: 22px;
}
.meta-recommend-style:hover{
  background-color: #2980B9;
  color: white;
}

.meta-recommend-ignore-style{
  color: #f04c54;
  padding: 4px 6px;
  border: 1px solid #f04c54;
  border-radius: 50%;
  font-size: 20px;
}
.meta-recommend-ignore-style:hover{
  background-color: #f04c54;
  color: white;
}

.drawer {
  .nav-item {
    .nav-link {
      color: $link-color !important;
    }
    .active.nav-link {
      color: #555 !important;
    }
  }
}
.drawer-warning{
  position: fixed;
  top: 4%;
  left: 34%;
  background-color: #fff;
  padding: 20px;
  z-index: 1000000000;
  width: 34%;
  hr{
    background-color: #e6e6e6;
    height: 1.5px;
    margin: 20px 0;
  }
  .drawer-ok{
    background-color: #3297c9;
    color: #fff;
    // border-color: #3397C9;
    height: 31.5px;
    width: 61.7px;
  }
  .drawer-ok:hover {
    color: #ffffff;
    background: #005c8a;
}
  .drawer-cancel{
    background-color: white;
    color: black;
    border: 1px solid #dadada;
  }
  @media (max-width: 1024px) {
    top: 3%;
    left: 22%;
    width: 59%;
  }
  @media (max-width: 720px) {
    top: 0%;
    left: 0%;
    width: 100%;
  }
}
.drawer-warning-overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 100000;
}
.reports-download-section{
  margin-top: 5em;
  @media (max-width: 1024px) {
    margin-top: 15em;
  }
}
.hoverExplanationThing{
  min-width: 200px;
}
.hoverExplanation{
  white-space: pre-line;
}

// .signleVisitExplanation{
//   position: absolute;
//   background-color: white;
//   border: 2px solid #d3d2d2;
//   z-index: 1000;
//   border-radius: 5px;
//   box-shadow: 0px 3px 30px #00000024;
//   width: 200px;
// }

.quick-links {
  
  .color-blue {
    color: #103756;
  }

  .position-realtive{
    position: relative;
  }

  .recommendations-dot {
    position: absolute;
    right: 66px;
    bottom: 134px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .recommendations-dot {
      right: 70px;
      bottom: 132px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .recommendations-dot {
      right: 98px;
      bottom: 126px;
    }
  }  

  @media (min-width: 320px) and (max-width: 480px) {
    .recommendations-dot {
      right: 60px;
      bottom: 93px;
    }
  }

  @media (min-width: 320px) and (max-width: 360px) {
    .recommendations-dot {
      right: 57px;
      bottom: 93px;
    }
  }
  
  @media (min-width: 120px) and (max-width: 319px) {
    .recommendations-dot {
      right: 34px;
      bottom: 91px;
    }
  }
}

@media (max-width: 1024px) {
  .powered-by-physioage {
    font-size: 0.8rem !important;
  }
}
@media (max-width: 1024px){
  .meta-edit-style {
    padding: 5px 6.5px;
  }
}
.biomarker .icon svg[title="PhenoAge"]{
  position: relative;
  bottom: 36px;
  right: 31px;
  width: 145px;
  height: 145px;
  max-width: initial; 
}



.survey_styles{
  position: fixed;
  top: 2.5%;
  left: 12.5%;
  z-index: 10000
}

.history_recommend_styles{
  position: fixed;
  top: 2.5%;
  left: 4.8%;
  z-index: 10000;
  width: 90% !important;
}


div.overlay_styles{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 10000   
}


.questionnaireFormCloseIcon{
    position: absolute;
    right: 10px;
    // bottom: 35px;
    font-size: 20px;
    color: #ffffff;
    // line-height: 0px;
    top: 15px;
}


.historyRecommendCloseIcon{
  position: absolute;
  right: 10px;
  // bottom: 35px;
  font-size: 20px;
  color: #ffffff;
  // line-height: 0px;
  top: 15px;
}

.recm-history-search>input{
  border: 2px solid transparent;
  border-radius: 50px;
  outline: none;
  padding-left: 10px;
  padding-right: 50px;
}

.recm-history-search>button{
  background-color: #2980b9;
}

.recm-history-search>button i{
  color: white !important;
}

.emumSelect{
  width: 85%;
  height: 25px;
}

.boldShadow{
  text-shadow: 0px 0px 0px #000000a6;
}

.semiboldShadow{
  text-shadow: 0px 0px 0px #000000a6;
}



// .radioButtonBox{
//   padding: 10px;
//   border-style: solid;
//   border-width: thin;
//   border-radius: 5px; 
//   display: block;
//   min-width: 220px;
// }

// .radioButtonBox:hover{
//   cursor: pointer;
//   // background-color: #81c6f5;
//   // border-color: #81c6f5;
//   // color: #000000;
// }

// .radioButtonBox input:checked{
//   background-color: #81c6f5;
//   border-color: #81c6f5;
//   color: #000000;
// }

// .radioCheckedStyle{
//   background-color: #81c6f5;
//   border-color: #81c6f5;
//   color: #000000;
// }

// .questionnaireFormStyle{
//   width: 700px !important;
//   height: 500px !important;
// }


.questionnaireFormStyle{
  height: 95vh !important;
  overflow-y: scroll;
}
.old_questionnaire{
  button.surveyNext{
    background-color: #2980b9;
    border-color: #2980b9;
    color: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
  }

  button.surveyPrev{
    background-color: #2980b9;
    border-color: #2980b9;
    color: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
  }

  button.surveySubmit{
    padding-left: 25px;
    padding-right: 25px;
  }

  button.surveyReview{
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }

  button.surveySave {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    padding-left: 25px;
    padding-right: 25px;
  }

  button.surveySave:hover {
    color: #fff;
    background-color: #17626e;
    border-color: #17626e;
    padding-left: 25px;
    padding-right: 25px;
  }
  .surveyHeader{
    position: sticky;
    top: 0;
    z-index: 2;
    width: 100%;
    background-color: #2980b9;
    border-color: #2980b9;
    color: #ffffff;
  }
  .reviewSurveyHeader{
    padding: 3px;
    background-color: #378dc4;
    color: white;
    cursor: pointer;
    height: 40px;
    .survey-name{
      vertical-align: middle !important;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      padding-top: 5px;
    }
  }
  
  .reviewSurveyHeader:hover{
    background-color: #2a7eb3;
  }
  
  .survey-here-link{
    cursor: pointer;
    color: #337ab7;
  }
  button.surveyRejectBtn{
    background-color: #ffffff;
    border-color: #2980b9;
    color: #2980b9;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  button.surveyRejectBtn:hover{
    background-color: #ffffff;
    border-color: #2980b9;
    color: #2980b9;
  }
  
  button.surveyAcceptBtn{
    background-color: #2980b9;
    border-color: #2980b9;
    color: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
  }
  
  button.surveyAcceptBtn:hover{
    background-color: #2980b9;
    border-color: #2980b9;
    color: #ffffff;
  }
  
}

// button.surveyReview:hover{
//   color: black;
//   background-color: #5bc0de;
//   border-color: #46b8da;
// }

.rating_styles{
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000
}

.surveySubmitAlert{
  padding: 0.2rem !important;
}

// .ratingCardStyle{
//   background-color: #ffffff;
// }

.ratingTick{
  font-size: 25px;
}



.exceed-boundary-range {
  background-color: red;
  animation: pulse 2s infinite;
  @keyframes pulse {
      0% {
          background-color: #ff6600;
      }
      50% {
          background-color: #ff0000;
      }
      100% {
          background-color: #ff6600;
      }
  }

  .bounds-caution {
      display: inline;
      border: 1px solid #cccccc;
      border-radius: 4px;
      background-color: #f5f5f5;
  }
}

// #scrollBar::-webkit-scrollbar {
//   width: 8px;
// }
// #scrollBar::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background-color: #737272;
//   border: 1px solid #000;
// }
// #scrollBar::-webkit-scrollbar-track {
//   border: 1px solid #000;
//   background-color: #404040;
// }

.biomarker.biomarkerMarquee .icon svg[title="PhenoAge"]{
  position: relative;
  bottom: 18px;
  right: 0px;
  height: 142px;
  max-width: initial;
}
.biomarker.biomarkerMarquee .icon svg[title="PhenoAge"]>use{
  fill: #0088AD;
}
.biomarker.biomarkerMarquee .icon svg[title="PhenoAge"] symbol[id="pheno"]>g {
  fill: inherit;
}
.biomarker.biomarkerMarquee.TruAge .icon img{
  position: relative;
  left: 20px;
  top: 8px;
  width: 90px;
  height: 100px;
}

@media (max-width: 920px){
  .biomarker.biomarkerMarquee.TruAge .icon img{
    left: 5px;
    top: 0px;
    width: 40px;
    height: 60px;
  }
  .biomarker.biomarkerMarquee .icon svg[title="PhenoAge"]{
    bottom: 22px;
    right: 27px;
    height: 100px;
  }
}

@media (max-width: 541px){
  .biomarker .icon svg[title="PhenoAge"]{
    position: relative;
    width: 70px;
    height: 70px;
    max-width: initial;
    bottom: 16px;
    right: 8px;
  }
  .biomarker.biomarkerMarquee.TruAge .icon img{
    left: 2px;
    top: 2px;
    width: 25px;
    height: 45px;
  }
  .biomarker.biomarkerMarquee .icon svg[title="PhenoAge"]{
    bottom: 15px;
    right: 8px;
    height: 75px;
  }
}


@media (max-width: 1025px){
  .questionnaireFormStyle{
    width: 1000px !important;
  }
  form.historyRecommendFormStyle{
    width: 75% !important;
  }
  div.historyRecommendFormStyle{
    width: 75% !important;
  }
  .history_recommend_styles{
    left: 12%;
  }
  // .historyRecommendCloseIcon{
  //   top: 10px;
  //   list-style: none;
  // }
  .mobileScroll{
    width: 87% !important;
    overflow: auto;
    position: fixed;
    top: 2.5%;
    left: 6.5%;
    z-index: 10000;
  }
  .survey_styles{
    position: initial;
  }
  // .questionnaireFormCloseIcon{
  //   right: 7%;
  //   top: 4%;
  // }
}

@media (max-width: 760px){
  .history_recommend_styles{
    left: 0px;
  }
  form.historyRecommendFormStyle{
    width: 100% !important;
  }
  div.historyRecommendFormStyle{
    width: 96% !important;
    left: 2%;
  }
  // .historyRecommendCloseIcon{
  //   top: 10px;
  //   list-style: none;
  // }
}

@media (max-width: 760px){
  // .questionnaireFormCloseIcon{
  //   top: 5%;
  // }
  .rating_styles{
    width: 100% !important;
    top: 18%;
  }
}

// .animated-check {
//   height: 50px;
//   width: 50px;
// }

.animated-check path {
  fill: none;
  stroke: white;
  stroke-width: 3;
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  -webkit-animation: none;
  animation: draw-check 0.5s linear forwards;
  stroke-linecap: round;
  stroke-linejoin: round
}

.animated-check-black path{
  stroke: #103756;
}

@keyframes draw-check {
  to {
      stroke-dashoffset: 0
  }
}

.noumneonValue {
    .result {
      font-size: 1rem;
      color: #333;
      font-weight: 500;
      padding: 0 12px 0 1rem;
    }
    .mh-250{
      max-height: 250px;
    }
    .overflow-x{
      overflow-X: auto;
    }
    .title {
      font-size: 1.6rem;
      font-weight: 700;
      color: #103756;
      padding: 0 12px 0 1rem;
    }
    .modal.fade.show {
      display: block;
      background-color: #00000010;
    }

    .ml-23 {
      margin-left: 23%
    }
}
.medication-loading-spinner{
  font-size: 16px !important;
  color: #155724;
  margin-right: 5px;
}
