@import "./variables.scss";
.page-title-container {
    @media (max-width: 768px) {
        padding: 0 !important;
    }
}
.no-page-title {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100vw;
    border: none;
    //background:#ffffff url('../assets/images/icon_physioage.svg') no-repeat center right;
    background-size: 50vw;
    background-attachment: fixed;
    .header-section {
        display: none;
    }
    .children-section,
    .children-content {
        margin: 0 !important;
    }
}
.row.helptext-section {
    display: block;
    margin: 20px 0px !important;
}

.card,
.footer {
    a {
        color: #008fbc !important;
        &.btn {
            color: white !important;
        }
    }
}
.side-panel {
    .btn {
        width: 100%;
        padding: 5px;
        // font-size: 14px;
    }
    .card-body {
        color: #777;
    }
    padding-left: 1%;
    // min-width: 270px !important;
    // max-width: 270px !important;
}

.wrapper {
    overflow: hidden;
}
.wrapper.unauth-layout {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    border: none;
    background-color: none;
    background: #ffffff url($login-background) no-repeat center bottom;
    background-size: 175vh;
}
.unauth-layout .status-card {
    width: 60rem;
}
.card.parent-card {
     & {
        box-shadow: 0 19px 38px #0000004d, 0 15px 12px #00000038;
        padding: 24px 36px;
     }
    .form-group {
        margin-bottom: 15px;
    }
    .row {
        margin: 0;
    }
}
.card {
    .container {
        padding: 0px 15px !important;
    }
}
.row.children-section {
    margin: 50px 0px 100px 0px;
}
.app-rendering {
    .col-lg-12.col-md-12.col-sm-12.ol-xs-12.data-section.p-0:first-child {
        @media (max-width: 1024px) {
            margin-left: 15px;
        }
        @media (max-width: 992px) {
            margin-left: 0;
        }
    }
    .row.children-section {
        margin: 50px 0px 100px 0px;
    }
    .children-content {
        padding: 15px;
    }
}
.children-content {
    // margin-top: 20px;
    &.container {
        padding: 0px;
        .container {
            padding: 0px;
        }
    }
    .order-1 {
        order: inherit !important;
    }

    .order-2 {
        order: inherit !important;
    }
    @media (max-width: 768px) {
        padding: 15px !important;
        @media (max-width: 360px) {
            padding: 10px !important;
        }
        .col-lg-12.col-md-12.col-sm-12.col-xs-12.data-section.p-0 {
            display: grid;
        }
        .col-lg-3.col-md-12.col-sm-12.col-xs-12 {
            padding: 0;
            margin-top: 5%;
        }
    }
}
.btn.btn-default {
    background-color: #ffffff;
    border: 1px solid #bbbbbb;
    color: black;
}
.btn.btn-primary {
    color: #fff;
    background-color: $primary-btn-background-color;
    border-color: $primary-btn-border-color;
    &:hover,
    &:focus,
    .focus {
        color: #fff;
        background-color: #286090;
        border-color: #122b40;
    }
}
.btn.btn-info {
    color: #fff;
    background-color: $info-btn-background-color;
    border-color: $info-btn-border-color;
}
.btn.btn-warning {
    color: white !important;
}
.btn.btn-warning:hover,
.btn.btn-warning:active,
.btn.btn-warning:focus {
    color: white;
}
.btn.btn-danger {
    color: white;
}
.footer-section {
    justify-content: center;
    border-top: 1px solid #cccccc;
    margin-top: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 35px;
    background-color: #fff;
    max-width: inherit !important;
    z-index: 0;
}
.app-rendering .footer-section {
    z-index: 100;
}
legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}
#react-paginate ul {
    display: inline-block;
}

#react-paginate {
    @media (max-width: 768px) {
        margin-left: 5px;
    }
    .previous {
        border-left: 1px solid #ccc;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
    }
    .next {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
    }
    li {
        z-index: 0;
        display: inline-block;
        color: #337ab7;
        &:focus {
            color: #337ab7;
        }
        a {
            color: #007dbc !important;
        }
        &.active .page-link {
            background-color: #337ab7 !important;
            border-color: #337ab7;
            color: #ffffff !important;
            z-index: 0;
        }
    }
}
.patients-table,
.users-table,
.credits-table,
.billing-summary-table,
.message-routing-list,
.oddities-list,
.inbox-section,
.providers-list,
.patients-report-preview-table,
.sites-list {
    tbody {
        tr:nth-of-type(odd) {
            background-color: #f9f9f9 !important;
        }
        tr:hover {
            background-color: $table-hover-background-color !important;
        }
    }
    .table {
        tr {
            border-bottom: 1px solid #dee2e6;
        }
        th {
            border: none;
            line-height: 1.5;
            vertical-align: top;
            padding: 8px;
            word-break: break-word !important;
        }
        td {
            border: none;
            line-height: 1.5;
            padding: 8px;
            vertical-align: top;
            word-break: break-word !important;
        }
    }
    .react-bootstrap-table {
        background-color: white !important;
        border: 1px solid #dee2e6 !important;
        padding: 15px;
        td,
        th {
            border: none;
            vertical-align: middle;
            word-break: break-word !important;
            outline: none;
        }
        th.sortable {
            cursor: pointer;
            outline: none;
            border: none;
        }
        tbody {
            tr {
                border-bottom: 1px solid #eeeeee;
            }
        }
        a {
            color: #008fbc !important;
        }
        a.page-link {
            color: #008fbc !important;
        }
        a:active {
            color: #0e4876 !important;
        }
    }
    .react-bootstrap-table-pagination {
        div {
            display: none;
        }
        div.react-bootstrap-table-pagination-list {
            display: block;
            margin-top: 15px;
        }
        .page-link:hover {
            z-index: 0;
        }
    }

    .pagination.react-bootstrap-table-page-btns-ul {
        .page-link {
            color: #007dbc !important;
            a {
                color: #007dbc !important;
            }
        }
        .active.page-item {
            z-index: 0;
            .page-link {
                background-color: $primary-btn-background-color;
                border-color: $primary-btn-background-color;
                color: #ffffff !important;
            }
        }
    }
}
//break if when no spaces
//todo: in IE
td {
    overflow-wrap: break-word;
}
.announcement {
    position: relative;
    border: 1px solid #e2d5bf;
    background-color: #f1eadc;
    padding: 0.8em;
    margin: 0.5em 0;
    color: indianred;
}
.reports-container {
    padding: 0 40px !important;
    text-align: justify;
}
.dropdown-item.active,
.dropdown-item:active {
    color: white !important;
}

.parent-component {
    padding: 0 5% 0 5%;
    .col-lg-12.col-md-12.col-sm-12.col-xs-12.mt-5 {
        padding-right: 0;
        padding-left: 0;
    }
    &.container {
        @media (max-width: 1024px) {
            display: contents;
        }
    }
    @media (max-width: 1610px) {
    }
    @media (max-width: 1440px) {
        // margin-left: 5% !important;
    }
    // @media (max-width: 1280px) {
    //     margin-left: 0% !important;
    // }
    // @media (max-width: 1300px) {
    //     &.container {
    //         max-width: inherit;
    //     }
    // }
    // @media (max-width: 1024px) {
    //     margin-left: 0% !important;
    //     &.container {
    //         padding: 0 15px !important;
    //     }
    // }
    @media (max-width: 992px) {
        margin: auto;
        padding: 0;
    }
    @media (max-width: 768px) {
        &.container {
            margin: 0 5px;
        }
    }
    @media (max-width: 500px) {
        &.container {
            margin: 0 5px;
        }
    }
}
.page-title-section {
    padding: 0;
}
.page-title-wrap {
    padding: 0 !important;
}
.page-title.container-fluid {
    padding: 30px 0 20px;
}
.page-title-sec {
    max-width: 75% !important;
    left: 7% !important;
    // .page-title-section {
    //     width: 70%;
    // }
    @media (max-width: 2460px) {
        left: 7% !important;
    }
    @media (max-width: 1920px) {
        left: 11% !important;
    }
    @media (max-width: 1680px) {
        left: 13% !important;
    }
    @media (max-width: 1610px) {
        left: 4% !important;
    }
    @media (max-width: 1440px) {
        max-width: 100%;
        left: 6% !important;
        // .page-title-section {
        //     width: 80%;
        // }
    }
    @media (max-width: 1280px) {
        left: 11% !important;
        // .page-title-section {
        //     width: 90%;
        // }
    }
    @media (max-width: 1024px) {
        left: 0% !important;
        // .page-title-section {
        //     width: 100%;
        //     margin-top: 15px;
        //     h1 {
        //         margin-left: 15px;
        //     }
        // }
    }
}

@media (max-width: 1024px) {
    .page-title-sec {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }
}

@media (max-width: 2460px) {
    // .active-site-reports,
    // .jumbotron {
    //     width: 1000px !important;
    // }
}
@media (max-width: 1920px) {
    // .active-site-reports,
    // .jumbotron {
    //     width: 920px !important;
    // }
}
@media (max-width: 1680px) {
    .page-title.container.col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width: 1440px) {
    .page-title.container.col-lg-6 {
        flex: 0 0 65%;
        max-width: 65%;
    }
    // .active-site-reports,
    // .jumbotron {
    //     width: 920px !important;
    // }
    .reports-container.container-fluid {
        width: 1140px;
    }
}

@media (max-width: 1200px) {
    .side-panel {
        min-width: 225px !important;
        max-width: 225px !important;
    }
}

@media (max-width: 1280px) {
    .page-title.container.col-lg-6 {
        flex: 0 0 65%;
        max-width: 65%;
    }
    .footer {
        justify-content: center;
    }
    // .jumbotron,
    // .active-site-reports {
    //     width: 800px !important;
    //     // width: inherit!important;
    // }

    // .active-site-reports {
    //     width: 788px !important;
    // }
    .reports-container.container-fluid {
        width: 960px;
    }
}
// @media(max-width: 1152px){
//     .page-title-sec {
//         padding-left: 10%!important;
//     }
// }

.data-section {
    display: flex;
    @media (max-width: 992px) {
        display: contents;
    }
}
@media (max-width: 1024px) {
    .page-title.container.col-lg-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    // .jumbotron,
    // .active-site-reports {
    //     width: 720px !important;
    // }

    // .parent-component {
    //     .data-section{
    //         margin-left: 15px;
    //     }
    //     display: flex;
    //     // justify-content: center;
    // }
}
@media (max-width: 992px) {
    .children-content {
        padding: 0;
        .order-1 {
            order: 1 !important;
        }

        .order-2 {
            order: 2 !important;
        }
    }
    // .jumbotron,
    // .active-site-reports {
    //     width: 920px !important;
    //     margin: auto;
    // }
    .side-panel {
        padding: 0 !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }
    .col-lg-9.col-md-12.col-sm-12.col-xs-12 {
        padding: 0px;
        width: 100%;
    }
    .parent-component {
        .side-panel {
            padding-right: 0;
        }
        .data-section {
            margin-left: 0;
        }
        // justify-content: center;
    }
}
@media (max-width: 991px) {
    // .jumbotron,
    // .active-site-reports {
    //     width: initial !important;
    //     margin: auto;
    // }
}
@media (max-width: 768px) {
    .react-bootstrap-table {
        td,
        th {
            border-top: none !important;
            border-bottom: none !important;
        }
        th,
        tr {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #dee2e6;
            border-bottom: 1px solid #dee2e6;
        }
    }
    // .children-content.container {
    //     padding: inherit;
    //     .container {
    //         padding: inherit;
    //     }
    // }
    .container {
        max-width: 100% !important;
    }
    .reports-container.container-fluid {
        width: 720px;
    }
}
@media (max-width: 576px) {
    .reports-container.container-fluid {
        width: 540px;
    }
}
