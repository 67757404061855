$status-icon: 15px;

#document-list {
  .document-list-row, .document-sublist-heading{
    background-position: 36px top;
    background-repeat: no-repeat;
    min-height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(221, 221, 221, 0.5);
    padding: $gap-sm $gap;

    a {
      text-decoration: none !important;
      color: $gray-md;
    }


  }

  .document-sublist-heading {
    background-color: $gray-lt;
    font-size: 1.3rem;

    .sublist-title {
      font-weight: 500;
    }

    .sublist-subtitle {
      padding-left: 0.5em;
      color: $blue-lt;
    }

  }
  .card-content {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .icon svg {
    position: relative;
    stroke: $blue-dk;
    position: relative;
    width: $icon-size-md;
    height: $icon-size-md;
    &:hover,&:focus {
      transform:scale(1.2);
    }
  }

  .icon.expander {
    margin-left: auto;
    margin-right: 0.5rem;
    svg {
      width: $icon-size;
      height: $icon-size;
    }
  }


  .title {
    font-size: 1.4rem;
    padding: 0 $gap;
    flex: 4;
    text-decoration:none;
    overflow-wrap: anywhere;
  }

  .publication-date {
    font-size: 1.4rem;
    padding: 0 12px;
    color: $blue-lt;
    flex: 1;
    text-align: right;
  }
}

@media (max-width: 912px){
  #document-list{
    .publication-date{
      flex: auto;
      padding: 0%;
    }
    .document-list-row{
      padding: 12px;
    }
    .title{
      padding: 0px 0px 0px 12px;
    }
  }
}

@media (max-width: 280px){
  #document-list{
    .publication-date{
      text-align: center;
    }
  }
}
