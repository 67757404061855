$status-icon: 15px;

.biomarkers {
  width:100%;
  max-width:1155px;
  @media (min-width:$screen-sm) {
    //margin-top:$gap;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    background:white;
    @include shadow(1);
  }

  .biomarker {
    &.historic{
      opacity: 0.70;
    }
    &.TruAge{
      img{
        opacity: 1 !important;
      }
    }
    width:100%;
    flex-wrap:wrap;
    @include ripple($blue1);
    @media (min-width:$screen-md) {
      margin-bottom:$gap;
    }
    @media (min-width:$screen-sm) {
      padding-left:$gap;
      padding-right:$gap;
    }
    @media (min-width:$screen-sm-ls) {
      padding-top:$gap;
      padding-bottom:$gap;
      width:50%;
      .age {
        margin-left:auto;
      }
    }
    @media (max-width:$screen-sm-min) {
      padding:$gap-sm;
      @include shadow(1);
      background:white;
    }
    .graph {
      flex-basis:100%;
      @media (max-width:$screen-sm-min) {
        margin-bottom:$gap-sm;
        svg {
          margin:0 auto;
        }
      }
      @media (min-width:$screen-sm) {
        padding-left:80px + $gap;
      }
    }
    &:hover,&:focus {
      text-decoration:none;
      .icon {
        transform:scale(1.2);
      }
    }
    &.PhenoAge{
      .icon{
        height: 90px !important;
      }
    }
    &.PhysioAge {
      @media (min-width:$screen-sm-ls) {
        width:100%;
        padding-top:$gap;
        padding-bottom:$gap;
        background:rgba($blue2, 0.15);
        border-radius:3px 3px 0 0;
        border-top:1px solid rgba($blue2,0.15);
        border-bottom:2px solid rgba($blue2,0.15);
        align-items:center;
        .icon {
          width:120px;
          height:120px;
          min-height:120px;
          svg {
            width:120px;
            height:120px;
          }
        }
        .detail {
          padding-left:$gap-md;
        }
        .name {
          @include fontsize-xl;
        }
        .graph {
          padding-left:120px + $gap-md;
        }
        .average {
          @include fontsize-md;
        }
      }
      .icon {
        img,svg {
          opacity:1;
        }
        svg {
          stroke:$blue;
        }
      }
    }
    &.biomarkerMarquee {
      margin-bottom: 0px !important;
      @media (min-width:$screen-sm-ls) {
        width:100%;
        padding-top:$gap;
        padding-bottom:$gap;
        background:rgba($blue2, 0.15);
        border-radius:3px 3px 0 0;
        border-top:1px solid rgba($blue2,0.15);
        border-bottom:2px solid rgba($blue2,0.15);
        align-items:center;
        .icon {
          width:120px;
          height:120px;
          min-height:120px;
          svg {
            width:120px;
            height:120px;
          }
        }
        .detail {
          padding-left:$gap-md;
        }
        .name {
          @include fontsize-xl;
        }
        .graph {
          padding-left:120px + $gap-md;
        }
        .average {
          @include fontsize-md;
        }
      }
      .icon {
        img,svg {
          opacity:1;
        }
        svg {
          stroke:$blue;
        }
      }
    }
    &:last-of-type {
      @media (min-width:$screen-sm-ls) {
        margin-bottom:$gap-lg;
      }
    }
    .icon{
      transition:all 0.15s;
    }
  }
}

.biomarker {
  display:flex;
  padding:$gap;
  position:relative;
  &.warning .status {
    background:$warning;
  }
  &.success .status {
    background:$success;
  }
  &.danger .status {
    background:$danger;
  }

  &.biomarker-sm {
    display: inline;
    padding: 0;
    .icon {
      width: 32px;
      display: inline-block;
      svg {
        width: 32px;
        min-width: 32px;
        max-width: 100%;
        height: 32px;
        margin: 0.2em;
        display: inline-block;
      }
    }
  }

  .status {
    width:$status-icon;
    height:$status-icon;
    min-width:$status-icon;
    min-height:$status-icon;
    border-radius:50%;
    position:absolute;
    display:flex;
    margin:auto 0;
    right:-($status-icon / 2 + 1px);
  }
  .icon {
    width:80px;
    position:relative;
    z-index:2;
    margin-right:0;
    @media (max-width:$screen-sm-min) {
      width:50px;
    }
    svg, img {
      width:80px;
      height:80px;
      max-width:100%;
      min-width:80px;
      opacity:0.2;
      @media (max-width:$screen-sm-min) {
        width:50px;
        height:50px;
        min-width:50px;
      }
    }
    svg {
      stroke:currentColor;
      color:darken($tan,50%);
    }
  }
  .detail {
    padding:0 $gap;
    //display: flex;
    align-items: flex-start;
    flex-direction: column;
    //justify-content: center;
    flex:1;
    //border-left: 2px solid #ddd;
    @media (max-width:$screen-sm-min) {
      padding:0 $gap-sm;
    }
    @media (min-width:$screen-sm-ls + 1px) and (max-width:$screen-sm-ls + 30px) {
      max-width:160px;
    }
  }
  .name {
    font-size:1.5rem;
    font-weight:600;
    margin-bottom:$gap-xs;
    color:$text-color;
    line-height:1.2;
    text-transform: capitalize;
  }

  .name {
    position: relative;
    display: inline-block;
  }
  
  .name .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color:#103756;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    margin-left: 10px;
    font-size: small;
    font-weight: bold;
  }
  
  .name .tooltiptext::after {
    //content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
  .name:hover .tooltiptext {
    visibility: visible;
  }

  .average {
    color:$text-color;
    margin-bottom:$gap-sm;
    font-size:1.1rem;
  }
  .age {
    position:relative;
    line-height:1;
    font-size:3.5rem;
    @include font-semibold;
    display:flex;
    //align-items:center;
    @media (max-width:$screen-sm-min) {
      width:40px;
      font-size:2.5rem;
    }
    > span:first-of-type {
      min-width:68px;
      display:block;
    }
  }
}

.biomarkers-of-aging {
  //background: white;
  position:relative;
  z-index:6;
  @include sidebar-compensation;
  padding-bottom: 0 !important;
  padding-top: 75px!important;
}

.biomarker-charts-section {
  //background: white;
  z-index:5; // WOW! Change this to 5 and the gradient above goes away!!! WHAT???
  @include sidebar-compensation;
  .card {
    padding:$gap;
  }
}

.biomarker-charts-section {
  .charts-container {
    background: #f9f9f9;
    -webkit-box-shadow: 0 1px 3px rgba(51, 51, 51, 0.12), 0 1px 2px rgba(51, 51, 51, 0.24);
            box-shadow: 0 1px 3px rgba(51, 51, 51, 0.12), 0 1px 2px rgba(51, 51, 51, 0.24);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 36px 24px 48px;

    .chart-container {
      margin: $gap-md $gap-sm 0;

      > .title  {
        padding-left: 40px;
        font-weight: 700;
        padding-bottom: 6px;
        font-size: 1.5rem;
        text-transform: capitalize;
      }
      .title {
        position: relative;
        display: inline-block;
      }
      
      .title .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color:#103756;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        margin-left: 10px;
        font-size: small;
        font-weight: bold;
      }
      
      .title .tooltiptext::after {
        //content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent black transparent transparent;
      }
      .title:hover .tooltiptext {
        visibility: visible;
      }
    
    }
    .biomarker-chart {
      position: relative;
      .y_axis {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 100%;
      }
      .chart_body {
        position: relative;
        top: 0;
        left: 40px;
      }
      .chart_body.rickshaw_graph {
        border: 0;
        box-shadow: 0 1px 3px rgba(51, 51, 51, 0.12), 0 1px 2px rgba(51, 51, 51, 0.24);
        background-color: white;
        .x_tick{
          .title{
            white-space: break-spaces;
          }
        }
      }
      .chart_body {
        left: 0;
      }
      text{
        display: none;
      }
    }
  }
}

@media (max-width: 768px){
    .page-section.biomarker-charts-section{
      display: none;
   }
}
