@import "../../Analytics-style/base/_colors.scss";

.treatment-plan{
    cursor: pointer;
    height: 100%;
    background: #F9FBFC;
    overflow: hidden;
    .b-bottom{
      border-bottom: 1px solid #e0e0e0;
    }
    .header{
      height: 6%;
      padding: 10px;
      align-content: center;
      .heading-1{
          color: var(--page-heading-text-c);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.154px;
          padding-left: 20px;
          letter-spacing: -0.18px;
      }
    }
    .recommendation-list {
      height: 92%;
      overflow-y: auto;
      padding-top: 10px;
      width: 99%;
      .active-card{
        border: var(--recommendation-list-active-card-b-d) !important;
      }
      .recommendation-body {
        // background-color: #FFF8E5;
        width: 100%;
        -webkit-flex-shrink: 0;
        flex-shrink: 0;
        border-radius: 3px;
        margin-bottom: 10px;
        position: relative;
        display: inline-block;
        border-radius: 5px;
        border: 0.5px solid #ACB1C7;
        // background: #FFF;
        min-height: 55px;
        align-content: center;
        display: grid;
        font-family: DM Sans;
        .jw-icon svg {
          height: 40px;
          width: 40px;
        }
        .drag-icon{
          color: gray;
          width: 25px !important;
          height: 25px !important;
        }   
        .recommendation-indicator {
          // color: #170F49 !important;;
          font-family: DM Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          margin-bottom: 0px;

        }
        .recommendation-details {
          border-top: 0.7px solid #acb1c7;
          padding-top: 10px;
          padding-bottom: 0px;
          margin-top: 13px;
        }

        .recommendation-body-text {
          color: #272727;
          font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          overflow-wrap: anywhere;
          /* 118.671% */
        }

        .recommendation-body-text-expanded {
          color: var(--recommendation-body-text-expanded);
          // font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          /* 93.75% */
          padding-top: 5px;
          padding-bottom: 5px;
          overflow-wrap: anywhere;
        }

        .recommendation-instructions-text {
          color: #170F49;
          // font-family: DM Sans;
          font-size: 12.64px;
          font-style: normal;
          font-weight: 400;
          line-height: 15px;
        }

        .expaned-div {
          padding: 0px 0px 0px 0px;
          cursor: pointer;
        }

        .heading-1 {
          color: #6F6C90;
          // font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          /* 114.286% */
        }

        .heading-2 {
          color: #6F6C90;
          // font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
        }

        .text-1 {
          color: #6F6C90;
          // font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        .text-2 {
          color: #6F6C90;
          // font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        .jw-recommendation-toggle {
          stroke: #57b9e8;
          width: 15px;
          min-height: 15px;
          vertical-align: text-bottom;
          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .recommendation-body.active{
        opacity: 1;
      }
      .recommendation-body.ended{
        opacity: 0.6 !important;
      }
      .meta-recommendation-body {
        min-height: 50px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
  
        .meta-resume-style {
          color: #2980B9;
          padding: 3px 3px;
          border: 1px solid #2980B9;
          border-radius: 50%;
          font-size: 15px !important;
        }
  
        .meta-resume-style:hover {
          background-color: #2980B9;
          color: white !important;
        }
  
        .meta-recommend-ignore-style {
          color: #f04c54;
          padding: 4px 6px;
          border: 1px solid #f04c54;
          border-radius: 50%;
          font-size: 13px;
        }
  
        .meta-recommend-ignore-style:hover {
          background-color: #f04c54;
          color: white !important;
        }
      }
    }
    .height-100{
      height: 100%;
      padding: 0px 10px 0px 10px;
    }
    .height-95{
      height: 94%;
      padding: 0px 10px 15px 10px;
      .MuiTabs-root{
        padding-left: 0;
      }
      .height-100{
        border-radius: 5px;
        // .css-1op82hf-MuiTabPanel-root{
        //   height: 95%;
        // }
      }   
    }
    .add-btn{
      width: 70px;
      height: 30px;
      color: #ffffff;
      border-radius: 3px !important;
      background: var(--btn-bg);
    }
    .medication-list{
      border: 1px solid var(--btn-bg) !important;
      color: var(--btn-bg) !important;
      height: 30px;
      background: transparent !important;
      padding-top: 4px;
      padding-block: 4px;
    }
    .add-problems-btn {
      width: 130px !important;
    }

    .priorityLoader {
      font-size: 25px;
      color: #3397C9;
      line-height: 0;
    }
    .recommendation-search-field{
      form{
        position: relative;
        left: 98%;
        transform: translate(-100%,0%);
        transition: all 0.5s;
        width: 50px;
        z-index: 9;
        height: 27px;
        z-index: 1;
        // margin-bottom: 8px;
        margin-top: 14px;
        .form-control:focus {
          color: #495057;
          background-color: #fff;
          border-color: #1f526b;
          outline: 0;
          box-shadow: none;
        }  
      }
      input{
          position: absolute;
          top: -3px !important;
          left: 0px !important;
          width: 100%;;
          height: 30px;
          line-height: 30px;
          outline: 0;
          border: 0;
          display: none;
          font-size: 14px !important;
          border-radius: 4px;
          padding: 0 20px;
          border: 1px solid var(--btn-bg);
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
      }
      .dropdown-item span{
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
      }
      .search-icon-div{
        display: block;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0px;
        top: -3px;
        text-align: center;
        cursor: pointer;
        .search-icon{
            box-sizing: border-box;
            position: relative;
            text-align: center;
            font-size: 1.2em;
            transition: all 0.5s;
            top: 3px;
            right: 0px;
            width: 20px;
        }
      }
      .search-open{
        svg{
          fill: #ca0020 !important;
        }
        border-left: 1.4px solid var(--btn-bg);
      }

      .serach-close{
        border-radius: 50%; 
        border: 1px solid var(--btn-bg);
        svg{
          fill: var(--btn-bg);
        }
      }
      
      .form-hover{
          width: 100%;
          cursor: pointer;
          border: none;
      }
      
      .form-hover{
         input{
          display: block;
        }
        ul{
          display: block !important;
        }
      }  
      
      ul{
        top: 30px !important;
        border-radius: 4px !important;
        display: none !important;
      }
      .close {
        float: right;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: .5;
        padding-right: 5px;
      }
    }
    .MuiTabs-flexContainer .MuiTab-textColorPrimary{
      text-transform: capitalize;
    }
    .MuiTabs-flexContainer .Mui-selected{
      color: var(--btn-bg) !important;
    }
    .MuiTabs-indicator{
      background-color: var(--btn-bg) !important;
    }
}
.recommendation {
  .recommendation-form-label{
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
  }
  select, input, .recommendation-form-input{
    font-family: Inter;
    font-size: 12.64px;
    font-weight: 600 !important;
    line-height: 24px !important;
    text-align: left !important;
  }
  .react-datepicker__time-container {
    border: none !important;
    // .react-datepicker__header{
    //   height: 78.5px;
    //   padding: 28px
    // }
  }
  .date-field {
    .input-container{
      padding: 2px !important
    }
    .input-container .react-datepicker__input-container  .form-control{
      padding: 5px 1px;
    }
  }  
  .drawer-warning{
    top: 50% !important;
    max-height: 700px;
    min-height: 400px;
    overflow-x: auto;
    border-radius: 0px !important;
  }

}

.surveyHeader {
  width: 100%;
} 
// .history_recommend_styles .react-bootstrap-table {
//   // overflow-x: scroll;
//   // width: 200%;
//   td{
//     width: 10%;
//   }
//   td:nth-child(2){
//     width: 20%;
//   } 
//   td:nth-child(4){
//     width: 15%;
//   }
//   td:nth-child(5){
//     width: 15%;
//   }
//   td:nth-child(6){
//     width: 20%;
//   }
//   td:nth-child(7){
//     width: 15%;
//   } 
//   td:nth-child(8){
//     width: 15%;
//   } 
//   td:nth-child(9){
//     width: 4%;
//   } 
//   table{
//     height: 80%;
//     thead{
//       height: 10%;
//       position: sticky
//     }
//     tbody{
//       height: 70%;
//       overflow: auto;
//     }
//   }
// }
  .treatment-plan-list .drawer-warning {
    top: 9%;
    right: -322px;
    left: auto;
  }

@media (max-width: 768px) {
  .treatment-plan {
    .header {
      height: 7%;
      .heading-1 {
        font-size: 16px;
      }
    }  
    .add-btn {
      color: #ffffff;
      padding-left: 10px;
      padding-right: 10px;
      height: 20px !important;
      width: auto !important;
      padding-top: 2px;
      border-radius: 3px !important;
      background: var(--btn-bg);
      font-size: 10px;
    }  

    .medication-list{
      height: 20px;
      background: transparent !important;
      padding-top: 2px;
      padding-block: 2px;
      padding: 2px;
      font-size: 10px;
    }

   .recommendation-list .recommendation-body {
      .recommendation-body-text {
        font-size: 11px;
      }
      .recommendation-indicator, .recommendation-indicator-expanded  {
        font-size: 10px;
      }
      .jw-recommendation-toggle img,i {
        width: 16px;
        height: 16px;
        font-size: 17px !important;
      }
      .heading-1 {
        font-size: 12px !important;
      } 
      .text-1{
        font-size: 11px !important;
      }
      .recommendation-body-text-expanded {
        font-size: 11px;
        padding-right: 55px;
      }
   }
  }
}
@media (max-width: 767px) {
  .height-95{
    height: 95% !important;
  }
}
@media (max-width: 820px) {
  .questionnaireFormStyle {
    .surveyHeader {
      width: 100%;
    }
  }
  .historyRecommendFormStyle{
    .surveyHeader {
      width: 200%;
    }  
  }
  .history_recommend_styles .react-bootstrap-table {
    overflow-x: scroll;
    width: 200%;
    td{
      width: 10%;
    }
    td:nth-child(2){
      width: 20%;
    } 
    td:nth-child(4){
      width: 15%;
    }
    td:nth-child(5){
      width: 15%;
    }
    td:nth-child(6){
      width: 20%;
    }
    td:nth-child(7){
      width: 15%;
    } 
    td:nth-child(8){
      width: 15%;
    } 
    td:nth-child(9){
      width: 4%;
    } 
  }
}

.MuiButtonBase-root{
  padding-left: 8px !important;;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  outline: 0;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
  padding-top: 6px !important;
}

@media screen and (max-width: 1024px) and (max-height: 600px) {
  .height-95{
    height: 95% !important;
  }
}

@media only screen and (width: 1280px) and (height: 800px) {
  .height-95{
    height: 95% !important;
  }
}  

@media screen and (max-width: 1024px) and (min-height: 1366px) and (max-height: 1367px) {
  // .height-95{
  //   height: 95% !important;
  // }
  .treatment-plan .recommendation-list .recommendation-body {
    .recommendation-body-text-expanded {
      padding-right: 55px;
    }
  }
}    

@media (max-width: 820px) {
  .treatment-plan .recommendation-list .recommendation-body {
    .recommendation-body-text-expanded {
      padding-right: 55px;
    }
  }
}


.analytics-screen {
  &.beta {
    font-family: 'Noto Sans' !important;
    &.light-theme {
      background: $light-background;
      color: #333;    
      .treatment-plan { 
        background: $light-component-background;
        box-shadow: $light-component-box-shadow;
        .recommendation-list .recommendation-body {
          .recommendation-body-text .recommendation-indicator-expanded {
            color: #272727;
          }
          .recommendation-indicator{
            color: #170F49
          }
          .recommendation-body-text-expanded {
            color: var(--recommendation-body-text-expanded);
          }
          .heading-1, .text-1 {
            color: #6F6C90;
          }  
        }
        .MuiBox-root {
          width: 100%;
          margin-left: 0;
        }  
      }
      .provider-notes.treatment-plan.treatment-plan-list .drawer-warning{
        box-shadow: rgba(0, 0, 0, 0.57) 1px 1px 24px;
      }
    }

    &.dark-theme {
      background: $dark-background;
      color: #F1F1F1;
      .treatment-plan {  
        background: #161616;
        .MuiBox-root {
          background: #1F1f1F;
          width: 100%;
          margin-left: 0;
        }
        .recommendation-list .recommendation-body{
          background-color: #2F2F2F;
          border: 0.5px solid #7d7f84;
          .recommendation-body-text, svg{
            color: #F1F1F1;
          }
          .recommendation-body-text-expanded {
            color: var(--recommendation-body-text-expanded-d);
          }

          .recommendation-indicator-expanded, .recommendation-indicator{
            color: #F1F1F1;
          }
          .heading-1, .text-1 {
            color: #cccccc;
          }  
          .recommendation-info{
            color: #F1F1F1;
          }
        } 
       .header {
          background: transparent !important;
          border: none;
          .heading-1 {
            color: var(--page-heading-text-c-d);
          }
         .add-btn {
            width: 70px;
            height: 30px;
          }
        }

        .recommendation-search-field {
          .serach-close {
            border: 1px solid var(--btn-bg) !important;
            svg {
              fill: var(--btn-bg) !important;
            }
            
          }
          .search-open {
            border-left: 1.4px solid var(--btn-bg);
          }
          input {
            border: 1px solid var(--btn-bg);
            background-color: transparent !important;
            color: #DDDDDD !important
          }
          .dropdown-menu {
            color: #DDDDDD;
            text-align: left;
            list-style: none;
            background-color: $dark-component-background !important;
            background-clip: border-box !important;
            .dropdown-item {
              color: #DDDDDD;
            }
            .dropdown-item:hover {
              color: #DDDDDD;;
              text-decoration: none;
              background-color: #5B5B5B
           }
          } 
        }
        
        .MuiTabs-flexContainer .MuiTab-textColorPrimary{
          color: #F1F1F1 !important;
          text-transform: capitalize;
        }
        .MuiTabs-flexContainer .Mui-selected{
          color: var(--btn-bg-d) !important;
        }
        .MuiTabs-indicator{
          background-color: var(--btn-bg-d) !important;
        }
        .no-meta-recommendations {
          color:#F1F1F1 !important;
        }
        .no-meta-recommendations.card{
          background-color: #2F2F2F;
          border: 0.5px solid #7d7f84;
        }
      }
      .recommendation,
       .card-body-div  {
        background: $dark-component-background;
        box-shadow: $dark-component-box-shadow; 
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        .header{
          background: #2F2F2F;
          border: none;
          box-shadow: 0px 1px 12px 0px #00000021;
        }
        .header .bold-14-text{
          color: #F1F1F1;
        }
        .form-group label{
          color: #F1F1F1;
        }
        .form-control, .input-container{
          background-color: transparent;
          border: 1px solid #434343;
          color: #F1F1F1;
        }
        .input-container {
          img.icon {
            cursor: pointer;
            margin-left: -17px;
            filter: brightness(7);
          }
          .form-control{
             border: none;
          }
          .react-datepicker {
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 0.8rem;
            background-color: #1F1F1F;
            color: #000;
            border: 0px;
            border-radius: 0;
            display: inline-block;
            position: relative;
            border: 1px solid gray;
            display: flex;
          }
          .react-datepicker__header {
            text-align: center;
            background-color: #2f2f2f;
            border-bottom: 0px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            padding-top: 8px;
            position: relative;
          
          }
          
          .react-datepicker__today-button {
            background: #2f2f2f;
            border-top: 0px;
            cursor: pointer;
            text-align: center;
            font-weight: bold;
            padding: 5px 0;
            clear: left;
          }
          .react-datepicker__header select,  .react-datepicker__time-list{
            background: #2f2f2f;
            color: white;
          } 
          .react-datepicker__day-names, .react-datepicker__week, .react-datepicker__day, .react-datepicker__day-name {
            white-space: nowrap;
            color: white;
          }
          .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
            color: white;
          }
          .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, li.react-datepicker__time-list-item:hover {
            border-radius: 0.3rem;
            background-color: #f0f0f03d;
          }
          .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled{
            color: #cccccc94 !important;
          }
        }
        .dropdown-menu {
          color: #DDDDDD;
          text-align: left;
          list-style: none;
          background-color: $dark-component-background !important;
          .dropdown-item {
            color: #DDDDDD;
          }
          .dropdown-item:hover {
            color: #DDDDDD;;
            text-decoration: none;
            background-color: #5B5B5B
         }
        }
        .cancle-btn {
          color: #F1F1F1;
          border: 1px solid #949191
        }
        .alert-danger {
          color: #e74c3c;
          background-color: transparent;
          border-color: none;
        }
      }
      .recommendation {
         .drawer-warning {
          background: $dark-component-background;
          .card-loader{
            background: $dark-component-background;
          }
          .card-loader:before {
            background-color: #9E9E9E;
            box-shadow: -48px 78px 0 -48px #9E9E9E, -51px 102px 0 -51px #9E9E9E;
           }
           .card-body-div{
            .form-group{
              #group{
                background-color: #1f1f1f;
              }
            }
          }
         } 
        .css-1kqie1h-control, .css-1xm9fka {
          background-color: #1f1f1f;;
          border-color: #434343;
        }
        .css-1nmdiq5-menu, .css-bral9o{
          background-color: #1f1f1f;;
        }  
        .css-15k3avv {
          background-color: #1f1f1f;
        }  
        .css-1wepk7h, .css-1b6mq8y {
          color: #DDDDDD;
          text-decoration: none;
          background-color: #5B5B5B;
        }
        .css-xp4uvy, .css-qr46ko, .css-1dimb5e-singleValue, .css-1kqie1h-control, .css-1w0eaas-control{
          background-color: transparent;
          color: #F1F1F1;
        }
        .rbt .rbt-input-hint{
          color: #dee2e685 !important;
        }
        .css-9uewtm-option, .css-14ckrh4-option{
          background-color: #5B5B5B;
        }
        .dropdown-item mark, .dropdown-item mark .rbt-highlight-text {
          padding: 0.5em;
          background-color: #1f1f1f !important;
          color: white;
          border: 1px solid #434343;
          border-radius: 4px;
        }
        .rbt-menu-custom-option{
          .dropdown-item{
            background-color: transparent !important;
            color: #ffffff;
          }
        }
      }
      .provider-notes.treatment-plan.treatment-plan-list .drawer-warning{
        border: var(--category-data-div-b-d);
        box-shadow: -4px 5px 10px 0px #00000040;
        box-shadow: var(--category-data-div-bs-d);
      }  
    }
  }
}
#historic_recommendations.dark-theme {
  background: $dark-component-background;
  box-shadow: $dark-component-box-shadow; 
  .surveyHeader {
    background: #2F2F2F;
    border: none;
    box-shadow: 0px 1px 12px 0px #00000021;
    color: #ffffff;
  }
 .card-body, .card {
  background: $dark-component-background;
  box-shadow: $dark-component-box-shadow; 
  .react-bootstrap-table
   .table {
    color: #F1F1F1;
  }
 
  thead tr , tbody tr {
    border-width: 0.5px, 0px, 0.5px, 0px;
    border-style: solid;
    border-color: #5B5B5B;
    border-left: none;
    border-right: none;
  }
  thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
  }
  tbody tr, .table-striped tbody tr:nth-of-type(odd) {
    color: #F1F1F1 !important;
    background-color: $dark-component-background;
  }
  .table-striped tbody tr:nth-of-type(odd):hover, .table-hover tbody tr:hover {
    color: #F1F1F1 !important;
    background-color: #5B5B5B
  }
  .recm-history-search>input{
    background: #1f1f1f;
    border: 1px solid gray;
    color: white;
  }
 }  

}

.fa-play-circle{
  color: var(--surveyHeader-bg)
}

.problem{
   .alert-danger {
    height: 41px !important;
    overflow-x: auto;
  }
  .react-datepicker{
    display: flex;
  }
  .drawer-warning{
    top: 50% !important;
    max-height: 700px;
    min-height: 400px;
    overflow-x: auto;
    border-radius: 0px !important;
  }
  .notes textarea.form-control {
    min-height: 60px;
  }
  .custom-input input{
    height: 38px;
    border-color: rgb(211, 211, 211);
    border-width: 1px;
    cursor: default;
  }
  .rbt-input-main{
    font-family: Inter !important;
    font-size: 12.64px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    text-align: left !important; 
  }
  .dropdown-menu{
    border-radius: inherit;
    max-height: 304px !important;
    overflow: auto;
    will-change: transform;
    // width: 280px !important;
  }

  input[type="text"]::-webkit-input-placeholder {
    font-weight: 400 !important;
    color: hsl(0,0%,50%) !important;
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
  }  
  .css-xp4uvy {
    font-family: Inter !important;
    font-size: 12.64px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    text-align: left !important;
  }
  ul.rbt-menu li{
    padding: 3px 0px;
  }
  .dropdown-item:hover{
    background-color: #DEEBFF;
  }
  .form-control:focus {
    outline: 0;
    border-color: #80bdff;
    box-shadow: none;
  }

}
.analytics-screen{
  .dropdown-item{
    white-space: normal !important;
  }
  .dropdown-item:hover, .dropdown-item:focus{
    font-weight: bold;
  }
}  
