.toaster {
    z-index: 9999;
    
    .fade{
        transition: opacity .15s linear;      
    }
    .toast{
        border: 1px solid #dc3545;
    }
    .toast-header{
        border-bottom: 1px solid #dc3545;
    }
    position: absolute;
    top: 80px;
    right: 20px;
    .close-btn {
        margin: 5px;
        cursor: pointer;
    }
    a{
        color: #007DBC !important;
    }
    a:hover{
        color: #0056b3 !important;
    }
    max-width: 16rem;
    .close-toaster-menu{
        display: flex;
        justify-content: space-between;
    }
}
.toaster-right {
    position: fixed;
    animation: animateright 0.4s;
}
@keyframes animateright {
    from {
        right: -300px;
    }
    to {
        right: 0;
    }
}
.toaster-left.fade {
    transition: opacity 0.4s linear;
    animation: animateleft 0.4s;
    .close-btn,.toast{
        pointer-events: none;
    }
}
@keyframes animateleft {
    from {
        right: 0;
    }
    to {
        right: -300px;
    }
}


