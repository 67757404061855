.icon {
  &.fill {
    fill:currentColor;
  }
  &.stroke {
    stroke:currentColor;
  }
  &.xs {
    margin-right:$gap-xs;
    svg {
      width:$gap-xs;
      height:$gap-xs;
    }
  }
  &.sm {
    margin-right:$gap-xs;
    svg {
      width:$gap-sm;
      height:$gap-sm;
    }
  }
  &.md {
    margin-right:$gap-xs;
    svg {
      width:16px;
      height:16px;
    }
  }
  &.lg {
    svg {
      width: 32px;
      height: 32px;
    }
  }
  &.xl {}
}
