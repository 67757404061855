.analytics-screen{
    .user-settings{
        font-size: 14px;
        .details-row{
            padding-bottom: 20px;
            padding-left: 0px;
        }
        .label{
            font-size: .9em;
        }
        .value{
            padding-top: 5px;
            font-size: 1em;
            font-weight: 600;
            overflow-wrap: anywhere;
        }
        .edit-icon{
            justify-content: flex-end;
            display: flex;
            cursor: pointer;
        }
        .user-details{
           padding: 1.5em !important;
        }
        .bb-color{
         border-bottom: 0.5px solid #8080804f;
        } 
        .heading-1{
            font-size: 1.5em;
            font-weight: 600;
            width: 100%;
            padding-top: 10px;
        }
        .heading-2{
            font-size: 1em;
            font-weight: 600;
            width: 100%;
        }
        .heading-3{
            font-size: 0.9em;
            font-weight: 400;
            width: 100%;
            color: #444
        }
        .no-gutters{
            padding-left: 0px;
            padding-right: 0px;
        }
        .bb-5{
            border-bottom: 0.5px solid #8080804f;
        }
        .btn-primary{
            height: 29px;
            width: 70px;
            padding: 0;
        }
        .f-w-600{
            font-weight: 600;
        }
        .card{
            min-width: 100% !important;
        }
    }

}

.analytics-screen {
    &.beta {
      font-family: 'Noto Sans' !important;
      &.light-theme {
        background: $light-background;
        color: #333;
        .user-settings{
            .heading-3{
                color: #444
            }
            .user-icon-color{
                color: var(--btn-bg) !important;
            }
        }
      }
      &.dark-theme {
        background: $dark-background;
        color: #F1F1F1;
        .user-settings{
            .heading-3{
                color: #ffffff99
            }
            .user-icon-color{
                color: var(--btn-bg-d) !important;
            }
        }
      }  
    }
}       

@media (max-width: 768px) {
    .analytics-screen{ 
        .user-settings{
            font-size: 10px;
            .user-details{
                padding: 0.5em !important; 
             }
        }
    }
}