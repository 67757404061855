// .btn-exit {
//   height:100%;
//   border-left:1px solid rgba(white,0.1);
//   padding-left:$gap-sm;
//   width:96px;
//   line-height:1;
//   display:flex;
//   font-size:0.9rem;
//   align-items:center;
//   color:rgba(white,0.5);
//   @include font-semibold;
//   margin-left:$gap;
//   @media (min-width:$screen-sm-ls) {
//     padding-right:$gap;
//     width:140px;
//   }
//   svg {
//     width:1.25rem;
//     height:1.25rem;
//     min-width:1.25rem;
//     min-height:1.25rem;
//     margin-right:$gap-sm;
//     fill:currentColor;
//     overflow:visible;
//   }
//   &:hover {
//     text-decoration:none;
//     color:white;
//     svg {
//       transform:scale(1.2);
//     }
//   }
// }

// .main-nav {
//   position:fixed;
//   top:0;
//   z-index:49;
//   width:100%;
//   margin-top:-$primary-nav-height;
//   left:0;
//   background:white;
//   box-shadow: 0 1px 3px rgba(51, 51, 51, 0.12), 0 1px 2px rgba(51, 51, 51, 0.24);
//   ~ .sidebar-left {
//     display:block;
//     right:-$sidebar-width;
//     transition:all 0.15s;
//   }
//   + input[type="checkbox"] {
//     position: fixed;
//     margin: -1px;
//     border: 0;
//     padding: 0;
//     width: 1px;
//     height: 1px;
//     overflow: hidden;
//     clip: rect(0 0 0 0);
//     text-transform: none;
//   }
//   @media (max-width:$screen-sm-ls) {
//     + input[type="checkbox"]:checked {
//       + .sidebar-left {
//         right:0;
//       }
//     }
//   }
//   .max-container {
//     display:flex;
//     align-items:center;
//     @media (max-width: $screen-lg) and (min-width: $screen-sm) {
//       padding:0 $gap;
//     }
//   }
//   .primary,.secondary {
//     display:flex;
//     background:transparent;
//   }
//   .primary {
//     height:60px;
//     position:relative;
//     @media (min-width:$screen-sm) {
//       height:$primary-nav-height;
//     }
//   }
//   .brand-logo {
//     margin-right:auto;
//     @media (min-width:$screen-sm) {
//       padding-left:$gap-sm;
//     }
//     img {
//       max-width: 100%;
//       height: auto;
//     }
//   }
//   .nav-logo {
//     @media (max-width:$screen-sm-min) {
//       max-width:175px;
//     }
//   }
//   nav.primary {
//     background-color: $blue2;
//     a.active {
//       color: white;
//       background-color: darken($blue2,10%);
//     }
//   }
//   .btn-exit {
//     @media (max-width:$screen-sm-ls) {
//       display:none;
//     }
//   }
//   .header-breadcrumb + .header-breadcrumb {
//     padding-left: $gap-md;
//   }
//   .header-breadcrumb {
//     //padding-right: 5rem;
//     line-height:1.2;
//     color:white;
//     @media (max-width:$screen-sm-ls) {
//       display:none;
//     }
//     .patient-name {
//       font-size: 1.5rem;
//       @include font-bold;
//     }
//     .strong {
//       @include font-bold;
//       font-size:1.1rem;
//     }
//   }
//   .visit-breadcrumb {
//     .patient-name {
//       font-size: 1.2rem;
//       line-height: 25px;
//     }
//     .patient-age {
//       color: rgba(white,0.7);
//     }
//   }
// }




// .mobile-nav-toggle-input {
//   position: absolute;
//   margin: -1px;
//   border: 0;
//   padding: 0;
//   width: 1px;
//   height: 1px;
//   overflow: hidden;
//   clip: rect(0 0 0 0);
//   text-transform: none;
//   &:checked {
//     ~ .slide-in-nav {
//       max-width:300px;
//       overflow:visible;
//     }
//   }
// }

// .slide-in-nav {
//   position:fixed;
//   transition:all 0.15s;
//   left:0;
//   top:0;
//   max-width:0;
//   overflow:hidden;
//   height:100%;
//   z-index:48;
//   background:#485965;
//   padding: 2rem 0;
//   box-shadow:0 0 10px rgba(0, 0, 0, 0.32);
//   color:white;
//   a {
//     color:white;
//     white-space:nowrap;
//   }
//   ul > li > a {
//     padding-left:$gap;
//     padding-right:$gap;
//   }
// }


// .mobile-nav-toggle-btn {
//   color:white;
//   display:none;
//   margin:0;
//   @media (max-width:$screen-sm-ls) {
//     display:flex;
//     align-items:center;
//   }
//   > span {
//     margin-right: $gap-sm;
//     text-transform: uppercase;
//     font-weight: bold;
//     font-size: 1.2rem;
//   }
//   svg {
//     width:2.5rem;
//     height:2.5rem;

//   }
// }
.btn-exit {
    height:100%;
    border-left:1px solid rgba(white,0.1);
    padding-left:$gap-sm;
    width:96px;
    line-height:1;
    display:flex;
    font-size:0.9rem;
    align-items:center;
    color:rgba(white,0.5) !important;
    @include font-semibold;
    margin-left:$gap;
    @media (min-width:$screen-sm-ls) {
      padding-right:25px;
      width:140px;
    }
    svg {
      width:1.25rem;
      height:1.25rem;
      min-width:1.25rem;
      min-height:1.25rem;
      margin-right:$gap-sm;
      fill:currentColor;
      overflow:visible;
    }
    &:hover {
      text-decoration:none;
      color:white !important;
      svg {
        transform:scale(1.2);
      }
    }
  }
.btn-analytics-beta {
  height:100%;
  border-left:1px solid rgba(white,0.1);
  padding-left:$gap-sm;
  // width:96px;
  line-height:1;
  display:flex;
  font-size:0.9rem;
  align-items:center;
  color:rgba(white,0.5) !important;
  @include font-semibold;
  margin-left:$gap;
  @media (min-width:$screen-sm-ls) {
    // padding-right:25px;
    // width:140px;
  }
  &:hover {
    text-decoration:none;
    color:white !important;
    svg {
      transform:scale(1.2);
    }
  }
}  
  
  .main-nav {
    //  display: none;
    position:fixed;
    position: sticky;
    top:0;
    z-index: 1000;
    width:100%;
    // margin-top:-$primary-nav-height;
    left:0;
    background:white;
    // box-shadow: 0 1px 3px rgba(51, 51, 51, 0.12), 0 1px 2px rgba(51, 51, 51, 0.24);
    ~ .sidebar-left {
      display:block;
      // right:-$sidebar-width;
      transition:all 0.15s;
    }
    + input[type="checkbox"] {
      position: fixed;
      margin: -1px;
      border: 0;
      padding: 0;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      text-transform: none;
    }
    @media (max-width:$screen-sm-ls) {
      + input[type="checkbox"]:checked {
        + .sidebar-left {
          right:0;
        }
      }
    }
    .max-container {
      display:flex;
      align-items:center;
      width:100%;
      @media (max-width: $screen-lg) and (min-width: $screen-sm) {
        padding:0 $gap;
      }
    }
    .primary,.secondary {
      display:flex;
      background:transparent;
    }
    .primary {
      height:60px;
      position:relative;
      @media (min-width:$screen-sm) {
        height:$primary-nav-height;
      }
    }
    .brand-logo {
      margin-right:auto;
      @media (min-width:$screen-sm) {
        padding-left:$gap-sm;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .nav-logo {
      height:50px!important;
      @media (max-width:$screen-sm-min) {
        max-width:175px;
      }
    }
    nav.primary {
      background-color: $blue2;
      &.beta{
        background-color:  #1F526B;
      }
      a.active {
        color: white;
        // background-color: darken($blue2,10%);
      }
    }
    .btn-exit, .btn-analytics-beta {
      @media (max-width:$screen-sm-ls) {
        display:none;
      }
    }
    .header-breadcrumb + .header-breadcrumb {
      padding-left: $gap-md;
    }
    .header-breadcrumb {
      //padding-right: 5rem;
      line-height:1.2;
      color:white;
      @media (max-width:$screen-sm-ls) {
        display:none;
      }
      .patient-name {
        font-size: 1.5rem;
        @include font-bold;
      }
      .strong {
        @include font-bold;
        font-size:1.1rem;
      }
    }
    .visit-breadcrumb {
      .patient-name {
        font-size: 1.2rem;
        line-height: 25px;
      }
      .patient-age {
        color: rgba(white,0.7);
      }
    }
  }
  .mobile-nav-toggle-input {
    position: absolute;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    text-transform: none;
    &:checked {
      ~ .slide-in-nav {
        max-width:300px;
        overflow:visible;
      }
    }
  }
  
  .slide-in-nav {
    position:fixed;
    transition:all 0.15s;
    left:0;
    top:0;
    max-width:0;
    overflow:hidden;
    height:100%;
    z-index:48;
    background:#485965;
    padding: 2rem 0;
    box-shadow:0 0 10px rgba(0, 0, 0, 0.32);
    color:white;
    a {
      color:white;
      white-space:nowrap;
    }
    ul > li > a {
      padding-left:$gap;
      padding-right:$gap;
    }
  }
  
  
  .mobile-nav-toggle-btn  {
    color:white;
    display:none;
    margin:0;
    cursor: pointer;
    @media (max-width:$screen-sm-ls) {
      display:flex;
      align-items:center;
    }
    > span {
      margin-right: $gap-sm;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
  // @keyframes fadein {
  //   from {opacity: 0;transform: scale(1,4)}
  //   to {opacity: 1}
  // }

.fade-in-element {
  // opacity: 1;
  //   position: sticky;//to avoid flickering in edge
  position: sticky;
  z-index: 9997;
  animation: slideDown .5s;
 }
 @keyframes slideDown{
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
 .button-menu{
   border:none;
   background: none;
   display: none;
   @media (max-width:$screen-sm-ls) {
    display:block;
  }
 }
 .button-menu:focus{
   outline: none;
 }
 .mobile-nav-toggle-btn.vendor{
   color:#2980B9;
 }
