.corrections-section {
    &.container .col-lg-6,
    &.container .col-lg-9 {
        padding: 0 15px 0 7px;
        .react-bootstrap-table {
            border: 1px solid #dee2e6 !important;
            background-color: white;
            border-radius: 4px;
            th {
                justify-content: left;
            }
            th,
            td {
                @media (max-width: 768px) {
                    min-width: 10%;
                    max-width: 40%;
                    width: 40%;
                    &:nth-child(2) {
                        min-width: 12%;
                        max-width: 10%;
                        @media (max-width: 375px) {
                            min-width: 15%;
                            max-width: 15%;
                            word-break: break-word;
                        }
                    }
                }
            }
        }
    }
    .individual-correction {
        list-style: none;
    }
}
.corrections-table {
    @media (max-width: 530px) {
        // max-width: 80%;
        .bootstrap-table-container {
            // max-width: 100%;
            overflow: auto;
            border: 1px solid #ccc;
        }
        .react-bootstrap-table {
            width: 700px;
            table {
                table-layout: auto;
            }
        }
    }
    @media (max-width: 530px) {
        max-width: 500px;
    }
    @media (max-width: 500px) {
        max-width: 470px;
    }
    @media (max-width: 450px) {
        max-width: 420px;
    }
    @media (max-width: 420px) {
        max-width: 390px;
    }
    @media (max-width: 375px) {
        max-width: 345px;
    }
    @media (max-width: 360px) {
        max-width: 340px;
    }
    @media (max-width: 320px) {
        max-width: 300px;
    }
    @media (max-width: 300px) {
        width: 92%;
    }
}
@media (max-width: 1024px) {
    .corrections-section {
        &.container .col-lg-9 {
            padding-left: 5px;
        }
    }
}
@media (max-width: 992) {
    .corrections-section {
        &.container .col-lg-9 {
            padding-left: 0;
        }
    }
}
