@font-face {
    font-family: "Sarala";
    font-weight: normal;
    src: url("../assets/fonts/sarala/Sarala-Regular.ttf");
}

@font-face {
    font-family: "Sarala";
    font-weight: 700;
    font-weight: bold;
    src: url("../assets/fonts/sarala/Sarala-Bold.ttf");
}
