@import "../../../styles/variables.scss";

.patients-filter {
    .search-btn {
        width: 100%;
    }
    label {
        font-weight: 400;
        color: #777;
        font-size: 14px;
    }
}
.reports-download-section {
    margin-top: 5em;
    @media (max-width: 1024px) {
        margin-top: 15em;
    }
}
.no-padding-json{
    padding: 0!important;
}
.patient-document-details{
    pre{
        white-space: pre-wrap;
        font-size: 100%!important;
    }
}
.adjustRightMargin{
    margin-right: 53px;
}
// .view-patient {
    // margin-left: 1% !important;
    // @media (max-width: 1610px) {
    //     margin-left: -15px !important;
    // }
    // @media (max-width: 1440px) {
    //     margin-left: 1% !important;
    // }
    // @media (max-width: 1280px) {
    //     margin-left: 2% !important;
    // }
    // @media (max-width: 1024px) {
    //     margin-left: 0% !important;
    // }
    // .col-lg-9 {
    //     padding: initial;
    // }
    .side-panel {
        padding-right: 15px;
    }
    iframe {
        border: none;
        padding: 15px;
    }
    .patients-visits-list {
        img {
            padding: 0;
            width: 150px;
            height: 75px;
        }
    }
// }
.add-patient-btn-sec {
    margin: 15px 0px;
    .btn{
        width: 100%;
    }
}

.patient-details {
    width: 100%;
    .form-group span {
        display: flex;
    }
}
.patients-list-section table {
    border-top: none;
    td {
        padding: 12px 8px !important;
    }
    .patient-fullname {
        word-break: break-word;
    }
}
.patients-list-section {
    legend {
        text-align: center;
    }
    .patient-stats {
        padding: 15px 25px;
        margin: 0;
        border-bottom: none !important;
    }
    .react-bootstrap-table:nth-child(2) {
        border-top: none !important;
    }
}

.upload-patient-document-table{
    min-height: 700px;
}

// .patients-visits-list{
// .highcharts-container{
//     height: auto!important;
//         width: auto!important;
//     g.highcharts-axis.highcharts-xaxis{
//         display: none;
//     }
//     >svg{
//         width: 70px;
//         height: 70px;
//     }
//     tspan{
//                 display: none;
//         }
// }
// }

.patient-form {
    .react-datepicker-wrapper {
        .form-control.col-lg-7 {
            width: 58.3%;
        }
    }
    // left: 15%;
    // @media (max-width: 1024px) {
    //     left: 10%;
    // }
    // @media (max-width: 992px) {
    //     left: 0%;
    // }
    .form-group {
        display: flex;
    }
    label {
        min-width: 150px;
    }
    .providers-selection {
        .input-entry {
            display: flex;
        }
        i.fa.fa-refresh {
            color: #31708f;
            margin: 10px;
        }
    }
    @media(max-width:360px){
        margin-left: -15px!important;
    }
}
.patient-analytics-nav-sec {
    margin-bottom: 2%;
    display: flex;
    padding: 0 3%;
    button {
        margin-top: 15px;
    }
    div {
        padding: 15px;
        vertical-align: middle;
    }
    .navigate-analytics {
        width: 33.333333%;
        flex: 0 0 33.333333%;
    }
    svg {
        width: 100%;
        // height: 150px;
    }
    .card{
        box-shadow: none;
    }
    .panel-info{
        border-color: #bce8f1;
        box-shadow: none;
    }
}
.view-patient-btn-sec {
    display: grid;
    button {
        margin-bottom: 2%;
    }
}

.patient-details-section {
    margin-bottom: 2%;
    table {
        td,
        th {
            padding: 0 8px;
            vertical-align: top;
        }
    }
    a {
        color: #008fbc !important;
    }
    a:active {
        color: #0e4876 !important;
    }
}

.create-visit-form {
    .form-group {
        display: flex;
        label {
            white-space: nowrap;
        }
    }
    input {
        max-width: 100%;
    }
}
.label_visit {
    line-height: 5rem !important;
    font-weight: bold;
}
.update-email {
    button {
        width: 100%;
        margin-bottom: 2%;
    }
}
.patient-visit-data-entry {
    i {
        margin: 0px 2px;
    }
}
.aggregate-age-chart {
    padding: 0px !important;
    .card-header {
        margin: 0;
        text-align: center;
    }
    iframe {
        border: none;
        padding: 15px;
    }
}
.modal-body {
    .highcharts-axis-labels.highcharts-xaxis-labels {
        text {
            text-transform: capitalize;
        }
    }
}
.time-point-col {
    white-space: nowrap;
}
#react-paginate ul {
    display: inline-block;
}

#react-paginate {
    @media (max-width: 768px) {
        margin-left: 5px;
    }
    .previous {
        border-left: 1px solid #ccc;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
    }
    .next {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
    }
    li {
        z-index: 0;
        display: inline-block;
        color: #337ab7;
        &:focus {
            color: #337ab7;
        }
        &.active .page-link {
            background-color: #337ab7 !important;
            border-color: #337ab7;
            color: #ffffff !important;
            z-index: 0;
        }
    }
}
.patient-jumb{
    &.jumbotron p, &.jumbotron li {
        line-height: 1.4em;
    }
    &.jumbotron p {
        margin-bottom: 15px;
        font-size: 21px;
        font-weight: 200;
    }
    &.jumbotron li {
        font-size: 21px;
        font-weight: 200;
    }
}
.patient-documents{
    margin: 1rem;
}
@media (max-width: 2560px) {
    // .card-visit {
    //     width: 1000px;
    //     .patients-visits-list .react-bootstrap-table {
    //         width: 1000px !important;
    //     }
    // }
    .patient-analytics-nav-sec {
        div:not(:first-child) {
            margin-left: 2%;
        }
    }
}
@media (max-width: 1920px) {
    // .card-visit {
    //     width: 920px;
    //     .patients-visits-list .react-bootstrap-table {
    //         width: 920px !important;
    //     }
    // }
    .patient-analytics-nav-sec {
        div:not(:first-child) {
            margin-left: 3%;
        }
    }
}
@media (max-width: 1320px) {
    // .card-visit {
    //     width: 820px;
    //     .patients-visits-list .react-bootstrap-table {
    //         width: 820px !important;
    //     }
    // }
    // .view-patient {
    //     .col-lg-9 {
    //         padding: 0 5px;
    //     }
    // }
    .patient-analytics-nav-sec {
        // div {
        //     margin-left: 2%;
        // }
        .col-lg-4 {
            width: 25%;
            flex: 0 0 25%;
        }
    }
}
@media (max-width: 1200px) {
    .gender-sec {
        margin: auto;
    }
    // .card-visit {
    //     width: 680px;
    //     .patients-visits-list .react-bootstrap-table {
    //         width: 680px !important;
    //     }
    // }
    .patient-analytics-nav-sec {
        div {
            // margin-left: 1%;
            padding: 10px;
        }
        svg {
            width: 100%;
        }
    }
}

@media (max-width: 1024px) {
    // .view-patient {
    //     padding-left: 1%;
    //     .col-lg-3,
    //     .col-lg-12 {
    //         padding: inherit;
    //     }
    // }
    .patient-analytics-nav-sec {
        svg {
            width: 100% !important;
            // height: 140px!important;
        }
    }
}
@media (max-width: 992px) {
    // .view-patient {
    //     padding-left: 0%;

        .side-panel {
            padding-right: 0%;
        }
    // }
    .patient-form {
        left: 0%;
        .react-datepicker-wrapper {
            .form-control.col-lg-7 {
                width: 100%;
            }
        }
    }
    .patient-analytics-nav-sec {
        // div {
        //     margin-right: 0%;
        // }
        svg {
            width: 100% !important;
            height: 100px !important;
        }
    }
}
@media (max-width: 768px) {
    .patients-visits-list img {
        width: 95px;
        height: 50px;
    }
    .patients-table {
        th:nth-child(n + 3) {
            display: none;
        }
        td:nth-child(n + 3) {
            display: none;
        }
    }
    .patients-list-section,
    .patients-visits-list {
        thead {
            display: none;
        }
        .react-bootstrap-table:nth-child(2) {
            border-top: 1px solid #cccccc !important;
        }
    }
    .patients-list-section {
        margin-bottom: 5%;
        tbody tr {
            display: grid;
        }
    }
    .card-visit {
        width: initial;
        .patients-visits-list .react-bootstrap-table {
            width: initial !important;
            th:nth-child(2) {
                display: none;
            }
            td:nth-child(2) {
                display: none;
            }
            th:nth-child(n + 3) {
                display: block;
            }
            td:nth-child(n + 3) {
                display: block;
            }
        }
    }
    .patients-component{
        .col-lg-12.col-md-12.col-sm-12.col-xs-12{
            padding: 0!important;
        }
    }
}

@media (max-width: 498px) {
    // .view-patient {
        // padding: 0px 0px !important;
        .patients-visits-list img {
            width: 95px;
            height: 50px;
        }
    // }
    .gender-sec.d-flex.justify-center {
        display: block;
    }
    .card-visit {
        width: initial;
        .patients-visits-list .react-bootstrap-table {
            width: initial !important;
        }
    }
}
#patientDeleteBtn {
    background: #d9534f;
}
.delete-document{
    svg{
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
}

.card-visit{
    .b-b-light{
        border-bottom: 1px solid #dee2e6;
        background-color: #eee;
    }
    .jumbotron p, .jumbotron li {
        font-size: 20px !important;
    }
    .jumbotron {
        padding: 1rem 1rem !important;
    }
}
.file-format-text{
    margin-left: 3px;
    font: initial;
}