.detail-list {
  //margin-bottom:$gap;
  //@media (min-width:$screen-sm) {
  //  display:flex;
  //}
  .title {
    text-transform:uppercase;
    //@include font-bold;
    //text-align:right;
    //width:100px;
    //padding-right:$gap-md;
    font-size:1rem;
    margin-bottom:$gap-xs;
    @include font-semibold;
    color:#828181;
  }
  .result,.item-result {
    //padding-left:$gap-md;
    @include font-semibold;
  }
  .item-result {
    font-size:1.6rem;
  }
}
.vertical-list {
  margin-top:$gap-sm;
  padding:0;
  list-style:none;
  > li {
    margin-bottom:$gap-sm;
    //@include shadow(1);
    border:1px solid #eee;
    padding:$gap-xs $gap-sm;
  }
  .item-name {
    font-size:1.2rem;
    margin-bottom:4px;
  }
  .detail {
    color:lighten($text-color,20%);
    font-size:0.9rem;
    > div {
      &:not(:first-of-type) {
        margin-top:3px;
      }
    }
  }
}
