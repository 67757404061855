.active-site-report{
    th.sortable{
    outline: none!important;
}
    &.col-lg-9{
        // left: 25%;
        // @media(max-width:1600px){
        //     left: 28%;
        // }
        // @media(max-width:1440px){
        //     left: 25%;
        // }
        // @media(max-width:1280px){
        //     left: 28%;
        // }
        // @media(max-width:1024px){
        //     left: 10%;
        // }
        // @media(max-width:992px){
        //     left: 0%;
        // }
        .react-bootstrap-table{
            width: inherit!important;
            border: 1px solid #dee2e6 !important;
            .table.table-hover{
                margin-bottom: 0px;
                text-align: center;
            }
        }
        .active-report-grid{
            margin: 20px;
            tr{
                display: table-row;
            }
            th{
                display: table-cell;
                border-bottom: 1px solid #dee2e6;
            }
        }
    }
    h2{
        margin-top: 15px;
    }
}

@media(max-width:1024px){
    .active-site-report.container.col-lg-9{
        left: 0;
    }
}