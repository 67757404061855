//sample- remove after completing dependency
.visit-summary-template{
    font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
}
.report-title{
    font-style: italic;
    color: #0093c2;
    margin-left: 5px;
}
.visit-summary-template .title{
    background-color: #0093c2;
    color: white;
    padding: 15px;
}
.recommendations-biomarker{
    display: flex;
    flex-wrap: wrap;
}
.jw-recommendation-flex{
    display:flex;
}
.visit-summary-sidebar{
    display: flex;
    flex-direction: column;
    position: fixed;
    border-top: 5px solid #0093c2;
    background-color: #f2f7fa;
    height: 100%;
    max-width: 25%;
}
.visit-summary-sidebar .summary-title{
    color: #0093c2;
}
.rec-division{
    color: #0093c2;
    margin: 30px 100px;
}
.visit-summary-right-section{
    margin-left: 25%;
}
// .biomarker .icon svg {
//     stroke: #0093c2;
//     color: #0093c2;
// }
// .jw-icon {
//     color: #0093c2;
//     margin-left:auto;
//     display: inline-block;
//     vertical-align: text-bottom;
//     align-items: center;
//   }
object{
    width: 100vw;
    height: 100vh;
}