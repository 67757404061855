.message-routing-component {
    &.parent-component {
        .col-lg-12.col-md-12.col-sm-12.col-xs-12.data-section {
            @media (max-width: 1024px) {
                margin-left: 15px;
            }
            @media (max-width: 768px) {
                margin-left: 0;
            }
        }
    }
    .side-panel {
        @media (max-width: 768px) {
            margin-top: 1% !important;
            margin-bottom: 1%;
        }
        @media (max-width: 530px) {
            .btn {
                width: 500px;
                margin-bottom: 1%;
            }
        }
        @media (max-width: 500px) {
            .btn {
                width: 470px;
            }
        }
        @media (max-width: 450px) {
            .btn {
                width: 420px;
            }
        }
        @media (max-width: 420px) {
            .btn {
                width: 390px;
            }
        }
        @media (max-width: 415px) {
            .btn {
                width: 380px;
            }
        }
        @media (max-width: 375px) {
            .btn {
                width: 345px;
            }
        }
        @media (max-width: 360px) {
            .btn {
                width: 340px;
            }
        }
        @media (max-width: 320px) {
            .btn {
                width: 300px;
            }
        }
        @media (max-width: 300px) {
            .btn {
                width: 280px;
            }
        }
    }
}
.message-routing-table {
    @media (max-width: 530px) {
        // max-width: 69%;
        .bootstrap-table-container {
            // max-width: 90%;
            overflow: auto;
            border: 1px solid #ccc;
        }
        .react-bootstrap-table {
            width: 800px;
            table {
                table-layout: auto;
            }
        }
    }

    @media (max-width: 530px) {
        max-width: 500px;
    }
    @media (max-width: 500px) {
        max-width: 470px;
    }
    @media (max-width: 450px) {
        max-width: 420px;
    }
    @media (max-width: 420px) {
        max-width: 390px;
    }
    @media (max-width: 415px) {
        max-width: 380px;
    }
    @media (max-width: 375px) {
        max-width: 345px;
    }
    @media (max-width: 360px) {
        max-width: 340px;
    }
    @media (max-width: 320px) {
        max-width: 300px;
    }
    @media (max-width: 300px) {
        max-width: 280px;
    }
}
.message-routing-children {
    .side-panel {
        padding-right: 15px;
    }
    .react-bootstrap-table {
        background-color: white;
        border: 1px solid #cccccc;
        border-radius: 4px;
        th {
            outline: none;
            &:focus {
                outline: none;
            }
        }
        th,
        td {
            word-break: break-word;
                width: 15%;
                &:nth-child(4)
                {
                    width: 11%;
                }
                &:nth-child(5)
                {
                    width: 11%;
                }
                &:nth-child(6)
                {
                   width: 14%;
                }
                &:nth-child(7)
                {
                   width: 10%;
                }
                &:nth-child(8)
                {
                   width: 4%;
                }
                &:last-child{
                    width: 5%;
                }
        }
    }

    form .form-group {
        margin-bottom: 1rem;
        margin-right: 19px;
    }
}
.message-routing-list {
    .btn-primary {
        width: 100%;
    }
    span.d-flex .btn {
        font-size: 12px;
        padding: 0px 5px;
        margin: 0px 5px;
    }
    .react-bootstrap-table {
        td,
        th {
            min-width: 10%;
        }
        td:last-child {
            padding: 8px 0;
        }
    }
}
.message-form {
    // left: 11%;
    .form-group {
        display: flex;
    }
    label {
        min-width: 150px;
    }
}
.copy-btn-routing{
    font-size: 20px;
    color: #007DBC !important;
}
@media (max-width: 992px) {
    .message-form {
        left: 0;
    }
    .msg-route-form-create-btn{
        padding: 0;
        white-space: nowrap;
    }
}

.add-rule-btn-sec {
    margin: 15px 0px;
    .btn{
        width: 100%;
    }
}
