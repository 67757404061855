.feedback-form textarea {
    // width: 70%;
    line-height: 100%;
    min-height: 100px;
}
// .feedback-form {
//     left: 12%;
//     @media (max-width: 1024px) {
//         left: 0;
//     }
//     @media (max-width: 768px) {
//         padding: 15px!important;
//     }
// }

