.grade-card {
  position: relative;
  height: 60px;
  width: 98%;
  padding: 0;
  margin: 6px 4px;
  display: inline-block;
  .grade-block {
    background-color: brown;
    position: absolute;
    top: 9px;
    left: 9px;
  }
  .no-grade {
    position: absolute;
    z-index: 2;
    top: 9px;
    left: 9px;
    height: 44px;
    width: 70px;
    color: #808080;
    background-color: #fff;
    font-size: 14px;
    text-align: center;
    border: 1px solid #CCC;
    border-radius: .25em;
    padding-top: 1px;
    transition: 0.2s ease;
    white-space: normal;
  }

  .description {
    font-size: 20px;
    left: 90px;
    position: absolute;
    top: $gap-xs;
  }

  .details, .reference {
    color: #352271 !important;
    font-size: 14px;
    left: 90px;
    position: absolute;
    top: 32px;
  }
}

.grade-block {
  border: none;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $gap-md;
  position: relative;
  text-align: center;
  width: $gap-md;
  white-space: nowrap;
  @include font-bold;
  &.lg {
    width:$gap-lg;
    height:$gap-lg;
    font-size: 1.3rem;
  }
}

.grade-Ap.grade-block {
  color: white;
  background-color: #2eb263;
}

.grade-A.grade-block {
  color: white;
  background-color: #37b45e;
}


.grade-Am.grade-block {
  color: white;
  background-color: #48b854;
}

.grade-Bp.grade-block {
  color: white;
  background-color: #59bc4a;
}

.grade-B.grade-block {
  color: white;
  background-color: #72c13b;
}

.grade-Bm.grade-block {
  color: white;
  background-color: #7bc336;
}

.grade-Cp.grade-block {
  color: white;
  background-color: #c7d40a;
}

.grade-C.grade-block {
  color: white;
  background-color: #D8D800;
}

.grade-Cm.grade-block {
  color: white;
  background-color: #cda60e;
}

.grade-Dp.grade-block {
  color: white;
  background-color: #c2771b;
}

.grade-D.grade-block {
  color: white;
  background-color: #ba5225;
}

.grade-Dm.grade-block {
  color: white;
  background-color: #b64129;
}

.grade-F.grade-block {
  color: white;
  background-color: #b2302e;
}
