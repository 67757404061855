@import '../../styles/variables.scss';

.header-section {
  background-color: $header-color!important;
  text-transform: uppercase;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 1px !important;
  .container{
    width: inherit;
  }
  .navbar {
    background-color: $header-color!important;
    .btn.feedback-btn{
      background-color: #a3e0ee;
      &:hover{
        background-color: #fff;
      }
    }
    @media(max-width:375px){
      justify-content: space-between;
    }
  }
  .dropdown-item{
    font-size: 14px;
    @media(max-width:768px){
      font-size: 12px;
    }
    @media(max-width:400px){
      font-size: 10px;
    }
  }
}
.collapse.navbar-collapse{
  white-space: nowrap;
}
.scroll-header {
  position: absolute;
}
.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.app-rendering{
    .fixed-header {
      position: fixed;
      // box-shadow: none!important;
    }
  }
.sidebar-toggle-btn {
  background-color: $header-color !important;
  border: none;
  outline: none;
  border: none;
  &:hover {
    background-color: $header-color !important;
    outline: none;
    border: none;
  }
  i {
    color: #000000;
  }
}

.navbar-brand{
    width: $logo-width;
    height: $logo-height;
    padding: 0px;
    font-size: 18px;
    line-height: 20px;
}
.header-menu-list{
  li{
    margin: 0 1em;
  }
  button{
    text-transform: uppercase;
  }
}
.feedback-btn{
  background-color: #a3e0ee;
  color: #444444;
  border: 0.5px solid #dddddd!important;
  outline: none;
  &:hover{
    background-color: #ffffff;
    color: #333;
    outline: none;
  }
}
.dropdown-item{
  cursor: pointer;
}
@media(max-width:1024px){
  .header-section {
    .container{
      display: flex;
      justify-content: center;
      width: 100%!important;
    }
  }
}

@media(max-width:400px){
  .container.navbar{
    display: table-row;
  }
}

@media(max-width:767px){

  .row.children-section{
    margin: 50px 0px 100px 0px !important;
  }
  .fixed-header {
      .btn.feedback-btn{
        width: 100%;
      }
    height: 60px;
    .collapsing{
      transition: none !important;
    }
  }
  .container.navbar{
    display: flex;
    justify-content: space-between;
  }
  .header-section .container{
    a{
      margin: 0;
    }
    display: flex;
    flex-direction: row !important;
    justify-content: space-evenly;
  }

}
@media(max-width:992px){
  .header-section .container{
    display: flex;
    flex-direction: column;
  }
  .row.children-section{
    margin: 85px 0px 100px 0px;
  }
}
@media(max-width:368px){
  .header-section{
    .container{
      justify-content: space-around;
    }
    .navbar-brand{
      width: 200px;
      height: 50px;
      img.logo-section{
        width: 200px;
        height: 50px;
      }
    }
  }
}
@media(max-width:270px){
  .header-section{
    .navbar-brand{
      width: 150px;
      height: 50px;
      img.logo-section{
        width: 150px;
        height: 50px;
      }
    }
  }
}
@media(max-width:200px){
  .header-section{
    .navbar-brand{
      width: 100px;
      height: 50px;
      img.logo-section{
        width: 100px;
        height: 50px;
      }
    }
  }
}