@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(50, 50);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(200, 200);
  }
}
