.php-violation h1{
    font-size: 3.2rem;
    font-weight: 500;
}
.php-violation p{
    margin: 0 0 10px;
    text-align: justify;
}
.spacer {
    display: block;
    height: 0.7em;
  }