@font-face {
    font-family: 'Concorde-SmallCaps';
    src: url('../../../assets/fonts/concorde-besc.woff2') format('woff2'),
         url('../../../assets/fonts/concorde-besc.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@mixin font-regular {
	font-weight:$weight-regular;
}

@mixin font-light {
	font-weight:$weight-light;
}

@mixin font-semibold {
	font-weight:$weight-semibold;
}

@mixin font-bold {
	font-weight:$weight-bold;
}

@mixin fontsize-xs {
	font-size:0.8rem;
}
@mixin fontsize-sm {
	font-size:0.9rem;
}
@mixin fontsize-md {
	font-size:1.225rem;
}
@mixin fontsize-lg {
	font-size:1.875rem;
}
@mixin fontsize-xl {
	font-size:2.25rem;
}
@mixin fontsize-xxl {
	font-size:2.75rem;
}

h1 {
	font-size:3.2rem;
	@include font-semibold;

}
h2 {
	font-size:2.4rem;
	@include font-semibold;
}
h3 {
	font-size:2rem;
	@include font-semibold;
}
h4 {
	font-size:1.8rem;
	@include font-semibold;
}
h5 {
	font-size:1.6rem;
	@include font-semibold;
}
h6 {
	font-size:1rem;
	@include font-semibold;
	text-transform:uppercase;
}

strong {
	@include font-semibold;
}

label {
	font-weight:$weight-semibold;
	color:#777;
}

.underline {
	text-decoration:underline
}

.txt-italic {
	font-style:italic;
}

.txt-reset {
	font-weight:normal;
	line-height:1;
}

.txt-uppercase {
	text-transform:uppercase;
}

.txt-small {
	font-size:85%;
}

.txt-date {
	color:rgba($text-color,0.7);
	font-size: 95%;
	font-style: italic;
	line-height: 1.2;
	margin-bottom: 5px;
}

.highlight {
	@include font-semibold;
	display:inline-block;
	background:rgba(yellow,0.4);
}

.txt-intro {
	font-size:16px;
	padding-bottom:$gap;
}

.txt-lg {
	font-size:22px;
}

.txt-gray {
	color:$text-medium;
}

.brand-error {
	color:$danger;
}

.field-label {
	display:block;
	margin-bottom:$gap-xs;
	color:$red;
	font-size:20px;
	line-height:1.2;
}

