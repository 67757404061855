.main-nav.vendor nav.primary {
  background-color: white;
  .header-breadcrumb {
    color:$blue2 !important;
    .strong {
      @include font-bold;
      font-size:1.1rem;
    }
  }
  a {
    color:$blue2 !important;
    @include font-bold;
    &.active {
      color: #5CBDA8;
    }
    &:hover {
      color: #BD5C9A !important;
    }
  }
}
