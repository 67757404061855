$drawer-width: 60vw;

@keyframes fade-in {
    from {
        background: rgba(white, 0);
    }

    to {
        background: rgba(#456, 0.93);
    }
}

@keyframes drawer-slide-out {
    0% {
        right: 0;
        width: $drawer-width;
    }

    50% {
        right: 0;
        width: $drawer-width + 2.5vw;
    }

    100% {
        right: -($drawer-width);
        width: $drawer-width;
    }
}

@keyframes drawer-slide-in {
    0% {
        right: -($drawer-width);
        width: $drawer-width + 2.5vw;
    }

    50% {
        right: 0;
        width: $drawer-width + 2.5vw;
    }

    100% {
        right: 0;
        width: $drawer-width;
    }
}

@keyframes drawer-slide-out-mobile {
    0% {
        right: 0;
        width: 100vw;
    }

    100% {
        right: -100vw;
        width: 100vw;
    }
}

@keyframes drawer-slide-in-mobile {
    0% {
        right: -100vw;
        width: 0;
    }

    100% {
        right: 0;
        width: 100vw;
    }
}


body.with-drawer {
    height: 100vh;
    overflow: hidden;

    //position:fixed;
    .drawer-shade {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;
        width: 100vw;
        height: 100vh;
        animation: fade-in 0.5s forwards;
    }
}

.drawer {
    //width: $drawer-width;
    //height: 100vh;
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transition: all 0.5s;
    overflow-y: scroll;
    z-index: 9999;
    overflow-x: hidden;

    .tab-pane.active {
        display: block;
        box-shadow: 0 0 0 rgba(51, 51, 51, 0.12), 0 4px 4px rgba(51, 51, 51, 0.24);
    }

    &.drawer-closed {
        @media (max-width: $screen-sm-ls) {
            animation: drawer-slide-out-mobile 0.35s forwards;
        }

        @media (min-width:$screen-md) {
            animation: drawer-slide-out 0.35s forwards;
        }
        .drawer-controls {
            display: none;
        }
        & { 
            right: -($drawer-width);
            width:$drawer-width;
        }
    }

    &:not(.drawer-closed) {
        @include shadow(5);

        @media (max-width: $screen-sm-ls) {
            animation: drawer-slide-in-mobile 0.55s forwards cubic-bezier(0.42, 0, 1, 1);
        }

        @media (min-width:$screen-md) {
            animation: drawer-slide-in 0.55s forwards cubic-bezier(0.42, 0, 1, 1);
            right: -($drawer-width);
        }

        .drawer-nav {
            @media (max-width: $screen-sm-ls) {
                animation: drawer-slide-in-mobile 0.55s forwards cubic-bezier(0.42, 0, 1, 1);
            }

            @media (min-width:$screen-md) {
                animation: drawer-slide-in 0.55s forwards cubic-bezier(0.42, 0, 1, 1);
                right: -($drawer-width);
            }

        }
    }

    .table {
        tbody tr {
            border: 1px solid #eee;
        }

        thead tr {
            border: 1px solid lighten($blue-lt, 15%);
        }

        th {
            background: lighten($blue-lt, 35%);
            border-color: lighten($blue-lt, 15%);
        }

        td {
            font-size: 1rem;
        }
    }

    .results {
        display: flex;
        flex-wrap: wrap;
        padding: 24px 24px 0;

        //margin-bottom:$gap-sm;
        @media (max-width: $screen-sm-ls) {
            width: 100%;
        }

        @media (min-width:$screen-md) {
            padding-right: $gap;
        }

        .card {
            flex: 1;
            max-width: 200px;
            margin-bottom: $gap;

            &.current-value {
                background: lighten($blue-lt, 35%);
                box-shadow: 0 0 0 2px lighten($blue-lt, 15%), 0 3px 6px rgba(51, 51, 51, 0.16), 0 3px 6px rgba(51, 51, 51, 0.23);

                .title {
                    border-bottom-color: rgba($blue-lt, 0.3);
                    display: flex;
                    justify-content: space-between;
                }

                // background:lighten($blue-lt,35%);
                // box-shadow:0 0 0 2px lighten($blue-lt,15%), 0 3px 6px rgba(51, 51, 51, 0.16), 0 3px 6px rgba(51, 51, 51, 0.23);
                // .title {
                //   border-bottom-color:rgba($antique-white,0.3);
                // }

                // .sprite svg{
                //   width: 3rem;
                //   height: 3rem;
                //   stroke-width: 3px;
                //   margin-right: 1.25rem;
                //   stroke: darken($antique-white, 10%);
                //   fill: darken($antique-white, 10%);
                //   color: darken($antique-white, 10%);
                // }
            }

            &.historic-value {
                background: lighten($antique-white, 7%);
                box-shadow: 0 0 0 2px darken($antique-white, 5%), 0 3px 6px rgba(51, 51, 51, 0.16), 0 3px 6px rgba(51, 51, 51, 0.23);

                .title {
                    border-bottom-color: rgba($antique-white, 0.3);
                }

                .sprite svg {
                    width: 3rem;
                    height: 3rem;
                    stroke-width: 3px;
                    margin-right: 1.25rem;
                    stroke: darken($antique-white, 10%);
                    fill: darken($antique-white, 10%);
                    color: darken($antique-white, 10%);
                }
            }

            &.card-wide {
                @media (min-width: $screen-xs) {
                    max-width: 350px;
                }

                @media (max-width:$screen-sm-min) {
                    flex-basis: 100%;
                    min-width: 100%;
                }
            }

            .value {

                //padding-bottom:$gap;
                //font-size:14px;
                .number {
                    @include font-bold;
                    padding-right: 3px;
                }
            }

            +.card {
                margin-top: 0;


                @media (min-width: $screen-sm) {
                    margin-left: $gap;
                }
            }
        }

        .title,
        hr {
            flex-basis: 100%;
        }

        .data-section{
            margin-left: 24px !important;
        }
    }

    .result-wrapper {
        //display: flex;
        flex-direction: column;
        height: 100%;

        .result>.title {
            margin-bottom: $gap-xs;
        }

        .date {
            padding: 0 $gap $gap;
        }

        .grade-icon {
            margin-right: 10%;
        }

        .grade-icon+.trend {
            line-height: 36px;
        }

        .grade-card {
            display: flex;
            align-items: center;
            margin: 0;
            width: 100%;
            padding: $gap-xs (
                $gap-sm + $gap-xs
            );
        height: auto;
        min-height: $gap-lg + ($gap-xs * 0.5);

        &:last-of-type {
            margin-bottom: $gap-sm;
        }

        .grade-icon {
            width: $gap-lg + $gap-sm;
        }

        .grade-block {
            left: $gap;
            top: 50%;
            transform: translateY(-50%);

        }
    }
}

.result {
    flex: 1;

    &:not(:first-of-type) {
        padding-left: $gap;
    }

    //@media (max-width:$screen-sm-min) {
    //  margin-bottom:$gap-md;
    //}
    .card {
        padding-bottom: $gap;
        @include shadow(1);

    }

    .grade-icon+.trend {
        line-height: 36px // to align with grade
    }

    .title {
        font-size: 1rem;
        line-height: 1;
        min-height: 60px;
        min-height: 60px;
        align-items: center;
        display: flex;
        @include font-bold;
        text-transform: uppercase;
        padding: $gap-sm $gap;
        //background:#f6f6f6;
        border-bottom: 1px solid #ddd;
    }
}

.date {
    //font-size:0.8rem;
    padding: $gap-xs 0 $gap-sm;
    color: lighten($text-color, 15%);
    margin-top: auto;
}
}

.drawer-controls {
    display: flex;
    height: $gap-lg;
    //@include shadow(2);
    position: fixed;
    z-index: 101;
    width: 48px;
    height: 48px;
    //margin-left:-38px;
    //transform:translateX(-48px);
    //transform: translate3d(0,0,0)
    top: $gap-sm;
    right: $gap-sm + $gap-xs;
    color: white;
    @include shadow(2);

    .drawer-control-close {
        align-items: center;
        display: flex;
        justify-content: center;
        flex: 1;
        //padding:0 $gap;
        @include font-semibold;

        //opacity:0.4;
        .icon.sm {
            margin: 0;
        }

        svg {
            width: 16px;
            height: 16px;
        }

        &:hover {
            opacity: 1;
            background: #222;
            color: white;
        }
    }

}

.drawer-nav {
    background-color: #eee;
    display: flex;
    justify-content: space-between;
    min-height: $primary-nav-height;
    position: fixed;
    width: 100%;
    bottom: 0;
    border-top: 1px solid #fff;
    z-index: 10;
    box-shadow: 0 0 15px rgba(black, 0.3);

    .drawer-nav-element {
        padding-top: $gap-sm;
        padding-bottom: $gap-sm;
        display: flex;
        align-items: center;
        //color:white;
        cursor: pointer;
        transition: all 0.15s;
        min-width: 50%;
        z-index: 10;
        border-top: 1px solid transparent;

        &:hover {
            background: white;
            border-color: #eee;
            //@include shadow(2);
            //transform:translateY(-5px);
            color: $link-color;
        }

        &:active {
            //@include shadow(1);
            //transform:translateY(-3px);
        }
    }

    .drawer-nav-right {
        padding-right: $gap;
        padding-left: $gap-md;
        justify-content: flex-end;
        margin-left: auto;

        .icon {
            margin-left: $gap;
        }
    }

    .drawer-nav-left {
        padding-right: $gap-md;
        padding-left: $gap;

        .icon {
            margin-right: $gap;
        }
    }

    .nav-meta {
        font-size: 0.8rem;
        @include font-semibold;
        opacity: 0.5;
    }

    .nav-label {
        font-size: 1.1rem;
    }
}

.drawer-body {
    //margin: 25px 0 25px 30px;
    //width: 900px;
    //padding-top:120px;
    background-color: white;

    //padding:$gap-md;
    //padding-top: 120px;
    //border: 1px solid #428BCA;
    //padding-bottom:$primary-nav-height;
    h4.title {
        font-size: 1.1rem;
        margin: 0;
        text-transform: uppercase;
        @include font-bold;
        margin-bottom: $gap;
    }

    .tab-group .nav-tabs>li.active>a {
        box-shadow: 0 0 4px rgba(51, 51, 51, 0.12), 0 3px 4px rgba(51, 51, 51, 0.24);
        border-color: transparent;
    }
}

.drawer-heading {
    display: flex;
    flex-wrap: wrap;
    padding: $gap;
    background: #f6f6f6;
    border-bottom: 1px solid #eee;
    min-height: 120px;
    align-items: center;
    background: $blue-dk;
    color: white;
    position: sticky;

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        position: fixed;
    }
   & {
    width: 100%;
    top: 0;
    z-index: 100;
   }  

    .drawer-category {
        font-size: 1.4rem;
        text-transform: uppercase;
        //@include font-semibold;
    }

    .grade-block {
        width: $gap-lg + 12;
        height: $gap-lg + 12;
        font-size: 1.8rem;
        margin-right: $gap;
    }

    .drawer-title {
        font-size: 2.5rem;
        line-height: 1;
        @include font-semibold;

        @media (max-width: $screen-sm) {
            width: 90vw;
        }
    }

    .meta {
        @media (min-width: $screen-sm) {
            min-width: 500px;
            padding-right: $gap-md;
        }
    }

    .item-detail {
        align-self: flex-start;
    }

    .item-history {
        @media (min-width: $screen-sm) {
            margin-left: auto;
        }

        //@media (min-width:$screen-md) {
        //  //flex:1;
        //  padding-left:$gap-lg;
        //}
        .result {
            .trend-card {
                margin-top: 0;

                .trend {
                    font-size: 3rem;
                }

                svg {
                    margin-left: $gap-md;
                    width: 3rem;
                    height: 3rem;
                }
            }

            .value {
                font-size: 1.4rem;
                letter-spacing: 1px;
            }
        }

    }
}



.drawer-content-spectrum {
    display: flex;
    position: relative;
    width: 100%;
    overflow: hidden;
    border-bottom: 2px solid #eee;
    margin: auto;
    padding: 25px;
    // padding: 10% 0;
    // margin-top: $gap;
    // iframe {
    //     display: block;
    //     // top: 50%;
    //     min-height:150px;
    //     left: 4%;
    //     width: 90%;
    //     margin: auto;
    //     border: none;
    //     // transform: translate(-50%, -50%);
    //     // width: calc(100% - 40px);
    //     // height: 100%;
    // }
    // svg {
    //     display: block;
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     width: calc(100% - 40px);
    //     width: 90%;
    //     height: 100%;
    //     margin: auto;
    // }

    img {
        width: 100%;
        height: 100%;
        margin: auto;

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            width: auto;
        }
    }

    object {
        height: auto !important;
    }

    // iframe {
    //     width: 100%;
    //     height: 100%;
    //     margin: auto;
    //     @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //         width: auto;
    //     }
    // }
}

.tab-pane .comments {
    margin-top: 0 !important;
}

.drawer-content-data {
    padding: $gap $gap 0;
    min-height: 220px;
    background: #ffffff;

    // @include shadow(1);
    @media (max-width: 1280px) {
        padding: $gap 4px 0;
    }

    @media (max-width: $screen-xs) {
        padding: $gap 4px 0;
    }

    .comments {
        .tab-pane.active {
            box-shadow: 0 0 0 rgba(51, 51, 51, 0.12), 0 4px 4px rgba(51, 51, 51, 0.24);
            // padding: 24px;
        }

        @media (max-width: $screen-sm-ls) {
            width: 100%;
        }
    }

    .trend-card {
        padding: 0 $gap $gap-sm;

        .trend {
            font-size: 1.8rem;
        }

        .percent-trend {
            color: $gray-md;
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 2rem;
        }

        .value {
            color: rgba($text-color, 0.8);
        }
    }

    .nav-link {
        color: $link-color !important;
    }

    .active.text-secondary.nav-link {
        color: #555 !important;
    }
}

.recommendation-content {
    padding: $gap;
}

.comment-controls {
    .tab-content {
        height: 100%;
        border: 1px solid #ddd;
        border-top: 0;
        background: white;
    }

    .nav-tabs>li>a {
        @include font-semibold;
    }

    .provider-photo {
        width: 50px;
        height: 50px;
        min-height: 50px;
        min-width: 50px;
        border-radius: 50%;
        border: 2px solid #ddd;
        margin-right: $gap;
        background-size: cover;
    }

    .provider,
    .recommendation,
    .suggested-recommendation {
        padding: $gap;
        display: flex;
    }

    .provider {
        align-items: flex-start;
    }

    .or {
        width: $gap-md;
        @include font-bold;
        font-size: 0.7rem;
        text-align: center;
        display: flex;
        flex-direction: column;

        &:before,
        &:after {
            content: "";
            height: calc(50% - 25px);
            display: block;
            width: 1px;
            background: #ddd;
            margin: $gap-sm auto;
        }
    }

    .recommendation-content {
        padding: $gap;

        @media (min-width: $screen-sm) {
            display: flex;
        }

        &.read-only {
            .icon-group {
                display: none;
            }
        }

        .heading {
            @media (min-width: $screen-sm) {
                min-width: 50%;
                padding-right: $gap;
            }

            .search {
                max-width: 600px;

                label {
                    color: $text-color;
                }

                .controls {
                    display: flex;
                    align-items: flex-end;
                }

                .inline-label {
                    flex: 1;
                }

                select {
                    flex: 5
                }

                button {
                    margin-right: 0;
                }
            }
        }

        .alert {
            display: flex;

            .icon {
                margin-right: $gap-sm;
                opacity: 0.4;
                fill: currentColor;
            }

            strong {
                @include font-bold;
            }

            p:last-of-type {
                margin-bottom: 0;
            }
        }

        .content {
            border: 1px solid #eee;
            width: 100%;
            padding: $gap;

            @media (min-width: $screen-sm) {
                margin-left: $gap;
            }
        }

        .title {
            @include font-semibold;
        }

        .item {
            display: flex;
            margin: $gap-sm 0;
            padding-bottom: $gap-sm;
            padding-top: $gap-xs;

            &:not(:last-of-type) {
                border-bottom: 1px solid #eee;
            }

            .details {
                padding-right: $gap-md;
                font-size: 0.8rem;
            }

            .name {
                @include font-bold;
            }

            .summary {
                font-size: 1.4rem;
            }

            .icon-group {
                align-self: center;
                align-items: right;
                margin-left: auto;
            }

            .icon {
                margin: 0.5rem;
                opacity: 0.2;
            }
        }

        +.recommendation-content>.recommendation {
            padding-top: 0;
        }
    }

    .suggested-recommendation {
        flex-direction: column;
    }

    .recommendation {
        align-items: flex-start;

        .controls {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
        }

        label {
            flex-basis: 100%;
        }

        .form-control {
            flex: 1;
        }

        .btn {
            margin-left: $gap-sm;
        }
    }

}

.historical-data {
    padding: $gap;
    //@media (min-width:$screen-lg) {
    //  padding:$gap-md;
    //}
}

.chart_body {
    // @media (min-width: $screen-sm-min) {
    //     display: inline-block;
    //     margin-left: 40px;
    // }

    // @media (max-width:$screen-sm-min - 1) {
    //     display: inline-block;
    //     margin-left: 10px;
    // }
    display: inline-block;

    @media (min-width:$screen-sm) {
        left: 40px;
    }

    @media (max-width: #{$screen-sm - 1px}) {
        left: 40px;
    }

    @media (max-width: #{$screen-xs - 1px}) {
        margin: 0 auto;
        left: 0;
    }
}

.chart-content {

    // padding-top: $gap;
    // padding:  $gap;
    // border: 1px solid #eee;
    .table {
        // margin-left: $gap;
        // padding: $gap;
        width: 100%;
        // width: calc(100% - #{$gap-lg});
    }
}

.unit_preference_tick {
    height: 27px;
    background-color: #103756;
}

.unit_preference_tick>i {
    color: white;
}

.unitSelect {
    height: 27px;
}

.chart-content-table {
    border: 1px solid #eee;

}

.chart-content,
.aggregate_age_chart {
    .chart_container {
        position: relative;
        display: flex;
        padding: 10px 0;
    }

    svg.rickshaw_graph.y_axis {
        // left: 40px;
        overflow: visible;
    }

    .y_axis {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 20px;
    }

    .chart_body {
        position: relative;
        // width: 100%;
        // >svg {
        //     width: 100%;
        // }
    }

    .grade {
        width: 26px;
        height: 26px;
        font-size: 0.6rem;

        &:not(.default):not(.grade-NA) {
            font-size: 1rem;
        }
    }

    .chart-key {
        padding: 24px;
        display: flex;

        @media (max-width:$screen-sm) {
            display: block;
        }
    }

    .chart-key-item {
        height: 20px;
        display: flex;

        @media (max-width:$screen-sm) {
            white-space: nowrap;
        }
    }
}

.rickshaw_graph {
    .y_ticks {
        .tick line {
            stroke-width: 1px;
            stroke: black;
            display: none;
        }

        text {
            display: none;
            font-weight: bold;
            font-size: 14px;
        }
    }

    .x_tick.fancy .title {
        font-family: "Sarala", sans-serif;
        font-weight: bold;
        font-size: 14px;
        color: #777;

    }

    // .x_tick .title{
    //     @media (max-width:500px) {
    //         transform: rotate(45deg)!important;
    //     }
    // }

    .detail {
        .item {
            margin-left: $gap;
            margin-right: $gap;

            &.active {
                background: white !important;
                border: 0;
                @include drop-shadow(0 2px 3px rgba(black, 0.4));
                padding: 0.75rem;
                color: $text-color;
            }

            &:after {
                border-width: 10px;
            }

            &.left:after {
                border-right-color: white !important;
                transform: rotate(90deg);
            }

            &.right:after {
                border-left-color: white !important;
                transform: rotate(-90deg);
            }

            .result {
                font-size: 1.4rem;
                color: var(--rickshaw_graph-detail-item-result-c);
            }

            .description {
                @include font-semibold;
                margin-bottom: $gap-xs;
                font-size: 0.8rem;
            }

            .date {
                color: rgba($text-color, 0.7);
                font-size: 0.8rem;
            }
        }

        .dot,
        .x_label {
            display: none !important;
        }
    }

    svg {
        overflow: visible !important;
    }
}

div .rickshaw_graph .detail .item {
    margin-left: -4em;
    margin-right: 0em;
    margin-top: 2.5em;
}

div .rickshaw_graph .detail .item.left:after {
    top: 1em;
    left: 50px;
    margin-top: -20px;
}

div .rickshaw_graph .detail .item.right:after {
    top: 1em;
    left: 80px;
    margin-top: -20px;
}

.aggregate_age_chart {
    h2 {
        text-align: center;
        font-weight: bold;
    }

    display: inline-block;
    /* FIXME */
    // min-width:400px;
    /* FIXME */
    // width: 32%;
    /* FIXME */
}

.trend-card {
    display: flex;
    //align-items: center;
    flex-wrap: wrap;
    fill: currentColor;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: $gap-sm;

    .trend {
        flex: 1;
        font-size: 3rem;
        line-height: 1;
        @include font-bold;
    }

    .value {
        color: white;
        margin-top: $gap-xs;
    }

    .icon {
        // margin-left: auto;
        // padding-left: $gap;
        padding-right: $gap-sm;
    }

    svg {
        width: 2rem;
        height: 2rem;
    }

    .date {
        flex-basis: 100%;
    }
}

.button-close {
    background: none;
    border: none;
    color: white;
    font-size: xx-large;
}

.close-drawer {
    display: none;
}

.datum-drawer-spec {
    width: 100%;

    .chart-content-table {
        padding: 24px !important;
    }
}

.moreInfoReference {
    padding: 0px 24px 24px 24px;
    &.biomarker-references{
        padding: 0px !important;
    }
    .reference-heading{
        font-size: 1.2rem;
    }
    .card {
        margin-top: 20px !important;
    }

    .card-header {
        font-size: 1rem;
        background-color: #d9edf7;
        color: #31708f;
        border-color: #bce8f1;
    }

    .card-body {
        padding-top: 0.5rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-bottom: 0.5rem;
        font-size: 1rem;
        margin: 0;
    }
    .card-body.url-reference{
        word-break: break-all !important;
    }
}
.moreInfoDescription {
    font-size: 1.2rem;
    // max-width: 640px;
    width: 100%;
    font-size: 1.2rem;
}

#datumdrawerComponent {
    .moreInfoDescription {
        padding: 24px;
    }

    .padded .tab-pane.active {
        box-shadow: none;
    }

    .comments {

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            margin-top: 150px;
        }
    }

    .chart_container {
        padding: 0;
    }

    .chart-content {
        margin-top: 20px;
        padding: 20px 0;
    }

    .chart_container {
        padding: 0 !important;
    }
}

#drawer {
    .drawer-content-data {

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            margin-top: 100px;
        }
    }

    .chart_container {
        padding: 0 !important;
    }

    .chart-content {
        padding: 20px 0;
    }
}

.description-shadow {
    width: 100%;
    // box-shadow: 0 0 0 rgba(51, 51, 51, 0.12), 0 4px 4px rgba(51, 51, 51, 0.24);
    background: white;
    padding: 24px;
    text-align: justify;

    #drawer {
        .moreInfoDescription {}
    }
}

@media (max-width: #{$screen-md - 1px}) {
    .drawer {
        &.open-drawer {
            width: 80% !important;
        }
    }
}

@media (max-width: #{$screen-sm-ls - 1px}) {
    .drawer {
        &.open-drawer {
            width: 100% !important;
        }
    }
}