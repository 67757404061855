.biomarker-of-age {
  cursor: pointer;
  height: 100%;
  margin-left: -15px;
  background: #F9FBFC;

  .b-bottom {
    border-bottom: 1px solid #e0e0e0;
  }

  .header {
    height: 5%;
    padding: 10px;

    .heading-1 {
      color: var(--page-heading-text-c);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 16px;
      font-family: Inter;
      font-weight: 600;
      line-height: 19.36px;
      letter-spacing: -0.154px;
      padding-left: 20px;
    }

    .heading-2 {
      color: #272727;
      font-size: 12.64px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration-line: underline;
      float: right;
      cursor: pointer;

      svg {
        width: 20px;
      }
    }
  }

  .biomarker-of-age-data {
    height: 94%;

    .heading-2 {
      color: #272727;
      font-size: 12.64px;
      font-weight: 700;
      line-height: normal;
      float: right;
      cursor: pointer;
      padding: 5px 12px 5px 12px;

      svg {
        width: 16px;
      }
    }

    // .heading-2 {
    //   background-color: #3297c9;
    //   border-radius: 4px;
    //   color: white;
    //   cursor: pointer;
    // }
    .toggle-button-risk-deault{
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid #D9D9D9;
      button{
        text-transform: none !important;
        margin: 2px 2px 2px 1px;
        border: none !important;
      }
    }

    .toggle-button-risk-deault .css-6x4ptp-MuiButtonBase-root-MuiToggleButton-root.Mui-selected{
      background-color: var(--btn-bg) !important;
    }

    .filter-btn {
      padding: 0px 10px 0px 10px
    }

    .biomarker-card {
      height: 90%;
      overflow-y: auto;
      overflow-x: hidden;

      .col-lg-4 {
        padding: 10px 10px 10px 10px;

      }

      .age-name {
        color: #272727;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16.94px;
      }

      .date {
        color: #272727;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16.94px;
        float: inline-end;
      }

      .value {
        color: #216689;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 114.286% */
      }

      .lable {
        font-size: 11.24px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      .risk {
        color: #A90000;
      }

      .safe {
        color: #00A900;
      }

      .normal {
        color: #216689;
      }

      .warning {
        color: #ffc107;
      }
      .color-white{
        color: #F1F1F1;
      }

      .icon {
        position: relative;
        z-index: 2;
        margin-left: -10px;

        .trudiag-svg-logo {
          left: 0;
        }

        svg,
        img {
          width: 20px;
          height: 20px;
          // max-width: 100%;
          // min-width: 100%;
          opacity: 1;
        }
      }

      .active {
        border: 1px solid #3397C9;
        border-radius: 4px;
      }

      .risky-bg {
        background: #FFF0F4;
        box-shadow: 0px 2px 2px 0px rgba(169, 0, 0, 0.20);
      }

      .risky-border {
        border-radius: 4px;
        border: 0.5px solid #A90000;
      }
    }

    .graph-div {
      width: 100%;
      margin-top: 4px;
      border-radius: 3px;
      background: #FFF;
      // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
      // height: 56%;
      .bioage-header {
        color: #272727;
        font-size: 12.64px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-left: 25px;
      }
      .no-gutters{
        // height: 95%;
        width: 100%;
        .header-biomarker-graph{
          height: 10%;
        }
      }
      img{
        vertical-align: middle;
        border-style: none;
        width: 98%;
        float: right;
      }
    }
    .noumenon-involved{
      margin-top: 5px;
      padding-bottom: 5px;
      padding-top: 5px;
      background: #FFF;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
      align-items: center;
      display: grid;
      .col-10{
       align-content: center;
       flex-wrap: wrap;
      }
      .nav-title {
        color: black;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.154px;
        cursor: pointer;
      }
      .show-details-button{
        width: max-content;
        height: max-content;
      }
    }

    .grade-div {
      height: 10%;

      .text {
        color: #000;
        line-height: 14.333px;
        /* 113.397% */
        text-transform: lowercase;
        font-style: normal;
      }

      .text-1 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0.5em
      }

      .text-2 {
        font-size: 12.64px;
        font-style: italic;
        font-weight: 500;
        margin-bottom: 0.5em
      }

      .text-3 {
        color: #636262;
        font-size: 12.64px;
        font-style: italic;
        font-weight: 400;
        line-height: 14.333px;
        /* 113.397% */
        text-transform: lowercase;
        margin-bottom: 0.5em
      }

      .red-icon {
        color: #C30000;
        vertical-align: text-top;
      }

      .blue-icon {
        color: #1D71B1;
        vertical-align: text-top;
      }

      .no-gutters {
        margin-right: 0;
        margin-left: 0;
        // [class*="col-"] {
        // padding-left: 0px;
        // padding-right: 0px  
        // }  
      }
    }

    .recommendation-list {
      // height: 43vh;
      padding-top: 15px;
      flex: 1;
      border-top: solid #DADDE7 1px;
      height: 30%;
      .recommendation-list-header{
        padding-bottom: 15px;
      }
      .nav-title {
        color: #80818B;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.154px;
        cursor: pointer;
      }

      .active {
        color: #0D3E56;
      }

      .recommendation-list-table {
        .react-bootstrap-table table{
          table-layout: auto;
        }
        height: 85%;
        overflow: scroll;
        // overflow-x: hidden;

        .instructions {
          &.no-recommendations {
            padding: 1rem;
          }
        }

        table {
          thead {
            height: 30px;
            position: sticky;
            top: -1px;
            background: white;

            th {
              overflow-wrap: normal;
              vertical-align: sub;
              border-bottom: 1px solid #dee2e6;
              position: sticky;
              top: -1px;
              background: #f9fbfc;
            }
          }

          tr td {
            overflow-wrap: normal;
            width: 16%;
            svg {
              width: 30px;
              height: 30px;
            }

            .actions svg,
            img {
              width: 15px;
              height: 15px;
              cursor: pointer;
            }
          }
          td:nth-child(2){
            overflow-wrap: break-word;
            width: 70%;
          }
          td:nth-child(1){
            width: 10%;
          }
          td:nth-child(5){
            width: 12%;
          }
        }
      }
    }

    .biomarker-of-age-data-left-container {
      height: 100%;
    }

    .biomarker-of-age-data-right-container {
      height: 100%;

      .biomarker-of-age-recommendation {
        height: 100%;
        display: flex;
        flex-direction: column;
        #drawerbody{
          overflow: hidden;
          .chart-content{
            overflow: visible;
            .chart_container{
              .rickshaw_graph{
                svg{
                  #biomarker-age-line{
                    stroke-width: 2;
                  }
                }
              }
            }  
            
          }
        }

      }
    }

  }

  .bg-white {
    background-color: white;
  }

  .biomarkers-overview-graph {
    width: 100%;
    height: 100%;
    // overflow-y: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding-top: 10px;
    .chart-content{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .chart_container{
        padding: 0 0;
        overflow-x: unset !important;
        width: 100%;
        .chart-gradient-background {
          width: 100%;
        }
      }
      .rickshaw_graph .detail{
        &.normalized-position{
          .item {
            position: absolute;
            z-index: 2;
            border-radius: 3px;
            font-size: 12px;
            font-family: Arial, sans-serif;
            background: rgba(0, 0, 0, 0.4);
            color: white;
            margin-left: 1em;
            margin-right: 1em;
            margin-top: -4em;
            white-space: nowrap;
          }
          .item:after {
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            content: "";
            border: 5px solid transparent;
          }
          .right:after {
            right: -6px !important;
            margin-top: -5px;
            border-left-color: rgba(0, 0, 0, 0.8);
            border-right-width: 0;
            top: 4em !important;
            left: auto;
            transform: rotate(0deg) !important;
          }
          .left:after {
            top: 4em !important;
            left: -6px !important;
            margin-top: -5px;
            border-right-color: rgba(0, 0, 0, 0.8);
            border-left-width: 0;
            transform: rotate(0deg) !important;
          }
          &.null-points{
            .item{
              margin-top: -3em;
            }
            .left:after, .right:after{
              top: 3em !important;
            }
            &.physioage{
              .item{
                margin-top: -5em;
              }
              .left:after, .right:after{
                top: 5em !important;
              }
            }
          }
          &.bottom-most-point{
            .item{
              margin-top: -11em;
              margin-left: -4em;
              &.right{
                margin-right: -4em;
              }
            }
            .left:after{
              top: 10em !important;
              left: 46px !important;
              margin-top: -14px;
              transform: rotate(-90deg) !important;
            }
            .right:after{
              right: 4em !important;
              margin-top: -14px;
              top: 10em !important;
              transform: rotate(90deg) !important;
            }
            &.null-points{
              .item{
                margin-top: -7em;
              }
              .right:after, .left:after{
                margin-top: -18px;
                top: 7em !important;
              }
            }
          }
        }
      }
    }
    .chart-content::-webkit-scrollbar {
      width: 0; /* Hide the scrollbar width */
      height: 0; /* Hide the scrollbar height (for vertical scrollbar) */
    }
    .biomarkers-timelines{
      display: flex;
      flex-direction: row;
      width: 100%;
      .baseline-container, .previous-container{
        flex: 1;
        .text-container{
          display: flex;
          flex-direction: column;
        }
      }
    }
    .rickshaw_graph {
      &.light-theme{
        border-left: 1px solid #DADDE7;
        border-bottom: 1px solid #DADDE7
      }
      &.dark-theme{
        border-left: 1px solid #5B5B5B;
        border-bottom: 1px solid #5B5B5B;
        circle{
          stroke: white !important;
          fill: black !important;
          stroke-width: 2 !important;
        }
      }
      .x_tick{ 
        .title{
          bottom: -20px;
        }
      }
      .y_grid{
        line{
          opacity: 0 !important;
        }
      }
    }
  }

  .MuiPaper-root.MuiPaper-outlined.MuiPaper-rounded.MuiCard-root {
    height: auto !important;
  }

  .more-info-description-toggle-info {
    height: 85%;
    overflow-y: auto;
    padding: 15px 24px;
    display: block;
  }

  .header-biomarker-graph {
    display: flex;
    justify-content: space-between;
    width: 99%;
  }

  .icon.biomarkers-overall-page {
    stroke: currentColor;
    color: "#272415";
  }
}

@media (max-width: 768px) {
  .biomarker-of-age {
    cursor: pointer;
    height: auto;
    margin-left: -15px;
    background: #F9FBFC;
    .biomarker-of-age-data{
      .noumenon-involved {
        .nav-title {
          font-size: 12px;
        }
        .add-btn {
          color: #ffffff;
          height: max-content;
          width: auto;
          border-radius: 3px !important;
          background: var(--btn-bg);
          font-size: 10px;
        }
      }      
      .recommendation-list {
        .nav-title {
          font-size: 13px;
          svg{
            height: 16px;
          }
        }
        .add-btn {
          color: #ffffff;
          padding-left: 10px;
          padding-right: 10px;
          height: 20px;
          width: auto;
          padding-top: 2px;
          border-radius: 3px !important;
          background: var(--btn-bg);
          font-size: 10px;
        }
      }
      .grade-div {
        padding: 10px 0px 10px 0px;
        .text-1 {
          font-size: 14px;
        }
        .text-2 {
          font-size: 11.64px;
        }
        .text-3 {
          font-size: 11.64px;
        }      
      }
      // .graph-div{
      //   .chart_body svg{
      //     height: 200px !important;
      //     width: 300px !important;
      //   }
      // }

    } 
  }
  .bio-name-div-1024{
    padding-top: 15px;
   }
}
@media (max-width: 767px) {
  .biomarkers-timelines {
    .baseline-container{
      justify-content: center;
      display: flex;
      .border-bottom{
        width: 35%;
      }
    }
    .previous-container {
      justify-content: center;
      display: flex;
    }
  }
}

@media (width: 820px) {
  .biomarker-of-age {
    height: auto;
    .biomarker-of-age-data{
      height: 68%;
    } 
  } 
}    

.biomarkers-timelines{
  .text-1{
    font-size: 18px;
    font-weight: 700;
  }
  .text-2{
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;

  }
  .text-3{
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;

  }
}

@media (max-width: 992px) {
  .biomarkers-timelines{
    // display: flex;
    .baseline-container{
      .border-bottom{
        border-bottom: 0 !important;
      }
    }
  }
}
@media (max-width: 1024px) and (min-width: 992px) {
  #drawer-body{
    .biomarkers-timelines{
      flex: 2;
    }
    .chart-content{
      flex: 3;
    }
  }
  .header-biomarker-graph{
    .bioage-header{
      padding-left: 1px !important;
    }
  }

  .biomarker-of-age{
    .biomarker-of-age-data{
      .recommendation-list{
        .recommendation-list-table{
          padding-right: 0% !important;
          table tr td{
            .delete-icon{
              padding-left: 0.5rem !important;
            }
          }
        }
      }
    }
    .biomarkers-overview-graph{
      padding-top: 0% !important;
      padding-bottom: 0% !important;
    }
    .header{
      height: max-content;
    }
    .show-details-button-container{
      max-width: 50%;
      padding: 0% !important;
    }
  }
  .biomarker-of-age .biomarker-of-age-data .biomarker-card .age-name{
    overflow-wrap: anywhere !important;
  }
  
  .biomarker-of-age .biomarker-of-age-data .biomarker-card .date{
    overflow-wrap: anywhere !important;
  }
}
@media (max-width: 993px) and (min-width: 768px) {
  #drawer-body{
    padding-right: 1%;
    flex-direction: column;
    .biomarkers-timelines{
      flex: 1;
    }
    .chart-content{
      flex: 1;
    }
  }
  
}
.biomarker-of-age-recommendation .graph-div .hoverthing .biomarker.biomarker-sm{
  img{
    float: none !important;
  }
}

@media (max-width: 1024px) and (min-width: 540px) {
  .biomarker-of-age{
    .biomarker-of-age-data{
      .recommendation-list{
        .recommendation-list-table{
          table tr td{
            .delete-icon{
              padding-left: 0.5rem !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 280px){
  .biomarker-of-age .biomarker-of-age-data{
     .biomarker-card  {
      .icon img {
        width: 25px;
        height: 25px;
      }
      .age-name {
        font-size: 11px;
        overflow-wrap: anywhere;
      }
      .date {
        font-size: 9px;
        overflow-wrap: anywhere;
      }
      .rickshaw_graph{
        overflow: auto;
        padding: 10px;
        width: 100px;
      } 
     }   
    .recommendation-list .recommendation-list-table table tr td {
      overflow-wrap: anywhere;
      width: 16%;
    }
    .noumenon-involved{
      .show-details-button-container{
        max-width: 50% !important;
        padding-left: 0px !important;
      }
    }  
  }  
  body .top-navbar {
    .p-name {
      font-size: 12px !important;
      font-weight: 700;
      padding-left: 0px;
    }
    .p-age {
      font-size: 12px !important;
    }
  }
  
  .toggle-button-risk-deault .css-14zs15f-MuiToggleButtonGroup-root {
    height: 33px;
  }
   
 
  
}



@media (max-width: 768px){
  .biomarkers-overview-graph {
    .chart-content{
      .chart_container{
        .x_tick{
          .title{
            bottom: -35px;
          }
        }
      }
    }
  }
}

@media (max-width: 539px){
  .biomarker-of-age{
    .biomarker-of-age-data{
      .recommendation-list{
        .recommendation-list-table{
          table tr td{
            .delete-icon{
              padding-left: 0rem !important;
            }
          }
        }
      }
    }
  }
}

@media  (max-width: 912px) and (min-height: 1368px)  {
  .biomarker-of-age {
    height: 100%;
    overflow: hidden;
  }
  .biomarker-of-age .header {
    height: 3%;
  }
  .biomarker-of-age .biomarker-of-age-data {
    height: 50%;
  }
  .report-card {
    height: 100%;
    overflow: hidden;
  }
}   

@media  (max-width: 1024px) and (min-height: 1366px)  {
  .report-card {
    height: 100%;
    overflow: hidden;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 992px){
  .report-card {
      height: 100%;
      overflow: hidden;
  }
}

.analytics-screen {
  &.beta {
    font-family: 'Noto Sans' !important;
    &.light-theme {
      background: $light-component-background !important;
      color: #333;  
      .biomarker-of-age, .biomarker-of-age, .biomarker-of-age .biomarker-of-age-data .graph-div {
        background-color: $light-component-background !important;
        box-shadow: $light-component-box-shadow;
      }  
    }
    &.dark-theme {
      background: $dark-background;
      color: #F1F1F1;
      .biomarker-of-age  {
        background: #161616;
        box-shadow: $dark-component-box-shadow;
        .biomarker-of-age-data {
           .heading-2 {
            color: #F1F1F1;
          }
      
          .graph-div {
            background: $dark-component-background;
            // box-shadow: $dark-component-box-shadow;
            .bioage-header {
              color: #F1F1F1;
            }   
          }
          .biomarker-card {
            .age-name, .age, .value, .date{
              color: white;
              stroke: white;
            }
          }
          .biomarker-card .icon svg{
            color: white;
            stroke: white;
          }
          .sparkline{
            svg{
              path{
                stroke: #ffffff;
                stroke-width: 1;
              }
              circle{
                stroke: #33363E;
                stroke-width: 2;
                fill: white;
              }
              .sparkline-age-circle{
                display: none;
              }
            }
          }
           
          .recommendation-list{
            border-top: solid #5B5B5B 1px;
            .nav-title {
              color: #A2A2A2;
            }
            .active {
              color: #F1F1F1;
            }
            .recommendation-list-table {
              td, th{
                color: #F1F1F1
              }
              tbody tr{
                border-width: 0.5px 0px 0.5px 0px;
                border-style: solid;
                border-color: #5B5B5B;
                border-left: none;
                border-right: none;
                td{
                  border-bottom: 0.5px solid #ACB1C7;
                }
              }
              thead{
                tr{
                  border-width: 0.5px 0px 0.5px 0px;
                  border-bottom-style: solid;
                  border-bottom-color: #5B5B5B;
                  border-top: none;
                  td{
                    border-bottom: 0.5px solid #ACB1C7;
                  }
                }
                th{
                  border-top: none;
                }
              }  
               thead,thead tr, th{
                background: #2F2F2F;
              }
              .instructions.no-recommendations{
                background-color: #2F2F2F;
              }
            }
            .more-info-description-toggle-info{
              background-color: #2F2F2F;
              .moreInfoReference .card {
                margin-top: 20px !important;
                background: #2F2F2F;
                border-width: 0.5px, 0px, 0.5px, 0px;
                border-style: solid;
                border-color: #5B5B5B;
                .card-header{
                  color: #F1F1F1;
                  background-color: #2F2F2F;
                  border-bottom: 0.5px solid #5B5B5B;
                }
                .card-body{
                  background-color: $dark-component-background !important;
                  color: #F1F1F1
                }
              }
            }
         }
         .noumenon-involved {
          background-color: #2f2f2f !important;
          .nav-title {
            color: #F1F1F1;
          }
         }
        }
        .b-bottom{
          border-bottom: none;
        }
        .header{
          background-color: transparent;
        }
        .header .heading-1{
          color: var(--page-heading-text-c-d);
        }
        .toggle-button-risk-deault{
          border: 1px solid #5B5B5B;
          .MuiToggleButtonGroup-root{
            height: 32px;
          }
          button{  
            font-size: 13px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.15399999916553497px;
            text-align: left;
            color: #F1F1F1;
            text-transform: none !important
          }
        }
        .biomarker-of-age-data-right-container {
          background: #1f1f1f;
        }   
      }
      .biomarker-compare-popup .biomarkers-list-popup .chart_body .icon svg {
        color: #f1f1f1 !important;
        stroke: #f1f1f1 !important;
        opacity: 0.9;
      }
      .biomarkers-timelines{
        .baseline-container, .previous-container{
          .text-container{
            .text-2, .text-3{
              color: #A2A2A2;
            }
          }
        }
      }
      .hoverthing{
        .biomarker.biomarker-sm .icon{
          stroke: white;
          svg{
            opacity: 1 !important;
            stroke: white;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px){
  .biomarker-of-age{
    .biomarker-of-age-data{
      .biomarker-card{
        .biomarker-card-div-1024{
          .sparkline{
            display: flex;
            justify-content: center;
          }
        }
        .MuiButtonBase-root{
          padding-left: 15px !important;
        }
      }
    }
    .biomarkers-overview-graph{
      .chart_body{
        .title{
          white-space: break-spaces;
          bottom: -35px !important;
          margin-left: auto !important;
        }
      }
    }
    .biomarkers-overview-graph .biomarkers-timelines{
      margin-top: 2rem !important;
      margin-left: 0% !important;
    }
    .biomarkers-overview-graph .chart-content .rickshaw_graph .detail{
      &.physioage{
        .item{
          .hoverthing{
            display: flex;
            flex-wrap: wrap;
            .biomarker-sm{
              flex: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 912px){
  .biomarker-of-age .biomarker-of-age-data .biomarker-of-age-data-right-container .biomarker-of-age-recommendation{
    padding-right: 0px !important;
  }
  .biomarker-of-age .biomarkers-overview-graph .biomarkers-timelines{
    margin-left: 0px !important;
    margin-top: 2rem !important;
  }
  .biomarker-of-age .biomarker-of-age-data .recommendation-list .recommendation-list-table{
    padding-right: 0px !important;
  }
}

@media(max-width: 450px) {
  .biomarker-of-age .biomarker-of-age-data .recommendation-list .recommendation-list-table table tr td{
    overflow-wrap: break-word;
  }
}

@media (max-width: 376px){
  .biomarker-of-age{
    .biomarker-of-age-data{
      .toggle-button-risk-deault{
        text-wrap: nowrap;
        max-width: 50%;
      }
    }
  } 
}
