.form-control{
    font-size: inherit!important;
}
.form-group{
    .input-entry{
        width: 100%;
    }
}
.react-datepicker-wrapper,
.react-datepicker__input-container{
    width: 100%;
}
.react-datepicker__month-container{
    width: 250px;
    font-size: larger;
}
.react-datepicker__day,
.react-datepicker__day-name{
    margin: 5px;
}
.btn.link{
    background: none;
    color: #2e6da4;
    text-decoration: underline;
    border: none;
    cursor: pointer;
}
.form-check input, .form-check label{
    cursor: pointer;
}
button:disabled{
    cursor: no-drop;
    background-color: gray!important;
    border: gray!important;
}
.patientDob{
    width: 150px !important;
}

.setTextFieldMargin{
    margin: 15px;
}

.red-color{
 color: red;
}
.bg-white{
    background-color: white !important;
}
.input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    height: 35.5px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    
    .form-control {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: transparent;
        background-clip: padding-box;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        cursor: pointer;
    }
    .form-control:focus {
        color: #495057;
        background-color: transparent;
        border-color: #80bdff00;
        outline: 0;
        box-shadow: none;
    }
    img.icon {
        cursor: pointer;
        margin-left: -17px;
    }
}


