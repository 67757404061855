@import "../../assets/Analytics-style/base/_colors.scss";

.verification{
    font-size: 14px;
    .modal.fade.show{
        display: block;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .modal-title{
        font-size: 16px;
        font-weight: 600;
    }
    .label-1{
        font-size: 1em;
        color: #444;
    }
    .label-2{
        font-size: 1em;
        font-weight: 600;
    }
    .label-3{
        font-size: 1.4em;
        font-weight: 600;
    }
    .label-4{
        font-size: 2em;
        font-weight: 600;
    }
    .label-5{
        font-size: 1.3em !important;
        font-weight: 400;
        color: #444;
    }
    .label-6{
        font-size: 1.2em;
        font-weight: 600;
    }

    .label-7{
        font-size: 1em;
        font-weight: 600;
    }

    .label-8{
        font-size: 1em !important;
        font-weight: 400;
        color: #444;
    }
    .label-9{
        font-size: 1em !important;
        font-weight: 600;
    }
    .recommended{
        background: #80808054;
        color: #337ab7;
        border-radius: 4px;
        padding: 3px;
        font-size: 10px !important;
    }

    .btn-primary{
        height: 30px !important;
        width: 73px !important;
        padding: 0 !important;
    }

    .cancle-btn{
        height: 30px !important;
        width: 73px !important;
        background-color: transparent !important;
        color: #444 !important;
        padding: 0px !important;
    }
    .link{
        font-size: 1em !important;
        font-weight: 400 !important;
        text-decoration: none !important;
        padding-left: 0px;
    }
    .form-group{
        margin: 0;
    }
    .w-40{
        width: 40%;
    }
    .h-40{
        height: 40%;
    }
    .w-60{
        width: 60%;
    }
    .h-60{
        height: 60%;
    }
    .modal-footer, .modal-header{
        border: none;
    }

    .h-50px{
        height: 50px;
    }   
    .btn-getStarted{
        background: transparent;
        color: #2e6da4;
        width: 90px !important;
    }

    .btn-skip{
        background: transparent;
        color: #2e6da4;
        width: 73px !important;
    }
    .pl-0{
        padding-left: 0px !important;
    }
    .card.auth-method{
        width: 40%;
    }

    .close {
        float: right;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 1;
    }
    .btn.link{
        color: var(--btn-bg, #337ab7);
    }
    .btn.link:hover{
        color: var(--btn-bg-h, #286090);
    }
    .btn.link:focus{
        box-shadow: none !important; 
    }
    .notification{
        &.alert {
            padding: 5px 5px 5px 10px;
            ul {
                margin-bottom: 0px !important;
                padding: 0 !important;
                margin-bottom: 5px !important;
                li{
                    justify-content: flex-start;
                    display: flex;
                }
            }
            .alert-error{
                background-color: transparent !important; 
                border-color: transparent !important;
            }
        }
    }    
}
@media (max-width: 768px) {
    .verification{
        font-size: 10px;
        .auth-method {
            padding: 2rem;
        }
        .label-5 {
            width: 100%;
        }
        .card.auth-method{
            width: 100%;
        }
        .btn-getStarted{
            height: 2em !important;
        }
        .btn-primary{
            height: 2em !important;
        }

    } 
    .card:has(.verify) {
        width: 100%;
        height: 100%;
        padding: 1em;
    }
    
}
.card:has(.verify) {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.analytics-screen {
    &.beta {
      font-family: 'Noto Sans' !important;
      &.light-theme {
        background: $light-background;
        color: #333;
        .verification{
            .label-1, .label-5{
                color: #444
            }
            .modal-content{
                background-color: #fff;
            }
        }
      }
      &.dark-theme {
        background: $dark-background;
        color: #F1F1F1;
        .verification{
            .label-1, .label-5{
                color: #ffffff99;
            }
            .modal-content {
                background-color: $dark-background;;
            }
            .cancle-btn{
                color: #F1F1F1 !important;
            }
            .modal.fade.show {
                background-color: var(--nav-overlay-sm-bg);
            }
            .close{
                color: #fff;
            }
        }
      }  
    }
}   



