// Probably a client request.
@mixin word-wrap {
      word-break:  break-word;
  -webkit-hyphens: auto;
     -moz-hyphens: auto;
          hyphens: auto;
}

// I've heard bad things, but these look nice.
@mixin font-smoothing {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing:antialiased;
          font-smoothing:antialiased;
             font-smooth:always;
}

// I mean, maybe this will come in handy.
@mixin box($box-sizing) {
  box-sizing:$box-sizing;
}

// Better than box-shadow for shadowing non-square shapes.
@mixin drop-shadow($dropshadow) {
  -webkit-filter:drop-shadow($dropshadow);
     -moz-filter:drop-shadow($dropshadow);
      -ms-filter:drop-shadow($dropshadow);
          filter:drop-shadow($dropshadow);
}

@mixin ellipsis($width) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Absolute vertical horizontal center.
// You should probably be using flexbox instead.
@mixin vertical-align($position) {
  position:$position;
  top: 50%;
  @include transform(translateY(-50%));
}

// A CSS only Material-ish ripple
@mixin ripple($background: white,$length: 1s) {
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: $gap-xs;
    height: $gap-xs;
    background: rgba($background, .5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }
  &:focus:not(:active)::after {
    animation: ripple $length ease-out;
  }
}

// Material-like shadowing
@mixin shadow($level: 1) {
  @if $level == 1 {box-shadow: 0 1px 3px rgba(#333,0.12), 0 1px 2px rgba(#333,0.24);}
  @else if $level == 2 {box-shadow: 0 3px 6px rgba(#333,0.16), 0 3px 6px rgba(#333,0.23);}
  @else if $level == 3 {box-shadow: 0 10px 20px rgba(#333,0.19), 0 6px 6px rgba(#333,0.23);}
  @else if $level == 4 {box-shadow: 0 14px 28px rgba(#333,0.25), 0 10px 10px rgba(#333,0.22);}
  @else if $level == 5 {box-shadow: 0 19px 38px rgba(#333,0.30), 0 15px 12px rgba(#333,0.22);}
}

// 1080p (TV) query, because who want's to type this twice?
@mixin screen-tv {
  @media (width: #{$tv-width}) and (height: #{$tv-height}) {
    @content;
  }
}

@mixin screen-tv-sm {
  @media (width: #{$tv-width-sm}) and (height: #{$tv-height-sm}) {
    @content;
  }
}

