.credits-btn-group {
    .btn {
        margin-bottom: 2%;
    }
}
.min-control {
    width: 300px !important;
    margin-bottom: 1.5rem;
}
.img-opacity {
    width: 10vw;
    opacity: 0.5;
    @media (max-width: 1680px) {
        width: 15vw;
    }
    @media (max-width: 768px) {
        width: 25vw;
    }
}
.receipt {
    position: absolute;
    .modal {
        overflow: hidden;
    }
}
.report-credits-table {
    @media (max-width: 530px) {
        // max-width: 80%;
        .bootstrap-table-container {
            max-width: 100%;
            overflow: auto;
            border: 1px solid #ccc;
        }
        .react-bootstrap-table {
            width: 700px;
            table {
                table-layout: auto;
            }
            td,
            th {
                min-width: 25%;
                &:first-child,
                &:nth-child(2) {
                    min-width: 10%;
                }
            }
        }
    }
    @media (max-width: 530px) {
        max-width: 500px;
    }
    @media (max-width: 500px) {
        max-width: 470px;
    }
    @media (max-width: 450px) {
        max-width: 420px;
    }
    @media (max-width: 420px) {
        max-width: 390px;
    }
    @media (max-width: 415px) {
        max-width: 380px;
    }
    @media (max-width: 375px) {
        max-width: 345px;
    }
    @media (max-width: 360px) {
        max-width: 340px;
    }
    @media (max-width: 320px) {
        max-width: 300px;
    }
    @media (max-width: 300px) {
        max-width: 280px;
    }
}
.model-wrapper {
    background-color: none;
    background: #ffffff url("../../../assets/images/brand_background.png") no-repeat center bottom;
    background-size: 60vh;
}
.credits-children {
    .side-panel {
        padding-right: 15px;
    }
}
.add-credits-form {
    .side-panel {
        min-width: inherit !important;
        max-width: inherit !important;
    }
    @media (max-width: 992px) {
        margin: 0 !important;
    }
}
.page-title-section {
    &.alert {
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
        &.alert-info {
            color: #31708f;
            background-color: #d9edf7;
            border-color: #bce8f1;
            label {
                color: #31708f;
            }
        }
    }
}
.credits-form-section {
    pre .form-control {
        background: #f5f5f5;
        border: 1px solid #ccc;
    }
    &.alert {
        b {
            font-weight: 700;
        }
        p {
            font-size: 14px;
            margin: 0 0 10px;
            margin-bottom: 0;
        }
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
        &.alert-info {
            color: #31708f;
            background-color: #d9edf7;
            border-color: #bce8f1;
            label {
                color: #31708f;
            }
        }
    }
}
#creditForm {
    label {
        min-width: 180px;
    }
    .form-group {
        display: flex;
        white-space: nowrap;
    }
    .min-control {
        display: flex;
    }
    .card-number-input {
        display: flex;
    }
    .credits-save-button {
        margin: 1rem 0 0 1rem;
    }
}
@media (max-width: 992px) {
    #creditForm {
        label {
            padding: 0;
        }
        .form-group {
            display: block;
            white-space: nowrap;
        }
        .min-control {
            display: block;
        }
        .card-number-input {
            display: block;
        }
        .credits-save-button {
            margin: 0;
        }
    }
}
@media (max-width: 992px) {
    .credits-form-section {
        &.alert {
            p {
                font-size: 13px;
            }
            pre {
                color: #333;
            }
        }
    }
    .min-control {
        width: 100% !important;
        margin-bottom: 1.5rem;
    }
}
