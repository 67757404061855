@import "../../Analytics-style/base/_colors.scss";
.downloadable-list, .document-list-beta {
    height: 90%;
    overflow-y: auto !important;
    width: 99%;
}
.downloadable-list::-webkit-scrollbar{
    display: none;
}

.download-library {
    // background: #161616 !important;
    .height-100 {
        height: 100%;
        // background: #1F1F1F;
        margin: 0px 10px 15px 10px;
        border-radius: 5px;
        &.no-gutters{
            height: 92%;
        }
        .MuiBox-root{
            margin: 0;
            padding: 0px 10px 15px 10px
        }
    }
    cursor: pointer;
    height: 100%;
    background: #F9FBFC;
    overflow: hidden;

    .b-bottom {
        border-bottom: 1px solid #e0e0e0;
    }

    .download-library-card {
        margin-top: 0.5rem;
        .card-body {
            -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
            min-height: 1px;
            padding: 1rem;
        }
    }

    .header {
        padding: 10px;
        align-content: center;
        // background: #161616;
        height: 6%;

        .heading-1 {
            color: var(--page-heading-text-c);
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.154px;
            padding-left: 20px;
            letter-spacing: -0.18px;
        }
    }
    .MuiTabs-flexContainer .MuiTab-textColorPrimary{
        text-transform: capitalize;
    }
    .MuiTabs-flexContainer .Mui-selected{
        color: var(--btn-bg) !important;
    }
    .MuiTabs-indicator{
        background-color: var(--btn-bg) !important;
    }  
    #document-list {
        .icon svg {
            position: relative;
            stroke: var(--download-icon-s);
        }
        .publication-date {
            color: var(--publication-date-c);
        }
        .document-sublist-heading {
            background-color: $gray-lt;
            font-size: 1.3rem;
        
            .sublist-title {
              font-weight: 500;
            }
        
            .sublist-subtitle {
              padding-left: 0.5em;
              color: var(--publication-date-c);
            }
        }    
    }
}

.document-list-beta {
    margin-top: 1rem;
}
.interpretation-list{
    position: static;
    width: 97%;
    margin: 2%;
}
.interpretation-beta{
    height: 98%;
    overflow: auto;
}
.light-theme{
    .interpretation-beta{
        background-color: white;
    }
}

@media (max-width: 1024px){
    .interpretation-beta{
        padding-left: 10px;
    }
}

@media (max-width: 280px){
    .interpretation-beta{
        padding-left: 13px;
    }
}

.analytics-screen {
    .moreInfoReference {
        padding: 0px 24px 24px 24px;
        &.biomarker-references{
            padding: 0px !important;
        }
        .reference-heading{
            font-size: 1.2rem;
        }
        .card {
            margin-top: 20px !important;
            a{
                color: var(--interpretation-card-a-c) !important;
            }
        }
    
        .card-header {
            font-size: 1rem;
            background-color: var(--interpretation-card-header-bg);
            color: var(--interpretation-card-header-c);
            border-color: var(--interpretation-card-header-bc);
        }
    
        .card-body {
            padding-top: 0.5rem;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            padding-bottom: 0.5rem;
            font-size: 1rem;
            margin: 0;
        }
        .card-body.url-reference{
            word-break: break-all !important;
        }
    }
    .moreInfoDescription {
        font-size: 1.2rem;
        // max-width: 640px;
        width: 100%;
        font-size: 1.2rem;
    }
}    

.analytics-screen {
    &.beta {
      font-family: 'Noto Sans' !important;
      &.light-theme {
        background: $light-background;
        color: #333; 
      }
      &.dark-theme {
        background: $dark-background;
        color: #F1F1F1;
        .download-library  {
          background: #161616;
          box-shadow: $dark-component-box-shadow; 
          color: #F1F1F1;
          .height-100{
            background: #1F1F1F;
          }
          .header{
            background: #161616;
            .heading-1{
                color: var(--page-heading-text-c-d);
            }
            }
          .b-bottom {
            border-bottom: none;
           }
           .card {
                background: $dark-component-background;
                box-shadow: $dark-component-box-shadow; 
                color: #F1F1F1;
                border: 0.5px solid #7d7f84;
                border-radius: 3px;
                .card-header {
                    padding: 0.75rem 1.25rem;
                    margin-bottom: 0;
                    background-color: #2F2F2F;
                    color: #F1F1F1;
                    border-bottom:  0.5px solid #7d7f84;
                }
            }
            #document-list {
                .document-sublist-heading{
                background-color: #2F2F2F !important;
                color: #F1F1F1 !important;
                border-bottom:  0.5px solid #7d7f84 !important;
                border-radius: 3px;
                }
                .icon svg {
                    position: relative;
                    stroke: var(--download-icon-s-d);
                }
                .publication-date {
                    color: var(--publication-date-c-d);
                }
            }
            .MuiTabs-flexContainer .MuiTab-textColorPrimary{
                color: #F1F1F1 !important;
                text-transform: capitalize;
            }

            .MuiTabs-flexContainer .Mui-selected{
                color: var(--btn-bg-d) !important;
              }
            .MuiTabs-indicator{
                background-color: var(--btn-bg-d) !important;
            }
        }
      }
    }
  }