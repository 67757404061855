.animated-check path {
    fill: none;
    stroke: white;
    stroke-width: 3;
    stroke-dasharray: 23;
    stroke-dashoffset: 23;
    -webkit-animation: none;
    animation: draw-check 0.5s linear forwards;
    stroke-linecap: round;
    stroke-linejoin: round
}

.animated-check-black path{
    stroke: #103756;
}
