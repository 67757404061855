@import "../../../styles/variables.scss";
.oddities-screen.parent-component {
    // width: 50%;
    // max-width: 100%;
    // margin-left: 15%!important;
    // max-width: 75%;
    // @media (max-width: 1680px) {
    //     max-width: 80%;
    // }
    // @media (max-width: 1610px) {
    //     margin-left: 6%;
    //     max-width: 75%;
    // }
    // @media (max-width: 1440px) {
    //     margin-left: 5%!important;
    //     max-width: 80%;
    // }
    // @media (max-width: 1280px) {
    //     max-width: 100%;
    // }
    @media (max-width: 1024px) {
        display: contents;
    }
}
.observations-component {
    .float-right.no-breadcrumb-header {
        // position: inherit!important;
        float: none !important;
        max-width: 100% !important;
        padding: 0 15px !important;
    }
}
.oddity-messages-list {
    &.col-lg-9 {
        // left: 30%;
        padding: 0;
        margin: auto;
        // @media (max-width: 1610px) {
        //     left: 12%;
        // }
        // @media (max-width: 1610px) {
        //     left: 15%;
        // }
        // @media (max-width: 1280px) {
        //     left: 10%;
        // }
        // @media (max-width: 1500px) {
        //     left: 10%;
        // }
    }
    a {
        color: #008fbc !important;
    }
    .message-column {
        word-break: break-word;
    }
}
.fa-search {
    color: #008fbc !important;
}
.observations-list {
    // left: 7%;
    h2,
    td:nth-child(3),
    td:nth-child(5) {
        word-break: break-all !important;
    }
    a {
        color: #008fbc !important;
    }
}
.observation {
    a {
        color: #008fbc !important;
    }

    // .history-section td {
    //     // padding: 4px;
    // }
}
.obs-details-rows {
    border-top: 1px solid #cccccc;
    padding: 5px;
    word-break: break-word;
}
.history-section {
    margin-top: 2%;
    table {
        width: 100%;
        border-collapse: collapse;
    }
    th {
        outline: none;
    }
    td,
    th {
        min-width: 10%;
        // padding: 5px;
        word-break: break-word;
    }
}
.oddities-list {
    &.col-lg-9 {
        // left: 14%;
        padding: 0;
        margin: auto;
    }

    .active-tab {
        background-color: white;
        box-shadow: 1px -1px #eeeeee;
        border: 1px solid #b4aeae !important;
        border-bottom: none !important;
    }
    .oddities.card {
        padding: 15px;
        border: none;
    }
    .errors-section.card {
        border: none;
    }
    tr:hover {
        background-color: $table-hover-background-color !important;
    }
    td {
        word-break: break-word !important;
    }
    .tab-pane:first-child {
        th:first-child,
        td:first-child {
            width: 15%;
        }
        th:nth-child(2),
        td:nth-child(2) {
            width: 30%;
        }
        th:nth-last-child(2),
        td:nth-last-child(2) {
            width: 40%;
        }
        th:nth-last-child(1),
        td:nth-last-child(1) {
            width: 15%;
        }
    }
    .modal {
        .modal-content {
            width: 1000px;
            right: 50%;
            left: -50%;
            table {
                td,
                th {
                    padding: 8px;
                }
            }
        }
    }
    table {
        th,
        td {
            text-align: center;
            word-break: break-all;
        }
    }
    .errors-section {
        @media (max-width: 530px) {
            td {
                &:first-child,
                &:last-child {
                    white-space: nowrap;
                }
                width: 25%;
                text-align: inherit;
                &:last-child {
                    text-align: center;
                }
            }
            th {
                width: 25%;
                justify-content: flex-end;
            }
        }
    }
    .oddities-section {
        @media (max-width: 530px) {
            th {
                justify-content: center;
            }
            td {
                min-width: 25%;
                text-align: center;
            }
        }
    }
    .oddities-section,
    .errors-section {
        @media (max-width: 530px) {
            // max-width: 80%;
            .bootstrap-table-container {
                max-width: 100%;
                overflow: auto;
                border: 1px solid #ccc;
            }
            .react-bootstrap-table {
                width: 700px;
                table {
                    table-layout: auto;
                }
                td {
                    min-width: 25%;
                }
            }
        }
        @media (max-width: 530px) {
            max-width: 500px;
        }
        @media (max-width: 500px) {
            max-width: 470px;
        }
        @media (max-width: 450px) {
            max-width: 420px;
        }
        @media (max-width: 420px) {
            max-width: 390px;
        }
        @media (max-width: 415px) {
            max-width: 380px;
        }
        @media (max-width: 375px) {
            max-width: 345px;
        }
        @media (max-width: 360px) {
            max-width: 340px;
        }
        @media (max-width: 320px) {
            max-width: 300px;
        }
        @media (max-width: 300px) {
            max-width: 280px;
        }
    }
    td:nth-child(3) {
        text-align: justify !important ;
    }
}
.observations-list-container,
.oddity-messages-list,
.history-section {
    @media (max-width: 530px) {
        // max-width: 80%;
        .bootstrap-table-container {
            max-width: 100%;
            overflow: auto;
            border: 1px solid #ccc;
        }
        .react-bootstrap-table {
            width: 700px;
            margin-bottom: 0;
            table {
                table-layout: auto;
            }
            td,
            th {
                word-break: break-word;
                min-width: 15%;
                &:nth-child(6),
                &:nth-child(7) {
                    min-width: 10%;
                }
            }
        }
    }
    @media (max-width: 530px) {
        max-width: 500px;
    }
    @media (max-width: 500px) {
        max-width: 470px;
    }
    @media (max-width: 450px) {
        max-width: 420px;
    }
    @media (max-width: 420px) {
        max-width: 390px;
    }
    @media (max-width: 415px) {
        max-width: 380px;
    }
    @media (max-width: 375px) {
        max-width: 345px;
    }
    @media (max-width: 360px) {
        max-width: 340px;
    }
    @media (max-width: 320px) {
        max-width: 300px;
    }
    @media (max-width: 300px) {
        max-width: 280px;
    }
}
.observations-list-container {
    td,
    th {
        
        min-width: 10%;
        &:first-child {
            min-width: 20%;
            max-width: 20%;
        }
        &:nth-child(6),
        &:nth-child(7) {
            white-space: nowrap;
            max-width: 12%;
        }
        &:nth-child(2) {
            max-width: 5%;
        }
        &:nth-child(4) {
            max-width: 8%;
        }
        &:nth-child(3) {
            max-width: 15%;
        }
    }
}
.oddity-messages-list {
    th {
        text-align: left;
    }
    td {
        text-align: left;
    }
    td,
    th {
        word-break: break-word;
        width: 25%;
        &:first-child {
            white-space: nowrap;
            width: 15%;
        }
        &:nth-child(2) {
            width: 15%;
        }
    }
}
.resolve-modal .modal-content {
    width: inherit !important;
    right: inherit !important;
    left: inherit !important;
}
.modal.fade.show {
    display: block;
    background-color: #00000040;
}
.modal {
    .observation {
        left: 0% !important;
    }
}
@media (max-width: 1920px) {
    .oddities-list {
        // .react-bootstrap-table {
        //     width: initial;
        // }
        .modal {
            .modal-content {
                width: 1000px;
            }
        }
    }
}

@media (max-width: 1440px) {
    // .observations-list {
    //     left: 11%;
    // }
}
@media (max-width: 1300px) {
    .oddities-list {
        .modal {
            .modal-content {
                width: 920px;
                right: 50%;
                left: -50%;
            }
        }
    }
}
@media (max-width: 1280px) {
    .oddities-list {
        // &.col-lg-9 {
        //     left: 10%;
        //     max-width: 70%;
        // }
        .modal {
            .modal-content {
                width: inherit;
                right: 0%;
                left: 0%;
                table {
                    td,
                    th {
                        padding: 0px;
                    }
                }
            }
        }
    }

    // .observations-list {
    //     left: 18%;
    // }
}
@media (max-width: 1024px) {
    // .oddities-list,
    // .oddity-messages-list {
    //     &.col-lg-9 {
    //         // left: 0%;
    //     }
    // }
    .observation {
        left: 0%;
    }
    .observations-list {
        // &.col-lg-9 {
        //     // width: 100%;
        // }
        left: 0%;
    }
}
@media (max-width: 768px) {
    .observation-list-section {
        td,
        th {
            min-width: 15%;
            &:nth-child(3) {
                min-width: 20%;
            }
            &:nth-child(2),
            &:nth-child(5) {
                width: 10%;
                min-width: 10%;
            }

            &:nth-child(6),
            &:nth-child(7) {
                max-width: 5%;
                min-width: 5%;
            }
        }
    }
}
@media (max-width: 500px) {
    .react-bootstrap-table.oddities-list,
    .oddity-messages-list {
        margin: 0;
        // max-width: inherit;
        table {
            thead {
                th {
                    border: none !important;
                }
                display: table-caption;
            }
            tbody tr {
                display: table-row;
            }
        }
    }
    .observation-list-section {
        padding: 0;
    }
}
