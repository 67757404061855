#your-health {
  padding-top:0;
}

.has-current-data {
  position: relative;
  &::before {
    content:"";
    background: #9CC1D4;
    border-radius: 50%;
    display: block;
    height: $gap-sm * 0.75;

    position: absolute;
    width: $gap-sm * 0.75;

  }
}

.health-content {
  display:flex;
  overflow:hidden;
  > .content {
    //padding-bottom: 100rem;
    width:100%;  
    @media (min-width: #{$screen-sm-ls + 1px}) {
      padding-left:$sidebar-width;
    }
    .max-container {
      padding-right:$gap;
      padding-left:$gap;
      @media (min-width: #{$screen-sm-ls + 1px}) {
        padding-right:$gap-md;
        padding-left:$gap-md;
        margin:0 auto;
        width:100%;
        max-width:1155px;
        //padding-bottom:$gap-md;
      }
      @media (max-width:$screen-xs) {
          padding-right:$gap-xs;
          padding-left:$gap-xs;
      }
    }
  }



  .observation-date svg {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 0.6rem;
    vertical-align: text-top;
    stroke:none;
    margin-top:2px;
    fill:currentColor;
  }
  .card{
    + .card {
      margin-top:$gap-lg;
    }
  }
}

.health-category {

  //top:50%;
  //transform:translateY(-50%);
  > a {
    font-size:1.6rem;
    padding:$gap-xs $gap;
  }
}
.report-card-block {
  font-size: 2rem;
  .grade {
    padding-right: 1.5rem;
    display: inline-flex;
  }
}
.report-card-card {
  @media (min-width:$screen-lg) {
    width:70%;
  }
}
.report-card-subject {
  font-size: 2.8rem;
  display: inline-flex;
  padding-left: 1.25rem;
}
.report-card-content {
  display:flex;
  flex-wrap:wrap;
  > div {
    //padding:0 $gap-sm;
    @media (max-width: #{$screen-lg - 1px}) {
      width:100%;
    }
    @media (min-width:$screen-lg) {
      width:50%;
      //padding:0 ($gap);
    }
  }
  .report-card-item {
    .col-md-2{
      cursor: pointer;
    }
    :focus{
      outline: none;
    }
    .scrollable{
      .title{
        color: #444 !important;
        margin-left: 0.8rem;
      }
    }
    color:$text-color;
    text-decoration:none;
    stroke:currentColor;
    position:relative;
    border-bottom:1px solid #eee;
    padding: 25px;
    @media (min-width:$screen-sm) {
      display:flex;
      flex-direction:column;
    }
    > a {
      height:100%;
      min-height: 70px;
      @include ripple($blue1);
      &:hover,&:focus {
        text-decoration:none;
      }
    }
    &.default {
      &.fade-row{
        opacity: 0.70;
      }
      .row{
        .col-md-10{
        &:hover,&:focus {
          transition:transform 0.15s;
          transform:translateY(-5px);
        }
      }
      > a {
        padding:$gap;
        justify-content:center;
        display:flex;
        flex-direction:column;
        transition:transform 0.15s;
        //@media (max-width:$screen-sm) {
        //  padding:$gap-sm 0;
        //}
        &:hover,&:focus {
          transform:translateY(-5px);
        }
      }
      .title {
        @media (max-width:$screen-sm) {
          font-size:1.5rem;
        }
      }
    }
    }
    .scrollable{
      overflow: visible;
    }
    .content {

      background:white;
      color:$text-color;
      display:flex;
      align-items:center;
      &.full-graph{
        cursor: pointer;
      }
    }
    .sections {
      //margin-top:-$gap-sm - 3px;
      position:relative;
      z-index:2;
      background:white;
      display:flex;
      flex-wrap:wrap;
      background:#f6f6f6;
      border:1px solid #eee;
      margin:$gap-sm (-$gap) (-$gap);
      padding:0 $gap-sm $gap-sm;
    }
    .grade-block {
      min-width: $gap-lg;
      min-height: $gap-lg;
      margin-right: $gap;
      font-size: 1.3rem;
    }
    .title {
      align-self:center;
      @include font-semibold;
      font-size:1.8rem;
      line-height:1;
      //padding:$gap 0 0;
      //text-align:center;
    }
    .title {
      position: relative;
      display: inline-block;
    }
    
    .title .tooltiptext {
      visibility: hidden;
      background-color:#103756;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      z-index: 1;
      font-size: small;
      font-weight: bold;
      position: absolute;
      width: 140px;
      top: 100%;
      left: 50%;
      margin-left: -60px;
      margin-top: 3%;
    }
    .title .tooltiptext::after {
      content: " ";
      position: absolute;
      bottom: 100%;  /* At the top of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent black transparent;
    }
    .title:hover .tooltiptext {
      visibility: visible;
    }
    .icon {
      margin-left:auto;
      opacity:0.2;
      svg {
        width:50px;
        height:50px;
      }
    }
    &.sm {
      flex-direction:row;
      align-items:center;
      //padding:$gap-xs $gap-sm;
      box-shadow:none;
      margin:0;
      padding:$gap-sm;
      //width:140px;
      justify-content:center;
      .content {
        background:none;
      }
      .icon {
        display:none;
      }
      .title {
        padding:0;
        font-size:0.8rem;
        text-align:left;
      }
    }

  }
}

// Couldn't find where this was used. Should probably use REM
.category-and-sections-grade-block .sections { padding-left: 2em; }


.health-category {
  position:relative;
  &:before {
    content:"";
    position:absolute;
    width:100%;
    height:100%;
  }
  .max-container {
    position:relative;
    z-index:3;
    @media (min-width:$screen-sm) and (max-width:$screen-lg) {
      padding-left:$sidebar-width;
      padding-right:0
    }
    .icon {
      stroke:$blue-dk;
    }
    > .heading {
      //background:white;
    & {  
      margin-top: $primary-nav-height / 2;
      padding: $gap $gap $gap 0;
    }  
      @media (max-width:$screen-sm) {
        padding:$gap-sm $gap-sm $gap-sm 0;
      }
    }
    .card > .heading {
      padding:$gap $gap 0;
      @media (max-width:$screen-sm-ls) {
        padding-left:$gap-sm;
      }
    }
  }
  .heading {
    position:relative;
    @media (min-width:$screen-sm) {
      display:flex;
      align-items:center;
    }
    .icon {
      margin-left:auto;
      @media (max-width:$screen-sm-min) {
        display:none;
      }
      svg {
        width:80px;
        height:80px;
        stroke:$blue-dk;
        //@include drop-shadow(0 2px 6px rgba($category-gradient-secondary,0.5));
        overflow:visible;
        opacity:0.7;
        z-index: 10;
        position: relative;
        @media (max-width:$screen-sm) {
          width:60px;
          height:60px;
        }
      }
    }
    .meta {
      padding-bottom:$gap-sm;
      .section-title {
        padding-bottom:0;
      }
      .section-title {
        position: relative;
        display: inline-block;
      }
      
      .section-title .tooltiptext {
        visibility: hidden;
        background-color:#103756;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        z-index: 1;
        font-size: small;
        font-weight: bold;
         position: absolute;
         z-index: 1;
        // top: 13px;
        // left: 97%;
        // width: 120px;
        width: 140px;
        bottom: 100%;
        left: 50%;
        margin-left: -60px; 
      }
      .section-title .tooltiptext::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }
      .section-title:hover .tooltiptext {
        visibility: visible;
      }
      .description {
        opacity: 0.9;
        color:rgba($blue-dk,0.5);
        font-size: 1.4rem;
        line-height: 1;
        padding-left:$gap-sm;

      }
    }
  }

  // categories
  &.heart {
    min-height:calc(100vh - #{$primary-nav-height});
    padding-top:0;
  }
}


.health-card {
  //margin-bottom:$gap-lg;
  position:relative;
  // margin-top:5%;
  .card-heading {
    align-items:center;
    border-bottom:1px solid $border-default;
    background:#f2f2f2;
    .card-title {
      padding:$gap $gap 0;
      font-size:2rem;
      margin-bottom:$gap-xs;
      font-family:$font-heading;
      color:$blue-dk;
    }
    .description {
      font-size:1.2rem;
      padding:0 $gap $gap;
      color:lighten($text-color,15%);
    }
  }
  .card-subheading {
    padding-right: 0px !important;
    text{
      display: none;
    }
    .sparkline{
      margin-left: auto;
    }
    &:hover {
      background-color:rgba($blue2,0.1);
      cursor:pointer;
    }
    .controls-grade {
      // position: absolute;
      right:0;
      top: 50%;
      transform: translateY(-25%);
      color:#999;
      svg {
        width: $icon-size;
        height:$icon-size;
      }
    }
  
    // .section-grade-comp{
    //   display: flex;
    //   .title{
    //     margin-right: 40rem;
    //   }
    // }
    .section-grades{
      margin-top: 10px;
    }
    &.fade-row{
      opacity: 0.70 !important;
    }
    display:flex;
    align-items:center;
    padding:$gap-sm;
    border-bottom:1px solid $border-default;
    @media (min-width:$screen-lg) {
      padding:$gap-sm $gap;
    }
    .title {
      font-size:1.6rem;
      @include font-bold;
      color:$blue-dk;
      padding:0 $gap-sm 0 1rem;
    }
    .title {
      position: relative;
      display: inline-block;
    }
    
    .title .tooltiptext {
      visibility: hidden;
      width: 150px;
      background-color:#103756;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      margin-left: 10px;
      font-size: x-small;
      font-weight: bold;
      margin-top: 3%;
    }
    
    .title .tooltiptext::after {
      //content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent black transparent transparent;
    }
    .title:hover .tooltiptext {
      visibility: visible;
    }
  }
  .card-content {
    list-style:none;
    margin:0;
    padding:0;
    &:not(:last-of-type) {
      padding-bottom:$gap;
    }
    &:last-of-type {
      padding-bottom:$gap-lg;
    }
    .heading{
      .messageIconHover a{
        color: 
        #444 !important;
      }
    }
  }
  .card-content-row {
    overflow: visible !important;
    &.fade-row{
      opacity: 0.70 !important;
    }
    padding-right:$gap-md;
    position:relative;
    @include ripple($blue1,0.5s);
    @media (min-width:$screen-lg) {
      padding-left:$gap-md * 2;
    }
    @media (min-width:$screen-lg) {
      &:last-of-type {
        background-image:url('../../../assets/images/icons/icon_pipe-last.svg');
      }
      &:not(:last-of-type) {
        background-image:url('../../../assets/images/icons/icon_pipe.svg');
      }
    }
    &.has-current-data {
      &::before {
        left: -1.5rem;
      }
    }
    &:hover {
      background-color:rgba($blue2,0.1);
      cursor:pointer;
    }
    .heading, .reference-range {
      align-self: flex-start;
    }
    .title {
      font-size:1.4rem;
      padding:0 $gap-sm 0 $gap-xs;
      //@include ellipsis(100%);
      //display:flex;
      @media (min-width:$screen-md) {
        width:280px;
        //padding-left:1.25rem;
      }
      .abbrev {

      }
      .full {

      }
    }
    .title {
      position: relative;
      display: inline-block;
    }
    
    .title .tooltiptext {
      visibility: hidden;
      width: 150px;
      background-color:#103756;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      margin-left: 10px;
      font-size: x-small;
      font-weight: bold;
    }
    
    .title .tooltiptext::after {
      //content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent black transparent transparent;
    }
    .title:hover .tooltiptext {
      visibility: visible;
    }

    .result {
      font-size:1.4rem;
      color:$gray-dk;
      @include font-semibold;
      @media (min-width:$screen-lg) {
        min-width:200px;
      }
    }
    .observation-date {
      padding-right:$gap-sm;
      display: inline-flex;
      color:rgba($text-color,0.8);
      font-size:1rem;
    }

    .btn-expand {
      margin-left:auto;
      padding:$gap-sm;
      border:0;
      background:none;
    }
    .grade-block {
      position:relative;
      top:0;
      left:0;
      font-size:0.9rem;
      width:$icon-size-md;
      height:$icon-size-md;
      min-width:$gap;
      min-height:$gap;
      margin-right:$gap-sm;
      flex:none;
      align-self:flex-start;
      font-size:0.6rem;
      &:not(.default):not(.grade-NA) {
        font-size:1rem;
        @include font-semibold;
      }
    }

    > .heading {
      display:flex;
      @media (max-width:$screen-xs) {
        flex-basis:100%;
      }
      @media (min-width:$screen-xs) and (max-width:$screen-sm-min) {
        display:flex;
        flex-basis:70%;
        padding-right:$gap-md;
      }
      @media (width:$screen-sm){
        flex-basis:50%;
        max-width:100%;
      }
      @media (min-width: #{$screen-sm + 1px}) and (max-width:$screen-sm-ls) {
        width:30%;
      }
      @media (max-width: $screen-xl) {
        width: 30% !important;
      }
    }
    .result {
      min-width:25%;
      padding-right: 1rem;
      @media (max-width:$screen-xs) {
        flex-basis:100%;
        padding-left:45px
      }
      @media (min-width:$screen-xs) and (max-width:$screen-sm-min) {
        flex-basis:30%;
        padding-top:$gap-sm;
      }
      @media (min-width:$screen-sm) {
        text-align: right;
      }
      .result-value {
        margin-right: 1rem;
      }
    }
    .graph {
      flex-basis: 25%;
      @media (min-width:$screen-lg) {
        margin-left:auto;
      }
      @media (max-width:$screen-sm-min) {
        flex-basis:100%;
      }
      @media (max-width:$screen-sm) {
        display:none;
      }
      @media (min-width: #{$screen-sm + 1px}) and (max-width:$screen-sm-ls) {
        display: block !important;
        margin-left:auto;
        margin-right:$gap-sm;
      }
      @media (max-width:$screen-lg) {
        display: none;
      }
      .sparkline {
        width: 99%;
      }

      .trend {
        display:flex;
        align-items:center;
        svg {
          width: 1.4rem;
          height: 1.4rem;
          margin-right: 10px;
        }
      }
      .percent-changed {
        font-size:1.4rem;
      }
      text{
        display: none;
      }
    }

    .reference_range {
      padding: 3px 12px;
      border-radius: 28px;
      box-shadow: inset 0 0 2px #aaa;
      color: #888;
      height:auto;
      @media (min-width:$screen-sm) {
        margin-right:$gap-sm;
        margin-left:auto;
      }
      @media (max-width:$screen-sm-min) {
        display:inline-flex;
        margin-left: $gap-lg;
        margin-top: $gap-xs;
      }
    }

    .sparkline {
      @media (max-width: #{$screen-lg - 1px}) {
        margin-top:$gap-sm;
      }
    }
  }
  .controls {
    position: absolute;
    right:0;
    top: 50%;
    transform: translateY(-50%);
    color:#999;
    svg {
      width: $icon-size;
      height:$icon-size;
    }
  }
}
.has-current-data {
  position: relative;
  &::before {
    content:"";
    background: #9CC1D4;
    border-radius: 50%;
    display: block;
    height: $gap-sm * 0.75;

    position: absolute;
    width: $gap-sm * 0.75;

  }
}
.gpa-container {
  padding: $gap;
  border-bottom: 1px solid #eee;
  background: #f9f9f9;
  .gpa-grade{
    display: flex;
    .grade-block {
      min-width: $gap-lg;
      min-height: $gap-lg;
      font-size: 1.3rem;
    }
    &.fade{
      opacity: 0.5;
    }
  }
  .heading {
    display: flex;
  }
  .gpa {
    font-size: 3rem;
    color: #333;
    line-height: 50px;
    margin-left: auto;
    margin-left: $gap;
  }
  .title {
    font-weight: bold;
    margin-bottom: $gap-xs;
  }
  .name {
    color: #888;
    border-right: 1px solid #eee;
    padding-right: $gap-sm;
    margin-bottom: 1rem;
  }
}

.section-culture {
  position: relative;
  display: inline-block;
}

.section-culture .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color:#103756;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  //margin-left: 10px;
  font-size: x-small;
  font-weight: bold;

  width: 120px;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  margin-top: 3%;
}

.section-culture .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.section-culture:hover .tooltiptext {
  visibility: visible;
}
