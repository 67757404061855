.card {
  @include shadow(1);
  background:white;
  color:$text-color;
  //border-radius:10px;
 
  .card-heading {
    //padding:$gap $gap-md;
    border-bottom:1px solid $border-default;
    &.has-controls {
      padding:0;
      @media (min-width:$screen-sm) {
        display:flex;
      }
    }
    .card-title {
      padding:$gap $gap-md;
      margin:0;
    }
    .controls {
      @media (min-width:$screen-sm) {
        display:flex;
        margin-left:auto;
      }
      a {
        padding:$gap;
        margin:0 $gap-sm;
        position:relative;
        border:2px solid transparent;
        border-left-color:rgba($border-default,0.5);
        &:hover {
          border-color:rgba($border-default,0.5);
        }
      }
    }
  }
  .card-content {
    padding:$gap $gap-md;
  }

  .card-content-row {
    background-position:$gap-md top;
    background-repeat:no-repeat;
    min-height:45px;
    display:flex;
    flex-wrap:wrap;
    border-bottom:1px solid rgba($border-default,0.5);
    padding-top: $gap-sm;
    padding-bottom: $gap-sm;
    @media (max-width:$screen-lg - 1px) {
      .heading{
        flex-basis: 35%;
        .title{
          word-break: break-word;
        }
      }
    }
    @media (max-width:$screen-lg - 1px) {
      padding:$gap-sm $gap-sm $gap-sm 20px;
    }
    &:nth-of-type(even) {
      background-color:#f9f9f9;
    }
    @media (max-width:$screen-sm-min) {
      flex-wrap:wrap;
    }
    @media (min-width:$screen-lg) {
      align-items:center;
    }
    &:hover {
      background-color:rgba($blue-lt,0.2);
      cursor:pointer;
    }

  }
  #overall_gpa_sparkline{
    margin-top: 1rem;
    margin-left: 5rem;
    text{
      display: none;
    }
  }

  @media (max-width: 541px){
    #overall_gpa_sparkline{
      margin-left: 1rem;
    }
  }
  
  @media (max-width: 500px){
    #overall_gpa_sparkline{
      margin-left: 0;
    }
  }

  #overall-grade-drawer{
    background: #f9f9f9;
    .gpa-container{
      border-bottom: none;
    }
  }
  .report-card-content{
    .category-grade-sparkline{
      margin-top: 1rem;
      
    }
    text{
      display: none;
    }
    .sparkline{
      margin-top: 1rem;
      margin-left: auto;
      // padding-left: 55px;
    }
  }

}


