@import "../../../styles/variables.scss";

.users-component {
    &.parent-component {
        .col-lg-12.col-md-12.col-sm-12.col-xs-12.data-section {
            @media (max-width: 1024px) {
                margin-left: 15px;
            }
            @media (max-width: 768px) {
                margin-left: 0;
            }
        }
    }
    .users-list-sidepanel {
        @media (max-width: 768px) {
            margin-top: 1% !important;
            margin-bottom: 1%;
        }
        @media (max-width: 530px) {
            .new-user-btn-sec,
            .form-group {
                width: 100%;
                margin-bottom: 1%;
            }
        }
        
    }
}
.users-children {
    .side-panel {
        padding-right: 1%;
    }
}
.user-form {
    // left: 15%;
    .form-group {
        display: flex;
    }
    label {
        min-width: 150px;
    }
}
.update-user,
.update-password {
    left: 15%;
}
.users-filter {
    .search-btn {
        width: 100%;
    }
    label {
        font-weight: 400;
        color: #777;
        font-size: 14px;
    }
    textarea {
        min-height: 320px;
    }
}
.new-user-btn-sec {
    margin-bottom: 5%;
}
.reset-password-label {
    color: #333333;
    border-bottom: 1px solid #dddddd;
}

.user-edit-btn {
    font-size: 14px !important;
    padding: 0px 10px !important;
}    

.help-inline {
    margin-left: 17%;
}

.user-settings{
    font-size: 14px;
    .details-row{
        padding-bottom: 20px;
        padding-left: 0px;
    }
    .label{
        font-size: .9em;
    }
    .value{
        padding-top: 5px;
        font-size: 1em;
        font-weight: 600;
    }
    .edit-icon{
        justify-content: flex-end;
        display: flex;
        cursor: pointer;
    }
    // .user-details{
    // //    border-bottom: 0.5px solid #8080804f;
    // }
    .heading-1{
      font-size: 1.5em;
      font-weight: 600;
      width: 100%;
      padding-top: 10px;
    }
    .heading-2{
      font-size: 1em;
      font-weight: 600;
      width: 100%;
    }
    .heading-3{
        font-size: 0.9em;
        font-weight: 400;
        width: 100%;
        // color: #00000082
    }
    .no-gutters{
        padding-left: 0px;
        padding-right: 0px;
    }
    .bb-5{
        border-bottom: 0.5px solid #8080804f;
    }
    .btn-primary{
        height: 29px;
        width: 70px;
        padding: 0;
    }
    .f-w-600{
        font-weight: 600;
    }
    .card{
        min-width: 100% !important;
    }
}

@media (max-width: 412px){
    .col-lg-9.col-md-12.col-sm-12.col-xs-12{
        width: 380px;
    }
}
@media (max-width: 376px){
    .col-lg-9.col-md-12.col-sm-12.col-xs-12{
        width: 344px;
    }
}
@media (max-width: 361px){
    .col-lg-9.col-md-12.col-sm-12.col-xs-12{
        width: 100%;
        }
}
.users-table {
    .btn {
        white-space: nowrap;
    }
    th,
    td {
        min-width: 15%;
        max-width: 17%;
        word-break: break-all;
    }
    td:first-child,
    th:first-child {
        min-width: 10% !important;
        text-align: center;
    }
    @media (max-width: 530px) {
        // max-width: 80%;
        .bootstrap-table-container {
            // max-width: 100%;
            overflow: auto;
            border: 1px solid #ccc;
        }
        .react-bootstrap-table {
            width: 700px;
            table {
                table-layout: auto;
            }
        }
    }
    @media (max-width: 530px) {
        max-width: 500px;
    }
    @media (max-width: 500px) {
        max-width: 470px;
    }
    @media (max-width: 450px) {
        max-width: 420px;
    }
    @media (max-width: 420px) {
        max-width: 390px;
    }
    @media (max-width: 375px) {
        max-width: 345px;
    }
    @media (max-width: 360px) {
        max-width: 340px;
    }
    @media (max-width: 320px) {
        max-width: 300px;
    }
    @media (max-width: 300px) {
        max-width: 280px;
    }
}
@media (max-width: 992px) {
    .user-form {
        left: 0%;
    }
}

@media (max-width: 370px) {
    .user-form {
        label {
            min-width: 110px;
        }
    }
}


@media (max-width: 768px) {
    .user-settings{
        font-size: 10px;
    }
}    
