@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=PT+Serif);
@import "../../Oddities/components/oddities-styles.scss";
@import "../../../assets/Analytics-style/base/_settings.scss";
@import "../../Data/components/palettes.scss";
.visits-component {
    table {
        td,
        th {
            word-break: break-word;
        }
    }
    @media (max-width: 768px) {
        overflow-x: hidden;
        // padding: 15px;
    }
    .mb-4.pl-3.width-nav.nav.nav-tabs {
        @media (max-width: 375px) {
            padding-left: 0px !important;
            .nav-link {
                padding: 0.5rem 0.5rem;
            }
        }
    }
    @media (max-width: 360px) {
        th,
        td {
            word-break: break-all !important;
        }
    }
}

@media (max-width: 992px){
    .visitsPage.col-lg-9.col-md-12.col-sm-12.col-xs-12 {
        padding: 0px 15px;
    }
}
@media (max-width: 415px){
    .visitsPage.col-lg-9.col-md-12.col-sm-12.col-xs-12 {
        width: 410px;
    }
}
@media (max-width: 376px){
    .visitsPage.col-lg-9.col-md-12.col-sm-12.col-xs-12 {
        width: 375px;
    }
}

@media (max-width: 361px){
    .visitsPage.col-lg-9.col-md-12.col-sm-12.col-xs-12 {
        width: 365px;
    }
}

@media (max-width: 321px){
    .visitsPage.col-lg-9.col-md-12.col-sm-12.col-xs-12 {
        width: 323px;
    }
}

@media (max-width: 992px){
    .visitsPage.side-panel{
        padding: 0px 15px !important;
    }
}

#visitHealthReportCard {
    .report-card-content {
        .title,
        .grade-block {
            font-size: 1.2rem;
        }
    }
    .grade-card {
        position: relative;
        height: 60px;
        width: 98%;
        padding: 0;
        margin: 6px 4px;
        display: inline-block;
        .grade-block {
            background-color: brown;
            position: absolute;
            top: 9px;
            left: 9px;
        }
        .no-grade {
            position: absolute;
            z-index: 2;
            top: 9px;
            left: 9px;
            height: 44px;
            width: 70px;
            color: #808080;
            background-color: #fff;
            font-size: 14px;
            text-align: center;
            border: 1px solid #ccc;
            border-radius: 0.25em;
            padding-top: 1px;
            transition: 0.2s ease;
            white-space: normal;
        }

        .description {
            font-size: 20px;
            left: 90px;
            position: absolute;
            top: $gap-xs;
        }

        .details,
        .reference {
            color: #352271 !important;
            font-size: 14px;
            left: 90px;
            position: absolute;
            top: 32px;
        }
    }

    .grade-block {
        border: none;
        border-radius: $border-radius;
        display: flex;
        align-items: center;
        justify-content: center;
        height: $gap-md;
        position: relative;
        text-align: center;
        width: $gap-md;
        white-space: nowrap;
        margin: 10px !important;
        // @include font-bold;
        // &.lg {
        min-width: 40px !important;
        min-height: 40px !important;
        font-size: 1rem !important;
        // }
    }

    .palette_skittles {
        .grade-block {
            background-color: #ca0020;
            color: #000000;
        }
        display: flex;
        flex-wrap: wrap;
    }

    //palette
    @mixin graded($color) {
        &.grade-block {
            color: white;
            background-color: $color;
        }

        &.grade {
            color: $color;
        }

        fill: $color;
        stroke: darken($color, 10%);
        stroke-width: 1px;

        &.historic-grade-block {
            background-color: fade($color, 60%);
        }

        &.historic-grade {
            color: fade($color, 60%);
        }
    }

    @mixin palette_sequential($good, $neutral, $bad) {
        .grade-Ap {
            @include graded(mix($good, $neutral, 100%));
        }
        .grade-A {
            @include graded(mix($good, $neutral, 95%));
        }
        .grade-Am {
            @include graded(mix($good, $neutral, 85%));
        }

        .grade-Bp {
            @include graded(mix($good, $neutral, 75%));
        }
        .grade-B {
            @include graded(mix($good, $neutral, 60%));
        }
        .grade-Bm {
            @include graded(mix($good, $neutral, 55%));
        }

        .grade-Cp {
            @include graded(mix($good, $neutral, 10%));
        }
        .grade-C {
            @include graded($neutral);
        }
        .grade-Cm {
            @include graded(mix($bad, $neutral, 30%));
        }

        .grade-Dp {
            @include graded(mix($bad, $neutral, 58%));
        }
        .grade-D {
            @include graded(mix($bad, $neutral, 80%));
        }
        .grade-Dm {
            @include graded(mix($bad, $neutral, 90%));
        }

        .grade-F {
            @include graded(mix($bad, $neutral, 100%));
        }

        .grade-NA {
            @include graded(#999);
            font-size: 10px;
        }
    }

    .palette_teal_to_brown {
        @include palette_sequential(#018571, #cccccc, #a6611a);
        display: flex;
        flex-wrap: wrap;
    }
    .palette_blue_to_red {
        @include palette_sequential(#0571b0, #cccccc, #ca0020);
        display: flex;
        flex-wrap: wrap;
    }
    .palette_green_to_purple {
        @include palette_sequential(#008837, #cccccc, #7b3294);
        display: flex;
        flex-wrap: wrap;
    }
    .palette_green_to_red {
        @include palette_sequential(#2eb263, #d8d800, #b2302e);
        display: block;
        flex-wrap: wrap;
    }
    .palette_brown_to_teal {
        @include palette_sequential(#a6611a, #cccccc, #018571);
        display: flex;
        flex-wrap: wrap;
    }
    .palette_red_to_blue {
        @include palette_sequential(#ca0020, #cccccc, #0571b0);
        display: flex;
        flex-wrap: wrap;
    }
    .palette_purple_to_green {
        @include palette_sequential(#7b3294, #cccccc, #008837);
        display: flex;
        flex-wrap: wrap;
    }
    .palette_red_to_green {
        @include palette_sequential(#d63a36, #cccccc, #008837);
        display: flex;
        flex-wrap: wrap;
    }

    .palette_default {
        @extend .palette_green_to_red;
    }
}
.modal.report-card_modal {
    .section-title {
        font-size: 16px;
    }
    .section-title-questionnaire {
        font-size: 1.5rem;
        font-weight: 600;
    }
    overflow: auto!important;
}
.modal-body.report-card-modal-body {
    max-height: 350px;
    overflow-y: auto;
    th {
        border-top: none;
    }
    td {
        padding-top: 4px;
        padding-bottom: 4px;
        min-width: 20%;
        &:first-child {
            min-width: 15%;
        }
        &:nth-child(2) {
            min-width: 40%;
        }
        .grade-block {
            height: auto;
        }
    }

    .react-bootstrap-table {
        .grade-Ap {
            color: #2eb263;
        }
        .grade-A {
            color: #37b45e;
        }
        .grade-Am {
            color: #48b854;
        }
        .grade-Bp {
            color: #59bc4a;
        }
        .grade-B {
            color: #72c13b;
        }
        .grade-Bm {
            color: #7bc336;
        }
        .grade-Cp {
            color: #c7d40a;
        }
        .grade-C {
            color: #d8d800;
        }
        .grade-Cm {
            color: #cda60e;
        }
        .grade-Dp {
            color: #c2771b;
        }
        .grade-D {
            color: #ba5225;
        }
        .grade-Dm {
            color: #b64129;
        }
        .grade-F {
            color: #b2302e;
        }
        .grade-NA {
            color: #999;
        }
    }
}

.modal-body.questionnaire-card-modal-body {
    max-height: 400px;
    overflow-y: auto;
    th {
        border-top: none;
    }
    td {
        padding-top: 4px;
        padding-bottom: 4px;
        min-width: 20%;
        &:first-child {
            min-width: 60%;
        }
        &:nth-child(2) {
            min-width: 25%;
        }
        .grade-block {
            height: auto;
        }
    }
}   
.two-columns-layout {
    column-count: 2;
    column-gap: 40px;
}
.historic-panel-header {
    background-color: transparent !important;
    overflow-wrap: break-word;
    font-size: 14px;
    margin-top: 15px;
}
.historic-data-visits-title {
    word-break: break-word;
    text-transform: capitalize;
    background-color: #f9f9f9 !important;
    .dates {
        word-break: break-word;
        @media (max-width: 768px) {
            padding: 0 !important;
        }
    }
}
#patientReportDownload,
#physician_report {
    .react-bootstrap-table {
        width: initial !important;
        td {
            text-align: left !important;
        }
    }
    @media (max-width: 1024px) {
        padding: 15px;
    }
    @media (max-width: 768px) {
        table {
            .grade-block {
                height: initial;
            }
        }
        h1,
        h2,
        h3,
        h4 {
            text-align: initial;
        }
    }
    #historic_data {
        margin-top: 15px;
        .bootstrap-table-container {
            table {
                td {
                    strong {
                        background-color: #eae2d5;
                        white-space: nowrap;
                        overflow-wrap: break-word;
                    }
                    &:first-child {
                        min-width: 15%;
                        text-align: left;
                    }
                }
            }
            background-color: white;
            @media (max-width: 530px) {
                border: 1px solid #f5f5f5 !important;
            }
        }
    }
}
.reports-container {
    &.parent-component {
        margin-left: 0 !important;
    }
    &.container {
        max-width: inherit;
    }
}
.reports-download-section {
    margin-top: 5em;
    @media (max-width: 1024px) {
        margin-top: 15em;
    }
}
.results-table {
    padding: 0 !important;
    max-width: 100%;
    .review-results-table {
        @media (max-width: 530px) {
            // max-width: 80%;
            .bootstrap-table-container {
                // max-width: 100%;
                overflow: auto;
                border: 1px solid #ccc;
            }
            .table {
                width: 700px;
                table {
                    table-layout: auto;
                }
            }
        }
        @media (max-width: 530px) {
            max-width: 500px;
        }
        @media (max-width: 500px) {
            max-width: 470px;
        }
        @media (max-width: 450px) {
            max-width: 420px;
        }
        @media (max-width: 420px) {
            max-width: 390px;
        }
        @media (max-width: 415px) {
            max-width: 380px;
        }
        @media (max-width: 375px) {
            max-width: 345px;
        }
        @media (max-width: 360px) {
            max-width: 340px;
        }
        @media (max-width: 320px) {
            max-width: 300px;
        }
        @media (max-width: 300px) {
            max-width: 280px;
        }
    }
}
.visits-history-section {
    @media (max-width: 768px) {
        // th,
        // td {
        //     min-width: 15%!important;
        // }
        th:first-child,
        td:first-child,
        th:nth-child(2),
        td:nth-child(2),
        th:nth-child(3),
        td:nth-child(3) {
            min-width: 25% !important;
            max-width: 25% !important;
            word-break: break-all;
            overflow-wrap: break-word;
        }
        th:nth-child(4),
        td:nth-child(4) {
            min-width: 10% !important;
            word-break: break-all;
            overflow-wrap: break-word;
        }
        th:last-child,
        td:last-child {
            max-width: 10% !important;
        }
    }
}
.panels-table,
.visits-history-section,
#patientReportDownload .patients-report-preview-table,
#physician_report .patients-report-preview-table,
.import-tab-func {
    @media (max-width: 530px) {
        // max-width: 80%;
        .bootstrap-table-container {
            // max-width: 100%;
            overflow: auto;
            border: 1px solid #ccc;
            padding: 5px;
            margin-bottom: 1em;
        }
        .table {
            width: 700px;
            table {
                table-layout: auto;
            }
        }
    }
    @media (max-width: 530px) {
        max-width: 500px;
    }
    @media (max-width: 500px) {
        max-width: 470px;
    }
    @media (max-width: 450px) {
        max-width: 420px;
    }
    @media (max-width: 420px) {
        max-width: 390px;
    }
    @media (max-width: 415px) {
        max-width: 380px;
    }
    @media (max-width: 375px) {
        max-width: 345px;
    }
    @media (max-width: 360px) {
        max-width: 340px;
    }
    @media (max-width: 320px) {
        max-width: 300px;
    }
    @media (max-width: 300px) {
        max-width: 280px;
    }
}
.all-downloads-btn {
    border: none !important;
    color: #008fbc !important;
    outline: none !important;
    &:hover {
        background-color: white;
    }
}
.artisen-physician {
    .aggregate {
        @media (max-width: 530px) {
            max-width: 500px;
        }
        @media (max-width: 500px) {
            max-width: 470px;
        }
        @media (max-width: 450px) {
            max-width: 420px;
        }
        @media (max-width: 420px) {
            max-width: 390px;
        }
        @media (max-width: 415px) {
            max-width: 380px;
        }
        @media (max-width: 375px) {
            max-width: 345px;
        }
        @media (max-width: 360px) {
            max-width: 340px;
        }
        @media (max-width: 320px) {
            max-width: 300px;
        }
        @media (max-width: 300px) {
            max-width: 280px;
        }
    }
}
.import-tab-func {
    table {
        th,
        td {
            min-width: 15%;
            max-width: 15%;
        }
    }
}
.visit-document-details {
    pre {
        white-space: pre-wrap;
        font-size: 100% !important;
    }
}
#patientReportDownload .patients-report-preview-table {
    @media (max-width: 530px) {
        th,
        td {
            word-break: break-word;
        }

        table {
            th,
            td {
                min-width: 15%;
                max-width: 15%;
                &:first-child {
                    max-width: 5% !important;
                    min-width: 5% !important;
                    white-space: nowrap;
                }
                &:nth-child(3),
                &:nth-child(4),
                &:last-child {
                    max-width: 10%;
                    min-width: 10%;
                }
            }
        }
    }
}

#physician_report .patients-report-preview-table {
    @media (max-width: 530px) {
        th,
        td {
            word-break: break-word;
        }

        table {
            th,
            td {
                text-align: left;
                // padding-left: 0;
                min-width: 10%;
                max-width: 10%;
                &:first-child {
                    max-width: 5% !important;
                    min-width: 5% !important;
                    white-space: nowrap;
                }
                &:nth-child(2) {
                    max-width: 15%;
                    min-width: 15%;
                }
                &:nth-child(3),
                &:nth-child(4),
                &:last-child {
                    max-width: 8%;
                    min-width: 8%;
                }
            }
        }
    }
}
@media (max-width: 530px) {
    #abnormal_results table,
    .completeData table,
    .health-status-indicators-section table {
        th,
        td {
            min-width: 10%;
            max-width: 10%;
            &:first-child {
                min-width: 5% !important;
                max-width: 5% !important;
                white-space: nowrap;
            }
            &:nth-child(3),
            &:nth-child(4),
            &:last-child {
                max-width: 8%;
                min-width: 8%;
            }
        }
    }
}
.sourcesPhysician {
    table {
        th,
        td {
            min-width: 25% !important;
            max-width: 25% !important;
            &:first-child {
                min-width: 15% !important;
                max-width: 15% !important;
            }
        }
    }
}
// #patientReportDownloadTemplate {
//     .historic-panel-header {
//         background-color: white !important;
//     }
// }
.individual-visit {
    width: auto !important;
    padding: 0;
    .col-md-12 {
        padding: 0;
    }
    .col-lg-9 {
        padding: initial !important;
    }
    a {
        color: #008fbc !important;
    }
    h1 {
        font-weight: 500 !important;
        font-size: 4rem !important;
    }
    .jumbotron {
        margin-left: 0;
        @media (max-width: 992px) {
            margin-left: 0;
        }
    }
    .react-bootstrap-table {
        word-break: break-all;
        th {
            word-break: initial;
            // min-width: 20%;
            @media (max-width: 500px) {
                white-space: nowrap;
                // padding: 0.75em 0;
            }
        }
        td {
            // min-width: 20%;
            vertical-align: middle;
        }
    }
    .panels-table {
        th {
            padding-left: 0;
            padding-right: 0;
        }
        td {
            padding: 0px;
            label {
                margin: 10px 0;
            }
            .form-group {
                margin: 0;
            }
            .form-control {
                margin: 5px 0px;
                height: calc(1em + 1rem + 3px);
                text-align: left;
                @media (max-width: 768px) {
                    height: 20px;
                }
            }
            // }
        }
        td,
        th {
            vertical-align: middle;
            &:first-child {
                width: 25%;
            }
            &:nth-child(2) {
                width: 25%;
                text-align: left;
            }
            &:nth-child(3)
            &:nth-child(4) {
                width: 20%;
                text-align: left;
            }
            &:last-child {
                width: 5%;
                text-align: left;
            }
        }
        // th:first-child, td:first-child{
        //     min-width: 20%;
        //     max-width: 20%;
        // }
    }
    // table th, td{
    //     text-align: center;
    // }
    //skitties
}

.headerId {
    display: none;
}

.patient-survey {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.visits-section {
    .col-lg-9 {
        padding-left: 15px;
    }
    .side-panel {
        padding-right: 1%;
    }
    .history-panel-view-btn {
        white-space: nowrap;
    }
    .view-health-analytics-lg-btn {
        font-size: 18px;
    }
}
.card-body-visit {
    flex: 1 1 auto;
    margin-bottom: 0.5rem;
    :hover {
        color: #333;
        background-color: #e6e6e6;
        border-color: #adadad;
    }
}
// .history-source{
//     max-width: 100px;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     white-space: nowrap;
// }
.history-source {
    word-break: break-word;
}
.individual-result-details {
    margin-bottom: 2%;
    td:nth-of-type(even) {
        // max-width: 150px;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
    }
}
.width-nav {
    width: max-content;
}
.patientVisitData {
    cursor: pointer;
    li {
        border-bottom: 1px solid rgba(221, 221, 221, 0.5);
        flex-wrap: inherit !important;
    }
    li:nth-of-type(even) {
        background-color: #f2f2f2;
    }
    li:hover {
        background-color: rgba(96, 180, 203, 0.2);
        cursor: pointer;
    }
}
.visit-details-section {
    margin-bottom: 7%;
    a:hover {
        text-decoration: none !important;
    }
}
.toggle-beacon {
    margin-top: 10%;
}
.card-body-report {
    flex: 1 1 auto;
    padding: 0.4rem !important;
    a {
        padding-left: 0.5rem;
        color: #008fbc;
        font-size: 14px;
        font-family: sans-serif !important;
    }
    a:hover {
        text-decoration: none !important;
    }
    a:active {
        text-decoration: none !important;
    }
}
.card-body-report:hover {
    background-color: #effcff;
}
.recycle-text {
    padding-left: 1.8rem;
    padding-bottom: 1rem;
    color: #777;
}
.jumbotron {
    background-color: #eee !important;
    @media (max-width: $screen-lg - 1px) {
        padding: $gap-sm $gap-sm $gap-sm 20px;
    }
    p {
        font-size: 21px;
        font-weight: 200;
    }
    .pl-5 {
        @media (max-width: 530px) {
            margin: 0 !important;
            padding: 0 !important;
        }
    }
}
.p-5.text-dark {
    @media (max-width: 530px) {
        padding: 0 !important;
    }
}
.line-height {
    line-height: 75px !important;
}
.btn-save {
    margin-left: 16.66666667%;
}
.sidebar-orders {
    max-width: 50px !important;
    font-size: 12px !important;
    height: 30px;
}
.sidebar-orders:hover {
    color: #333;
    background-color: #e0e0e0;
    border-color: #adadad;
}

.sidebar-order {
    a:hover {
        text-decoration: none;
    }
}
.individual-visit {
    a:hover {
        text-decoration: none;
    }
}
.panelName {
    margin-left: 19px;
    color: #96a0a3 !important;
}
.bounds-caution {
    display: none;
}
.exceed-reference-range {
    background-color: #ffc0c0 !important;
}
.table-name-break {
    word-break: break-word;
}
.rec-provider-name{
    color: #0093c2!important;
}
.exceed-boundary-range {
    background-color: red;
    animation: pulse 2s infinite;
    @keyframes pulse {
        0% {
            background-color: #ff6600;
        }
        50% {
            background-color: #ff0000;
        }
        100% {
            background-color: #ff6600;
        }
    }

    .bounds-caution {
        display: block;
        border: 1px solid #cccccc;
        border-radius: 4px;
        background-color: #f5f5f5;
    }
}
.input-entry {
    .react-datepicker-wrapper input {
        max-width: 100%;
    }
}
.visit-documents {
    width: 100%;
    table {
        th {
            border: none;
        }
    }
}
.individual-result-details.react-bootstrap-table {
    word-break: break-word;
    @media (max-width: 1280px) {
        // width: 920px!important;
    }
    @media (max-width: 1080px) {
        // width: 720px!important;
    }
    @media (max-width: 992px) {
        // margin: auto;
    }
    @media (max-width: 799px) {
        // width: inherit!important;
    }
    @media (max-width: 320px) {
        padding: 0px 10px;
    }
}
.source-label {
    @media (max-width: 500px) {
        padding-left: 0 !important;
        white-space: nowrap !important;
    }
}
.results-table .review-results-table {
    a p {
        margin: 2px 10px;
    }
    // .table {
    //     word-break: break-word;
    //     @media (max-width: 1280px) {
    //         width: 920px !important;
    //     }
    //     @media (max-width: 1080px) {
    //         width: 720px !important;
    //     }
    //     @media (max-width: 799px) {
    //         width: inherit !important;
    //     }
    // }
    table {
        word-break: inherit;
        th {
            // white-space: nowrap;
            width: inherit !important;
            word-break: keep-all;
        }
        td.message-icon {
            max-width: 20px;
            vertical-align: middle;
            i {
                margin: 10px;

                @media (max-width: 320px) {
                    margin: 0px 5px 0px 15px;
                }
            }
        }
        td {
            width: 15%;
            word-break: break-word !important;
            &:first-child {
                width: 10%;
                text-align: center;
            }
            &:last-child {
                width: 10%;
                text-align: center;
            }
            @media (max-width: 400px) {
                padding: 0.75rem 0.4rem;
            }
        }
        td.label-section {
            width: 40%;
            vertical-align: middle;
            padding-left: 0;
            padding-right: 0;

            @media (max-width: 400px) {
                // width: 70px !important;
                width: 20%;
                a p {
                    margin: 0;
                }
            }
            @media (max-width: 360px) {
                width: 65px !important;
                word-break: break-all;
            }
            @media (max-width: 320px) {
                width: 50px !important;
                word-break: break-all;
            }
        }
    }
    .badge-warning {
        padding: 5px;
        color: white;
        background-color: #17a2b8;
        font-weight: lighter;
    }
}
@media (min-width: 1440px) {
    // .panels-table {
    //     td,
    //     th {
    //         vertical-align: middle !important;
    //     }
    //     th:nth-child(1) {
    //         width: 250px;
    //     }
    //     td:nth-child(1) {
    //         width: 250px;
    //     }
    //     th:nth-child(2) {
    //         width: 150px;
    //     }
    //     td:nth-child(2) {
    //         width: 150px;
    //     }
    // }
    .results-table {
        td,
        th {
            vertical-align: middle !important;
        }
        th:nth-child(1) {
            width: 250px;
        }
        td:nth-child(1) {
            width: 250px;
        }
        th:nth-child(2) {
            width: 350px;
        }
        td:nth-child(2) {
            width: 350px;
        }
    }
}
@media (max-width: 1920px) {
    .result-width {
        width: 920px;
    }
}
@media (max-width: 1700px) {
    .result-width {
        width: inherit;
    }
}
@media (max-width: 1400px) {
    .result-width {
        width: inherit;
    }
}
@media (max-width: $screen-lg) {
    .visits-section .side-panel {
        margin-left: 0%;
    }
    .result-width {
        width: inherit;
    }
}

.rightPanel {
    display: flex;
    flex-direction: row;
    .col-lg-6 {
        padding: 0;
        &.left-detail-section {
            padding-right: 2%;
        }
        // &.right-detail-section{
        // }
    }
}
.agg-charts.rightPanel {
    display: block;
}
// @media (max-width: 1440px) {
//     .rightPanel {
//         width: 900px;
//     }
// }
// @media (max-width: 1440px) {
//     .rightPanel {
//         width: 800px;
//     }
// }
// @media (max-width: 1024px) {
//     .rightPanel {
//         width: 720px;
//     }
// }
@media (max-width: 992px) {
    .d-flex.visits-section {
        display: block !important;
    }
    .toggle-beacon {
        margin-top: 5%;
    }
    .rightPanel {
        display: flex;
        flex-direction: column;
        div:nth-of-type(1) {
            flex-grow: 1;
        }
        div:nth-of-type(2) {
            flex-grow: 1;
        }
    }
    .aggregate_age_chart iframe {
        height: 320px !important;
    }
}
@media (max-width: 768px) {
    .rightPanel {
        width: 520px;
    }
    .individual-visit {
        table {
            margin: auto;
        }
    }
}

@media (max-width: 520px) {
    .rightPanel {
        width: auto;
    }
}
.panel-active {
    background-color: #d4edf3;
}
.aggregate {
    padding-top: 1em;
    width: 800px !important;
    height: 400px;
    border: none;
    margin-bottom: 100px;
}

.abnormalResult {
    margin-top: 40px;
    // border-bottom: 1px solid black;
}
.abnormalResult .patients-report-preview-table .react-bootstrap-table {
    margin-bottom: 40px;
    margin-top: 40px;
    @media (max-width: 530px) {
        margin: 0;
    }
}
.completeData .patients-report-preview-table .react-bootstrap-table td {
    word-break: break-word;
}
.categoriesPhysio h3 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.spanForAlignment {
    padding-right: 20px;
    word-break: break-all;
}
.sourcesPhysician {
    margin-top: 40px;
}
.physicianReportRender {
    height: 0;
    overflow: hidden;
}
.aggregate_age_chart.card-body {
    background-color: white;
    width: fit-content;
}
.aggregate_age_chart iframe {
    border: 0;
    width: 100%;
    padding-bottom: 1em;
}
.observation-value {
    width: 150px;
    padding-right: 5% !important;
    @media (max-width: 500px) {
        padding-right: 3% !important;
        select {
            padding: 0;
            margin-left: 0;
            margin-right: 0;
        }
    }
    .width103{
       width: 103px !important;
    }
    textarea{
      min-height: 100px;
      text-align: left !important;
      width: 109%;
      @media (max-width: 768px) {
        width: 105%;
      }
    }
}
@media (max-width: 768px){
    .individual-visit .react-bootstrap-table th, .react-bootstrap-table tr {
        display: -webkit-flex;
        display: inherit;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
    }
    .custimization-table .react-bootstrap-table th, .react-bootstrap-table tr {
        display: -webkit-flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
    }
}

.ta-c{
    text-align: center !important;
 }
.age-weights.card-body {
    padding: 24px 34px !important;
    background-color: white;
    @media (max-width: 768px) {
        padding: 24px !important;
        h3 {
            padding: 10px;
        }
    }
    @media (max-width: 500px) {
        padding: inherit !important;
    }
}
@media (min-width: 992px) {
    .aggregate_age_chart iframe {
        height: 320px !important;
    }
}

@media (max-width: 768px) {
    .aggregate_age_chart iframe {
        height: 220px !important;
    }
}

@media (max-width: 520px) {
    .aggregate_age_chart iframe {
        width: 100% !important;
        height: 100% !important;
    }
}

//reports styles
.aggregate-chart-card {
    // margin: 15px;
    margin-top: 2%;
    .card {
        margin-bottom: 30px;
    }
}
.download-visits {
    margin-top: 2%;
}
.rightPanel.aggregate-chart-card {
    background-color: white;
}
@media (max-width: 768px) {
    .aggregate-chart-card {
        margin: 15px 0px;
    }
}
.reportImg img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25vh;
}
.baseline-intro-img {
    width: 35vh;
    @media (max-width: 420px) {
        width: 25vh;
    }
}
.titlepage .title {
    font-family: "Raleway";
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    @media (max-width: 500px) {
        font-size: 30px;
    }
    @media (max-width: 375px) {
        font-size: 25px;
    }
}
.titlepage .subtitle {
    font-family: "Raleway";
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    @media (max-width: 500px) {
        font-size: 25px;
    }
    @media (max-width: 375px) {
        font-size: 20px;
    }
}
.titlepage .patient-identification {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}
.titlepage .title-block {
    width: 28%;
    display: inline-block;
    vertical-align: top;
}
.titlepage .small-line {
    font-family: "Raleway";
    font-size: 18px;
    @media (max-width: 500px) {
        font-size: 12px;
    }
    @media (max-width: 375px) {
        font-size: 9px;
    }
}
.titlepage .line {
    font-family: "Raleway";
    font-size: 30px;
    @media (max-width: 500px) {
        font-size: 20px;
    }
    @media (max-width: 375px) {
        font-size: 15px;
    }
}
.toc .chapter-heading {
    font-size: 28px;
    @media (max-width: 500px) {
        font-size: 20px;
    }
    @media (max-width: 375px) {
        font-size: 18px;
    }
}
.chapter {
    padding-top: 40px;
    padding-bottom: 40px;
}
.chapter .chapter-heading {
    font-family: "PT Serif";
    font-size: 40px;
    @media (max-width: 500px) {
        font-size: 30px;
    }
    @media (max-width: 375px) {
        font-size: 20px;
    }
}
.section-heading {
    @media (max-width: 375px) {
        font-size: 18px;
    }
}
.passage {
    font-size: 18px;
    @media (max-width: 500px) {
        font-size: 16px;
    }
    @media (max-width: 375px) {
        font-size: 14px;
    }
}
.section {
    margin-top: 3em;
}
.section .section-heading:first-of-type {
    margin-top: 0;
}
blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    @media (max-width: 530px) {
        text-align: left;
    }
}
.react-high-chart {
    width: 40%;
    padding-top: 40px;
    padding-bottom: 40px;
    .highcharts-title {
        display: none;
    }
    @media (max-width: 530px) {
        width: 100%;
    }
}
.section {
    margin-top: 3em;
}
.passage .passage-heading {
    font-weight: 600;
    margin-top: 0;
}
.datum {
    clear: both;
    margin: 15px 0 0;
}
.passage .name {
    font-weight: bold;
}
.passage .explanation p:first-child {
    display: inline;
}
.section-data .patients-report-preview-table {
    padding-top: 40px;
    padding-bottom: 40px;
    @media (max-width: 530px) {
        padding: 0;
    }
}

.toc ul li {
    color: #008fbc;
}
.toc ul li a {
    color: #008fbc;
    font-size: 18px;
    @media (max-width: 500px) {
        font-size: 16px;
    }
    @media (max-width: 375px) {
        font-size: 14px;
    }
}
.categoriesReadMore {
    padding-right: 15px;
}
.chapter {
    @media (min-width: $screen-lg) {
        .report-card-card {
            width: 90%;
        }
    }
}
.grade-card {
    position: relative;
    height: 60px;
    width: 98%;
    padding: 0;
    margin: 6px 4px;
    display: inline-block;
}
.grade-card .grade-block {
    background-color: brown;
    position: absolute;
    top: 9px;
    left: 9px;
}
.grade-card .description {
    font-size: 20px;
    left: 90px;
    position: absolute;
    top: 6px;
    display: flex;
    flex-direction: column;
    @media (max-width: 500px) {
        font-size: 16px;
    }
    @media (max-width: 375px) {
        font-size: 14px;
    }
}
.grade-card .details,
.grade-card .reference {
    color: #5020e7 !important;
    font-size: 14px;
    left: 90px;
    position: absolute;
    top: 32px;
    display: block;
}
.highcharts-credits {
    display: none;
}

.section .react-high-chart .highcharts-legend-item {
    display: none;
}
.danger {
    color: red;
}
.success {
    color: green;
}
.spectrum {
    padding-left: 0;
    padding-right: 0;
    .full-width {
        width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .full-width-huge {
        width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
iframe.spectrum {
    border: none;
}
.panel-list {
    a {
        color: #555 !important;
    }
    .dropdown-item {
        padding: 5px 0.5rem !important;
        white-space: normal;
    }
    ul {
        width: 100% !important;
    }
}
.new-order {
    label {
        line-height: 2.3em;
    }
}
@media (max-width: 768px) {
    .reports-container {
        padding: 0 70px !important;
    }
    .two-columns-layout {
        column-count: 1;
    }
    .aggregate {
        width: fit-content !important;
        margin: 0 !important;
    }
}
@media (max-width: 1024px) {
    .panel-list {
        ul {
            width: 100% !important;
        }
    }
}

@media (max-width: 992px) {
    .d-flex .visits-section {
        display: flex !important;
        flex-direction: column;
    }
    .individual-visit {
        .col-lg-9 {
            margin: 0 !important;
        }
        .rightPanel {
            width: 100%;
            .col-lg-6 {
                padding: 0px !important;
            }
        }
    }
}
@media (max-width: 498px) {
    .individual-visit {
        .rightPanel {
            .col-lg-6 {
                padding: 0px !important;
            }
        }
    }
}

#patientReportDownloadTemplate {
    width: 9in;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        page-break-after: avoid;
        color: #896a48;
        font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
        page-break-inside: avoid;
    }

    a {
        cursor: pointer;
        text-decoration: none !important;
        color: #352274;
    }

    ul.contents {
        max-width: 7in;
        padding: 0;
        overflow-x: hidden;
        list-style: none;
    }
    ul.contents li:before {
        float: left;
        width: 0;
        white-space: nowrap;
        // content: ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . "
        //     ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . "
        //     ". . . . . . . . . . . . . . . . . . . . ";
    }
    ul.contents a.xref span:first-child {
        padding-right: 0.33em;
        background: white;
    }
    ul.contents a.xref span:last-child {
        float: right;
        padding-left: 0.33em;
        background: white;
    }

    .chapter,
    .passage,
    p {
        color: #155678 !important;
        text-align: justify;
        line-height: unset;
    }

    .coverpage {
        padding: 5%;
    }

    .break-page {
        page-break-after: always;
        column-span: all;
        margin: auto;
    }

    .break-page-before {
        page-break-before: always !important;
        column-span: all;
        margin: auto;
    }

    .complete-col-span {
        column-span: all;
        padding-top: 15px;
    }

    .two-columns-layout {
        column-count: 2;
        column-gap: 40px;
    }

    table {
        font-family: "Sarala", "Noto Sans JP" !important;
        font-weight: 400 !important;
        border: none;
        column-span: all;
        width: 100%;
        font-size: 12px;
        border-collapse: collapse;
    }

    table td {
        word-break: break-all;
        padding: 10px;
        page-break-inside: avoid;
    }

    th,
    thead,
    thead tr {
        background-color: #c7b392 !important;
        color: white;
        padding: 10px;
        text-align: left;
        margin-top: 0;
    }

    tbody {
        color: #155678;
        font-weight: 400 !important;
    }

    .categoriesPhysio {
        page-break-after: avoid;
        width: 100%;
    }
    .categoriesPhysio h3 {
        background-color: #eae2d5;
        font-family: "Sarala", "Noto Sans JP";
        color: #155678;
        font-size: smaller;
        font-weight: 700 !important;
        padding: 10px;
        margin: 0;
        column-span: all;
        page-break-after: avoid;
    }

    .spanForAlignment {
        padding-right: 20px;
        word-break: break-all;
    }

    .physicianReportRender {
        height: 0;
        overflow: hidden;
    }

    iframe {
        border: none;
    }

    .aggregate-chart-card {
        margin: 15px;
    }

    .card {
        margin-bottom: 30px;
    }

    .reportImg img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 4in;
        max-height: 2.5in;
    }

    .title {
        font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
        text-align: center;
        color: #0091c7 !important;
        font-weight: 500;
        font-size: 0.6in;
        padding-bottom: 0.5in;
        max-width: 500px;
        margin: 4% auto 0;
    }

    .subtitle {
        font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
        font-size: 50px;
        font-weight: normal;
        text-align: center;
        color: #896a48;
        font-weight: 300;
    }

    .patient-identification {
        margin-bottom: 30px;
        display: grid;
        text-align: left;
        left: 0;
        bottom: 0;
        position: absolute;
        padding-bottom: 10%;
        padding-left: 5%;
    }

    .title-block {
        display: inline-block;
        vertical-align: top;
    }

    .small-line {
        font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
        font-size: 20px;
    }

    .line {
        font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
        font-size: 28px;
        margin-bottom: 20px;
        display: flex;
    }

    .toc .chapter-heading {
        font-weight: 500;
        font-size: 0.5in;
        color: #896a48;
        column-span: all;
        font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
        margin-top: auto;
        margin-bottom: auto;
        white-space: nowrap;
    }

    .toc ul,
    .toc ul ul {
        list-style-type: none;
    }

    .chapter .chapter-heading {
        font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
        color: #896a48;
        font-size: 0.5in;
        font-weight: 500;
        column-span: all;
        font-weight: normal;
        margin-top: auto;
        margin-bottom: auto;
        white-space: nowrap;
    }

    .chapter {
        page-break-before: always;
    }

    .section {
        margin-top: 1em;
    }

    i {
        page-break-inside: avoid !important;
        page-break-before: avoid !important;
        page-break-after: avoid !important;
        page-break-inside: avoid;
    }

    .section .section-heading:first-of-type {
        margin-top: 0;
    }

    blockquote {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 40px;
        margin-inline-end: 40px;
    }

    .highcharts-container {
        margin: auto;
    }

    .passage .passage-heading {
        font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.24in;
        page-break-inside: avoid;
        page-break-before: avoid;
        page-break-after: avoid;
        padding-bottom: 0.25em;
        color: #155678 !important;
    }

    .datum {
        clear: both;
        margin: 15px 0 0;
    }

    .passage .name {
        font-weight: 700;
        page-break-inside: avoid;
        page-break-before: avoid;
        page-break-after: avoid;
    }

    .passage .explanation p:first-child {
        display: inline;
    }

    ul li {
        color: #155678;
    }

    ul li a {
        color: #155678;
        font-size: 18px;
        text-decoration: none;
    }

    .explanation ul li {
        page-break-inside: avoid;
        page-break-before: avoid;
        page-break-after: avoid;
    }

    .passage ul {
        margin: 0px;
    }

    .baseline-image img {
        width: 250px;
    }

    .categoriesReadMore {
        padding-right: 15px;
    }

    .grade-card {
        position: relative;
        height: 60px;
        width: 98%;
        padding: 0;
        margin: 6px 4px;
        display: inline-table;
    }

    .grade-card .grade-block {
        background-color: brown;
        position: absolute;
        padding: 12px;
        border-radius: 4px;
        width: 64px;
        text-align: center;
    }

    .grade-card .description {
        font-size: 20px;
        left: 100px;
        position: relative;
        display: flex;
        flex-direction: row;
        color: #896a48;
        font-family: "Open Sans", "Noto Sans JP", sans-serif;
        max-width: 220px;
        word-break: break-word;
        text-align: left;
    }

    .grade-card .details,
    .grade-card .reference {
        color: #352271 !important;
        font-size: 14px;
        left: 100px;
        position: initial;
        top: 32px;
        margin-left: 100px;
    }

    table .grade-block {
        background-color: #a58274;
        color: white;
        text-align: center;
        border-radius: 4px;
        padding: 5px 0px;
        max-width: 25px;
    }

    .highcharts-credits {
        display: none;
    }

    .highcharts-title {
        display: none;
    }

    .highcharts-legend-item {
        display: none;
    }

    .danger {
        color: red;
    }

    .success {
        color: green;
    }

    .full-width {
        width: 100% !important;
        display: block;
        width: auto;
        height: 90px;
    }

    .full-width-huge {
        width: 100% !important;
        display: block;
        width: auto;
        height: 90px;
    }

    .spectrum {
        page-break-inside: avoid;
    }

    .section-heading {
        page-break-after: avoid;
        page-break-inside: avoid;
        margin: 0;
        color: #0091c7;
        font-size: 35px;
        font-weight: 300;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        display: flex;
        flex-wrap: wrap;
        font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
    }

    .react-high-chart {
        padding: 0;
        page-break-inside: avoid;
        page-break-before: avoid;
        page-break-after: avoid;
        margin: auto;
    }

    .grade-Ap.grade-block {
        color: white;
        background-color: #2eb263;
    }

    .grade-A.grade-block {
        color: white;
        background-color: #37b45e;
    }

    .grade-Am.grade-block {
        color: white;
        background-color: #48b854;
    }

    .grade-Bp.grade-block {
        color: white;
        background-color: #59bc4a;
    }

    .grade-B.grade-block {
        color: white;
        background-color: #72c13b;
    }

    .grade-Bm.grade-block {
        color: white;
        background-color: #7bc336;
    }

    .grade-Cp.grade-block {
        color: white;
        background-color: #c7d40a;
    }

    .grade-C.grade-block {
        color: white;
        background-color: #d8d800;
    }

    .grade-Cm.grade-block {
        color: white;
        background-color: #cda60e;
    }

    .grade-Dp.grade-block {
        color: white;
        background-color: #c2771b;
    }

    .grade-D.grade-block {
        color: white;
        background-color: #ba5225;
    }

    .grade-Dm.grade-block {
        color: white;
        background-color: #b64129;
    }

    .grade-F.grade-block {
        color: white;
        background-color: #b2302e;
    }

    .palette_default {
        display: flex;
    }

    .physician-details {
        display: flex;
        margin-bottom: 1%;
    }

    .physician-details .individual {
        margin: 25px;
        display: contents;
        line-height: 2em;
    }

    .physician-details .individual h5 {
        line-height: 2;
        display: flex;
        font-weight: 400;
    }

    img.aggregate {
        width: 800px;
        margin-top: 20px;
        page-break-inside: avoid;
    }

    iframe.aggregate {
        width: 100%;
    }

    .physician-detail {
        background-color: #5f9be3;
        font-family: "Alegreya Sans SC", "Noto Sans JP";
        font-weight: bolder;
        color: white;
        padding: 10px;
        margin: 2px 15px;
        white-space: nowrap;
        height: fit-content;
    }

    .physician-label {
        font-family: "Alegreya Sans SC", "Noto Sans JP";
        margin-top: inherit;
    }

    .physician-report-title {
        text-align: justify;
    }

    .physician-report-title h1 {
        color: #0091c7 !important;
    }

    .artisen-physician {
        display: flex;
        justify-content: center;
    }

    tr:nth-of-type(odd) {
        background-color: #f3f3f3 !important;
    }

    tr:nth-of-type(even) {
        background-color: #f8f8f8 !important;
    }

    .warn,
    .warn::before,
    .warn::after {
        position: relative;
        padding: 0;
        margin: 0;
    }

    .warn {
        font-size: 23px;
        color: transparent;
        font-style: initial;
    }

    .warning-caution {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        page-break-inside: avoid;
    }

    .warn.warning {
        display: inline-block;

        top: 0.225em;

        width: 1.15em;
        height: 1.15em;

        overflow: hidden;
        border: none;
        background-color: transparent;
        border-radius: 0.625em;
    }

    .warn.warning::before {
        content: "";
        display: block;
        top: -0.08em;
        left: 0em;
        position: absolute;
        border: transparent 0.6em solid;
        border-bottom-color: #fd3;
        border-bottom-width: 1em;
        border-top-width: 0;
        box-shadow: #999 0 1px 1px;
    }

    .warn.warning::after {
        display: block;
        position: absolute;
        top: 0.3em;
        left: 0;
        width: 100%;
        padding: 0 1px;
        text-align: center;
        font-family: "Garamond", "Noto Sans JP";
        content: "!";
        font-size: 0.65em;
        font-weight: bold;
        color: #333;
    }

    .cover-page-details {
        display: flex;
        justify-content: space-between;
        margin-top: 100px;
    }

    .reports-powered-by {
        font-family: "Alegreya Sans SC", "Noto Sans JP", sans-serif;
        text-align: center;
        bottom: 0;
        right: 0;
        position: absolute;
        padding-bottom: 10%;
        padding-right: 5%;
    }

    .reports-powered-by img {
        max-width: 0.7in;
        max-height: 0.6in;
    }

    #historic_data h1 {
        color: #896a48;
    }

    #exceptional_results .react-bootstrap-table thead,
    #abnormal_results .react-bootstrap-table thead,
    #historic_data .react-bootstrap-table thead,
    #complete_data .react-bootstrap-table thead {
        display: none;
        page-break-inside: avoid;
    }

    #exceptional_results table tr td:nth-child(n + 6),
    #abnormal_results table tr td:nth-child(n + 6),
    #complete_data table tr td:nth-child(n + 7) {
        text-align: center;
    }

    #exceptional_results table,
    #abnormal_results table,
    #historic_data table,
    #complete_data table {
        margin-top: 0;
        margin-bottom: 0;
        page-break-inside: avoid;
    }

    #exceptional_results table tr td,
    #abnormal_results table tr td,
    #complete_data table tr td,
    table tr td {
        min-width: 50px;
        white-space: normal;
        word-break: break-all;
    }

    #exceptional_results table th,
    #abnormal_results table th,
    #complete_data table th,
    table th {
        min-width: 50px;
        white-space: nowrap;
    }

    table tr td:nth-child(2),
    table th:nth-child(2) {
        width: 180px;
        word-break: break-word;
        white-space: normal;
        padding-left: 0;
    }

    #complete_data table th:nth-child(2) {
        max-width: 220px;
        white-space: normal;
    }

    #complete_data table tr td:nth-child(2) {
        width: 200px;
        word-break: break-word;
        white-space: normal;
    }

    #exceptional_results table tr td:nth-child(2),
    #exceptional_results table th:nth-child(2),
    #abnormal_results table tr td:nth-child(2),
    #abnormal_results table th:nth-child(2) {
        width: 50px;
    }

    #complete_data table tr td:first-child,
    #complete_data table th:first-child,
    tr td:first-child,
    th:first-child {
        width: 35px !important;
    }

    #exceptional_results table tr td:first-child,
    #abnormal_results table tr td:first-child {
        width: 180px !important;
        word-break: break-all;
        white-space: normal;
        padding-right: 10px;
    }

    #exceptional_results table th:first-child,
    #abnormal_results table th:first-child {
        width: 180px !important;
        word-break: break-word;
        text-align: left;
        white-space: intial !important;
    }

    #exceptional_results .complete-col-span,
    #abnormal_results .complete-col-span,
    #historic_data .complete-col-span,
    #complete_data .complete-col-span {
        padding-top: 0;
    }

    .absolute-result-header td {
        padding: 10px;
    }

    #historic_data th,
    #historic_data td {
        width: 180px !important;
        word-break: break-word;
        white-space: normal;
    }
    #historic_data table th:not(:first-child),
    td:not(:first-child) {
        text-align: center;
    }
    #historic_data {
        table {
            td {
                strong {
                    background-color: #eae2d5;
                    white-space: nowrap;
                    overflow-wrap: break-word;
                }
                &:first-child {
                    min-width: 15%;
                }
            }
        }
    }
    table th:first-child {
        white-space: nowrap;
    }

    img.baseline-chart {
        height: 180px;
        width: 230px;
    }
}
#visitDeleteBtn {
    background: #d9534f;
}
.expositer-loader{
    color:#008fbc;
    font-size: 15px !important;
}
.expositer-loader-header{
    color:#008fbc;
    font-size: 20px !important;
}
.individual-visit{
    .report-card-item{
        padding: 0px !important;
    }
}
.jw-report-recommendation-details{
    page-break-inside: avoid!important;
    display: flex;
}
.jw-report-recommendation-card{
    page-break-inside: avoid!important;
    margin: 20px 0px;
}
.jw-report-recommendation-flex{
    display:flex;
}
.jw-report-icon {
    color: #000000;
    display: grid;
    vertical-align: text-bottom;
    text-align: center;
    width: 100px;
    page-break-inside: avoid!important;
    font-size: 12px;
    word-break: break-word;
    line-height: 1;
    height: 70px;
}
.jw-report-icon svg{
    max-width: 1cm;
    max-height: 1cm;
    color: #000000;
    margin: auto;
    page-break-inside: avoid!important;
}
.jw-report-recommendation-content{
    padding: 0;
    max-width: 500px;
    page-break-inside: avoid!important;
    margin-top: 3px;
}
.jw-report-recommendation-content strong{
    font-weight: 1000;
}

.new-order-input_field{
    margin-left: 15px;
    width: 87%;
}

.ml-17 {
   margin-left :17px
}

.add-custom-field-btn{
    padding: 5px 25px 5px 25px !important;
}

.custom-field, .createMultipleDocument {
    .trash-icon{
        font-size: 20px;
    }
    table td .form-group {
        margin: 0;
    }

    .is-invalid {
        input {
            border: 1px solid red;
            // background-color: pink;
        }
    }
}

.container .manual-form .required:after {
    content:" *";
    color: red;
}


.createMultipleDocument .form-group .react-datepicker-popper {
    z-index: 999999
}

.createMultipleDocument {
    .ml-105{
        margin-left: 105px;
    }
    .ml-50{
        margin-left: 50px 
    }   

    .icon-color{
        color: #007DBC;
    }

    .icon-color:hover, .icon-color:focus, .icon-color:active {
        text-decoration: none;
        color: #0E4876;
    }
}
.fw-10000 {
    font-weight: 1000;
}

.noumenon-value-card{
    background-color: white;
    border: 1px solid #00000024;
}

.bb{
    border-bottom: 1px solid  #0000000d;
}

.bt{
    border-top: 1px solid  #0000000d;
}