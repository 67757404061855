.default-theme {
    --top-nav-bg: #1F526B;
    --switch-button-bg: #1e536b;
    --provider-n-b-bg: transparent;
    --provider-n-b-b: #ffffff;
    --provider-n-b-c: #ffffff;
    --provider-n-b-bg-h: white;
    --provider-n-b-c-h: #1f526b;

    --left-nav-bg: #2D6683;
    --left-nav-active-li-c: #01486C;
    --left-nav-active-li-bg: #C7E7F6;
    --left-nav-li-c: #C7E7F6;

    --dash-report-card-text-c: #0D3E56;
    --dash-report-card-text-c-d: #F1F1F1;
    --dash-report-date-text-c: #263238;
    --dash-report-date-text-c-d: #A3E0FF;
    --dash-report-pointer-date-text-c-d: #84D6FF;
    --view-all-text-c: #1C1B1F;
    --view-all-text-c-d: #F1F1F1;
    --filter-icon-c-d: #3297c7;
    --filter-icon-c: #3297c7;

    --dropdown-item-active-c: #E5F6FF;
    --dropdown-item-active-bg: #216689;
    --dropdown-item-active-c-d: #3397C8;
    --dropdown-item-active-bg-d: #484848;

    

    --text-active-c: #264C5F;
    --text-active-c-D: #3397C9;
    --recommendation-body-text-expanded: #3A6D87;
    --recommendation-body-text-expanded-d: #84D6FF;
    --recommendation-list-active-card-b:  0.5px solid #15A055 !important;
    --recommendation-list-active-card-b-d:  0.5px solid #00AAFF !important;
    --real-age-bar-c: #3397C9;
    --real-age-bar-c-d: #3397C9;

    --category-data-div-b-d: 1px solid #3397C9;
    --category-data-div-bs-d: -1px 1px 10px 0px #3397C9;
    --btn-bg: #3397C9 !important;
    --btn-bg-d: #3397C9 !important;
    --btn-bg-h: #005c8a;
    --feedback-btn-b: #03A9F4;
    --unread-chat-icon-b: #0D3E56;
    --unread-chat-icon-b-d: #A6E1FF;

    --page-heading-text-c: #0D3E56;
    --page-heading-text-c-d: #F1F1F1;
    --rickshaw_graph-detail-item-result-c: #0088AD;
    --rickshaw_graph-detail-item-result-c-d: #0088AD;
    --surveyHeader-bg: #2980b9;
    --surveyHeader-bc: #2980b9;
    --surveybtn-bc: #2980b9;
    --download-icon-s: #103756;
    --download-icon-s-d: #1bcaffbf;
    --publication-date-c: #60B4CB;
    --publication-date-c-d: #1bcaffbf;
    --mobile-text-color-d: #3397C9;
    --switch-button-bg-d: #3397c9;
    --top-nav-mobile-text: #1f526b;
    --top-nav-mobile-text-d: #3397c9;
    --loading-card-c: #7ebbe9;
    --interpretation-card-header-bg: #d9edf7;
    --interpretation-card-header-c: #31708f;
    --interpretation-card-header-bc: #bce8f1;
    --interpretation-card-a-c: #008fbc;
    --mobile-nav-active-li-c: #00486c;
    --li-svg-filter-d: invert(79%) sepia(37%) saturate(2) hue-rotate(174deg) brightness(101%) contrast(90%);
    --nav-overlay-sm-bg: #234d6299;
    --loader-circle-bg: #134570;
    --gpa-grade-c-d: white;

}


.viavi-theme {
    --top-nav-bg: #2B2F31;
    --switch-button-bg: #366468;
    --provider-n-b-bg: #92CCB7;
    --provider-n-b-b: #C7DDD5;
    --provider-n-b-c: #1F1F1F;
    --provider-n-b-bg-h: white;
    --provider-n-b-c-h: #366468;

    --left-nav-bg: #505756;
    --left-nav-active-li-c: #366468;
    --left-nav-active-li-bg: #C7DDD5;
    --left-nav-li-c: #C7DDD5;

    --dash-report-card-text-c: #366468;
    --dash-report-card-text-c-d: #F1F1F1;
    --dash-report-date-text-c: #263238;
    --dash-report-date-text-c-d: #76B29D;
    --dash-report-pointer-date-text-c-d: #76B29D;

    --text-active-c: #366468;
    --text-active-c-D: #92CCB7;
    --recommendation-body-text-expanded: #366468;
    --recommendation-body-text-expanded-d: #E283D6;
    --recommendation-list-active-card-b:  1px solid #E283D6 !important;
    --recommendation-list-active-card-b-d:  1px solid #E283D6 !important;

    --view-all-text-c: #366468;
    --view-all-text-c-d: #92CCB7;
    --filter-icon-c-d: #92CCB7;
    --filter-icon-c: #366468;

    --dropdown-item-active-c: #366468;
    --dropdown-item-active-bg: #C7DDD5;
    --dropdown-item-active-c-d: #92CCB7;
    --dropdown-item-active-bg-d: #484848;
    --real-age-bar-c: #366468;
    --real-age-bar-c-d: #92CCB7;
    --category-data-div-b-d: 1px solid #92ccb7;
    --category-data-div-bs-d: -1px 1px 10px 0px #92CCB7;
    --btn-bg: #366468 !important;
    --btn-bg-h: #254c50;
    --feedback-btn-b: #366468;
    --unread-chat-icon-b: #366468;
    --unread-chat-icon-b-d: #92CCB7;

    --page-heading-text-c: #366468;
    --page-heading-text-c-d: #F1F1F1;
    --rickshaw_graph-detail-item-result-c: #366468;
    --rickshaw_graph-detail-item-result-c-d: #92CCB7;
    --surveyHeader-bg: #366468;
    --surveyHeader-bc: #366468;
    --surveybtn-bc: #366468;
    --download-icon-s: #366468;
    --download-icon-s-d: #366468;
    --publication-date-c: #366468;
    --publication-date-c-d: #366468;
    .top-nav-brand-logo .nav-logo {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        width: 130px;
    }    
    --mobile-text-color-d: #366468;
    --switch-button-bg-d: #366468;
    --top-nav-mobile-text: #366468;
    --top-nav-mobile-text-d: #366468;
    --loading-card-c: #92CCB7;
    --interpretation-card-header-bg: #92ccb761;
    --interpretation-card-header-c: #366468;
    --interpretation-card-header-bc: #92ccb742;
    --interpretation-card-a-c: #366468;
    --mobile-nav-active-li-c: #366468;
    --li-svg-filter-d: brightness(0) saturate(100%) invert(34%) sepia(22%) saturate(748%) hue-rotate(136deg) brightness(93%) contrast(81%);
    --nav-overlay-sm-bg: #36646885;
    --loader-circle-bg: #366468;
    --gpa-grade-c-d: #92CCB7;
    --btn-bg-d: #92CCB7;
    --ecg-icon: brightness(0) saturate(100%) invert(93%) sepia(29%) saturate(128%) hue-rotate(89deg) brightness(91%) contrast(89%);

} 