.elk-component {
    &.parent-component {
        .col-lg-12.col-md-12.col-sm-12.col-xs-12.data-section {
            @media (max-width: 1024px) {
                margin-left: 15px;
            }
            @media (max-width: 768px) {
                margin-left: 0;
            }
        }
    }
}
.elk-list-table {
    @media (max-width: 530px) {
        // max-width: 80%;
        .bootstrap-table-container {
            max-width: 100%;
            overflow: auto;
            border: 1px solid #ccc;
        }
        .react-bootstrap-table {
            width: 700px;
            table {
                table-layout: auto;
            }
            th:firth-child {
                justify-content: left;
            }
            th:not(:firth-child) {
                justify-content: center;
            }
        }
    }

    @media (max-width: 530px) {
        max-width: 500px;
    }
    @media (max-width: 500px) {
        max-width: 470px;
    }
    @media (max-width: 450px) {
        max-width: 420px;
    }
    @media (max-width: 420px) {
        max-width: 390px;
    }
    @media (max-width: 415px) {
        max-width: 380px;
    }
    @media (max-width: 375px) {
        max-width: 345px;
    }
    @media (max-width: 360px) {
        max-width: 340px;
    }
    @media (max-width: 320px) {
        max-width: 300px;
    }
    @media (max-width: 300px) {
        max-width: 280px;
    }
}
.credits-table {
    tr {
        word-break: break-word;
    }
    th,
    td {
        min-width: 15%;
        max-width: 17%;
        word-break: break-all !important;
    }
}
.credits-transaction-children {
    .side-panel {
        padding-right: 15px;
    }
}
.credit-transformation-form {
    left: 12%;
    .form-group {
        display: flex;
        label {
            min-width: 150px;
        }
    }
}
.credit-submit-btn {
    margin-left: 18%;
}
@media (max-width: 1024px) {
    .credit-transformation-form {
        left: 0%;
    }
    .credit-submit-btn {
        margin-left: 22%;
    }
}
@media (max-width: 992px) {
    .credit-submit-btn {
        justify-content: flex-end;
    }
}
