@import '../../styles/variables.scss';

.linkexpired {
    width: $login-container-width;
    .alert.alert-warning{
      display: flex;
      padding-left: 0px;
      padding-right: 0px;
      .fa.fa-warning{
        font-size: 30px;
        margin: 5px 15px;
        pointer-events: none;
      }
    }
  }
  @media(max-width:1140px){
    .linkexpired {
      width: 100%;
    }
  }