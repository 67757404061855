@import '../../styles/variables.scss';

.header-skeleton{
    &, *,
    ::after, ::before {
      box-sizing: border-box;
    }
    &{
      direction: ltr;
      position: relative;
      display: flex;
      flex-wrap: wrap;
    }  
      // padding: $ph-gutter ($ph-gutter / 2) ($ph-gutter - $ph-spacer) ($ph-gutter / 2);
    &{  
      overflow: hidden;
    }
    // margin-bottom: $ph-gutter;
  
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      z-index: 1;
      width: 500%;
      margin-left: -250%;
      animation: phAnimation $ph-animation-duration linear infinite;
      background: linear-gradient(to right, rgba($ph-bg, 0) 46%, rgba($ph-bg, .35) 50%, rgba($ph-bg, 0) 54%) 50% 50%;
    }
  
    > * {
      flex: 1 1 auto;
      display: flex;
      flex-flow: column;
      padding-right: ($ph-gutter / 2);
      padding-left: ($ph-gutter / 2);
    }

    .title-loading-section {
        width: 100%;
        height: 50px;
        background-color: $ph-color;
        margin-bottom: $ph-spacer;
        border-radius: 0.25rem;
      }
      .breadcrumb-loading-section {
        width: 100%;
        height: 35px;
        background-color: $ph-color;
        margin-bottom: $ph-spacer;
        border-radius: 0.25rem;
      }
  }
  
//   .ph-row {
//     display: flex;
//     flex-wrap: wrap;
//     margin-bottom: ($ph-spacer / 2);
  
//     div {
//       height: 10px;
//       margin-bottom: ($ph-spacer / 2);
//       background-color: $ph-color;
//     }
//     .big,
//     &.big div {
//       height: 20px;
//       margin-bottom: $ph-spacer;
//     }
//     .empty {
//       background-color: rgba($ph-bg, 0);
//     }
//   }
  


  @keyframes phAnimation {
    0% {
      transform: translate3d(-30%, 0, 0);
    }
    100% {
      transform: translate3d(30%, 0, 0);
    }
  }
  