.touch-group-horizontal {
  display:flex;
  flex-wrap:wrap;
  margin:0 (-$gap-sm);
  .checkbox,.radio {
    padding:0 $gap-sm;
    margin:0;
    display:flex;
  }
}

.touch-group-vertical {
  margin-top:$gap-md;
  legend {
    border:0;
    font-size:1rem;
    margin-bottom:$gap-sm;
    @include font-semibold;
  }
}


@keyframes touch-blip {
  0% {
    transform:scale3d(2.5,2.5,2.5);
  }
  100% {
    transform:scale3d(1,1,1);
  }
}

.pa-checkbox,.pa-radio {
  margin-bottom:$gap-sm;
  > label {
    padding:0;
    display:flex;
    align-items:flex-start;
    font-size:0.9rem;
  }
  input {
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;

    &:checked {
      ~ .faux-input {
        color:$blue;
        border-color:$blue;
        transition:all 0.15s;
        transform:scale3d(1.1,1.1,1.1);
        .icon {
          animation: touch-blip 0.15s forwards;
        }
      }
      ~ span {
        color:$blue;
      }
    }
  }
  .faux-input {
    width:24px;
    height:24px;
    margin-right:$gap-sm;
    .icon {
      display:none;
      fill:currentColor;
      width:24px;
      height:24px;
      svg {
        width:22px;
        height:22px;
      }
    }
  }
  > span {
  }
}

.pa-checkbox {
  .faux-input {
    border:2px solid #aaa;
    display:block;

  }
  input:checked {
    ~ .faux-input {
      .icon {
        display:block;
      }
    }
  }
}
