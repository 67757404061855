// @import 'palettes';

//-- Scaffolding
@import url(https://fonts.googleapis.com/css?family=Sarala);
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans);

@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
@import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);

@import "../../../assets/AnalyticsBeta-style/modules/_provider.scss",
"../../../assets/AnalyticsBeta-style/modules/_dashboard.scss",
"../../../assets/AnalyticsBeta-style/modules/_report-card.scss",
"../../../assets/AnalyticsBeta-style/modules/_card-loader.scss",
"../../../assets/AnalyticsBeta-style/modules/_biomarker-of-age.scss",
"../../../assets/AnalyticsBeta-style/modules/_treatment-plan.scss",
"../../../assets/AnalyticsBeta-style/modules/_warning-component.scss",
"../../../assets/AnalyticsBeta-style/modules/_loading.scss",
"../../../assets/AnalyticsBeta-style/modules/_download-library.scss",
"../../../assets/AnalyticsBeta-style/modules/_nomenon-details.scss",
"../../../assets/AnalyticsBeta-style/modules/_theme-colors.scss",
"../../../assets/AnalyticsBeta-style/modules/_user_details.scss";


@import "../../../assets/Analytics-style/base/_colors.scss",
"../../../assets/Analytics-style/base/_settings.scss",
"../../../assets/Analytics-style/base/_mixins.scss",
"../../../assets/Analytics-style/base/_selectors.scss",
"../../../assets/Analytics-style/base/_functions.scss",
"../../../assets/Analytics-style/base/_helpers.scss",
"../../../assets/Analytics-style/base/_typography.scss", "./rickshaw.min.css",
"../../../styles/sarala.css",
"../../../assets/Analytics-style/modules/_hero.scss";

@import "../../../assets/Analytics-style/themes/_vendor.scss";
@import "../../Data/components/palettes.scss";


@import "../../../assets/Analytics-style/state/_animation.scss",
  "../../../assets/Analytics-style/state/_accessibility.scss",
  "../../../assets/Analytics-style/state/_debug.scss",
  "../../../assets/Analytics-style/state/_scrolled.scss";

body {
  font-family: "Open Sans", Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  width: 100%;
  -webkit-align-items: stretch;
  align-items: stretch;
  height: auto;
  color: #333 !important;
  background: #f4f7fb url(/static/media/icon_physioage.22a56542.svg) no-repeat center !important;
  font-size: 14px;
}

// .body-class{
//   // background: #E3E8EA !important;
//   overflow: hidden;
// }
// .analytics-screen {
//   &.beta{
//     font-family: 'Noto Sans' !important;
//     background: #E3E8EA;
//   }
// }
.body-class{
  background: #E3E8EA !important;
}
.analytics-screen {
  &.beta{
    font-family: 'Noto Sans' !important;
    background: #E3E8EA;
    overflow-y: hidden;
  }
}
.recommendation-review .content {
  background-image: url("../../../assets/images/analytics-background-spring.jpg");
}

.loader-fafa {
  color: white;
}

.success {
  color: #24a54e !important;
}

.danger {
  color: #d9534f !important;
}

.analytics-blue {
  color: #2980B9 !important;
}

.top-nav-site-logo {
  width: 137.65628051757812px;
  height: 26.897558212280273px;
  top: 11px;
  left: 8px;
}

.left-nav {
  height: calc(100vh - 48px);
  background:  var(--left-nav-bg);
  width: 100%;
}

.top-navbar .nav-logo {
  height: 46px !important;
  width: 152px;
}

.top-navbar {
  position: fixed;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  // margin-top:-$primary-nav-height;
  left: 0;
  background: white;
  @media screen and (min-device-width: 768px)
  and (max-device-width: 1024px){
    .nav-logo{
      width: 145px;
    }
  } 
  &.analytics-beta{
    height: 60px;
    .container-fluid{
      height: 100%;
      .header-container{
        height: 100%;
        .header-breadcrumb{
          height: 100%;
          padding: 6px 0;
          padding-right: 15px;
          .no-gutters{
            height: 100%;
            .bg-top-nav{
              max-width: fit-content;
              .top-nav-brand-logo{
                .nav-logo{
                  height: 60px !important;
                  padding-top: 3px ;
                  padding-bottom: 3px;
                }
              }
            }
          }
        }
      }
    }
  }

  .header-container{
    .bg-top-nav{
      max-width: fit-content;
    }
  } 
  .container-fluid{
    padding: 0;
  }
  // box-shadow: 0 1px 3px rgba(51, 51, 51, 0.12), 0 1px 2px rgba(51, 51, 51, 0.24);
  ~.sidebar-left {
    display: block;
    // right:-$sidebar-width;
    transition: all 0.15s;
  }

  +input[type="checkbox"] {
    position: fixed;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    text-transform: none;
  }

  @media (max-width:$screen-sm-ls) {
    +input[type="checkbox"]:checked {
      +.sidebar-left {
        right: 0;
      }
    }
  }

  .max-container {
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: $screen-lg) and (min-width: $screen-sm) {
      padding: 0 $gap;
    }
  }

  .primary,
  .secondary {
    display: flex;
    background: transparent;
  }

  .primary {
    height: 60px;
    position: relative;

    @media (min-width:$screen-sm) {
      height: $primary-nav-height;
    }
  }

  .brand-logo {
    margin-right: auto;

    @media (min-width:$screen-sm) {
      padding-left: $gap-sm;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .nav-logo {
    height: 53px !important;

    @media (max-width:$screen-sm-min) {
      max-width: 143px;
    }
  }

  nav.primary {
    background-color: #2D6683;

    a.active {
      color: white;
      // background-color: darken($blue2,10%);
    }
  }

  .btn-exit {
    @media (max-width:$screen-sm-ls) {
      display: none;
    }
  }

  .header-breadcrumb+.header-breadcrumb {
    padding-left: $gap-md;
  }

  .header-breadcrumb {
    color: white;

    // @media (max-width:$screen-sm-ls) {
    //   display: none;
    // }

    .strong {
      @include font-bold;
      font-size: 1.1rem;
    }

    line-height: 44px;
  }

  .p-name {
    font-size: 12.64px;
    font-weight: 700;
  }


  .p-age {
    font-size: 12.64px;
  }
  .most-recent-visit{
    font-size: 11.26px;
  }

  @media screen and (min-device-width: 992px)
  and (max-device-width: 1439px) {
  .col-auto {
      margin-left: 75px;
  }
}
}


button.provider-Notes {
  border-color: var(--provider-n-b-b);
  color: var(--provider-n-b-c);
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--provider-n-b-bg);
}

button.provider-Notes:hover {
  color: var(--provider-n-b-c-h);
  background: var(--provider-n-b-bg-h);
}

label.visite-date {
  background-color: #ffffff;
  color: black;
  border-radius: 4px;
  margin: 0px;
  vertical-align: middle;
  padding-left: 6px;
  padding-right: 6px;
  line-height: 1.5;
  font-size: 11.26px;
}

.info-logo {
  line-height: 0;
  margin-top: 8px;
  cursor: pointer;
}

.left-nav .left-navbar-arrow {
  position: relative;
  margin-top: -6px;
  position: relative;
  margin-bottom: 5px;
  width: 100%;
  padding-left: 10px;

  .left_arrow {
    float: right;
  }
}

.ai-baseline {
  align-items: baseline !important;
}

.ai-baseline .sidebar {
  li {
    // padding-bottom: 16px; 
    padding-left: 5px;
    margin-bottom: 16px;
    // margin-left: -7px;
    color: var(--left-nav-li-c);

    .text {
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      cursor: pointer;
      display: flex;
      flex-direction: row;
    }
    img, svg {
      margin-right: 5px;
      vertical-align: sub;
    }
    img {
      margin-right: 5px;
      vertical-align: middle;
      filter: var(--ecg-icon);
    }
  }

  .open-nav .MuiSvgIcon-root {
    margin-bottom: 2px !important;
  }
  .close-nav .MuiSvgIcon-root {
    margin-top: 2px !important;
  }

  .active_li {
    border-radius: 3px;
    color: var(--left-nav-active-li-c);
    background: var(--left-nav-active-li-bg);
    ;
    // width: 186px;
    // height: 32px;
    padding-top: 3px;
    // padding-left: 9px;
    // padding-right: 9px;
    padding-bottom: 5px;
    width: 100%;
    img{
      filter: none;
    }
    &.close-nav {
      // margin-left: -9px !important;

      img {
        margin-right: 7px !important;
        filter: none;
      }
    }
  }

  .bottom-menu {
    position: absolute;
    bottom: 0%;
  }
}

.gray-text {
  color: #87878A;
}

.add-btn {
  color: #ffffff !important;
  padding-left: 15px;
  padding-right: 15px;
  height: 30px;
  width: 72px;
  padding-top: 4px;
  padding-bottom: 5px;
  border-radius: 3px !important;
  background: var(--btn-bg) !important;
  outline: none;
}
.analytics-screen {
  .btn.btn-primary{
    background: var(--btn-bg) !important;
    border-color: var(--btn-bg) !important;
  }

  .btn.btn-primary:hover{
    background: var(--btn-bg-h) !important;
    border-color: var(--btn-bg) !important;
  }
}

.add-btn:hover {
  color: #ffffff !important;
  background: var(--btn-bg-h) !important;
}

.medication-list:hover {
  color: #ffffff !important;
  background: var(--btn-bg) !important;
}

.cancle-btn {
  border: 1px solid red;
  padding: 0px;
  width: 72px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 3px !important;
  border: 1px solid #DEDEDE !important; 
}

.recommendation {
  label {
    color: #000000
  }
  .drawer-warning {
    width: 620px;
    min-height: 245px;
    background-color: #fff;
    z-index: 1000000000;
    // padding: 10px 15px 15px 15px;
    border-radius: 3px;
    position: fixed;
    top: 34%;
    left: 50%;
    padding: 0;
    transform: translate(-50%, -50%);
    overflow-x: hidden;
    .header {
      background: #FFF;
      // border-bottom: 1px solid #80808052;
      padding: 10px 15px 10px 15px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .card-body-div{
      padding: 10px 15px 10px 15px;
    }
  }
}

.bold-14-text {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.bold-13-text {
  color: #000;
  font-size: 12.64px;
  font-weight: 600;
}

div.info-logo span {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #333;
  color: #fff;
}

div.info-logo:hover span {
  display: block;
}

.survey-tooltip {
  text-align: left !important;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.98);
  padding: 20px;
  min-width: 200px !important;
  max-height: 200px !important;

  td {
    padding-bottom: 10px;
  }
}

.tooltip-inner {
  min-width: 230px !important;
  max-height: 220px !important;
  padding: 10px;
}

.mt-48 {
  margin-top: 48px;
}

.ml-6 {
  margin-left: 6px;
}

.mr-6 {
  margin-right: 6px;
}

.c-p {
  cursor: pointer;
}

.provider-notes,
.recommendation {
  .alert {
    position: relative;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    padding: 8px 8px 8px 8px;
  }

  .alert-danger {
    color: #e74c3c;
    background-color: white;
    border-color: #e74c3c;
    height: -14px;
    margin-top: 10px;
    min-height: 41px;
  }
}

.ml-4px {
  margin-left: 4px;
}

.mr-4px {
  margin-right: 4px;
}


.left-div-analytics-nav {
  width: 13%;
  // transition: 0.3s ease !important;
}

.p-4px {
  padding: 4px;
}

.top-nav {
  min-height: 48px;
  background-color: var(--top-nav-bg);
}
.provider-notes-icon{
  display: none;
}
.button.switch-to-old-btn{
  display: none;
}


.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.w-100 {
  width: 100%;
}

.ml-20 {
  margin-left: -20px;
}

.w-86 {
  width: 86%;
}

.w-95 {
  width: 95%;
}

.mt-7px {
  margin-top: 7px;
}

.height-60px {
  height: 60px;
}

.wip {
  font-size: 26px;
  padding-bottom: 20px;
  text-align: center;
  padding-top: 10px;
  height: 75px;
  background: white;
  width: 30%;
  margin: auto;
}

.wip-img {
  margin: auto;
  width: 300px;

  img {
    width: 100%;
  }
}

.padding-0 {
  padding: 0px;
}

.open-nav {
  // width: 13rem !important;

  .nav-name {
    display: initial !important;
  }
}

.close-nav {
  .nav-name {
    display: none !important;
  }
  .MuiSvgIcon-root{
    margin-bottom: 0;
  }
}

.main-div-nav-close {
  width: 100% !important;
}

.main-div-nav-close .sidebar .left-navbar-arrow a .left_arrow-analytics {
  transform: rotate(180deg) !important;
  float: none;
}

.main-div-nav-open .sidebar .left-navbar-arrow a .left_arrow-analytics {
  transform: rotate(0deg) !important;
  float: right !important;
}

.analytics-left-nav-open {
  width: 13.5% !important;
  .navbar{
    padding: 0.5rem 0.5rem;
  }
}

.analytics-left-nav-close {
  width: 3.25% !important;
  .navbar{
    padding: 0.5rem 0.5rem;
  }
}

@media screen and (min-device-width: 992px)
  and (max-device-width: 1439px) {
    .analytics-left-nav-open {
      width: 19.5% !important;
    }

    .analytics-left-nav-close {
      width: 4.5% !important;
      .main-div-nav-close{
        .sidebar{
          align-items: center;
        }
      }
    }
}

.biomarkers-grid-view {
  display: grid;
}

.no-meta-recommendations {
  color: var(--text-active-c) !important;
  align-items: center;
}

.instructions {
  &.no-recommendations {
    // height: 85%;
    padding: 1rem;
    .card-content-container{
      height: 100%;
      p{
        margin-bottom: 0rem;
      }
    }
  }
}

.fa-area-chart {
  // display: flex;
  // align-items: end;
  // flex-direction: column;
}

.biomarkers-grid-view{
  display: grid;
}

.body-container{
  height: calc(100vh - 53px);
  &.analytics-beta{
    height: calc(100vh - 60px);
  }
}

#mySidebar .list-group{
  width: 100%;
}

.main-div-nav-open{
  .sidebar li{
      padding: 5px 15px;
      // height: 35px;
    }
}

.active_li{
  .text{
    padding-top: 10x;
    .MuiSvgIcon-root{
      margin-bottom: 0 !important;
    }
  }
}

.analytics-left-nav-close {
  .main-div-nav-close{
    .sidebar{
      align-items: center;
      li{
        padding-left: 7px;
      }
      .active_li{
        height: 30px;
      }
    }
  }
}

.main-div-nav-open{
  .sidebar{
    .bottom-menu{
      width: 95%;
      padding-right: 4px;
    }
  }
}

.sidebar{
  &.list-group{
    margin-top: 10px;
  }
}


@media screen and (min-device-width: 768px) and (max-device-width: 992px){
  .analytics-left-nav-close {
    width: 6.25% !important;
  }
  .analytics-left-nav-open {
    width: 20% !important;
  }  

  .right-div {
    overflow-x: auto;
    &.analytics-right-nav-close{
      width: 93.75% !important
    }

    &.analytics-right-nav-open {
      width: 80% !important;
    }
  }
  button.provider-Notes {
    padding-left: 5px;
    padding-right: 5px;
  }
  .top-navbar {
    .p-name {
      font-size: 14px;
      line-height: 1.5;
    }
    .p-age {
      font-size: 13px;
      line-height: 1.5;
    }  
    .header-breadcrumb {
      line-height: 30px;
    }
  }
}


@media (min-width: 767px) {
  .right-div {
    &.analytics-right-nav-close{
      width: 96.75%;
    }

    &.analytics-right-nav-open {
      width: 87%;
    }
    // margin-left: 17px;
    // margin-top: 4px;
    // margin-bottom: 8px;
  }
  .top-nav {
    min-height: 48px;
    .left-nav-icon-m{
      display: none;
    }
  }
  .provider-notes-icon{
    display: none;
  }
  .button.switch-to-old-btn{
    display: none;
  }  
  
}

@media (max-width: 767px) {
  body {
    overflow: scroll !important;
    .top-nav {
      min-height: 48px;
      color: #1F526B !important;
      .provider-notes-icon{
        display: block;
        svg{
          float: right;
          margin-top: 11px;
        }
      }
      button.switch-to-old-btn {
        display: block;
        border-color: #1f526b;
        color: #1f526b;
        padding-left: 3px;
        padding-right: 3px;
        font-size: 9px;
        margin-top: 9px;
        float: right;
        font-size: 6px;
      }
      .left-nav-icon-m{
        display: block !important;
        .page-title{
          font-size: 14px;
          font-weight: 700;
          color: var(--dash-report-card-text-c);
        }
        svg{
          float: right;
          margin-top: 11px;
        }
      }
    }
    .top-navbar {
      top: 0;
      z-index: 1000;
      width: 100%;
      left: 0;
      background: white;
      .p-name {
        font-size: 14px;
        font-weight: 700;
      }
      .p-age {
        font-size: 14px;
      }
    }  
    .top-navbar .header-breadcrumb {
      color: var(--top-nav-mobile-text);
      line-height: 44px;
    }
    .bg-top-nav{
      background: var(--top-nav-bg) !important;;
    }
    .provider-top-nav{
      display: none !important;
    }
    .top-navbar.analytics-menu .top-nav {
      .switch-label {
        cursor: pointer;
        width: 40px;
        height: 18px;
        z-index: 10;
        // margin-right: 13px;
        .switch-button {
          content: '';
          position: absolute;
          top: 1px;
          // left: 3px;
          width: 17px;
          height: 16px;
        }  
      }
      .switch-checkbox:checked + .switch-label .switch-button {
        left: calc(100% - 20px);
      }
    }
  }
  .analytics-left-nav-close {
    display: none;
  }
  .analytics-left-nav-open {
    width: 200px !important;
    z-index: 10;
    position: fixed;
    top:  137px;
    .left-nav {
      height: calc(100vh - 48px);
      background: white;
      width: 100%;
  }
    .sidebar li {
      padding-left: 5px;
      margin-bottom: 10px;
      color: black;
      img{
        filter: none;
      }
    }
    .sidebar .active_li {
      border-radius: 3px;
      color: var(--mobile-nav-active-li-c);
      background: none;
      padding-top: 3px;
      padding-bottom: 5px;
      width: 100%;
    }
  }
  .right-div {
    width: 100% !important;
    margin-left: 0px;
    margin-top: 2px;
    margin-bottom: 8px;
    margin-right: 2px;
    position: absolute;
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
  }
  .provider-notes .drawer-warning {
    min-width: 97% !important;
    max-width: 97%;
    left: 50%;
    top: 9%;
  }

  .bg-w{
    background: white;
  }
  .nav-overlay-sm {
    transition: all 0.5s;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 9;
    bottom: 0;
    top: 0;
  }
  .recommendation{
    .drawer-warning {
      min-width: 97% !important;
      max-width: 97%;
      top: 50% !important;
      height: 95%;
    }
  }
  .sidebar .bottom-menu {
    position: absolute;
    bottom: 14% !important;
  }     
}

.z-index-100 {
  z-index: 1000;
}
.download-icon{
  .MuiSvgIcon-root{
    height: 16px;
    width: 16px;
  }
}
.close-icon{
  color: #ff4444;
  stroke: none;
}  

@media (max-width: 479px){
  .chart_body {
      margin: 0 auto;
      left: 40px;
  }
}

@media (max-width: 1024px) and (min-height: 1366px){
  .dashboard{
    .graph-analytics-div-1024{
      padding-left: 28px !important;
    }
    .gpa-chart-dashboard-1024 {
      padding-left: 22px !important;
    }
    .biomarker-card-div-1024{
      max-width: 50%;
    }
    .bio-name-div-1024{
      padding-top: 15px;
    }
    .top-navbar .col-auto {
      margin-left: 0px !important;
    }
  }
  .report-card .category-data-div .recommendation-container .recommendation-more-info .recommendation-list-table {
    height: 72%;
  }
  .dashboard .report-card-section .dashboard-charts-main-div {
    height: auto;
    padding-top: 0px;
    justify-content: center;
  } 
  .dashboard .report-card-section {
    width: 100%;
  }
  .dashboard .dashboard-report-card .category-overview {
    height: 56%;
    padding-top: 20px;
  }
  .dashboard .biomarkers-of-Aging-plan {
    height: 21.5%;
  }
  .dashboard .treatment-plan {
    height: 78%;
  }  
  .dashboard .treatment-plan .header-div {
    height: 5%;
  }
  .report-card .category-data-div .border-bottom {
    border-bottom: 1px solid #DADDE7;
    padding-bottom: 10px;
    height: 30%;
  }
  .report-card .category-data-div .recommendation-container {
    height: 70%;
  }
 }

@media screen and (max-width: 1024px) and (max-height: 600px) {
  .dashboard{
    .graph-analytics-div-1024{
      margin-top: -28px;
      padding-left: 28px !important;
    }
    .gpa-chart-dashboard-1024 {
      padding-left: 22px !important;
    } 
    .biomarker-card-div-1024{
      max-width: 50%;
    }
    .bio-name-div-1024{
     padding-top: 15px;
    }
    .top-navbar .col-auto {
      margin-left: 0px !important;
    }
  }
  .report-card .category-data-div .recommendation-container .recommendation-more-info .recommendation-list-table {
    height: 72%;
  }
  // .dashboard .biomarkers-of-Aging-plan .biomarkers-grid-view {
  //   height: 72%;
  // } 
  .dashboard .biomarkers-of-Aging-plan {
    height: 46.5%;
  }  
  .dashboard .treatment-plan {
    height: 52%;
  }  
}
.top-navbar.analytics-menu{
.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  cursor: pointer;
  width: 55px;
  height: 27px;
  background: grey;
  border-radius: 100px;
  position: relative;
  // transition: background-color .2s;
  margin-right: 13px;
  margin-bottom: 0;
}

.switch-label .switch-button {
  content: '';
  position: absolute;
  top: 1px;
  left: 3px;
  width: 25px;
  height: 25px;
  border-radius: 45px;
  background: var(--switch-button-bg);
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 27px);
  // transform: translateX(-100%);
}

.switch-label:active .switch-button {
  width: 26px;
}
}
.anonymized-text {
  filter: blur(5px);
}

.analytics-screen {
  &.beta {
    overflow: hidden;
    font-family: 'Inter' !important;
    &.light-theme {
      background: $light-background;
      color: #333;
      .dashboard {
        .dashboard-report-card, .treatment-plan, .biomarkers-of-Aging-plan  {
            background: $light-component-background;
            box-shadow: $light-component-box-shadow
          }
      }  
      .biomarkers-of-Aging-plan .biomarker-container .header-div, .treatment-plan .header-div {
        background: $light-component-background;
        box-shadow: $light-component-box-shadow;
        .heading {
          color: #0D3E56;
        }
        .view-all-text{
          color: #1C1B1F;
        }  
        .text {
          color: #80818B;
        }
        .active {
          color: var(--text-active-c);
        } 
      }
      .render-biomarker, .biomarker-of-age-data .biomarker-card{
        .risk-bg{
          background: #fff0f4;
          box-shadow: 0 2px 2px 0 rgba(169,0,0,.2);
        }
        .safe-bg{
          background: #F3FFF3;
          box-shadow: 0px 2px 2px 0px #A9000033;

        }
        .normal-bg{
          background: #F9F9F9;
          box-shadow: 0px 2px 2px 0px #A9000033;
        }

        .risk-br{
          border: 0.5px solid #A90000
        }
        .safe-br{
          border: 0.5px solid #00A900
        }
        .normal-br{
          border: 0.5px solid #007AB7
        }
        .risk {
          color: #A90000;
        }
        .safe {
          color: #00A900;
        }
        .normal {
          color: #216689;
        }
        .warning {
          color: #ffc107;
        }
        .color-white{
          color: #F1F1F1;
        }
        .sparkline{
          svg{
            path{
              stroke-width: 1;
            }
            circle{
              stroke: #000000;
              stroke-width: 2;
              fill: white;
            }
            .sparkline-age-circle{
              display: none;
            }
          }
        }
      }  
      
      .dashboard{
        .report-card-text {
          color: var(--dash-report-card-text-c);
        }
        .report-date-text {
          color: var(--dash-report-date-text-c);
        }  
        .pointer, .date-text {
          color: var(--dark-color, #263238);
        } 
        .changed-text {
          color: #000;
        } 
        .treatment-plan { 
          .recommendation-list .recommendation-body {
            .recommendation-body-text .recommendation-indicator-expanded {
              color: #272727;
            }
            .recommendation-indicator{
              color: #170F49
            }
            .recommendation-body-text-expanded {
              color: var(--recommendation-body-text-expanded);
            }
            .heading-1, .text-1 {
              color: #6F6C90;
            }
            .recommendation-indicator-expanded{
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
              letter-spacing: 0px;
              margin-bottom: 0;
            }  
          }  
        }   

        .noumenon-filter {
          .dropdown-menu{
            border: 1px solid #fff;
          }
         .dropdown-item{
            justify-content: center;
            display: flex;
            font-size: 13px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.15399999916553497px;
          }
          .dropdown-item.active{
            // text-decoration: none;
            background-color: var(--dropdown-item-active-bg);
            color: var(--dropdown-item-active-c) !important;
          }  
          .dropdown-item:active {
            // text-decoration: none;
            background-color: #E5F6FF;
            color: #216689 !important;
          }
        }
        .nuomenon-table {
          tbody tr{
            border-width: 0.5px 0px 0.5px 0px;
            border-style: solid;
            border-color: #D7D7D7;
            border-left: none;
            border-right: none;
          }
        }
      }
      .skeleton-box {
        background-color: #fff;
        &:before {
          background-color: #b4bcc1;
        }
      }
    } 

    .chart-content{
      .chart_container{
        .y_axis{
          display: none;
        }
        .rickshaw_graph{
          svg path{
            stroke-width: 1;
          }
          #age-line{
            stroke-width: 2;
          }
          .y_grid{
            .tick{
              stroke-dasharray: none;
            }
          }
          .x_tick{
            border-left: none;
            .title{
              bottom: -20px;
            }
          }
          circle{
            stroke-width: 3;
            fill: white;
            r: 4;
          }

        }
      }
      .chart_container::-webkit-scrollbar{
        display: none;
      }
    }

    &.dark-theme {
      background: $dark-background;
      color: #F1F1F1;
      .dashboard {
        .dashboard-report-card, .treatment-plan, .biomarkers-of-Aging-plan {
          background: $dark-component-background;
          box-shadow: $dark-component-box-shadow
        }
      }
      .biomarkers-of-Aging-plan .biomarker-container .header-div, .treatment-plan .header-div {
        background:  $dark-header-div;
        box-shadow: $dark-header-div-shadow;
        .heading, .view-all-text, .text {
          color: #F1F1F1;
        }
        .active {
          color:  var(--text-active-c-D);
        }
        .normal-heading{
          color: #80818B;
        }
      }
      .render-biomarker, .biomarker-of-age-data .biomarker-card{
        .risk-bg{
          background-image: linear-gradient(96.89deg, #C73B3B 5.39%, #A56B6B 96.8%);
          box-shadow: 0px 2px 2px 0px #A9000033;
        }
        .safe-bg{
          background-image:linear-gradient(90deg, #459C45 0%, #60BE60 100%);
          box-shadow: 0px 2px 2px 0px #A9000033;
        }
        .normal-bg{
          background-image: linear-gradient(96.89deg, #33A5C9 5.39%, #73BDD4 96.8%),
          linear-gradient(0deg, #393939, #393939);
        }

        .risk-br{
          border: 0.5px solid #A90000
        }
        .safe-br{
          border: 0.5px solid #00A900
        }
        .normal-br{
          border: 0.5px solid #007AB7
        }
        .biomarker-card .biomarker {
          .name, .age, .average, .date {
            color: white;
            stroke: white;
          }
        }
        .biomarker-card .icon svg{
          color: white;
           stroke: white;
        }

        .sparkline{
          svg{
            path{
              stroke: #ffffff;
              stroke-width: 1;
            }
            circle{
              stroke: #33363E;
              stroke-width: 2;
              fill: white;
            }
            .sparkline-age-circle{
              display: none;
            }
          }
        }
      }
      .dashboard{
        .report-card-text {
          color: inherit;
        }
        .report-date-text {
          color: var(--dash-report-date-text-c-d);
        }  
        .pointer, .date-text {
          color: var(--dash-report-pointer-date-text-c-d);
        } 
        .changed-text {
          color: inherit;
        } 
        .treatment-plan {  
          .recommendation-list .recommendation-body{
            background: #1F1F1F;
            .recommendation-body-text, svg{
              color: #F1F1F1;
            }
            .recommendation-body-text-expanded {
              color: var(--recommendation-body-text-expanded-d);
            }

            .recommendation-indicator-expanded, .recommendation-indicator{
              color: #AAAAAA;
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
              letter-spacing: 0px;
              margin-bottom: 0;
            }
            
            .heading-1, .text-1 {
              color: #EEEEEE;
            }  
          } 
          .interpretations-information{
            background-color: #1f1f1f;
            .card {
              margin-top: 20px !important;
              background: #1f1f1f;
              border-width: 0.5px, 0px, 0.5px, 0px;
              border-style: solid;
              border-color: #5B5B5B;
              .card-header{
                color: #F1F1F1;
                background-color: #1f1f1f;
                border-bottom: 0.5px solid #5B5B5B;
              }
              .card-body{
                background-color: $dark-component-background !important;
                color: #F1F1F1
              }
            }
          }
        }
        .noumenon-filter {
          .view-all-text{
            color: var( --view-all-text-c-d);
          }
          .btn svg { 
            color: var(--filter-icon-c-d);
          }  
          .dropdown{
            .btn ,.btn.btn-primary:hover, .btn.btn-primary:focus{
              color: $dark-component-background;
            }
          }
          .dropdown-menu{
            border: none;
            background-color: #1f1f1f !important;
          }
          .dropdown-item{
            justify-content: center;
            display: flex;
            font-size: 13px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.15399999916553497px;
          }  
          .dropdown-item{
            // text-decoration: none;
            background-color: #2F2F2F;
            color: #F1F1F1 !important;
          }
          .dropdown-item.active {
            text-decoration: none;
            background-color: var(--dropdown-item-active-bg-d);
            color: var(--dropdown-item-active-c-d) !important;
          }
          .view-all-text{
            color: var( --view-all-text-c-d);
          }
          .btn svg { 
            color: var(--filter-icon-c-d)
          }
        }
        .nuomenon-table {
          td, th{
            color: #F1F1F1
          }
          tbody tr{
            border-width: 0.5px 0px 0.5px 0px;
            border-style: solid;
            border-color: #5B5B5B;
            border-left: none;
            border-right: none;
          }
          tbody td{
            .red-icon{
              padding-left: 5px;
            }
          }
          thead{
            th{
              border: none;
            }
          }  

          thead,thead tr, th{
            background: #1F1F1F;
          }  
        }
      }
      .table-hover tbody tr:hover {
        background-color: #2f2f2F
      }  
      .chart-content{
        .chart_container{
          .y_axis{
            display: none;
          }
          .rickshaw_graph{
            svg path{
              stroke-width: 1;
            }
            .detail{
              .item{
                background: #2F2F2F !important;
                .date{
                  color: white !important;
                }
              }
              .item{
                &.left::after{
                  border-right-color: #2F2F2F !important;
                }
                &.right::after{
                  border-left-color: #2F2F2F !important;
                }
              }
            }
            circle{
              r: 4 !important
            }
            .x_tick{
              border-left: none;
              .title{
                bottom: -20px;
                color: white;
                opacity: 1;
              }
            }
            .y_grid{
              line{
                stroke: #5B5B5B;
                opacity: 0.5;
              }
              .tick{
                stroke: #fdf9f9;
                opacity: 0.4;
                text{
                  font-size: 11px;
                }
              }
            }
            .grade-Ap{
              fill: #a3debc !important;
            }
            .grade-A{
              fill: #9ee4b4 !important;
            }
            .grade-Am{
              fill: #97de9e !important;
            }
            .grade-Bp{
              fill: #a4db9b !important;
            }
            .grade-B{
              fill: #b9daa2 !important;
            }
            .grade-Bm{
              fill: #c0e29f !important;
            }
            .grade-Cp{
              fill: #d4d98e !important;
            }
            .grade-C{
              fill: #d7d799 !important;
            }
            .grade-Cm{
              fill: #d6c687 !important;
            }
            .grade-Dp{
              fill: #caad8a !important;
            }
            .grade-D{
              fill: #d49e87 !important;
            }
            .grade-Dm{
              fill: #e6b4aa !important;
            }
            .grade-F{
              fill: #e69996 !important;
            }
          }
        }
        .chart_container::-webkit-scrollbar{
          display: none;
        }
      }
      .drawer-warning-overlay {
        background: var(--nav-overlay-sm-bg);
      }  
      .dashboard-loading-div{
        background-color: $dark-background;
        .skeleton-box {
          background-color: $dark-background;
          &:before {
            background-color: #b4bcc16b;
          }
        }
      }
    }    
  }
}

.nuomenon-table::-webkit-scrollbar, .recommendation-list::-webkit-scrollbar, 
  .recommendation-list-table::-webkit-scrollbar, .category-dropdown-div .MuiDrawer-paper::-webkit-scrollbar,  .category-dropdown-div ul.dropdown-menu::-webkit-scrollbar,  .category-dropdown-div .rbt-menu.dropdown-menu.show::-webkit-scrollbar, .recommendation-search-field .rbt-menu.dropdown-menu.show::-webkit-scrollbar, .biomarker-of-age .biomarker-card::-webkit-scrollbar, .biomarkers-overview-graph::-webkit-scrollbar,  .nomenon-details .drawer-warning .noumenon-list::-webkit-scrollbar, .category-graph-section::-webkit-scrollbar, .treatment-plan ul.dropdown-menu::-webkit-scrollbar, .recommendation ul.dropdown-menu::-webkit-scrollbar, .questionnaireFormStyle::-webkit-scrollbar, .interpretations-information::-webkit-scrollbar, .interpretation-beta::-webkit-scrollbar,
  .rating-div::-webkit-scrollbar, ul.react-datepicker__time-list::-webkit-scrollbar, 
  .css-11unzgr::-webkit-scrollbar, .problem .drawer-warning::-webkit-scrollbar, .provider-notes .drawer-warning::-webkit-scrollbar, .problem .css-qr46ko::-webkit-scrollbar, .problem .rbt-menu.dropdown-menu.show::-webkit-scrollbar, .recommendation .drawer-warning::-webkit-scrollbar, .rbt-menu.dropdown-menu.show::-webkit-scrollbar {
  width: 0px;
}


.popup-dark-theme{
  background-color: #000000;
  color: #F1F1F1;
  // border: 1px solid #808080ba;
  border-radius: 4px;
  .drawer-cancel {
    background-color: transparent;
    color: white;
    border: 1px solid #939191;
  }
}

.drawer-warning-overlay-dark-theme{
  background-color: var(--nav-overlay-sm-bg);
}


body:has(.dark-theme) {
  background: $dark-component-background !important;
}



@media (max-width: 767px){
  body{
    .analytics-screen {
      &.beta {
        &.dark-theme {
          @media (max-width: 767px){ 
            .right-div{
              background: $dark-component-background;
              box-shadow: $dark-component-box-shadow
            }
          }  
          .dashboard-container{
            background: $dark-component-background;
            box-shadow: $dark-component-box-shadow
          }
          .top-navbar {
            button.switch-to-old-btn{
              border-color: var(--switch-button-bg-d);
              color: var(--switch-button-bg-d);
              // background-color: var(--provider-n-b-bg);
            }
            .header-breadcrumb {
              color: var( --top-nav-mobile-text-d);
            }    
            .left-nav-icon-m .page-title {
              color: var(--top-nav-mobile-text-d);
            }
          }
          .top-navbar.analytics-menu .switch-label .switch-button {
            background: var(--switch-button-bg-d);
          }
          .analytics-left-nav-open{
            .left-nav {
              background: $dark-header-div;
            }
            .sidebar {
              .active_li{
                color: var( --mobile-text-color-d)
              }
              li{
                color: #F1F1F1
              }
              li.active_li{
                svg{
                  filter: var(--li-svg-filter-d);
                }
                img{
                  filter: var(--li-svg-filter-d);
                }
              }
              li img{
                filter: brightness(10);
              }
              
            }    
          }  
          .nav-overlay-sm{
            background-color: var(--nav-overlay-sm-bg);
          }
          .bg-black-header{
            background-color: $dark-header-div;
          }
        }
      } 
    }
  }
}
.dashboard-loading-div{
  background: #e3e8ea ;
  .height-95{
    height: 95vh;
  }
  .height-25vh{
    height: 25vh;
  }

  .height-70vh{
    height: 70vh;
  }
  .height-30{
    height: 30vh;
  }

  .height-65{
    height: 65vh;
  }
  .height-53{
    height: 53px;
  }

  .height-8{
    height: 8%;
  }
  .height-40{
    height: 40px;
  }
  .width-70{
    width: 70px;
  }
  .width-4_2{
    width: 4.2%;
  }
  .width-95_8{
    width: 95.8%;
  }
  .br-r{
    border-radius: 4px;
  }

  .width-23{
    width: 23%;
  }

  .skeleton-box {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    &:before {
      content: '';
      height: 100vh;
      display: block;
      background-color: #b4bcc1;
      box-shadow: -48px 78px 0 -48px #b4bcc1, -51px 102px 0 -51px #b4bcc1;
    }

    &::after {
      content: '';
      background-color: #333;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-name: loader-animate;
      animation-timing-function: linear infinite;
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
      background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 30%, rgba(255, 255, 255, 0) 81%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
    }  
    @keyframes loader-animate {
      0% {
          transform: translate3d(-100%, 0, 0);
      }
      
      100% {
          transform: translate3d(100%, 0, 0);
      }
    }
  } 
}   

      
@media (max-width: 768px) {
  .analytics-screen {
    &.beta{
      overflow: auto !important;
    }
  }
}


.questionnaire-light-theme, .questionnaire-dark-theme {
  button {
    padding-left: 25px;
    padding-right: 25px;
    color: #fff !important;
  }
  button.survey-save-btn {
    background-color: #00A900;
  }
  button.survey-btn{
    background-color: var(--btn-bg);    
  }
  button.survey-btn:hover{
    background-color: var(--btn-bg-h); ;
  }
  button.survey-save-btn:hover {
    background-color: #129812;
  }  
  .card{
    border: none !important;
  }
  .subQuestion-text{
    font-family: Inter;
    font-size: 12.64px;
    font-weight: 400;
    line-height: 15.3px;
    text-align: left;
  }
}  

.feedback-button {
  height: 25px;
  border: none;
  background: var(--feedback-btn-b);
  width: 100px;
  // line-height: 32px;
  -webkit-transform: rotate(-90deg);
  font-weight: 600;
  color: white;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  text-align: center;
  font-size: 16px;
  position: fixed;
  right: -41px;
  top: 40%;
  font-family: "Roboto", helvetica, arial, sans-serif;
  z-index: 999;
  border-radius: 4px;
}

.w-80{
  width: 80%;
  hyphens: auto;
  word-break: break-word;
}

.surveyHeader{
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  background-color: var(--surveyHeader-bg);
  border-color: var(--surveyHeader-bc);
  color: #ffffff;
}

button.surveyRejectBtn{
  background-color: #ffffff;
  border-color: var(--surveybtn-bc);
  color: var(--surveybtn-bc);
  padding-left: 20px;
  padding-right: 20px;
}

button.surveyRejectBtn:hover{
  background-color: #ffffff;
  border-color: var(--surveybtn-bc);
  color: var(--surveybtn-bc);
}

button.surveyAcceptBtn{
  background-color: var(--surveybtn-bc);
  border-color:var(--surveybtn-bc);
  color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
}

button.surveyAcceptBtn:hover{
  background-color: var(--surveybtn-bc);
  border-color: var(--surveybtn-bc);
  color: #ffffff;
}

.reviewSurveyHeader{
  padding: 3px;
  background-color: var(--btn-bg);
  color: white;
  cursor: pointer;
  height: 40px;
  .survey-name{
    vertical-align: middle !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 5px;
  }
}

.reviewSurveyHeader:hover{
  background-color: var(--btn-bg-h);   
}

.analytics-screen.beta .loader .circle {
  background: var(--loader-circle-bg, #134570)
}

.analytics-screen.beta {
  .chart_body.rickshaw_graph svg{
     margin-right: 12px;
  }
}

.report-card .category-data-div .category-non-numeric-value.recommendation-list-table {
    .react-bootstrap-table table{
      table-layout: fixed !important;;
    }
    table {
      tr td { 
        overflow-wrap: normal;
      } 
      td:nth-child(1){
        overflow-wrap: break-word;
        width: 80%;
      }
      td:nth-child(2){
        width: 20%;
      }
  } 
}  

@media (max-width: 280px){
  .report-card {
    .category-non-numeric-value.recommendation-list-table table tr td {
      overflow-wrap: anywhere;
      width: auto;
    } 
  }
}  