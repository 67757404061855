@import "../../Analytics-style/base/_colors.scss";
.report-card{
  .breadcrumb a {
      color: #272727 !important;
      font-size: 12.64px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.154px;
      text-decoration-line: underline;
      .active {
        text-decoration-line: none
      }
  }  
    .category-dropdown-div{
      height: 100%;
      // background: #FFF;
      // box-shadow: rgba(0, 0, 0, 0.06) 1px 1px 24px;
      padding:  0 10px 10px 10px;
      padding-left: 0px;
      padding-right: 0px;
      .grade-NA.grade-block {
        background-color: #999;
        font-size: 0.5rem !important;
      }
      .breadcrumb .individual-breadcrumb {
        font-weight: 400;
        font-size: 12px;
        line-height: 14.52px;
        margin: 0px;
        a {
          cursor: pointer;
          text-decoration: none;
        }
      }
      .breadcrumb {
        padding: 4px 15px !important;
      }  
      .MuiDrawer-paper {
        top: auto;
        -webkit-flex: 1 0 auto;
        flex: 1 0 auto;
        height: 100%;
        display: -webkit-flex;
        display: flex;
        outline: 0;
        z-index: 0;
        position: relative;
        overflow-y: auto;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
      }
  
      .MuiDrawer-paperAnchorDockedLeft {
        border-right: none !important;
      }
  
      .MuiDrawer-paperAnchorLeft {
        left: auto;
        right: auto;
      }
      .MuiListItemText-multiline {
        margin-top: 6px;
        margin-bottom: 6px;
      }
  
      .MuiListItemText-inset {
        padding-left: 0px !important;
        display: flex;
        align-items: center;
      }
  
      .MuiCollapse-wrapperInner {
        width: 100%;
      }
  
      .MuiSvgIcon-root {
        fill: #272727;
        width: 1.3em;
        display: inline-block;
        font-size: 1.5rem;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        flex-shrink: 0;
        user-select: none;
      }
  
      .MuiListItem-gutters {
        padding-left: 0px;
        padding-right: 0;
      }
      .MuiListItem-alignItemsFlexStart{
        padding-right: 0;
      }
  
      .MuiButtonBase-root{
        padding-left: 0px !important;;
        a{
          width: 100%;
        }
      }  
  
      .MuiTypography-body1.dark {
        color: #272727;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 15px;
      }
  
      .MuiTypography-body1 {
        color: #272727;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 15px;
        padding-top: 3px;
      }
  
      .MuiList-padding {
        padding-top: 8px;
        padding-bottom: 8px;
  
        .active {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
  
      .faded {
        opacity: 0.5;
        background-color: #D3D3D3;
      }

      // .fade-row{
      //   background-color: #E7FDF6;
      // }

  
      .dark {
        opacity: 1;
      }
  
      .grade-block {
        min-height: 20px;
        height: 20px !important;
        color: white;
        border-radius: 30px;
        width: 20px !important;
        font-size: 10px;
        min-width: 20px;
      }

      .MuiDrawer-root .MuiDrawer-docked{
        height: 100%;
      }
      .noumenon-search-field{
        display: flex;
        flex-direction: row;
        padding-right: 10px;
        align-items: center;
        .search-container{
          margin-left: auto;
          flex-grow: 1;
        }
        .expand-collapse-container{
          justify-content: flex-end;
          width: 15%;
          .collapse-all-container{
            margin-right: 20%;
          }
        }
        form{
          position: relative;
          left: 100%;
          transform: translate(-100%,0%);
          transition: all 0.5s;
          width: 50px;
          z-index: 9;
          height: 27px;
          z-index: 1;
          margin-top: 8px;
          margin-bottom: 8px;
          .form-control:focus {
            color: #495057;
            background-color: #fff;
            border-color: #1f526b;
            outline: 0;
            box-shadow: none;
          }  
        }
        .collapes-all-icon{
          position: inherit;
          right: 35px;
          width: 23px;
          cursor: pointer;
        }
        .expand-all-icon{
          position: inherit;
          right: 30px;
          width: 23px;
          cursor: pointer;
        }
        
        input{
            position: absolute;
            top: -3px !important;
            left: 0px !important;
            width: 100%;;
            height: 30px;
            line-height: 30px;
            outline: 0;
            border: 0;
            display: none;
            font-size: 1em;
            border-radius: 4px;
            padding: 0 20px;
            // border: 1px solid #1f526b;
            border: 0.5px solid #303030;
            border-radius: 50px;
        }
        .search-icon-div{
          display: block;
          width: 30px;
          height: 30px;
          position: absolute;
          right: 0px;
          top: -3px;
          text-align: center;
          cursor: pointer;
          .search-icon{
              box-sizing: border-box;
              position: relative;
              text-align: center;
              font-size: 1.4em;
              transition: all 0.5s;
              top: 3px;
              right: 0px;
              width: 20px;
          }
        }
        
        svg.times-icon{
          fill: #ca0020 !important;
          top: 3px !important;
        }

        .serach-close{
          border-radius: 50%; 
          // border: 1px solid #1f526b;
          border: 1.5px solid #303030;
          svg{
            // fill: #1f526b !important;
            fill: #303030 !important;
          }
        }
        
        .form-hover{
            width: 97%;
            cursor: pointer;
            border: none;
        }
        
        .form-hover{
           input{
            display: block;
          }
          ul{
            display: block !important;
          }
          .collapes-all-icon{
            right: 55px;
          }
          .expand-all-icon{
            right: 52px;
          }
        }  
        
      //  .form-hover .fa{
      //   //   margin-right: -23px;
      //     // display: none;
      //   }
        ul{
          top: 30px !important;
          border-radius: 4px !important;
          display: none !important;
        }
        .dropdown-menu{
          background-clip: unset !important;
        }
        .close {
          float: right;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          color: #000;
          text-shadow: 0 1px 0 #fff;
          opacity: .5;
          padding-right: 5px;
        }
      }
      .collapsed-all{
        color: #272727;
        font-size: 12.64px;
        font-style: normal;
        font-weight: 600;
        cursor: pointer;
        width: 110px;
      }
      .breadcrumb{
        margin-bottom: 0px;
      } 
    }
    
    .category-data-div {
      height: 100%;
      background: #FFF;
      box-shadow: rgba(0, 0, 0, 0.06) 1px 1px 24px;
      padding: 10px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      .header-div{
        padding-top: 1px;
        border-radius: 3px 3px 0px 0px;
        background: #FFF;
        box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.13);
        height: 6%;
        margin-top: -9px;
        z-index: 1;
        position: relative;
        margin-left: -10px;
        margin-right: -10px;
        background-color: #2F2F2F !important;
        .breadcrumb{
          height: 100%;
          margin-bottom: 0px;
          .individual-breadcrumb{
            padding-bottom: 1%;
          }
        }
      }
      .border-bottom{
        border-bottom: 1px solid #DADDE7;
        height: max-content;
        .timelines-container{
          height: 100%;
          overflow: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 0 0 auto;
          .height-35vh{
            .border-bottom{
              height: max-content !important;
            }
          }
        }
      }
      .height-22{
        height: 22% !important
      }
      .text{
        color: #000;
        line-height: 14.333px; /* 113.397% */
        text-transform: lowercase;
        font-style: normal;
      }
      .text-1, .text-1-value{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0.5em;
        text-transform: uppercase;
      }
      .text-1-value{
        text-transform: none !important;
      }
      .text-2{
        color: #A2A2A2;
        font-size: 12.64px;
        font-style: italic;
        font-weight: 500;
        margin-bottom: 0.5em
      }
      .text-3{
        font-size: 12.64px;
        font-style: italic;
        font-weight: 400;
        line-height: 14.333px; /* 113.397% */
        text-transform: lowercase;
        margin-bottom: 0.5em
      }
      .red-icon{
        color: #C30000;
        vertical-align: text-top;
      }
      .blue-icon{
        color: #1D71B1;
        vertical-align: text-top;
      }
      .recommendation-more-info{
        .nav-title{ 
          color: #80818B;
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.154px;
          cursor: pointer;
        }
        .active{
          color: #0D3E56;
        }
      }
      
      .more-info-description-toggle-info{
        padding: 15px 24px;
        flex: 1;
        overflow: auto;
      }

      .nav-title {
        color: #80818B;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.154px;
        cursor: pointer;
      }

      .active {
        color: #0D3E56;
      }

      .interpretations-information{
        height: 80%;
        overflow-y: scroll;
        padding: 0px 24px;
        display: block;
        padding: 10px;
      }

      // .recommendation-container{
      //   // height: 42%;
      //   // height: max-content;
      //   // max-height: 42%;
      //   flex-grow: 1;
      //   // overflow: auto;
      //   .recommendation-more-info{
      //     height: 100%;
      //     .recommendation-list-table{
      //       height: 80%;
      //       .react-bootstrap-table{
      //         height: 100%;
      //       }
      //     }
      //     .more-info-description-toggle-info{
      //       height: 85%;
      //       overflow-y: auto;
      //       padding: 15px 24px;
      //       display: block;
      //     }
          
      //   }
      

      // }

      // .recommendation-container::-webkit-scrollbar{
      //   display: none;
      // }

      .rubric-ranges-chart-container{
        width: 80%;
        display: flex;
        flex-direction: column;
        max-width: 100% !important;
        flex-grow: 1;
        .rubric-ranges-container{
          height: 20% !important;
          width: 100%;
          margin-left: 20px;
          &.category-section-container{
            height: 6%;
          }
          &.null-points{
            height: 0%;
          }
          .rubric-ranges{
            margin-right: 10px;
            width: 30%;
            .range-color{
              width: 12px;
              height: 12px;
              border: 1px solid black;
              border-radius: 25%;
              font-weight: 400;
              line-height: 13px;
              letter-spacing: -0.01em;
              text-align: left;
              margin-right: 5px;
              display: flex;
              margin-top: 5px;
            }
            .range-type{
              width: 70%;
            }
          }

          .animated-filled-div-arrow{
            position: absolute;
            height: 2%;
            width: 97%;
            border-radius: 25px;
            display: flex;
          }
          .category-section-labels-container, .datum-labels-container{
            display: flex;
            flex-direction: row;
            width: 100%;
            // margin-left: 3%;
          }
          .datum-labels-container{
            padding-top: 1%;
            align-items: center;
          }
          .datum-label, .category-section-label{
            z-index: 2;
            font-weight: 400;
            font-size: 11px;
            line-height: 16.31px;
            // height: 13px;
            flex: 1;
            .range-label-text{
              font-size: 14px;
            }
          }
          .arrow {
            position: absolute;
            left: 43%;
            margin-left: -3%;
            content: url("../../../assets/images/rubric_ranges_arrow_indicator.svg");
          }
          .rubric-ranges-bar-arrow{
            &.undefined-pointer{
              .arrow{
                display: none;
              }
            }
          }
          .rubric-ranges-bar-container{
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        }
        .rubric-ranges-container::-webkit-scrollbar {
          display: none;
        }
        .category-graph-section{
          flex: 1;
          .chart_container{
            padding: 30px 0px !important;
          }
        }
      }
      .gpa-grade .grade-block {
          border-radius: 30px;
          width: 40px !important;
          font-size: 15px;
          text-transform: uppercase;
      }
    }
    .value-units{
      padding-top: 3px;
      padding-bottom: 1px;
    }
    .reference-range-value{
      padding-top: 3px;
    }
    .w-100{
      width: 100%;
    }

    .recommendation-list-table {
      overflow: auto;
      // overflow-x: hidden;
      .react-bootstrap-table table{
        table-layout: auto;
      }
      table {
        thead{
          height: 30px;
          position: sticky;
          top: -1px;
          background: white;
          border-radius: 3px 3px 0px 0px;
        th {
          overflow-wrap: normal;
          vertical-align: sub;
          border-bottom: 0.5px solid #dee2e6;
          position: sticky;
          top: -1px;
          background: #f9fbfc;
          }
        }
        tr td { 
          overflow-wrap: normal;
          width: 19%;
          svg {
            width: 30px;
            height: 30px;
          }
          .actions svg, img{
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        } 
        td:nth-child(2){
          overflow-wrap: break-word;
          width: 80%;
        }
        td:nth-child(1){
          width: 10%;
        }
        td:nth-child(5){
          width: 14%;
        }
      }
    }  
    

    // .breadcrumb{
    //   height: 6%;
    // }

    .MenuBar-list-19{
      height: 93%;
    }
    .category-graph-section{
      height: 100%;
      width: 100%;
      overflow: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      .rickshaw_graph .detail{
        .item{
          margin-top: 1.5em;
        }
        &.normalized-position{
          .item {
            position: absolute;
            z-index: 2;
            border-radius: 3px;
            font-size: 12px;
            font-family: Arial, sans-serif;
            background: rgba(0, 0, 0, 0.4);
            color: white;
            margin-left: 1em;
            margin-right: 1em;
            margin-top: -4em;
            white-space: nowrap;
          }
          .item:after {
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            content: "";
            border: 5px solid transparent;
          }
          .right:after {
            right: -6px !important;
            margin-top: -5px;
            border-left-color: rgba(0, 0, 0, 0.8);
            border-right-width: 0;
            top: 4em !important;
            left: auto;
            transform: rotate(0deg) !important;
          }
          .left:after {
            top: 4em !important;
            left: -6px !important;
            margin-top: -5px;
            border-right-color: rgba(0, 0, 0, 0.8);
            border-left-width: 0;
            transform: rotate(0deg) !important;
          }
          &.null-points{
            .item{
              margin-top: -3em;
            }
            .left:after, .right:after{
              top: 3em !important;
            }
          }
          &.bottom-most-point{
            .item{
              margin-top: -11em;
              margin-left: -4em;
              &.right{
                margin-right: -4em;
              }
            }
            .left:after{
              top: 8.75em !important;
              left: 46px !important;
              margin-top: 1px;
              transform: rotate(-90deg) !important;
            }
            .right:after{
              right: 4em !important;
              margin-top: -14px;
              top: 10em !important;
              transform: rotate(90deg) !important;
            }
            &.null-points{
              .item{
                margin-top: -7em;
              }
              .right:after, .left:after{
                margin-top: -18px;
                top: 7em !important;
              }
            }
          }
        }
      }
    }
    .category-graph-section::-webkit-scrollbar {
      display: none;
    }

    .chart_body {
      &.rickshaw_graph{
        g{
          circle{
            r: 5;
          }
        }
      }
    }

  .category-overview-graph{
    .chart-content{
      .chart_container{
        // position: absolute !important;
        .x_tick{
          .title{
            bottom: -20px;
          }
        }
      }
    }
    .chart-content{
      position: relative;
      /* z-index: 2; */
      overflow: auto;
      width: 100%;
      height: 100%;
    }
    .chart-content::-webkit-scrollbar, .category-chart::-webkit-scrollbar{
      display: none;
    }
  }
    
}

@media (min-width: 768px) {
    .report-card {
      .padding {
        padding: 0.75%;
        height: 100%;
      }

      .padding-0{
        height: 100%;
      }  
    }
}
@media (max-width: 767px) {
  .report-card {
    .category-data-div {
      height: auto;
    } 
  }
}

@media (min-width: 992px) {
    .report-card-container{
      height: 100%;
      .category-dropdown-div{
        .menubar-list-container{
          height: 89%;
          &.collapse-all{
            height: 90%;
          }
          .MuiDrawer-root {
            &.MuiDrawer-docked{
              height: 100%;
            }
          }
          
        }
      }
    .col-lg-3{
      &.padding-0{
        flex: 1 0 0;
        max-width: 35%;
      }
    }
    .col-lg-9 {
      -webkit-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 65%;
      &.padding{
        flex: 2 0 0;
      }
    }  
  }
}
.MuiTypography-root{
  font-weight: normal !important;
}
.category-overview-graph {
  width: 100%;
  height: 100%;
  // overflow: auto;
}

@media (min-width: 992px) {
  .report-card-container{
    .reference-range-value{
      padding-right: 10px !important;
      left: 25px;
      width: 50%;
      flex: 1;
    }
    .value-units{
      width: 50%;
    }
  }
  .category-data-div{
    .border-bottom{
      .col-lg-2 {
        max-width: 20%;
        &.col-md-12{
          height: 100%;
          flex: 1;
          .height-35vh{
            height: 95%;
            .border-bottom{
              height: 20%;
              .text-1, .text-1-value{
                height: 50%;
                text-transform: capitalize;
              }
              .text-1-value{
                text-transform: none !important;
              }
              .text-2{
                height: 50%;
              }
            }
            .baseline-container{
              height: 35%;
              .border-bottom{
                height: 100%;
                .text-1, .text-1-value{
                  height: 33.33%;
                }
                .text-1-value{
                  text-transform: none !important;
                }
                .text-2{
                  height: 33.33%;
                }
                .text-3{
                  height: 33.33%;
                }
              }
            }
            .previous-container{
              height: 35%;
            }
          }
        }
      }
      .col-lg-0{
        &.col-md-12{
          flex: 2;
          max-width: 25%;
        }
      }
    }
  }
}

// .item-active{
//     background-color: #E5F7FF !important;
// }

.category-data-div{
  .individual-breadcrumb{
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    letter-spacing: 0em;
    text-align: left;
    color: #80818B;
    .header-div-active-element{
      color: #264C5F;
    }
  }
  .overview-graph{
    flex: 2;
    height: 95%;
    margin-top: 10px;
    width: 90%;
  }
}
.col-md-1.unread-chat-icon svg{
    height: 20px;
    width: 20px;
    float: right;
}

@media (max-width: 768px) {
  .report-card { 
    .category-data-div{
      .text {
        text-align: center;
      }
      .text-1, .text-1-value {
        font-size: 14px;
      }    
      .text-3, .text-2 {
        font-size: 11.64px;
      }
      
      .nav-title{
        font-size: 13px;
        svg{
          height: 16px;
        }
      }  
      .add-btn {
        color: #ffffff;
        padding-left: 10px;
        padding-right: 10px;
        height: 20px;
        width: auto;
        padding-top: 2px;
        border-radius: 3px !important;
        background: var(--btn-bg);
        font-size: 10px;
      }
      .breadcrumb .individual-breadcrumb{
        font-size: 13px;
      }
      .gpa-grade{
        justify-content: center;
        display: flex;
      }
    }
    
    .category-graph-section{
      height: 100%;
      width: 100%;
    }
    .category-overview-graph{
      overflow: visible;
      .chart-content{
        position: relative !important;
        .chart_container{
          position: relative !important;
        }
      }
    }
    .padding {
      padding-top: 3px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 3px;
    }
    .height-mult-list{
      height: 500px !important;
      .value-units{
        flex-wrap: wrap;
      }
    }
    .category-dropdown-div .breadcrumb
      .individual-breadcrumb {
        font-size: 13px;
    }
    .col-md-1 .unread-chat-icon svg{
        height: 16px;
        width: 16px;
        float: right;
    }
  }
  .rubric-ranges-chart-container{
    .rubric-ranges-container{
      // margin-left: 0% !important;
      width: 100% !important;
    }
  }

}

.category-chat-icon{
  padding: 10px;
}
.section-chat-icon{
  padding: 10px;
}
.noumenon-chat-icon{
  padding: 10px;
}
.MuiButtonBase-root{
  padding-left: 8px !important;;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  outline: 0;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
  padding-top: 6px !important;
}


@media screen and (min-device-width: 768px) and (max-device-width: 992px){
  .report-card { 
    height: 100%;
    .category-dropdown-div{
      .menubar-list-container{
        height: 85%;
        &.collapse-all{
          height: 90%;
        }
        .MuiDrawer-root {
          &.MuiDrawer-docked{
            height: 100%;
          }
        }
        
      }
    }
  }
}
@media (max-width: 280px){
  .report-card {
    .recommendation-list-table table tr td {
      overflow-wrap: anywhere;
      width: 16%;
    }  
    .padding-left-8px{
      padding-left: 8px;
    }
    .category-data-div {
      .nav-title {
        font-size: 10px;
        overflow-wrap: anywhere;
        svg {
          height: 15px;
        }
      }
    }    
  }
}

.analytics-screen {
  &.beta {
    font-family: 'Noto Sans' !important;
    &.light-theme {
      background: $light-background;
      color: #333;
      .report-card {
        .category-data-div {
          background-color: $light-component-background !important;
          box-shadow: $light-component-box-shadow;
        }

        .breadcrumb, .header-div {
          background-color: $light-component-background !important;
          box-shadow: none
        }
        .category-dropdown-div, .height-mult-list {
          background: #FFF;
          box-shadow: rgba(0, 0, 0, 0.06) 1px 1px 24px; 
          .item-active {
            &.sections-tree{
              padding-left: 20px;
            }
            &.datum-tree{
              padding-left: 48px;
            }
            background-color: #E5F7FF;
            .MuiTypography-body1{
              font-weight: 600 !important;
            }
          }
          .MuiTypography-body1{
            font-family: 'Inter';
            font-weight: 400 !important;
          }
          .fade-row{
            .MuiTypography-body1{
              font-weight: 600 !important;
            }
          }
          .item-not-active{
            &.sections-tree{
              padding-left: 20px;
            }
            &.datum-tree{
              padding-left: 48px;
            }
          }
          .noumenon-search-field {
            border-bottom: 1px solid #DDDDDD;
          }
          .MuiListItem-root{
            padding-top: 1px;
            padding-bottom: 1px;
          }
          .dropdown-menu{
            overflow: overlay !important;
          }
          .unread-chat-icon svg{
            fill: var(--unread-chat-icon-b) !important;
          }
          .MuiTypography-colorTextSecondary, .MuiListItemText-secondary{
            color: #272727;
            font-size: 14px;
            font-family: 'Inter';
            font-weight: 400 !important;
            line-height: 15px;
          }
        }
      }  
    }
    &.dark-theme {
      background: $dark-background;
      color: #F1F1F1;
      
      .catgory-details .report-card {
        .category-data-div {
          border: var(--category-data-div-b-d);
          box-shadow: -4px 5px 10px 0px #00000040;
          box-shadow: var(--category-data-div-bs-d);
        } 
      }  
      .report-card { 
        .category-data-div {
          background: linear-gradient(0deg, #1F1F1F, #1F1F1F), linear-gradient(0deg, #3397C9, #3397C9);
          .breadcrumb{
            display: flex;
            align-items: center;
            .individual-breadcrumb{
              padding: 0;
              margin-left: 2.5%;
              font-size: 16px;
              font-weight: 600;
              line-height: 19.36px;
              letter-spacing: 0em;
              text-align: left;
              color: #A2A2A2;
            }

          }
        } 
        .breadcrumb, .header-div {
          background-color: #2F2F2F !important;
          box-shadow: none;
        } 
        .recommendation-list-table {
          td, th{
            color: #F1F1F1
          }
          tbody tr{
            border-width: 0.5px 0px 0.5px 0px;
            border-style: solid;
            border-color: #ACB1C7;
            border-left: none;
            border-right: none;
            border-top: none;
          }
          thead{
            tr{
              border-width: 0.5px 0px 0.5px 0px;
              border-bottom-style: solid;
              border-bottom-color: #ACB1C7;
              border-top: none;
              border-bottom: none;
            }
            th{
              border-top: none;
              border-bottom: none;
            }
          }  
           thead,thead tr, th{
            background: #2F2F2F;
          }
          .instructions.no-recommendations{
            background-color: #2F2F2F;
          }
          td{
            border-top: none;
          }
        } 
        .more-info-description-toggle-info{
          background-color: #2F2F2F;
          .moreInfoReference .card {
            margin-top: 20px !important;
            background: #2F2F2F;
            border-width: 0.5px 0px 0.5px 0px;
            border-style: solid;
            border-color: #5B5B5B;
            .card-header{
              color: #F1F1F1;
              background-color: #2F2F2F;
              border-bottom: 0.5px solid #5B5B5B;
            }
            .card-body{
              background-color: $dark-component-background !important;
              color: #F1F1F1
            }
          }
        }
        .interpretations-information{
          background-color: #1f1f1f;
          .card {
            margin-top: 20px !important;
            background: #2F2F2F;
            border-width: 0.5px, 0px, 0.5px, 0px;
            border-style: solid;
            border-color: #5B5B5B;
            .card-header{
              color: #F1F1F1;
              background-color: #2F2F2F;
              border-bottom: 0.5px solid #5B5B5B;
            }
            .card-body{
              background-color: $dark-component-background !important;
              color: #F1F1F1
            }
          }
        }
         .category-data-div {
          .individual-breadcrumb .header-div-active-element {
            color: #F1F1F1;
          }
          .nav-title{
            color: #80818B;
          }
          .active {
            color: #F1F1F1;
          } 
          .recommendation-more-info{
            font-family: 'Inter';
            font-size: 12.64px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
          }
          .text-1, .text-1-value {
            color: #F1F1F1;
          } 
          .text-2, .text-3 {
            color: #A2A2A2;
          } 
          .border-bottom {
            border-bottom: 1px solid #5B5B5B !important;
            &.valid-points{
              padding-top: 10px !important;
            }
          }
          .rubric-ranges-chart-container .rubric-ranges-container .rubric-ranges .range-color {
            border: 1px solid #212529;
          }  
        
        }   
        .category-dropdown-div, .height-mult-list {
          background-color: $dark-component-background !important;
          box-shadow: none;
          .item-active {
            background: #2B5F7680;
            box-shadow: 0px 2px 4px 0px #0000001A;
            &.sections-tree{
              padding-left: 20px;
            }
            &.datum-tree{
              padding-left: 48px;
            }
            .MuiTypography-body1{
              color: #F1F1F1 !important;
              font-weight: 600 !important;
            }
          }
          .item-not-active{
            &.sections-tree{
              padding-left: 20px;
            }
            &.datum-tree{
              padding-left: 48px;
            }
          }


          .MuiListItem-root{
            padding-top: 1px;
            padding-bottom: 1px;
          }
          .MuiTypography-body1.dark, .MuiTypography-body1 {
            color: #C3C3C3;
            font-family: 'Inter';
            font-weight: 400 !important;
          }
          .MuiTypography-colorTextSecondary, .MuiListItemText-secondary{
            color: #F1F1F1;
            font-size: 14px;
            font-family: 'Inter';
            font-weight: 400 !important;
            line-height: 15px;
          }
          .fade-row{
            .MuiTypography-body1{
              color: #F1F1F1 !important;
              font-weight: 600 !important;
            }
          }
          .noumenon-search-field {
            border-bottom: 1px solid #5B5B5B;
            .serach-close svg {
              fill: #DDDDDD !important;
            }
            .serach-close, input {
              border: 0.5px solid #DDDDDD;
            }  
            .search-open {
              border-left: 1.5px solid #DDDDDD;
          }
          }
          .form-control {
            color: #DDDDDD;
            background-color: $dark-component-background;
          }  
          .MuiSvgIcon-root {
            fill: #868686;
          }
          .MuiPaper-root{
            background-color: $dark-component-background !important;
          }
          .unread-chat-icon svg{
            fill: var(--unread-chat-icon-b-d) !important;
          }
        }  
        .individual-breadcrumb a {
          color: #717171 !important;
          text-decoration: none;
        }
        .category-dropdown-div{
          .breadcrumb{
            background-color: #1F1F1F !important;
            .individual-breadcrumb{
              color: #717171 !important;
            }
          }
        }
        .dropdown-menu {
          color: #DDDDDD;
          text-align: left;
          list-style: none;
          background-color: #2f2f2f !important;
          // border: 1px solid #DDDDDD;
          .dropdown-item {
            color: #DDDDDD;
          }
          .dropdown-item:hover {
            color: #DDDDDD;;
            text-decoration: none;
            background-color: #5B5B5B
         }
        }  
      }  
    }
  }
}
.dark-icon-color{
  // filter: invert(79%) sepia(37%) saturate(20) hue-rotate(174deg) brightness(101%) contrast(70%);
  filter: brightness(1) saturate(77%) invert(89%) sepia(5%) saturate(215%) hue-rotate(182deg) brightness(110%) contrast(74%);
}
.dark-icon{
  filter: brightness(20%);
}
.new-label-container{
  padding-top: 1px;
}
.new-label{
  min-width: 31px;
  height: 16px;
  top: 281px;
  left: 225px;
  padding: 2px 3px 2px 3px;
  border-radius: 3px;
  gap: 10px;
  background: #00A90033;
  display: flex;
  .new-text{
    font-family: Inter;
    font-size: 11px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #00A900
  }
}
.datum-tree{
  .new-label{
    margin-left: 10px;
    padding-top: 1px;
  }
}

@media (min-width: 1024px){
  .report-card{
    .recommendation-list-table {
      table {
        thead{
          white-space: nowrap;
        }
      }
    }
  }
}

@media (max-width: 1024px){
  .report-card{
    .category-data-div{
      .timelines-container{
        padding-bottom: 10px !important;
      }
      .rubric-ranges-chart-container .rubric-ranges-container .arrow{
        margin-left: -1%;
      }
    }
    .category-dropdown-div, .height-mult-list {
      .sections-tree{
        padding-left: 10px !important;
      }
      .datum-tree{
        padding-left: 20px !important;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px){
  .report-card{
    .height-mult-list{
      .value-units{
        flex-wrap: wrap !important;
      }
      .reference-range-value{
        width: 2% !important;
      }
      .noumenon-chat-icon, .category-chat-icon, .section-chat-icon{
        padding-right: 2px !important;
      }
    }
    .category-data-div .timelines-container{
      padding-bottom: 0px !important;
    }
    .category-dropdown-div .noumenon-search-field .expand-collapse-container .collapse-all-container{
      margin-right: 2% !important;
    }
    
  }
}

@media (max-width: 820px) {
  .report-card .category-dropdown-div .noumenon-search-field  .search-container{
    margin-right: 20px;
    .form-hover{
      width: 94%;
    }
    svg {
      top: 5px !important;
    }
  }
  .report-card .category-data-div .rubric-ranges-chart-container .rubric-ranges-container .datum-label{
    overflow: auto;
  }
}

@media (max-width: 768px){
  .report-card{
    .category-data-div{
      .graph-timelines-container{
        padding-left: 0px;
        padding-right: 0px;
      }
      .rubric-ranges-chart-container .rubric-ranges-container .animated-filled-div-arrow{
        width: 100%;
      }
    }
  } 
}

@media (max-width: 1024px) and (min-width: 540px) {
  .report-card{
    .recommendation-list-table{
      table tr td{
        .delete-icon{
          padding-left: 0.5rem !important;
        }
      }
    }
  }
}

@media (max-width: 576px){
  .report-card .category-data-div .rubric-ranges-chart-container{
    .rubric-ranges-container.category-section-container{
      margin-left: 0% !important;
    }
    .rubric-ranges-container{
      margin-left: 0% !important;
    }
    .category-graph-section{
      margin-left: 0% !important;
    }
  } 
}

@media (max-width: 539px){
  .report-card{
    .recommendation-list-table{
      table tr td{
        .delete-icon{
          padding-left: 0rem !important;
        }
      }
    }
  }
}

@media (max-width: 280px){
  .report-card .category-data-div .rubric-ranges-chart-container .rubric-ranges-container .datum-labels-container{
    overflow: auto;
  }
}

.lh-30{
  line-height: 30px;
}