.explore-noumena-component{
    .noumenon-container{
        .col-lg-4{
            .highcharts-container{
                width: 100%!important;
                background-color: white;
                @media(max-width:767px){
                    margin-top:10px;
                }
            }
        }
    }
    &.parent-component {
        .col-lg-12.col-md-12.col-sm-12.col-xs-12.data-section {
            @media (max-width: 1024px) {
                margin-left: 15px;
            }
            @media (max-width: 768px) {
                margin-left: 0;
            }
        }
    }
    .float-right.no-breadcrumb-header {
        // position: inherit!important;
        float: none !important;
        max-width: 100% !important;
        padding: 0 15px !important;
    }
}
.filter-help {
    margin-top: 2%;
}
.data-menu-children{
    .side-panel{
        padding-right: 15px;
    }
}
.volume-report {
    width: 100%;
    padding: 0;
    margin: auto;
    @media(max-width:992px){
        display: contents;
    }
    .volume-category {
        margin-top: 2%;
        h3 {
            margin-top: 2%;
        }
    }
    .table-responsive {
        overflow-x: inherit;
    }
    tr {
        .site-value {
            font-weight: bold;
            white-space: normal;
            min-width: 200px;
        }
        td {
            // word-break: break-all;
            white-space: initial;
        }
    }
}
.download-csv-btn{
    margin: 0;
}
.report-card {
    select {
        font-size: 20px !important;
        width: fit-content;
    }
    .grade-block {
        min-width: 40px;
        min-height: 40px;
        height: fit-content;
        border-radius: 5px;
        padding: 10px;
    }
    .grade-section {
        display: flex;
        margin: 15px 0px;
        flex: 0 0 50%;
        max-width: 50%;

        .desc {
            font-size: 20px;
            margin: 0px 0px 15px 15px;
        }
    }
    @media(max-width:768px){
        margin: 0;
    }
}

.agg-age-charts {
    width: 700px;
    height: 350px;
    border: none;
    margin-bottom: 5%;
}
.noumena-preview table th {
    border-top: none;
}
.noumenon-container .heading {
    margin-top: 15px;
}
.appear-report table tbody tr :first-child {
    &.td:first-child {
        border-top: none;
    }
}
.noumena-preview-chart .noumenon-container .heading {
    display: none;
}
.noumena-preview-chart .noumenon-container a {
    display: none;
}
.noumena-preview td {
    word-break: break-all;
}
@media (max-width: 992px) {

    .agg-age-charts {
        width: inherit;
        height: inherit;
    }
}
@media (max-width: 1530px) {
    .volume-report {
        tr {
            white-space: initial;
        }
    }
}

@media (max-width: 1200px) {
    .volume-report {
        .volume-category {
            overflow-x: scroll;
        }
    }
}
@media (max-width: 1160px) {
    .volume-report {
        table {
            &.table-responsive {
                overflow-x: auto;
            }
        }
    }
}
