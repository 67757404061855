@import "../../../styles/variables.scss";
.sites-component {
    &.parent-component {
        .col-lg-12.col-md-12.col-sm-12.col-xs-12.data-section:first-child {
            @media (max-width: 1024px) {
                margin-left: 15px;
            }
            @media (max-width: 768px) {
                margin-left: 0;
            }
        }
    }
    .float-right.no-breadcrumb-header {
        // position: inherit!important;
        float: none !important;
        max-width: 100% !important;
        padding: 0 15px !important;
    }
}
.sites-list {
    a {
        color: #008fbc !important;
    }

    legend {
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
        font-size: 21px !important;
        line-height: inherit;
        color: #333;
        border: 0;
        border-bottom: 1px solid #e5e5e5;
    }

    .card {
        word-wrap: inherit;
    }

    strong {
        font-weight: 500;
    }

    .card.card-content {
        padding: 24px 36px;
        margin-top: 5%;
    }

    .card-content-row {
        word-break: break-all;

        &.headings {
            word-break: inherit;
        }

        border-bottom: 1px solid #3333;

        &:nth-child(even) {
            background-color: #f9f9f9;
        }
    }
    .download-site-list-btn {
        line-height: 3.2;
        margin-top: 1.5rem;
    }

    @media (max-width: 576px) {
        .download-site-list-btn {
            line-height: 0;
            margin-top: 0px;
            margin-bottom: 10px;
        }
    }   
}
.site-brief-details {
    p {
        margin: 0;
    }
}
.site-table {
    .react-bootstrap-table {
        width: inherit !important;
    }
    p {
        margin: 0;
    }
}
.site-details {
    .side-panel {
        padding-right: 15px;
    }
}
.site-details-main-sec {
    legend {
        font-size: 20px !important;
    }
}

.sites-form {
    // left: 16%;

    legend {
        color: #333333;
        border-bottom: 1px solid #dddddd;
        margin: 5% 0;
    }

    .form-group {
        display: flex;
    }

    label {
        min-width: 185px;
    }

    .form-check {
        text-align: center;
        margin: 15px;
    }

    .form-check-label.col-lg-8 {
        text-align: initial;
        padding: 0;
    }
    .default-panel {
        label {
            position: relative !important;
            padding: 0 15px !important;
        }
    }

    .site-form-active .form-check {
        text-align: center;
        margin: 0px !important;
    }
    .required:after {
        content: " *";
        color: red;
    }

    .toggle-switch {
        display: flex;
        align-items: center;
        .help-block {
            display: block;
            color: #737373;
            padding-left: 10px !important;
        }
    }
      
    .switch {
        width: 50px;
        height: 25px;
        background: #ccc;
        border-radius: 25px;
        position: relative;
        cursor: pointer;
        transition: background 0.3s;
    }
    
    .switch.toggled {
        background: #026dec;
    }
    
    .switch-handle {
        width: 23px;
        height: 23px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        top: 1px;
        left: 1px;
        transition: left 0.3s;
    }
    
    .switch.toggled .switch-handle {
        left: 26px;
    }
      
}

.sites-list-details.card {
    border: none;
    border-top: 1px solid #3333;
    border-radius: unset;
    padding: 0px;
    display: table;

    .card-content-row {
        padding: 10px 0px;
        align-items: center;

        &:hover {
            background-color: $table-hover-background-color !important;
        }
    }
}

.transaction-history {
    color: #008fbc !important;
}

#defaultOrders {
    padding: 0;
}

.site-details-btn-section {
    height: fit-content;

    button {
        width: 100%;
        margin-bottom: 2%;
    }
}

.site-info {
    display: inline-grid;
    justify-content: center;
    margin: 20px;

    tr.d-flex {
        justify-content: space-between;
        margin: 0px 10px;
    }
}

.row.providers-users-sec {
    margin: 10px 0px 0px;
    width: auto;
    .site-provider-name {
        display: inline-flex;
        .badge.badge-warning {
            color: white;
            font-size: 80%;
        }
    }
    a {
        white-space: normal;
    }
    tr {
        margin-bottom: 10px;
        white-space: nowrap;
        display: flex;
    }

    .card {
        padding: 10px 15px;
    }
}

.recent-activity.card {
    padding: 10px 15px;
    th {
        border-top: none;
    }
    tr:hover {
        background-color: $table-hover-background-color !important;
    }
}

.sites-filter {
    text-decoration: none !important;
    color: #007DBC !important;
    margin: 10px;
}

.site-logo-sec,
.site-banner-logo-sec {
    // width: 100px;
    // height: 100px;
    max-width: 80%;
    max-height: 120px;
    margin: 15px;
}

.sites-notification {
    margin: 0px 15px;
}

.card {
    .muted {
        color: black !important;
        cursor: default !important;
    }

    .muted:hover {
        text-decoration: none !important;
    }
}

.site-logo-preview {
    //width: 200px;
    //height: 200px;
    max-width: 80%;
    max-height: 120px;
    padding-right: 0.8rem;
}

.image-preview {
    max-width: 300px;
    padding-right: 0.8rem;
}
#defaultPanels {
    padding: 0px 0px;
    // div:first-child{
    //     width: 94%;
    // }
    // div:nth-child(2) > div{
    //      width: 100% !important;
    //  }
    width: 100%;
}
// .referral-site {
//     display: block;
//     margin: 8px;
//     width: 750px;
//     p {
//         font-weight: 600;
//         font-family: Arial, Helvetica, sans-serif;
//     }
// }
.site-document-details {
    pre {
        white-space: pre-wrap;
        font-size: 100% !important;
    }
}
.no-padding-json {
    padding: 0 !important;
}
.site-documents .instructions {
    margin-top: 20px;
    border: none;
    p {
        color: #444;
    }
    ul li {
        color: #444;
    }
}
.site-doc-no-preview {
    .jumbotron p,
    .jumbotron li {
        line-height: 1.4em;
    }
    .jumbotron p {
        margin-bottom: 15px;
        font-size: 21px;
        font-weight: 200;
    }
    .jumbotron li {
        font-size: 21px;
        font-weight: 200;
    }
}
.update-site-form{
    .form-check{
        display: flex;
        padding-left: 0!important;

        .check-content{
            display: block;
        }
    }
}
.document-table {
    table {
        td {
            word-break: break-word;
        }
        a {
            color: #008fbc !important;
        }
        td,
        th {
            min-width: 10% !important;
            &:nth-child(1) {
                min-width: 5% !important;
                max-width: 5% !important;
            }
            &:nth-child(3) {
                min-width: 50% !important;
                max-width: 50% !important;
            }
            &:nth-child(4) {
                min-width: 15% !important;
                max-width: 15% !important;
            }
        }
    }
    @media (max-width: 530px) {
        // max-width: 80%;
        .bootstrap-table-container {
            // max-width: 100%;
            overflow: auto;
            border: 1px solid #ccc;
            padding: 5px;
            margin-bottom: 1em;
        }
        .table {
            width: 700px;
            table {
                table-layout: auto;
            }
        }
    }
    @media (max-width: 530px) {
        max-width: 500px;
    }
    @media (max-width: 500px) {
        max-width: 470px;
    }
    @media (max-width: 450px) {
        max-width: 420px;
    }
    @media (max-width: 420px) {
        max-width: 390px;
    }
    @media (max-width: 415px) {
        max-width: 380px;
    }
    @media (max-width: 375px) {
        max-width: 345px;
    }
    @media (max-width: 360px) {
        max-width: 340px;
    }
    @media (max-width: 320px) {
        max-width: 300px;
    }
    @media (max-width: 300px) {
        max-width: 280px;
    }
}
@media (min-width: 768px) {
    .jumbotron .h1,
    .jumbotron h1 {
        font-size: 63px !important;
    }
}
@media (max-width: 998px) {
    .sites-list {
        .headings div {
            padding: 0px;

            div {
                max-width: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: nowrap;
            }
        }

        .card.card-content {
            padding: 24px 24px;
        }
    }
}

@media (max-width: 992px) {
    .sites-form,
    .site-details-main-sec {
        left: 0%;
    }
    .sites-form label{
        min-width: 150px !important;
    }

}

// @media(max-width:1920px) {

//     .site-details-main-sec .card,
//     .providers-users-sec,
//     .recent-activity.card {
//         width: 1000px;
//     }
// }

// @media(max-width: 1300px) {

//     .site-details-main-sec .card,
//     .providers-users-sec,
//     .recent-activity.card {
//         width: 920px;
//     }
// }

// @media(max-width: 1200px) {

//     .site-details-main-sec .card,
//     .providers-users-sec,
//     .recent-activity.card {
//         width: inherit;
//     }
// }

.site-table {
    margin-top: 0%;

    tr,
    th,
    td {
        border: 0 !important;
    }
}

@media (min-width: 1280px) {
    .site-table {
        padding: 1%;
        td,
        th {
            vertical-align: middle !important;
        }

        th:nth-child(1) {
            width: 300px;
        }

        td:nth-child(1) {
            width: 300px;
        }
    }
}

@media (max-width: 768px) {
    .site-table {
        tr {
            flex-direction: column;
        }
        th:nth-child(n) {
            display: none;
        }

        td:nth-child(n + 3) {
            display: none;
        }
        td:nth-child(8) {
            display: inline;
        }
        td:nth-child(7) {
            display: table-cell;
        }
    }
    // .site-details{
    //     .order-1{
    //         order: 1!important;
    //     }

    //     .order-2{
    //         order: 2!important;
    //     }
    // }
    .providers-users-sec.react-bootstrap-table {
        width: inherit !important;
        tr {
            border: none;
            display: block;
        }
    }
    .recent-activity {
        table tr {
            border-top: none;
        }
        td,
        th {
            min-width: 30%;
            max-width: 30%;
        }
    }
}
@media (max-width: 576px) {
    .providers-users-sec.react-bootstrap-table {
        tr {
            display: flex;
        }
    }
}

.update-site-form .manual-form .react-datepicker-popper {
    z-index: 999999
}

.createMultipleDocument .help-block {
    padding: 0 !important;
    display: block;
    color: red;
    margin-top: 5px;
}

.customiz-table { 
    th:first-child, td:first-child, td,th {
        text-align: left;
   }
   .action-td {
    display: flex;
   }

   .trash-icon-noumenon {
    margin-top: 1px;
    font-size: 16px;
   }

   .users-table td:first-child, .users-table th:first-child {
        min-width: 20% !important;
   }    

   .users-table td:nth-child(2), .users-table th:nth-child(2) {
        min-width: 30% !important;
    }   

    .users-table td:nth-child(3), .users-table th:nth-child(3) {
        min-width: 14% !important;
        // text-align: center;
    } 

    .users-table td:nth-child(4), .users-table th:nth-child(4) {
        min-width: 14% !important;
        text-align: center;
    } 
    
    .users-table td:nth-child(5), .users-table th:nth-child(5) {
        min-width: 5% !important;
    }   

    .users-table td:nth-child(6), .users-table th:nth-child(6) {
        min-width: 5% !important;
    }  
    
    .users-table td:nth-child(7), .users-table th:nth-child(7) {
        min-width: 5% !important;
    }  

}

.custom-noumenon-details-btn-section {
    button {
        width: 100%;
        margin-bottom: 2%;
    }
}

.custimization-table th:first-child, .custimization-table td:first-child {
    min-width: 20% !important;
    text-align: left;
}

.custimization-table td:not(:first-child), .custimization-table th:not(:first-child) {
    // text-align: center !important;
    min-width: 10% !important;
}