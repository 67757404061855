.biographical-info {
  //background:white;
  position:relative;
  z-index:7;
  @include sidebar-compensation;
  //.max-container {
  //  padding-bottom:$gap-md;
  //}
}

.patient-info {
  .user-header {
    background:rgba($blue2,0.1);
    padding:$gap-sm $gap;
    @media (min-width:$screen-sm) {
      display:flex;
      flex-wrap:wrap;
      align-items:center;
    }
  }
  .user-icon {
    margin-right:$gap;
    svg {
      width:70px;
      height:70px;
    }
  }
  .controls {
    @media (min-width:$screen-sm) {
      margin-left:auto;
    }
  }
  .patient-name {
    font-size:2.2rem;
    @include font-semibold;
    color:$gray-dk;
  }
  .gender {
    text-transform:capitalize;
  }
  .patient-age {
    font-size:1.5rem;
  }
  .user-stats {
    display:flex;
    flex-wrap:wrap;
    padding-top:$gap;
    .detail-list {
      min-width:200px;
      padding:0 $gap-md $gap;
      @media (min-width:$screen-sm) {
        width:33.33%;

        margin-bottom:0;
      }
    }
  }
  .user-history {
    padding:$gap-sm $gap-sm $gap-md;
    @media (min-width:$screen-sm) {
      display:flex;
      flex-wrap:wrap;
    }
  }
  .column {
    padding-bottom:$gap-md;
    display:flex;
    margin-top:$gap;
    flex:1;
    @media (min-width:$screen-sm-ls + 1px) {
      width:100%;
      padding:0 $gap;
    }
    @media (min-width:$screen-sm) {
      width:50%;
      padding:0 $gap;
      max-width:50%;
      min-width:300px;
    }
    .detail-list {
      display:flex;
      flex-direction:column;
      height:100%;
      @media (max-width:$screen-sm-ls) {
        margin-bottom:0;
      }
    }
    .block {
      width:100%;
    }
    .result ul {
      margin-bottom:0;
    }
  }
}
