@import "../../Analytics-style/base/_colors.scss";
.dashboard {
  .dashboard-report-card {
    height: 100%;
    // background: #FFF;
    // box-shadow: rgba(0, 0, 0, 0.06) 1px 1px 24px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    .category-overview{
      flex: 1;
      display: contents;
      .noumenon-filter{
        align-content: center;
        flex-wrap: wrap-reverse;
      }
    }
  }

  .report-card-text {
    color: var(--dash-report-card-text-c);
    font-size: 20.25px;
    font-weight: 600;
    letter-spacing: -0.154px;
  }

  .report-date-text {
    color: var(--dash-report-date-text-c);
    font-size: 10px;
    font-style: italic;
    line-height: 14.333px;
    text-transform: lowercase;
  }

  .pointer {
    color: var(--dark-color, #263238);
    font-size: 14.22px;
    font-weight: 700;
    line-height: 14.333px;
    text-transform: lowercase;
    padding: 3px;
  }

  .changed-text {
    color: #000;
    font-size: 12.64px;
    font-weight: 500;
    line-height: 14.333px;
    text-transform: lowercase;
    padding: 3px;
  }

  .date-text {
    color: var(--dark-color, #263238);
    font-size: 11.22px;
    font-weight: 500;
    line-height: 14.333px;
    text-transform: lowercase;
    padding: 3px;
  }

  .gps-score {
    width: 157.3px;
    height: 143px;
    flex-shrink: 0;
  }

  .gps-progress {
    width: 157.3px;
    height: 143px;
    flex-shrink: 0;

    .gps-progress-text {
      padding-top: 30px;

      .grade-block {
        color: white;
        border-radius: 50px;
        width: 22.688px;
        height: 22.688px;
        margin-left: 65px;
      }

      .gpa {
        color: #0D3E56;
        text-align: center;
        /* font-family: Inter; */
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.32px;
      }

      .name {
        color: #33363E;
        text-align: center;
        font-size: 12.64px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.333px;
        /* 113.397% */
        text-transform: capitalize;
      }
    }
  }

  .report-card-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    .dashboard-charts-main-div{
      flex: 1;
    }
    &.site-specific-empty-data{
      .gpa-chart-dashboard{
        .rickshaw_graph{
          circle{
            display: none;
          }
          .x_tick{
            .title{
              display: none;
            }
          }
        }
        .chart_container{
          overflow: hidden !important;
        }
      }
    }
  }

  .nuomenon-table {
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    .react-bootstrap-table{
      height: 100%;
    }
    thead{
      height: 50px;
      position: -webkit-sticky;
      position: sticky;
      top: -1px;
      // background: #fff;
      z-index: 1;
      text-align: center;
      th{
        overflow-wrap: normal;
        vertical-align: sub;
        border-bottom: 1px solid #dee2e6;
        position: -webkit-sticky;
        position: sticky;
        top: -1px;
        background: #f9fbfc;
        color: #454545;
        border-top: 1px solid #dee2e6;
        hyphens: auto;
      }
      th:nth-child(2){
        text-align: left;
      }
      th:nth-child(4){
        text-align: right;
      }
      th:nth-child(5){
        text-align: right;
      }
    }
    tbody{
      text-align: center;
      tr{
        border-left: none;
        border-right: none;
      }
      td{
        vertical-align: middle !important;
        hyphens: auto;
      }
    }
    

    .text-Dp {
      color: #c2771b;
    }

    .text-D {
      color: #ba5225;
    }

    .text-Dm {
        color: #b64129;
    }

    .text-F{
      color: #b2302e;
    }
    td{
      color: #444444;
      text-align: center
    }
   
    td:nth-child(2){
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: left;
      color: #272727;
      cursor: pointer;
    }

    // td:nth-child(4){
    //   text-align: center;
    // }

    // td:nth-child(5){
    //   text-align: center;
    // }
   
   td:first-child {
      // width: 10%;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      font-size: 18px;
      font-weight: 600;
      // display: grid;
      justify-content: center;
      display: grid;
      border: none;
      .grade-block {
        height: 30px;
        color: white;
        border-radius: 30px;
        width: 30px;
        font-size: 1rem;
      }
    }
    td{
      border-top: 0px solid #dee2e6 !important;
    }
    
  }

  .noumenon-filter {
    .btn.btn-primary {
      background-color: transparent !important ;
      border: none !important;

      .btn.btn-primary:hover {
        background: white;
        border: none;
        outline: none;
      }
    }
    .btn::after{
      background: none;
    }
    .view-all-text{
      color: var(--view-all-text-c);
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 15px;
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      cursor: pointer;
      margin-right: 5px;
    }
    .btn svg{
      color: var(--filter-icon-c);
    }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      float: left;
      min-width: 102px;
      margin: 0.125rem 0 0;
      font-size: 1rem;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #fff;
      border-radius: 0.25rem;
      padding-top: 0px;
      padding-bottom: 0px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.1);
      transform: translate3d(-70px, 26px, 7px) !important;
      .dropdown-item{
        justify-content: center;
        display: flex;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.15399999916553497px;
      }
      .dropdown-item.active{
        // text-decoration: none;
        background-color: var(--dropdown-item-active-bg);
        color: var(--dropdown-item-active-c) !important;
      }  
      .dropdown-item:active {
        // text-decoration: none;
        background-color: #FFF;
        color: #3397C8 !important;
      }
    }
  }

  .btn-primary:focus,
  .btn-primary.focus,
  .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .treatment-plan {
    // min-height: 300px;
    // background: #FFF;
    // box-shadow: rgba(0, 0, 0, 0.06) 1px 1px 24px;
    border-radius: 5px;
    // margin-top: 10px;
    cursor: pointer;
    height: 61%;
    margin-left: 0px !important;

    // overflow-x: hidden;
    // max-height: 525px;
    .header-div {
      // padding-top: 15px;
      border-radius: 3px 3px 0px 0px;
      // background: #FFF;
      // box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.13);
      // min-height: 50px;
      // margin-top: -9px;
      z-index: 1;
      position: relative;
      height: 10%;

      .active {
        color: var(--text-active-c);
        font-weight: 600 !important;
      }

      .text {
        color: #80818B;
        font-size: 12.64px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.154px;
        cursor: pointer;
        text-transform: capitalize;
      }
    }

    .recommendation-list {
      height: 92%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px;
      width: 98%;
      .active-card{
        border: var(--recommendation-list-active-card-b-d) !important;
      }
    }

    .heading {
      color: #0D3E56;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.154px;
    }

    .view-all-text {
      color: var( --view-all-text-c);
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 15px;
      /* 136.364% */
      text-decoration-line: underline;
      cursor: pointer;
    }

    .recommendation-body {
      // background-color: #FFF8E5;
      width: 100%;
      -webkit-flex-shrink: 0;
      flex-shrink: 0;
      border-radius: 3px;
      margin-bottom: 10px;
      position: relative;
      display: inline-block;
      border-radius: 5px;
      border: 0.5px solid #ACB1C7;
      // background: #FFF;
      min-height: 40px;
      align-content: center;
      display: grid;
      .jw-icon svg {
        height: 40px;
        width: 40px;
      }
      // .recommendation-indicator {
      //   border-top: 0.7px solid #acb1c7;
      //   padding-top: 10px;
      //   padding-top: 7px;
      //   margin-top: 8px;
      // }

      .recommendation-indicator {
        // color: #170F49 !important;;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 0px;
      }

      .recommendation-details {
        border-top: 0.5px solid #969696 !important;
        padding-top: 10px;
        padding-bottom: 0px;
        margin-top: 13px;
        .heading-1{
          font-weight: 400;
        }
        .text-1{
          font-size: 12px;
        }
      }

      .recommendation-body-text {
        color: #272727;
        // font-family: DM Sans;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important;
        /* 118.671% */
      }

      .recommendation-body-text-expanded {
        color: var(--recommendation-body-text-expanded);
        // font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 93.75% */
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .recommendation-instructions-text {
        color: #170F49;
        // font-family: DM Sans;
        font-size: 12.64px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
      }

      .expaned-div {
        padding: 0px 0px 0px 0px;
        cursor: pointer;
      }

      .heading-1 {
        color: #6F6C90;
        // font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        /* 114.286% */
      }

      .heading-2 {
        color: #6F6C90;
        // font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }

      .text-1 {
        color: #6F6C90;
        // font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      .text-2 {
        color: #6F6C90;
        // font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      .jw-recommendation-toggle {
        stroke: #57b9e8;
        width: 15px;
        min-height: 15px;
        vertical-align: text-bottom;
        cursor: pointer;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .meta-recommendation-body {
      min-height: 50px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;

      .meta-resume-style {
        color: #2980B9;
        padding: 3px 3px;
        border: 1px solid #2980B9;
        border-radius: 50%;
        font-size: 15px !important;
      }

      .meta-resume-style:hover {
        background-color: #2980B9;
        color: white !important;
      }

      .meta-recommend-ignore-style {
        color: #f04c54;
        padding: 4px 6px;
        border: 1px solid #f04c54;
        border-radius: 50%;
        font-size: 13px;
      }

      .meta-recommend-ignore-style:hover {
        background-color: #f04c54;
        color: white !important;
      }
    }
    .interpretations-information{
      height: 92%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px;
    }  
  }

  .biomarkers-of-Aging-plan {
    // height: 49.5%;
    height: 38%;
    // background: #FFF;
    // box-shadow: rgba(0, 0, 0, 0.06) 1px 1px 24px;
    border-radius: 5px;
    margin-top: 7px;

    .header-div {
      // padding-top: 10px;
      border-radius: 3px 3px 0px 0px;
      background: #FFF;
      box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.13);
      // min-height: 40px;
      height: 10%;
      // margin-top: -9px;
    }

    .heading {
      color: #0D3E56;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.154px;
    }
    .normal-heading{
      font-weight: 400;
      color: #0D3E56;;
    }

    .view-all-text {
      color: var( --view-all-text-c);
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      /* 136.364% */
      text-decoration-line: underline;
      cursor: pointer;
    }

    .compare-text {
      color: #80818B;
      font-size: 12.64px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.154px;
      cursor: pointer;
    }

    .render-biomarker {
      overflow: auto;
      scroll-behavior: smooth;
      // padding: 5px 2px;
      // height: 99%;
      // padding-bottom: 20px;
      .react-multi-carousel-list {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        position: relative;
        // height: 90%;
        overflow-x: hidden;
        overflow-y: auto;
        li{
          justify-content: center;
          display: flex;
        }
      }
      .react-multi-carousel-track {
        list-style: none;
        padding: 1px !important;
        margin: 1px !important;
      }
      
    }

    .left-right-div {
      font-size: 20px;
      font-weight: 100;
      color: gray;
      // position: relative;
      // height: 10%;
      justify-content: center;
      justify-content: center;
      // position: absolute;
      // bottom: 4%;
      svg{
        cursor: pointer;
        width: 25px !important;
        height: 25px !important;
      }
    }

    .biomarker-card {
      width: 90%;
      border-radius: 5px;
      // border: 0.5px solid #ACB1C7;
      // background: #FFF;
      margin-left: 6px;
      margin-right: 6px;
      .biomarker-body .biomarker-div{
        height: 57px;
      }
      .logo-biomarker-name{
        height: 50px;
        padding-top: 3px;
      }
      .date{
        color: #272727;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16.94px;
        float: inline-end;
      }
      .icon {
        width: 30px;
        position: relative;
        z-index: 2;
        // margin-bottom: 5px;
        // height: 25%;
        // padding-right: 10px;

        .trudiag-svg-logo {
          left: 0;
        }
        svg,
        img {
          width: 20px;
          height: 20px;
          min-width: 0px;
          // max-width: 100%;
          // min-width: 100%;
          opacity: 1;
          color: #0D3E56
        //  g{
        //   stroke-width: 5px !important;
        //  }
        }

        svg[title="PhenoAge"] {
          bottom: 0px;
          right: 4px;
        }
      }

      .batch-div {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;        
        /* 142.096% */
      }

      .safe {
        // background: #daf0da;
        .batch-text {
          color: #00A900;
        }
      }

      .risk {
        .batch-text {
          color: #E80000;
        }
      }

      .warning {
        background: #ffc107;

        .batch-text {
          color: #000;
        }
      }

      .biomarker {
        display: block;
        padding: 10px;
        position: relative;
        border-radius: 5px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;


        // &-body {
          // .icon svg {
          //   max-width: 0%;
          //   min-width: 0px;
          // }  
        // }

        .name {
          color: #170F49;
          /* 114.286% */
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 0 !important;
        }

        .average {
          color: #444;
          margin-bottom: 5px;
          font-size: 12px;
        }

        .age {
          // text-align: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
          /* 48.485% */
          position: relative;
          line-height: 16px;
          // font-weight: 500;
          display: -webkit-flex;
          display: flex;
          width: 100%;
          color: #272727;
          height: 20%;
          color: #216689;
          &.text-D{
            color: #ba5225 !important;
          }
        }
      }
    }

    .biomarker-container{
      height: 100%;
      .header-div{
        height: 15%;
      }
    }

    .biomarkers-grid-view{
      height: 84%;
      align-content: center;
      // height: 90%;
      // grid-template-rows: auto 7%;
    }
  }
  
}

.graphs-analytics-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: auto;
   height: 100%;
  .grade-icon{
    .grade-block{
      border-radius: 50px;
      height: 22.3px;
      width: 22.21px;
    }
  }
  .circular-gauge{
    z-index: 0;
    margin-top: 15%;
    #gaugeChart{
      height: 125px !important;
      width: 135.65px !important;
    }
    .chartBox{
      position: relative;
      .grade-icon{
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .age-bars-container{
    justify-content: space-evenly;
    display: flex;
    height: 50%;
    width: 100%;
    flex-direction: column;
  }

.bar-container-real-age,
.bar-container-physioage {
  position: relative;
  width: 90%;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 3%;
}

.bar-container-real-age .bars-container,
.bar-container-physioage .bars-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  min-height: 20px;
}

.bar-container-real-age .text-container,
.bar-container-physioage .text-container {
  display: flex;
  flex: 1;
  align-items: center;
}

.start-text-real-age,
.start-text-physioage,
.end-text-real-age,
.end-text-physioage {
  position: relative;
  top: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  flex: 1;
}

.end-text-real-age,
.end-text-physioage {
  color: #3397C9; 
}

  
  .bar-background-physioage,  .bar-background-real-age{
    background-color: #ecf0f1;
    height: 32.6%;
    width: 100%;
    border-radius: 25px;
    position: relative;
  }
  
  .animated-filled-div-physioage, .animated-filled-div-real-age {
    position: absolute;
    // top: 0;
    left: 0;
    height: 32.6%;
    width: 0;
    border-radius: 25px;
    z-index: 1;
  }
}

.gpa-chart-dashboard{
  padding-left: 10px !important;
}

.historic-data-chart-beta {
  display: flex;
  flex-direction: row;
}

.chart-icon-analytics {
  float: right;
  margin-right: 5rem;
  color: var(--text-active-c) !important;
  font-size: larger;
}

.no-biomarkers {
  min-height: 100%;
}

.biomarker-compare-popup {
  position: fixed;
  width: 90%;
  top: 6%;
  left: 5%;
  right: 5%;
  height: 90%;
  background-color: #fff;
  min-width: 90%;
  min-height: 450px;
  z-index: 1000000000;
  padding: 0;
  border-radius: 8px;
}  
  // .biomarker-compare-popup-mobile {
  //   position: fixed;
  //   width: 100%;
  //   top: 0%;
  //   left: 0%;
  //   right: 0%;
  //   height: 100%;
  //   background-color: #fff;
  //   z-index: 1000000000;
  //   padding: 10px 15px 15px 15px;
  //   border-radius: 3px;
  //   display: flex;
  //   // justify-content: end;
  //   overflow: auto;
  //   display: flex;
  //   flex-direction: row-reverse;
  //   justify-content: space-between;
  // }

  // .biomarker-compare-popup .header, .biomarker-compare-popup-mobile .header {
  //   width: 0%;
  //   position: fixed;
  //   margin-right: 1rem;
  //   z-index: 10000;
  // }
  .header {
    background: #FFF;
    border-bottom: 1px solid #80808052;
    padding: 10px 15px 10px 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  // input{
  //   width: 16px;
  //   height: 16px;
  // }
  
  .checkbox-main {
    display: block;
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
  }
  
  .checkbox-main input[type=checkbox] {
    visibility: hidden;
  }
  
  .biomarker-checkbox-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 1px solid #f7f3f3;
    border-radius: 5px;
  }
  
  .checkbox-main input:checked~.biomarker-checkbox-container {
    background-color: white;
  }
  
  .biomarker-checkbox-container::after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 3px;
    width: 4px;
    height: 7px;
    border: solid black;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
  }
  
  .checkbox-main input:checked~.biomarker-checkbox-container::after {
    display: block;
  }
  .age-line{
    .checkbox-main{
      opacity: 0.5;
      cursor: default;
    }
  }

.biomarker-chart-label {
  font-weight: bolder;
  color: grey;
  margin-bottom: 1rem;
}

.biomarkers-list-popup {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  .biomarkers-render{
    &.selected{
      .not-selected{
        opacity: 0.1;
      }
      .selected-data-point{
        opacity: 1;
      }
      .unselected-data-point{
        opacity: 0.1;
        pointer-events: none;
      }
      .color-description{
        &.selected{
          opacity: 1;
        }
        &.age-line{
          opacity: 1;
        }
      }
      .unselected-asterisk{
        opacity: 0.1;
      }

      .detail{
        &.selected-hover{
          opacity: 1 !important;
        }
        &.unselected-hover{
          opacity: 0 !important;
        }
      }
    }
    .not-selected, .unselected-data-point, .unselected-asterisk{
      opacity: 0.1 !important;
    }
    .selected-biomarker-line{
      opacity: 1 !important;
    }
    
    .selected-asterisk{
      opacity: 1 !important;
    }
    .line-color-container, .biomarker-name{
      &.unselected{
        opacity: 0.5;
      }
      &.selected{
        opacity: 1;
      }
    }

    .detail{
      .item{
        opacity: 0 !important;
      }
      &.selected-hover{
        .item{
          opacity: 1 !important;
        }
        &.inactive{
          opacity: 0 !important;
        }
      }
      &.unselected-hover{
        opacity: 0 !important;
      }
      
    }
    
    display: flex;
    .chart-content{
      margin-top: 7%;
      flex: 0 0 auto ;
      .chart_container{
        margin-left: 5%;
        overflow: auto;
      }
    }
    .chart-content::-webkit-scrollbar{
      display: none;
    }
    .compare-biomarkers-color-description{
      flex: 1 1 auto;
      margin-top: 5%;
      .color-description{
        .line-color-container{
          cursor: pointer;
        }
        display: flex;
        margin-top: 10px;
        .line-color{
          width: 50px;
          height: 5px;
          margin-top: 9px;
          border: 0.5px solid white;
          border-radius: 50px;
        }
        .biomarker-name{
          cursor: pointer;
          margin-left: 10px;
          line-height: 20px;
        }
        &.age-line{
          .biomarker-name{
            cursor: default;
          }
        }
      }
      .enable-all-lines, .disable-all-lines{
        margin-top: 5%;
        border-radius: 4px;
        color: white;
        height: 30px;
        background: var(--btn-bg);
        border: 1px solid transparent;
          font-size: 12.64px;
      }
      .disable-all-lines{
        margin-left: 5%;
      }
      .enable-all-lines:hover, .disable-all-lines:hover{
        background-color: var(--btn-bg-h);
      }
    }
    
    @media (max-width: 768px){
      .chart-content{
        flex: 0 0 auto !important;
        .chart_container{
          overflow-x: auto;
          overflow-y: auto;
          margin-left: 0%;
          height: 100%;
          padding: 20px 0px !important;
          .rickshaw_graph{
            circle{
              r: 3 !important
            }
          }
        }
        .chart_container::-webkit-scrollbar{
          display: none;
        }
      }
      .compare-biomarkers-color-description{
        flex: 1 1 auto;
      }
      .color-description{
        flex: 1;
      }
      .age-line{
        flex: auto;
      }
      .reset-biomarkers{
        display: flex;
        margin-left: -2% !important;
        margin-top: 2%;
        .enable-all-lines, .disable-all-lines{
          // width: 165px;
          height: 30px;
          margin: 1% 2% !important;
          text-wrap: nowrap;
        }
      }
    }
    @media (min-width: 769px) and (max-width: 1024px){
      .chart-content{
        overflow-x: auto !important;
        overflow-y: auto !important;
        flex: 0 0 auto;
        // max-width: 80% !important;
      }
      .reset-biomarkers{
        .enable-all-lines, .disable-all-lines{
          text-wrap: nowrap;
        }
      }
    }
  }  
}

.biomarkers-render {
  width: 100%;
  height: 100%;
  .rickshaw_graph {
    .x_tick{ 
      .title{
        bottom: -20px;
      }
    }
  }
  .chart-content{
    .chart_container{
      .y_axis{
        top: 10px;
      }
      .tick{
        line{
          opacity: 0 !important;
        }
      }
    }
  }
}

.catgory-details{
  display: flex;
  z-index: 1000;
  // padding: 10px 10px 20px 0px;
  border-radius: 24px;
  position: absolute;
  top: 6.4%;
  height: 93.5%;
  width: 49.25%;
  padding-top: 10px;
  padding-bottom: 10px;
  right: 0;
  animation: slideFromRight 1s ease forwards;
  .individual-breadcrumb{
    padding-bottom: 0%;
  }
  .category-overview-div-1024{
    padding: 0px !important;
  }
}

@keyframes slideFromRight {
  from {
      right: -100%; /* Starting position */
  }
  to {
      right: -1%; /* End position (center) */
      transform: translateX(0%); /* Center the element */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .graphs-analytics-dashboard{
    flex: 1;
    max-width: 50%;
  }
  .gpa-chart-dashboard{
    flex: 1;
  }
  .chart_container{
    .y_axis{
      left: -18px ;
    }
  }
}

@media (max-width: 768px) {
  .biomarkers-render {
    display: flex;
    flex-direction: column;
    .chart-content{
      margin-top: 0% !important;
      max-width: 100% !important;
      .y_axis{
        left: -10px;
      }
      .chart_body{
        left: 20px;
      }
    }
    .compare-biomarkers-color-description{
      max-width: 100%;
      white-space: normal;
      flex-wrap: wrap;
      .color-description{
        margin-right: 10px;
        max-width: fit-content;
      }
      .enable-all-lines, .disable-all-lines{
        margin-top: 7% !important;
      }
      .disable-all-lines{
        margin-right: 40% !important;
      }
    }
  }
  .graphs-analytics-dashboard{
    flex: 1;
  }
  .gpa-chart-dashboard{
    flex: 1;
  }
}

@media (max-width: 426px){
  .biomarkers-render{
    .chart-content{
      flex: none;
    }
    .compare-biomarkers-color-description{
      margin-left: 5%;
      .age-line{
        min-width: 200px;
      }
    }
  }
}

@media (max-width: 320px) {
  .biomarkers-render{
    .chart-content{
      flex: 0 !important;
      .chart_container{
        padding: 15px 0 !important;
      }
    }
    .compare-biomarkers-color-description{
      margin-left: 0%;
      padding-right: 0px;
      .reset-biomarkers{
        margin-left: -2% !important;
        margin-top: 2%;
      }
      .color-description{
        .line-color{
          margin-top: 6px !important;
        }
      }
    }
  }
}


@media (max-width: 1024px) and (min-height: 1024px) and (max-height: 1368px){
  .biomarkers-render{
    flex-direction: column;
    .chart-content{
      flex: 0 0 auto !important;
      circle{
        r: 5 !important
      }
    }
    .compare-biomarkers-color-description{
      flex: 1 1 auto !important;
    }
  }
}

.dashboard-charts-main-div{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.gpa-chart-dashboard{
  height: 100%;
  width: 100%;
  // margin-top: 2.5rem;
  overflow: auto;
  overflow-y: hidden;
  .rickshaw_graph .detail{
    .item{
      margin-top: 1.5em;
    }
    &.normalized-position{
      .item {
        position: absolute;
        z-index: 2;
        border-radius: 3px;
        font-size: 12px;
        font-family: Arial, sans-serif;
        background: rgba(0, 0, 0, 0.4);
        color: white;
        margin-left: 1em;
        margin-right: 1em;
        margin-top: -4em;
        white-space: nowrap;
      }
      .item:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        content: "";
        border: 5px solid transparent;
      }
      .right:after {
        right: -6px !important;
        margin-top: -5px;
        border-left-color: rgba(0, 0, 0, 0.8);
        border-right-width: 0;
        top: 4em !important;
        left: auto;
        transform: rotate(0deg) !important;
      }
      .left:after {
        top: 4em !important;
        left: -6px !important;
        margin-top: -5px;
        border-right-color: rgba(0, 0, 0, 0.8);
        border-left-width: 0;
        transform: rotate(0deg) !important;
      }
      &.null-points{
        .item{
          margin-top: -3em;
        }
        .left:after, .right:after{
          top: 3em !important;
        }
      }
      &.bottom-most-point{
        .item{
          margin-top: -11em;
          margin-left: -4em;
          &.right{
            margin-right: -4em;
          }
        }
        .left:after{
          top: 10em !important;
          left: 46px !important;
          margin-top: -18px;
          transform: rotate(-90deg) !important;
        }
        .right:after{
          right: 4em !important;
          margin-top: -14px;
          top: 10em !important;
          transform: rotate(90deg) !important;
        }
        &.null-points{
          .item{
            margin-top: -7em;
          }
          .right:after, .left:after{
            margin-top: -18px;
            top: 7em !important;
          }
        }
      }
    }
  }

}
.gpa-chart-dashboard::-webkit-scrollbar{
  display: none !important;
}
.change-from-past-dashboard{
  .padding-left-50{
    padding-left: 50px;
  }
}
// .chart-content{
//   width: 100%;
//   height: 100%;
// }
.dashboard .padding {
  padding: 4px;
  height: 100%;
}
.dashboard
  .treatment-biomarker-container {
    height: 100%;
}
.recommendation-list-container{
  height: 100%;
}
.dashboard-container{
  height: 100%;
  padding: 0.5%;
}

@media (max-width: 767px) {
  .dashboard {
    .padding{
      height: auto !important;
      padding: 0px !important;
    }
    .report-card-section {
      height: 50%;
      width: 100%;
      .change-from-past-dashboard {
        padding-bottom: 0px !important;
        .padding-left-50{
          padding-left: 0px !important;
        }
      }
    }
    .report-card-text {
      font-size: 16px;
    }
    .dashboard-charts-main-div {
      height: 80%;
      padding-top: 0px !important;
      flex-direction: column !important;
    }
    .treatment-plan {
      .heading {
      font-size: 13px;
      }
      .view-all-text{
        font-size: 10px !important;
      }
      .header-div .text {
        font-size: 11.64px !important;
      }
      .recommendation-list .recommendation-body{
          .jw-recommendation-toggle img,i {
            width: 16px;
            height: 16px;
            font-size: 17px !important;
          }
          .heading-1 {
            font-size: 12px !important;
          } 
          .text-1{
            font-size: 11px !important;
          } 
        .recommendation-body-text-expanded {
          font-size: 13px;
        }  
        .expaned-div img {
          width: 20px;
        }

      }
    }
    .biomarkers-of-Aging-plan {
      margin-top: 2px;
      // min-height: 44%;
      height: auto;
      .biomarkers-grid-view {
        height: auto;
        // background: white;
      }
      .heading {
        color: #0D3E56;
        font-size: 13px !important;
      }
      .view-all-text {
        font-size: 10px !important;
      }  
      .compare-text {
        font-size: 11.64px !important;
      }  
      .render-biomarker {
        padding: 5px 2px;
      }
      .biomarker-card {
        .biomarker {
          overflow-y: hidden; 
          overflow-x: hidden;
          .graph{
            padding: 10px 0px 20px 0px;
          }
          .detail {
            padding: 0px;
            height: 30%;
            padding: 10px 0px 10px 0px;
          }  
        }
      }
      .render-biomarker .left-right {
        padding-top: 9px;
      }
    }
    .noumenon-filter .dropdown-menu {
      transform: translate3d(0px, 26px, 7px) !important;
    }
    .graphs-analytics-dashboard {
      padding-top: 0px;
      .circular-gauge{
        margin-top: 0 !important;
      }
      .bar-container-real-age{
        margin-top: 10% !important;
      }
    }
    .gpa-chart-dashboard{
      .chart_container{
        padding: 20px 0px !important;
      }
    }
    .padding-left-8{
      padding-left: 0%;
    }
    .dashboard-report-card .category-overview {
      height: 400px;
      padding-top: 0px !important;
    }
  }  
  .right-div {
    width: 99% !important;
    margin-left: 2px;
    margin-top: 4px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 820px){
  .catgory-details{
    // left: 0%;
    height: 100%;
    width: 100%;
    right: 0;
    animation: slideFromRightLeft 1s ease forwards;
}

@keyframes slideFromRightLeft {
  from {
      right: -100%; /* Starting position */
  }
  to {
      right: 0%; /* End position (center) */
      transform: translateX(0%); /* Center the element */
  }
}
  
}


@media only screen and (width: 820px) and (height: 1180px) {
  .dashboard .treatment-plan {
    height: 78% !important;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 992px){
  .dashboard {
    .report-card-text {
      font-size: 16px;
    }
    .gpa-chart-dashboard {
      // padding-left: 10px !important;
    }
    .treatment-plan {
      height: 73%;
      .heading {
       font-size: 13px;
      }
      .view-all-text{
        font-size: 10px !important;
      }
      .header-div .text {
        font-size: 11.64px !important;
      }
      .recommendation-list .recommendation-body{
          .jw-recommendation-toggle img,i {
            width: 16px;
            height: 16px;
            font-size: 17px !important;
          }
          .heading-1 {
            font-size: 12px !important;
          } 
          .text-1{
            font-size: 11px !important;
          } 
        .recommendation-body-text-expanded {
          font-size: 13px;
        }  
        .expaned-div img {
          width: 20px;
        }

      }
    }
    .biomarkers-of-Aging-plan {
      .heading {
        color: #0D3E56;
        font-size: 13px !important;
      }
      .view-all-text {
        font-size: 10px !important;
      }  
      .compare-text {
        font-size: 11.64px !important;
      }  
      .biomarkers-grid-view {
        height: auto;
        // background: white;
        display: flex;
      }
    }
    .dashboard-charts-main-div {
      height: 80%;
      padding-top: 0px !important;
    }
    .padding {
      padding-top: 0px;
      padding-left: 2px;
      padding-right: 0px;
      padding-bottom: 3px;
    }
    .biomarkers-of-Aging-plan {
      margin-top: 2px;
      // height: 40%;
      height: auto;
    }
    .dashboard-report-card{
      .report-card-section {
        height: auto;
      }  
      //  height: 99% !important;
      .category-overview {
        height: 64%;
        padding-top: 0px !important;
        .nuomenon-table {
          // height: 42%;
        }
      }
    }
  }
  .dashboard-container {
    height: 100%;
    padding: 0.5%;
    overflow: hidden;
  }  
  body{
    overflow: hidden;
  }
}


.react-multi-carousel-list {
  .carousel-arrow {
    font-size: 20px;
    color: #fff;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(0,0,0,.5);
    min-width: 30px;
    min-height: 30px;
    opacity: 1;
    cursor: pointer;
  }
  .carousel-arrow:hover {
    background: rgba(0,0,0,.8);
  }
  .left-arrow{
    left: calc(1% + 2px);
  }
  .right-arrow{
    right: calc(1% + 2px);
  }
}



@media (max-width: 280px)
{
  .dashboard .biomarkers-of-Aging-plan .biomarker-card {
      width: 90%;
  }
}
@media (max-width: 912px)
{
  .dashboard {
    .treatment-plan {
      .header-div {
        height: 5%;
      }
    }  
    .dashboard-report-card .category-overview .nuomenon-table {
      height: 76%;
    }
  }

  .biomarkers-list-popup{
    .biomarkers-render{
      .chart-content {
        max-width: 100% !important;
      }
      .compare-biomarkers-color-description{
        max-width: 100% !important;
        .reset-biomarkers-container{
          justify-content: flex-start !important;
        }
        .biomarker-name{
          max-width: fit-content !important;
        }
      }
    }
  }
  .dashboard .biomarkers-of-Aging-plan .render-biomarker{
    display: flex;
  }
}

@media (min-width: 2559px){
  .dashboard{
    .graphs-analytics-dashboard{
      .circular-gauge{
        margin-top: 0%;
        #gaugeChart{
          height: 145px !important;
          width: 180px !important;
        }
      }
    }
  }
}

@media (min-width: 1440px){
  .dashboard{
    .gpa-chart-dashboard{
      overflow-x: hidden !important;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1439px){
  .dashboard{
    // .graphs-analytics-dashboard{
    //   max-width: fit-content;
    // }
    .gpa-chart-dashboard{
      flex: 1;
    }
  }
}

//3539
// @media (max-width: 1023px) and (min-width: 768px){
//   .dashboard{
//     .graphs-analytics-dashboard{
//       max-width: fit-content;
//     }
//   }
// }


.analytics-screen {
  &.beta {
    font-family: 'Noto Sans' !important;
    &.light-theme {
      background: $light-background;
      color: #333;
      .biomarker-compare-popup{
        .biomarker-checkbox-container {
          border: 1px solid #101010;
        }
        
        .checkbox-main input:checked~.biomarker-checkbox-container {
          background-color: var(--btn-bg);
        }
        
        .biomarker-checkbox-container::after {
          content: "";
          position: absolute;
          display: none;
          left: 6px;
          top: 3px;
          width: 4px;
          height: 7px;
          border: solid rgb(253, 250, 250);
          border-width: 0px 1px 1px 0;
          transform: rotate(45deg);
        }

        .checkbox-main input:checked~.biomarker-checkbox-container::after {
          display: block;
        }
      }
      .dashboard{
        .treatment-plan{
          .header-div{
            .text{
              color: #80818B !important;
              &.active{
                color:  var(--text-active-c) !important;
              }
            }
          }
          .recommendation-list{
            .active-card{
              border: var(--recommendation-list-active-card-b);
            }
          } 
        }
      }
      .dashboard{
        .render-biomarker{
          #biomarker-phenoage-levine{
            .icon{
              svg{
                filter: brightness(0) saturate(100%) invert(18%) sepia(59%) saturate(691%) hue-rotate(157deg) brightness(94%) contrast(96%);
              }
            }
          }
        }
      }
    }
    &.dark-theme {
      background: $dark-background;
      color: #F1F1F1;
      .biomarker-compare-popup  {
        background: $dark-component-background;
        box-shadow: $dark-component-box-shadow; 
        .header{
          background: #2F2F2F;
          border: none;
          box-shadow: 0px 1px 12px 0px #00000021;
          .bold-14-text{
            color: #F1F1F1;
          }  
        }
      } 
      .dashboard{
        .interpretations-information{
          .card-header{
            background-color: #2F2F2F !important;
          }
        }
        .treatment-plan{
          .header-div{
            .text{
              &.active{
                &.recent, .historic{
                  color: var(--text-active-c-D) !important;
                }
                &.suggestions{
                  color: var(--text-active-c-D) !important;
                }
              }
            }
            .normal-heading{
              &.heading{
                color: #F1F1F1 !important;
              }
            }
          }
        }
      } 
    }
  }
}

.questionnaire-light-theme{
  input {
    width: 100%;
    height: 36px;
  }
}

.questionnaire-dark-theme {
  background: $dark-component-background;
  box-shadow: $dark-component-box-shadow; 
  .surveyHeader {
    background: #2D6683;
    border: none;
    box-shadow: 0px 1px 12px 0px #00000021;
    color: #ffffff;
  }
 .card-body, .card {
  background: $dark-component-background;
  box-shadow: $dark-component-box-shadow; 
  color: #F1F1F1;
    input {
      background-color: transparent;
      border: 1px solid #434343;
      color: #F1F1F1;
      outline: none;
      width: 100%;
      height: 36px;
    }
    .alert-info {
      color: #F1F1F1;;
      background-color: #2F2F2F;
      border: none;
    }
    .reviewSurveyHeader {
      padding: 3px;
      background-color: #2D6683;  
    }
 }
 .card-body .card{
  border: 1px solid #D4D4DD;
 }
} 

.questionnaire-submitted-dark-theme{
  background: $dark-component-background;
  border: 1px solid gray;
  .card-body {
    background: $dark-component-background;
    color: #F1F1F1;
  }
}

.overlay_styles-dark-theme{
  background-color: var(--nav-overlay-sm-bg) !important;
}

.rating-div {
  font-size: 5em;
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
  display: flex;
}

.questionnaire-form-beta{
  .rating-component{
    padding-left: 0% !important;
  }
}

.card-body, .card {
  .box{
    width: 22px;
    height: 25px;
    border-radius: 2px;
    padding: 0px 5px 0px 5px;
    color: #F1F1F1;
    gap: 10px;
    font-size: 16px;
    margin-left: 40px;
  }
  .selected-box{
    border: 1px solid #DC7212;
    background-color: #DC7212;
  }
  .not-selected-box{
    border: 1px solid #434343;
    background-color: #434343;
  }
}

@media (max-width: 1280px) {
  .questionnaire-form-beta{
    .container{
      padding: 0% !important;
      max-width: none;
      .rating-labels{
        padding-right: 0px !important;
        .low-rating{
          padding-left: 25px !important;
        }
        .high-rating{
          padding-left: 25px !important;
          padding-right: 5px !important;
        }
      }
      .rating-div{
        overflow-x: unset !important;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@media (max-width: 1024px) {
  .catgory-details{
    padding: 10px 0px 10px 0px;
    top: 8.5%;
    height: 91.5%;
    width: 100%;
  }
  .dashboard .treatment-plan .header-div{
    height: auto !important;
  }
  .biomarkers-list-popup{
    .biomarkers-render{
      .compare-biomarkers-color-description{
        margin-top: 0% !important;
        .reset-biomarkers-container{
          justify-content: flex-start !important;
        }
      }
    }
  } 
}

@media (min-width: 2560px) {
  .catgory-details{
    top: 5%;
    height: 94%;
  }
}

@media(max-width: 1024px) and (min-width: 281px){
  .dashboard{
    .nuomenon-table{
      .table thead th:first-child {
        width: 17% !important;
      }
      thead th:nth-child(2){
        width: 30% !important;
      }
      thead th:nth-child(3){
        width: 13% !important;
      }
      thead th:nth-child(4){
        width: 20% !important;
        text-align: left;
      }
      thead th:nth-child(5){
        width: 20% !important;
        text-align: left;
      }
      .table tbody td:first-child {
        width: 17% !important;
        display: flex !important;
      }
      tbody td:nth-child(2){
        width: 30% !important;
      }
      tbody td:nth-child(3){
        width: 13% !important;
      }
      tbody td:nth-child(4){
        width: 20% !important;
        text-align: left;
      }
      tbody td:nth-child(5){
        width: 20% !important;
        text-align: left;
      }
      tbody td:nth-child(4), tbody td:nth-child(5){
        .text-1{
          justify-content: center !important;
          .col-9{
            justify-content: center !important;
          }
        }
      }
    }
  } 
}

@media(max-width: 912px){
  .dashboard{
    .treatment-biomarker-container{
      display: flex;
      flex-direction: column;
      .treatment-plan{
        flex: 0 0 auto;
      }
      .biomarkers-of-Aging-plan{
        flex: 1 1 auto;
        .biomarker-container{
          display: flex;
          flex-direction: column;
          .header-div{
            flex: 0 0 auto;
          }
          .biomarkers-grid-view{
            flex: 1 1 auto;
          }
        }
        .biomarker-card{
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 768px)
{
  .biomarker-compare-popup{
    overflow-y: auto;
    .rickshaw_graph .detail{
      .item {
        margin-left: 2em;
        margin-top: -2.5em;
      }
      .item.left:after{
        top: 3.75em;
        transform: rotate(0deg);
        left: -6px;
      }
      .item.right:after{
        top: 3.75em;
        transform: rotate(0deg);
        right: -6px;
      }
    } 
  }
  .dashboard .biomarkers-of-Aging-plan .biomarker-card {
    height: max-content;
  }
  .dashboard .nuomenon-table thead th{
    width: 20% !important;
    text-align: left !important;
    padding-left: 2px ;
    padding-right: 2px;
  }
  .dashboard .nuomenon-table tbody td{
    width: 20% !important;
    text-align: left !important;
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media (max-width: 540px)
{
  .dashboard .dashboard-charts-main-div{
    flex-direction: column;
  }
  .dashboard .biomarkers-of-Aging-plan .biomarker-card {
    height: 100%;
  }
  .dashboard .dashboard-report-card .category-overview .nuomenon-table {
    height: auto !important;
  }
  .dashboard .treatment-biomarker-container{
    height: fit-content !important;
  }
  .dashboard .nuomenon-table thead th{
    text-align: left !important;
    padding-left: 2px ;
    padding-right: 2px;
  }
  .dashboard .nuomenon-table tbody td{
    text-align: left !important;
    padding-left: 2px ;
    padding-right: 2px;
  }
}

@media (max-width: 280px)
{
  .dashboard .nuomenon-table thead th{
    overflow-wrap: anywhere !important;
  }
  .dashboard tbody .text-1{
    justify-content: center !important;
  }
}

@media(min-width: 1477px){
  .graphs-analytics-dashboard{
    .bar-container-physioage, .bar-container-real-age{
      .end-text-physioage, .end-text-real-age{
        text-align: end;
      }
    }
  }
}

@media(min-width: 1025px) and (max-width:1476px){
  .graphs-analytics-dashboard{
    .bar-container-physioage, .bar-container-real-age{
      width: 100%;
      .end-text-physioage, .end-text-real-age{
        max-width: 30%;
      }
      .bars-container{
        min-height: 20px;
      }
    }
  }
}

@media(min-width: 768px) and (max-width:1024px){
  .graphs-analytics-dashboard{
    .bar-container-physioage, .bar-container-real-age{
      .end-text-physioage, .end-text-real-age{
        text-align: end;
      }
      .bars-container{
        min-height: 20px;
      }
    }
  }
}

@media (max-width:768px){
  .graphs-analytics-dashboard{
    .bar-container-physioage, .bar-container-real-age{
      .end-text-physioage, .end-text-real-age{
        text-align: end;
      }
    }
  }
}
.analytics-screen {
  &.beta {
    &.light-theme {
      .rickshaw_graph .detail .item .result {
          color: var(--rickshaw_graph-detail-item-result-c);
      }
    }
    &.dark-theme {
      .rickshaw_graph .detail .item .result {
          color: var(--rickshaw_graph-detail-item-result-c-d);
      }
    }
  } 
} 