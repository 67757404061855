
.btn-reset {
  background:transparent;
  appearance:none;
  -webkit-appearance:none;
  border:0;
}

.btn {
  @include font-semibold;
  //border-radius:0;
  @include ripple;
  @media (min-width:$screen-sm) {
    // margin:0 $gap-xs;
  }
}


.btn-icon {
  display:inline-flex;
  align-items:center;
  justify-content:center;
  padding:$gap-sm;
  img,svg {
    width:1rem;
    height:1rem;
    opacity:0.3;
  }
  &:hover,&:focus {
    img,svg {
      opacity:1;
    }
  }
  &.btn-default {
    fill:currentColor;
  }
}


.btn-default {
  background:white;
  border:2px solid #eee;
  color:$text-color;
  &:hover,&:focus {
    background:#eee;
    border-color:$gray-lt;
  }
}

.btn-controls {
  @media (min-width:$screen-sm) {
    margin:0 (-($gap-xs))
  }
}

.message-dot{
  position: absolute;
  top: 1px;
  left: 3px;
  font-size: 9px;
}

.message-blue-dot{
  left: 3px;
  color: #5BC0DE;
}

.message-red-dot{
  left: 15px;
  color: #dc3545;
}

.message-blue-dot-section{
  left: 13px;
  color: #5BC0DE;
  position: absolute;
}


.messageIconHover{
  display: none;
}

.comment-indicator:hover + .messageIconHover{
  display: inline ;
  position: absolute;
  left: -30px;
  top: 30px;
  z-index: 100000;
  background-color: rgb(255, 255, 255);
  padding: 2px;
  border: 1px solid black;
}

.comment-indicator {
  display:none;
  position: absolute;
  left: -70px;
  top: 5px;
  svg {
    display:block;
    width:14px;
    height:18px;
  }
  &.active {
    display:block;
  }
}
.comment-indicator-section {
  display:none;
  // position: absolute;
  left: -70px;
  top: 5px;
  svg {
    display:block;
    width:14px;
    height:18px;
  }
  &.active {
    display:block;
    position: relative;
    margin-left: 67px;
    margin-top: -10px;
  }
}

