@import "../../Analytics-style/base/_colors.scss";
.nomenon-details {
    .drawer-warning {
      min-width: 90%;
      min-height: 450px;
      background-color: #fff;
      z-index: 1000000000;
      // padding: 10px 15px 10px 15px;
      padding: 0;
      border-radius: 3px;
      position: fixed;
      left: 5%;
      right: 5%;
      .header {
        // border-radius: 5px 5px 0px 0px;
        background: #FFF;
        border-bottom: 1px solid #80808052;
        padding: 10px 15px 10px 15px;
      }
      .card-body-div{
        padding: 10px 15px 10px 15px;
      }
      .noumenon-list{
        overflow: auto !important;
        max-height: 500px;
        .card-div{
          background-color: #FFF;
        }
      }
    }
    .active {
      border: var(--recommendation-list-active-card-b-d);
      border-radius: 4px;
    }
    .rubric-ranges-chart-container{
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      .noumenon-label-container{
        margin-right: 0 !important;
        flex-direction: row;
        .rubric-ranges-bar-container{
          flex: 1;
          margin-left: 2%;
        }
      }
      .rubric-ranges-container{
        justify-content: space-between;
        overflow: scroll;
        scrollbar-width: none;
        .animated-filled-div-arrow{
          background: linear-gradient(to right, rgb(216, 216, 0) 33.3333%, rgb(55, 180, 94) 66.6667%, rgb(216, 216, 0) 100%);
          position: absolute;
          height: 11%;
          width: 95%;
          border-radius: 25px;
          display: flex;
          padding: 0 !important;
          
        }
        .datum-labels-container{
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 50px;
          .datum-label{
            z-index: 2;
            font-weight: 400;
            font-size: 11px;
            line-height: 16.31px;
            height: 13px;
            flex: 1 1;
            .range-label-text{
              font-size: 14px;
            }
          }
        }
        .arrow{
          position: absolute;
          left: 43%;
          margin-left: -2%;
          content: url("../../../assets/images/rubric_ranges_arrow_indicator.svg");
          
        }
        .rubric-ranges-bar-arrow{
          &.undefined-pointer{
            .arrow{
              display: none;
            }
          }
        }
        &.category-section-container{
          height: 6%;
          margin-top: 4%;
        }
        &.null-points{
          height: 0%;
        }
        .rubric-ranges{
          margin-right: 10px;
          width: 30%;
          .range-color{
            width: 12px;
            height: 12px;
            border: 2px solid black;
            border-radius: 25%;
            font-weight: 400;
            line-height: 13px;
            letter-spacing: -0.01em;
            text-align: left;
            margin-right: 5px;
            display: flex;
            margin-top: 5px;
          }
          .range-type{
            width: 70%;
          }
        }
      }
      .rubric-ranges-container::-webkit-scrollbar {
        display: none;
      }
      .category-graph-section{
        flex: 1;
        min-height: 400px;
        margin-top: 0%;
        align-content: center;
        display: block;
        overflow-x: auto;
        .biomarker-noumenon-chart::-webkit-scrollbar{
          display: none;
        }
      }
      .category-graph-section::-webkit-scrollbar{
        display: none;
      }

    }
}


@media (max-width: 767px) {
  .nomenon-details {
    .drawer-warning {
      // min-width: 98%;
      min-height: 400px;
      left: 0%;
      right: 0%;
    }
  }    
}

@media screen and (min-device-width: 768px) and (max-device-width: 992px){
  .nomenon-details {
    .drawer-warning {
      min-width: 98%;
      min-height: 400px;
      left: 1%;
      right: 1%;
    }
  } 
}

.analytics-screen {
  &.beta {
    font-family: 'Noto Sans' !important;
    &.light-theme {
      background: $light-background;
      color: #333; 
    }
    &.dark-theme {
      background: $dark-background;
      color: #F1F1F1;
      .nomenon-details .drawer-warning, .nomenon-details, .card-body-div  {
        background: $dark-component-background;
        box-shadow: $dark-component-box-shadow; 
        .header{
          background: #2F2F2F;
          border: none;
          box-shadow: 0px 1px 12px 0px #00000021;
        }
        .rubric-ranges-chart-container .bold-14-text, .header .bold-14-text{
          color: #F1F1F1;
        }  
        .noumenon-list{
          .card-div {
            border: 1px solid #8080804a;
            background-color: #2F2F2F;
            color: #F1F1F1;
          }  
          .card-div .active{
            background-color: #2F2F2F;
            color: #F1F1F1;
          }
        }  
      }
    }
  }
}

@media (max-width: 912px){
  .rubric-ranges-chart-container .noumenon-label-container .rubric-ranges-bar-container{
    max-width: 100% !important;
  }
}

@media (max-width: 768px) {
  .nomenon-details {
    .noumenon-label-container{
      flex-direction: column !important;
      .rubric-ranges-bar-container{
        padding: 0% !important;
      }
    }
    .rubric-ranges-container{
      .animated-filled-div-arrow{
        left: 0 !important;
        width: 100% !important;
      }
    }
    .rubric-ranges-chart-container{
      padding-left: 0% !important;
    }
    
  }
}

@media (max-width: 540px) {
  .nomenon-details {
    .drawer-warning{
      height: 100%;
      .card-body-div{
        height: 95%;
        overflow: auto;
        .rubric-ranges-chart-container{
          padding-top: 5%;
          .noumenon-label-container{
            margin-top: 0% !important;
            margin-left: 0% !important;
          }
          .rubric-ranges-bar-arrow{
            margin-top: 1% !important;
          }
        }
      }
    }
  }
}