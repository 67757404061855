.privacy-policy{
    padding: 5% 15%;
    text-align: justify;
    background: #f4f7fb url("../../assets/images/icon_physioage.svg") no-repeat center;
    .policy-title{
        border-bottom: 2px solid #eeeeee;
    }
    .footer-sec{
        padding: 24px;
        padding-top: 0;
        padding-bottom: 10px;
        border-top: 1px solid #eeeeee;
        .footer{
            justify-content: center;
        }
    }
    @media (max-width: 768px) {
        padding: 5%;
        h1, h2, h3, h4{
            text-align: initial;
        }
    }
}