.billing-summary-component {
    &.parent-component {
        .col-lg-12.col-md-12.col-sm-12.col-xs-12.data-section {
            @media (max-width: 1024px) {
                margin-left: 15px;
            }
            @media (max-width: 768px) {
                margin-left: 0;
            }
        }
    }
}
.billing-summary-table {
    @media (max-width: 530px) {
        // max-width: 80%;
        .bootstrap-table-container {
            // max-width: 100%;
            overflow: auto;
            border: 1px solid #ccc;
        }
        .react-bootstrap-table {
            width: 700px;
            table {
                table-layout: auto;
            }
        }
    }
    @media (max-width: 530px) {
        max-width: 500px;
    }
    @media (max-width: 500px) {
        max-width: 470px;
    }
    @media (max-width: 450px) {
        max-width: 420px;
    }
    @media (max-width: 420px) {
        max-width: 390px;
    }
    @media (max-width: 415px) {
        max-width: 380px;
    }
    @media (max-width: 375px) {
        max-width: 345px;
    }
    @media (max-width: 360px) {
        max-width: 340px;
    }
    @media (max-width: 320px) {
        max-width: 300px;
    }
    @media (max-width: 300px) {
        max-width: 280px;
    }
}
.billing-reports {
    margin-bottom: 0px !important;
    tr {
        border: none;
        border-bottom: 1px solid #eeeeee;
    }
}
.billing-summary-table {
    .btn-primary {
        width: 100%;
    }
    .side-panel {
        padding-right: 15px;
    }
    th,
    td {
        min-width: 15%;
        max-width: 17%;
        word-break: break-all !important;
    }
}
@media (max-width: 768px) {
    .row .container .billing-summary-table {
        padding-left: 30px;
    }
}
