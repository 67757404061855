.inbox-component {
    &.parent-component {
        .col-lg-12.col-md-12.col-sm-12.col-xs-12.data-section {
            @media (max-width: 1024px) {
                margin-left: 15px;
            }
            @media (max-width: 768px) {
                margin-left: 0;
            }
        }
    }
    .float-right.no-breadcrumb-header {
        // position: inherit!important;
        float: none !important;
        max-width: 100% !important;
        padding: 0 15px !important;
    }
    
}
.raw-btn {
    padding: 0 5px !important;
    background-color: white !important;
    white-space: nowrap;
    overflow: auto;
    font-size: inherit;
    border: 0.5px solid #ccc;
    @media (max-width: 400px) {
        white-space: inherit;
    }
}
.inbox-table {
    @media (max-width: 530px) {
        // max-width: 80%;
        .bootstrap-table-container {
            max-width: 100%;
            overflow: auto;
            border: 1px solid #ccc;
        }
        .react-bootstrap-table {
            width: 700px;
            table {
                table-layout: auto;
            }
            th{
                white-space: nowrap!important;
            }
            th,td{
                min-width: 12%;
                &:first-child{
                    min-width: 10%;
                }
                &:nth-child(6){
                    min-width: 5%;
                }
            }
        }
    }

    @media (max-width: 530px) {
        max-width: 500px;
    }
    @media (max-width: 500px) {
        max-width: 470px;
    }
    @media (max-width: 450px) {
        max-width: 420px;
    }
    @media (max-width: 420px) {
        max-width: 390px;
    }
    @media (max-width: 415px) {
        max-width: 380px;
    }
    @media (max-width: 375px) {
        max-width: 345px;
    }
    @media (max-width: 360px) {
        max-width: 340px;
    }
    @media (max-width: 320px) {
        max-width: 300px;
    }
    @media (max-width: 300px) {
        max-width: 280px;
    }
}
.inbox-children {
    .side-panel {
        padding-right: 15px;
    }
    .react-bootstrap-table {
        background-color: white;
        border: 1px solid #cccccc !important;
        border-radius: 4px;
        border: 0;
        td,
        th {
            min-width: 10%;
            @media (max-width: 375px) {
                word-break: break-all;
                white-space: normal;
            }
        }
        th {
            white-space: nowrap;
            @media (max-width: 375px) {
                word-break: break-all;
                white-space: normal;
            }
        }
        td:nth-child(6) {
            padding: 8px 0px;
        }
        @media (max-width: 768px) {
            padding: 0;
        }
    }

    .msg-filters {
        padding: 10px;
        &.card {
            cursor: pointer;
            border-radius: 0px;
            .badge {
                line-height: 1.5;
                border-radius: 25px;
                background-color: #777;
                color: #fff;
                padding: 2px 7px;
                height: fit-content;
            }
            &.active-card {
                background-color: #337ab7;
                color: #fff;
                border-color: #337ab7;
                .badge {
                    background-color: #fff;
                    color: #337ab7;
                }
            }
            .last-active {
                color: #ff8000;
            }
        }
        .d-flex {
            justify-content: space-between;
            .sender {
                max-width: 130px;
            }
        }
    }
    @media (max-width: 400px) {
        padding: 0;
    }
}
.message-details-sec {
    padding: inherit !important;
    .message-details-body {
        border: 1px #cccccc solid;
        max-height: 400px;
        margin-top: 20px;
        background-color: #fff;
        overflow: auto;
    }
    table {
        border-bottom: 1px solid #ccc;
        tr {
            border-bottom: 1px solid #ccc;
            &:last-child {
                border-bottom: none;
            }
        }
        td {
            padding: 10px;
            border: none;
        }
    }
    table,.message-details-body{

        @media (max-width: 530px) {
            max-width: 500px;
        }
        @media (max-width: 500px) {
            max-width: 470px;
        }
        @media (max-width: 450px) {
            max-width: 420px;
        }
        @media (max-width: 420px) {
            max-width: 390px;
        }
        @media (max-width: 415px) {
            max-width: 380px;
        }
        @media (max-width: 375px) {
            max-width: 345px;
        }
        @media (max-width: 360px) {
            max-width: 340px;
        }
        @media (max-width: 320px) {
            max-width: 300px;
        }
        @media (max-width: 300px) {
            max-width: 280px;
        }
    }
    pre {
        overflow-wrap: anywhere;
        white-space: pre-wrap;
        padding: 10px;
        margin: 10px;
        display: flex;
        @media(max-width: 530px){
            white-space: initial;
        }
    }
    .data-row.col-lg-6 {
        border-bottom: 1px solid #ccc;
        padding: 0;
        display: inline-block !important;
        width: 50%;
        &:last-child {
            border: none;
        }
        .col-lg-4,
        .col-lg-8 {
            word-break: break-word;
            display: inline-flex;
        }
    }
    .msg-detail-patient span {
        text-transform: uppercase;
    }
}
.two-columns-layout {
    column-count: 2;
}
.resolve-message-section {
    .d-flex.justify-center {
        margin: 10px;
        .btn {
            padding: 10px 40px;
        }
    }
    .modal-body {
        overflow: auto;
        word-break: break-all;
    }
}
.failure-messages{
    table td{
        a{
            color: #007DBC !important;
        }
    }
}
.unresolved-count{
    color: #212529;
}
.message-form{
     textarea{
        height: 20rem !important;
     }
     button{
         margin-right: 20% !important;
     }
}
.message-details-card{
    display: flex;
    justify-content: space-between;
    .message-controls{
        display: flex;
        button{
            font-size: 0.8rem !important;
        }
    }
}
@media (max-width: 1024px) {
    .inbox-section.col-lg-9 {
        left: 0;
    }
}
