.page-wrapper {
	position:relative;
	width:100%;
}
.page-section .card-group-title{
	margin: 0;
    padding: 0 12px 12px;
    font-family: "Alegreya Sans", sans-serif;
    font-weight: 700;
    color: #103756;
    font-size: 2.75rem;
}
.page-content {
	transition:all 0.15s;
	padding:$gap-md $gap $gap-lg * 2;
	position:relative;
	width:100%;
	z-index:2;
}

.with-max-width {
	max-width:1155px;
	//padding-left: 0 !important;
}

.max-container {
	margin:0 auto;
	width:100%;
	@include screen-tv {
		max-width:80%;
	}
	@media (max-width:$screen-lg) {
		padding:0 $gap;
	}
	@media (min-width:$screen-sm) and (max-width:$screen-md) {
		padding:0 $gap-md;
	}
}

.page-header {
	position:relative;
	@media (min-width:$screen-md) {
		margin-bottom:$gap;
		display:flex;
		flex-wrap:wrap;
	}
}


.page-section {
	//min-height:100vh;
	position:relative;
	padding-bottom: $gap-md;
	&:first-of-type {
		padding-top:$gap-lg + $gap-md;
	}
	&:not(:first-of-type) {
		padding-top:$gap-lg;
	}
	.section-title {
		margin:0;
		padding:0 $gap-sm $gap-sm;
		font-family:$font-heading;
		@include font-bold;
		color:$blue-dk;
		@include fontsize-xxl;
		@media (min-width:$screen-sm-ls) {
			padding:0 $gap $gap $gap-sm;
		}
	}
}


.flex-all {
	display:flex;
}
.flex-sm {
	flex:1;
	@media (min-width:$screen-sm) {
		display:flex;
	}
}
.flex-md {
	flex:1;
	@media (min-width:$screen-md) {
		display:flex;
	}
}

.placeholder {
	//@include shadow(1);
	color:rgba($text-color,0.7);
	padding-bottom:$gap;
	max-width: 400px;
	.title {
		font-size:1rem;
		margin-bottom:$gap-xs;
	}
	.description {
		font-size:0.8rem;
	}
}
