@import "./styles/variables.scss";

body {
    font-family: "Open Sans", Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    width: 100%;
    align-items: stretch;
    height: auto;
    color: #333 !important;
    background: #f4f7fb url($site-background-image) no-repeat center !important;
    overflow-x: hidden;
    font-size: 14px;
    th {
        outline: none;
    }
    @media (max-width: 1440px) {
        font-size: 14px !important;
        a {
            font-size: 14px !important;
        }
        table .btn {
            white-space: nowrap;
            font-size: 12px !important;
        }
    }

    // @media (max-width: 1280px) {
    //     font-size: 13px !important;
    //     table .btn {
    //         white-space: nowrap;
    //         font-size: 12px !important;
    //     }
    // }
    @media (max-width: 1024px) {
        font-size: 12px !important;
        a,
        .breadcrumb {
            font-size: 12px !important;
        }
        table .btn {
            white-space: nowrap;
            font-size: 10px !important;
        }
    }

    @media (max-width: 768px) {
        font-size: 12px !important;
        a,
        .breadcrumb {
            font-size: 12px !important;
        }
        table .btn {
            white-space: nowrap;
            font-size: 10px !important;
        }
    }
    @media (max-width: 500px) {
        font-size: 12px !important;
        a,
        .breadcrumb {
            font-size: 12px !important;
        }
        table .btn {
            white-space: nowrap;
            font-size: 10px !important;
        }
    }
    @media (max-width: 400px) {
        font-size: 11px !important;
        a,
        .breadcrumb {
            font-size: 11px !important;
        }
        table .btn {
            white-space: nowrap;
            font-size: 10px !important;
        }
    }
}

a,
button {
    cursor: pointer !important;
    text-decoration: none;
}
a:hover,
a:focus,
a:active {
    text-decoration: none !important;
    outline: none;
}
.button:focus {
    outline: none;
}
.disabled {
    cursor: no-drop;
    a {
        color: gray !important;
    }
}
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.display-grid {
    display: grid;
}
.btn {
    border-radius: 0.24rem !important;
}
.login-btn-section .btn {
    margin: 4px;
}
.help-block {
    padding: 0 !important;
    display: block;
    color: #737373;
    b{
        font-weight: 700;
    }
}
.margin-auto {
    margin: auto;
}
.left-15 {
    left: 15%;
}
.left-10 {
    left: 10%;
}
.justify-center {
    justify-content: center !important;
}
.justify-end {
    justify-content: flex-end !important;
}
.justify-space-between {
    justify-content: space-between !important;
}
.justify-space-around {
    justify-content: space-around !important;
}
.font-bold {
    font-weight: bold;
}
.card-header.info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
.display-grid {
    display: grid;
}
i.fa {
    cursor: pointer;
    &.fa-pencil {
        color: #007DBC;
        margin: 5px;
    }
}
.no-wrap {
    white-space: nowrap;
}
.break-all {
    word-break: break-all;
}
.modal {
    overflow: scroll;
}
.modal-header.justify-end {
    justify-content: flex-end;
}
.error {
    color: #a94442;
}
.success {
    color: #34a441;
}
.danger {
    color: #d63a3c;
}
.cursor-pointer {
    cursor: pointer;
}
.no-cursor {
    pointer-events: none;
}
.display-none {
    display: none;
}
.grey-out {
    color: #aaaaaa;
}
.jumbotron h1 {
    font-size: 3.5rem;
}
.visible-xs {
    display: none !important;
}
.public-access-route {
    background: inherit;
    justify-content: flex-start;
}
@media (max-width: 992px) {
    .container-fluid.parent-component {
        justify-content: center !important;
        margin: auto;
    }
}
@media (max-width: 768px) {
    .hidden-xs {
        display: none !important;
    }
    .visible-xs {
        display: block !important;
    }
    .container-fluid.parent-component {
        justify-content: center;
        margin: auto;
    }
}
@media(max-width:767px){
    .react-bootstrap-table, table{
        overflow-x: scroll;
    }
    .bootstrap-table-container{
        box-shadow: 0.2px 0.2px 0px;
    }
    table::-webkit-scrollbar,
    .bootstrap-table-container .react-bootstrap-table::-webkit-scrollbar {
        width: 14px;
        color: black;
    }
    table::-webkit-scrollbar-track,
    .bootstrap-table-container .react-bootstrap-table::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 8px rgba(0,0,0,0.3);
        border: 1px solid #eeeeee;
    }
}

