@import '../../styles/variables.scss';

.login-container {
  width: $login-container-width;
  .form-group{
    margin-bottom: 0.5rem!important;
  }
  .alert.alert-warning{
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    .fa.fa-warning{
      font-size: 30px;
      margin: 5px 15px;
      pointer-events: none;
    }
  }
}
@media(max-width:1140px){
  .login-container {
    width: 100%;
  }
}