.download-card {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
  width: 100%;
  @media (max-width: 530px) {
    display: grid;
  }
}
.downloadable {
  color: $text-color;
  background: white;
  padding: 2rem;
  border: 2px solid white;
  @include shadow(1);
  display: inline-flex;
  margin: 2rem 1rem;
  &:hover {
    background: $link-color;
    text-decoration: none;
    border-color: rgba($link-color, 0.5);
    transform: translateY(-3px);
    @include shadow(2);
    .description,
    .action,
    svg {
      stroke: currentColor;
      color: white;
    }
  }
  &:active,
  &:focus {
    text-decoration: none;
  }
  .detail {
    display: block;
  }
  .action {
    font-size: 1.5rem;
    line-height: 1;
    padding-bottom: 0.25rem;
    @include font-bold;
    color: $link-color;
  }
  .description {
    color: #aaa;
  }
  svg {
    width: 3rem;
    height: 3rem;
    stroke-width: 3px;
    margin-right: 1.25rem;
    stroke: #ddd;
    fill: #ddd;
    color: #ddd;
  }
}
