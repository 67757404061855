.manuals {
    .side-panel {
        padding-right: 1%;
    }
}

.current-manual,
.all-manual {
    padding: 0;
    @media (max-width: 1024px) {
        padding: 0 15px;
    }
    @media (max-width: 992px) {
        padding: 0;
    }
    .card {
        padding: 24px 36px;
        margin-bottom: 25px;
        width: 40%;
        @media (max-width: 1440px) {
            width: 45%;
        }
        @media (max-width: 1280px) {
            width: 50%;
        }
        @media (max-width: 500px) {
            width: 100%;
        }
    }
    .btn-section {
        justify-content: space-between;
        margin-top: 10%;
        .btn {
            font-size: 14px;
            padding: 5px 10px;
        }
    }
    // @media (max-width: 1440px) {
    //     padding: 0px 5px !important;
    // }
    // @media (max-width: 992px) {
    //     padding: 0px 5% !important;
    // }
}
// .parent-component.manuals {
.current-manual {
    &.card {
        padding: 24px 36px !important;
        margin-bottom: 5%;
        max-width: 35%;
        @media (max-width: 992px) {
            max-width: 50%;
        }
        @media (max-width: 500px) {
            max-width: 100%;
        }
    }
    display: flex;
    // .col-lg-3 {
    //     @media (max-width: 1920px) {
    //         max-width: 11%;
    //     }
    //     @media (max-width: 1680px) {
    //         max-width: initial;
    //     }
    //     @media (max-width: 1440px) {
    //         max-width: initial;
    //     }
    //     @media (max-width: 992) {
    //         max-width: 0;
    //         display: contents;
    //     }
    // }
    // .col-lg-9 {
    //     max-width: 40%;
    // }
    // @media (max-width: 1280px) {
    //     padding: 0px 45px !important;
    //     margin: 0;
    // }
    // @media (max-width: 1024px) {
    //     padding: 0px 5px !important;
    // }
}
// }
.add-doc-btn-section {
    margin-bottom: 5%;
    width: 100%;
}
.manual-form {
    .form-group {
        display: flex;
    }
    label {
        min-width: 150px;
    }
}
.videos {
    a {
        color: #008fbc;
    }
    a:hover {
        color: #0e4876;
    }
}
.individual-video-sec {
    margin-top: 5%;
}
.play-video{
    a{
        color: #008fbc !important;
    }
    a:hover {
        color: #0e4876 !important;
    }
}
@media (max-width: 1024px) {
    .manual-form {
        left: 0%;
    }
}
