// @import url("https://js.recurly.com/v4/recurly.css");
iframe{
    padding: initial;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
}


.recurly-hosted-field-icon-container {
    flex: 2;
    order: 1;
    align-self: center;
    max-width: 50px;
    position: relative;
    transition: flex 150ms, padding 150ms;
    perspective: 1000px;
    height: 100%;
    padding: 5px 0 5px 5px;
    box-sizing: border-box;
}

.d-f{
    display: flex;
}

.d-b{
    display: block !important;
}
.p-r-0{
    padding-right: 0px !important;
}
.fw-bold{
    font-weight: bold;
}    