.hidden {
  display:none;
}
.assistive-text {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
}

.fill {
  fill:currentColor;
}
.stroke {
  stroke:currentColor;
}

.sample-icons {
  padding:$gap-lg;
}

.sample-icon {
  display:flex;
  align-items:center;
  margin-bottom:$gap-xs;
  svg {
    width:60px;
    height:60px;
    color:$text-color;
    stroke:currentColor;
    margin-right:$gap-sm;
  }
  .title {
    font-size:2.4rem;
  }

}
.file-name {
  margin-bottom:$gap-md;
  display:inline-flex;
}

.success {
  color:$success;
  &:hover {
    color:$success
  }
}
.danger {
  color:$danger;
  &:hover {
    color:$danger
  }
}
.warning {
  color:$warning !important;
  &:hover {
    color:$warning
  }
}
.historic {
  color: $gray !important;
  &:hover {
    color:$gray 
  }
}
.capitalize {
  text-transform:capitalize;
}

.clickable {
  cursor: pointer;
}

.padded {
  padding-bottom: 2rem;
}


.descriptive-card {
  padding: 24px;
}
.past-value{
  color:'#ddd' !important;
  &:hover {
    color:$gray 
  }
}
