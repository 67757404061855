
@media (min-width:$screen-sm-ls + 1px) {
  .mobile-nav,.nav-trigger, .mobile-nav-trigger {
    display:none;
  }
}

.nav-trigger {
  position: fixed;
  clip: rect(0, 0, 0, 0);
  left: 0;
  top: 0;
}

@keyframes page-overlay {
  0% {
    background-color:transparent;
  }
  100% {
    background-color:white;
  }
}

.nav-trigger:checked {
  ~ .mobile-nav {
    left:0;
  }
  ~ .page-wrapper {
    &:before {
      animation:page-overlay 0.5 0.2s forwards;
      content:"";
      background:white;
      width:100vw;
      height:100vh;
      position:fixed;
      z-index:50;
      left:0;
      top:0;
    }
  }
}

label[for="nav-trigger"] {
  color:$text-color;
  align-items:center;
  padding-left:0;
  padding-right:$gap;
  margin:0;
  @media (min-width:$screen-sm-ls + 1px) {
    display:none;
  }
  .icon {
    //border:2px solid rgba(#222,0.5);
    //border-radius:$border-radius-sm;
    width:32px;
    height:32px;
    display:flex;
    align-items:center;
    justify-content:center;
    color:rgba(#222,0.5);
  }
  svg {
    fill:currentColor;
    width:24px;
    height:24px;
  }
}

.mobile-nav {
  position:fixed;
  z-index:51;
  background:white;
  left:0;
  height:100vh;
  width:$mobile-nav-width;
  display:flex;
  left:-($mobile-nav-width);
  flex-direction:column;
  transition:left 0.5s 0.15s;
  @include shadow(3);

  .nav-heading {
    display:flex;
    align-items:center;
    min-height:$primary-nav-height;
    border-bottom:1px solid #eee;
    padding:0 $gap;
  }
  .brand-logo {
    padding:0 $gap 0 0;
    //width:250px;

    display:flex;
    align-items:center;
    img {
      max-width:100%;
      height:auto;
    }
  }
  nav {
    flex-basis:100%;
    display:flex;
    flex-direction:column;

    padding-top:$gap-sm;
    > a {
      padding:$gap-sm $gap;
      font-size:1.2rem;
    }
  }
}
