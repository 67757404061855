@import '../../styles/variables.scss';

.footer.container{
    background-color: transparent;
    width: 100%;
    position: fixed;
    text-align: center;  
    margin: 0;
    height: 50px;
    a{
        color:#008fbc!important;
    }
    // justify-content: flex-start;
}
.footer-link{
    margin: 0px 5px;
}
@media (max-width: 530px) {
    .footer.container{
        position: relative;
        bottom: 0;
    }
}