// #recommendations {
//   position:relative;
//   z-index:7;
//   background:$body-bg;
//   padding-bottom:0;
// }

// .instruction-content {
//   font-size: 1.3rem;
//   // **wrist slap** no units for line-height :)
//   line-height: 1.3;
// }
// //.provider-icon { stroke: currentColor; }

// .recommendation {
//   min-height: 100vh;
//   position:relative;
//   flex-wrap:wrap;
//   background: linear-gradient(to bottom, rgba(#f4f7fb,1) 20%,rgba($blue2, 1) 100%);
//   > .title, hr {
//     flex-basis:100%;
//   }
//   //.photo-bg {
//   //  position:absolute;
//   //  min-height:100vh;
//   //  width:100%;
//   //  background-size:cover;
//   //  background-repeat:no-repeat;
//   //  width:100%;
//   //  //opacity:0.2;
//   //  //position:relative;
//   //  &:after {
//   //    content:"";
//   //    width:100%;
//   //    height:100%;
//   //    background: linear-gradient(to bottom, rgba(#f4f7fb,1) 20%,rgba($blue2, 1) 100%);
//   //    position:absolute;
// //
//   //  }
//   //}

//   .content {
//     position:relative;
//     z-index:2;
//     padding-top:$gap-lg;
//     // background-image: url('../../images/analytics-background-spring.jpg');
//     background-size: cover;
//     background-position: center right;
//     background-repeat: no-repeat;
//     background-attachment: fixed;

//     min-height: 100vh;
//   }
//   hr {
//     //background:white;
//     //opacity:0.1;
//     margin:0;
//     background:#eee;
//   }

//   .card {
//     .card-heading { border-bottom: none; }

//   }

//   .point {
//     display:flex;
//     color:$text-color;
//     text-decoration:none;
//     .grade {
//       margin-right:$gap-sm + $gap-xs;
//     }
//     .title {
//       font-size:1rem;
//       @include font-semibold;

//     }
//     .result {
//       font-size:1.2rem;
//       @include font-bold;
//     }

//   }

// }

// .recommendation-cards {
//   display: flex;
//   flex-wrap: wrap;
//   //flex-direction: row;
//   //align-items: baseline;
//   padding-bottom:$gap-lg * 2;
//   .column {
//     padding-top:$gap-sm;
//     padding-bottom:$gap-sm;
//     @media (min-width:$screen-sm+1) {
//       width:50%;
//     }
//     @media (max-width:$screen-sm) {
//       width:100%;
//     }
//   }
//   .card {

//     @media (min-width:$screen-sm) {
//       margin-left:$gap-sm;
//       margin-right:$gap-sm;
//     }
//   }
// }

// .recommendation-card {
//   height:100%;
//   @media (min-width:$screen-md+1) {
//     flex: 1;
//     //max-width: 30%;
//     //margin-right: 3%;
//   }

//   .recommendation-group-name {
//     font-size: 1.15rem;
//     padding:$gap-sm $gap;
//     text-transform:uppercase;
//   }
//   .recommendation-card-heading {
//     background-color: rgba($blue2,0.1);
//     padding: $gap-sm $gap;
//     font-size: 1.65rem;
//   }
//   .card-content {
//     padding:$gap-sm $gap;
//   }

//   .recommendation-text {
//     flex: 6;
//     margin-right: $gap-lg;

//     .recommendation-body {
//       font-size: 1.6rem;
//       margin-bottom: $gap;
//     }

//     .recommendation-notes {
//       display: flex;
//     }

//     .recommendation-instructions, .recommendation-notes {
//       font-size: 1rem;
//       margin-top: $gap-xs;

//       .provider-photo {
//         width: 50px;
//         height: 50px;
//         min-height: 50px;
//         min-width: 50px;
//         border-radius: 50%;
//         border: 2px solid #ddd;
//         margin-right: 24px;
//         background-size: cover;
//       }
//     }
//   }

//   .refute {
//     float: right;
//     color:#ddd;
//     &:hover {
//       color:$danger;
//     }
//     svg {
//       width: 1.25rem;
//       height: 1.25rem;
//     }
//   }

//   .recommendation-indicators {
//     display:flex;
//     //flex: 4;
//     flex-direction:column;
//     .grade-block {
//       margin-right: $gap-sm;
//     }
//     .recommendation-indicator {
//       //width: 320px;
//       padding: $gap-xs 0;
//       + .point {

//       }
//     }
//   }

// }

// .recommendation-heading {
//   color:white;
//   display:flex;
//   padding-top:$gap-lg * 3;
//   padding-bottom:$gap-lg;
//   .icon {
//     margin-right:$gap-md;
//     color:white;
//     svg,img {
//       width:90px;
//       height:90px;
//       @include drop-shadow(0 2px 4px $text-color);
//     }
//     svg {
//       stroke:currentColor;
//     }
//   }
//   h3 {
//     font-size:3rem;
//     //text-transform: uppercase;
//     margin:0 0 $gap-sm;
//     padding:0;
//     @include font-bold;
//     @include drop-shadow(0 2px 2px $text-color);
//   }
//   .description {
//     font-size:2rem;
//     line-height:26px;
//     max-width:600px;
//     text-shadow: 0 1px 2px $text-color;
//   }
// }
div#historic_recommendations{
  z-index:10001;
  overflow-x: auto;
}

#medication{
  padding-top: 84px;
}

.medication-banner {
  margin:0;
	background-image:url("../../../assets/images/medication_image.jpg");
  background-position-x: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
  padding-top: 0px;
  background-size: cover;
  min-height: 77vh;
  padding-bottom: 50px;
  margin-top: 20px;
}

#treatment-plan, #medication,  #metaRecommendation, #historic_recommendations {
  position:relative;
  z-index:7;
  //background:$body-bg;
  padding-bottom:0;

  @media (min-width: $screen-md) {
    .jw-recommendation-inner-heading {
      padding: $gap-sm $gap-md;
    }

    .jw-recommendation-container {
      .card-group-title{
        padding: 0 24px 24px 12px;
      }
      .card{
        width: 70%;
      }
      //padding: 80px 180px 180px;
    }

    .jw-recommendation-card {
      padding: 12px 24px;
    }

    .jw-icon {
      svg {
        width:60px;
        height:60px;
      }
    }

    .jw-recommendation-body {
      line-height: 60px;
    }

    .jw-recommendation-toggle {
      svg {
         width:20px;
         height:20px;
         margin-top: 20px;
      }
    }

    .jw-recommendation-details {
      margin-left: $gap-sm;
      margin-right: $gap-lg;
    }
  }

  @media (max-width: $screen-md - 1px) {
    .jw-recommendation-inner-heading {
      padding: $gap-xs $gap-sm;
      h2 { font-size: 1.8rem; }
    }

    .jw-recommendation-card {
      padding: $gap-sm $gap;
    }

    .jw-icon {
      svg {
        width:30px;
        height:30px;
      }
    }

    .jw-recommendation-toggle {
      svg {
         width:15px;
         height:15px;
         margin-top: 5px;
      }
    }

    .jw-recommendation-details {
      margin-left: $gap-sm;
      margin-right: $gap-lg;
      margin-top: $gap-sm;
    }
  }


  .jw-recommendation-card {

    &.jw-recommendation-flex {
      display: flex;
      border-bottom: 1px solid #2980B9;
      // cursor: move !important;
      &.ended{
        opacity: 0.6 !important;
      }
    }

    strong {
      font-weight: bold;
      color: $blue1;
    }

    .jw-recommendation-content {
      flex: 1;
    }

    .jw-recommendation-body {
      font-size: 1.6rem;
      font-weight: 700;
      color: $blue2;
      padding: 0 12px 0 1rem;
      display: inline-block;
      word-break: break-all;
    }

    .jw-icon {
      color: $blue2;
      margin-left:auto;
      display: inline-block;
      vertical-align: text-bottom;
      align-items: center;
    }

    .jw-recommendation-toggle {
      stroke: #57b9e8;
      width: 15px;
      height: 15px;
      vertical-align: text-bottom;
    }
  }

  .jw-recommendation-inner-heading {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
}

div#historic_recommendations{
  .card-body{
    padding: 0px;
    .jw-recommendation-card{
      .jw-icon{
        svg{
          width: 35px;
          height: 35px;
          position: relative;
          top: 6px;
        }
      }
      .jw-recommendation-content{
        .jw-recommendation-body{
          line-height: 35px;
          font-size: 1.3rem;
        }
        .recommendation-notes{
          p{
            margin-bottom: 0px;
          }
        }
        .jw-recommendation-details{
          margin-left: 14px;
          .recommendation-instructions{
            margin-bottom: 0px;
          }
          .recommendation-indicator{
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}

.handle {
  color: #103756;
  cursor: move !important;
}

.priorityLoader {
  font-size: 0.7em;
  color: #545454;
  line-height: 0;
}

// .priorityResetBtn {
  
// }

.instruction-content {
  font-size: 1.3rem;
  // **wrist slap** no units for line-height :)
  line-height: 1.3;
}
//.provider-icon { stroke: currentColor; }

.recommendation-review {
  min-height: 100vh;
  position:relative;
  flex-wrap:wrap;
  background: linear-gradient(to bottom, rgba(#f4f7fb,1) 20%,rgba($blue2, 1) 100%);
  > .title, hr {
    flex-basis:100%;
  }

  .content {
    position:relative;
    z-index:2;
    padding-top:$gap-lg;
    //background-image: url('../../images/analytics-background-spring.jpg');
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    background-attachment: fixed;

    min-height: 100vh;
  }
  hr {
    //background:white;
    //opacity:0.1;
    margin:0;
    background:#eee;
  }

  .card {
    .card-heading { border-bottom: none; }

  }

  .point {
    display:flex;
    color:$text-color;
    text-decoration:none;
    .grade {
      margin-right:$gap-sm + $gap-xs;
    }
    .title {
      font-size:1rem;
      @include font-semibold;

    }
    .result {
      font-size:1.2rem;
      @include font-bold;
    }

  }

}

.recommendation-cards {
  display: flex;
  flex-wrap: wrap;
  //flex-direction: row;
  //align-items: baseline;
  padding-bottom:$gap-lg * 2;
  .col {
    padding-top:$gap-sm;
    padding-bottom:$gap-sm;
    @media (min-width:$screen-sm+1) {
      width:50%;
    }
    @media (max-width:$screen-sm) {
      width:100%;
    }
  }
  .card {

    @media (min-width:$screen-sm) {
      margin-left:$gap-sm;
      margin-right:$gap-sm;
    }
  }
}

.recommendation-card {
  height:100%;
  @media (min-width:$screen-md+1) {
    flex: 1;
    //max-width: 30%;
    //margin-right: 3%;
  }

  .recommendation-group-name {
    font-size: 1.15rem;
    padding:$gap-sm $gap;
    text-transform:uppercase;
  }
  .recommendation-card-heading {
    background-color: rgba($blue2,0.1);
    padding: $gap-sm $gap;
    font-size: 1.65rem;
  }
  .card-content {
    padding:$gap-sm $gap;
  }

  .recommendation-text {
    flex: 6;
    margin-right: $gap-lg;

    .recommendation-body {
      font-size: 1.6rem;
      margin-bottom: $gap;
    }

    .recommendation-notes {
      display: flex;
    }

    .recommendation-instructions, .recommendation-notes {
      font-size: 1rem;
      margin-top: $gap-xs;

      .provider-photo {
        width: 50px;
        height: 50px;
        min-height: 50px;
        min-width: 50px;
        border-radius: 50%;
        border: 2px solid #ddd;
        margin-right: 24px;
        background-size: cover;
      }
    }
  }

  .refute {
    float: right;
    color:#ddd;
    &:hover {
      color:$danger;
    }
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .recommendation-indicators {
    display:flex;
    //flex: 4;
    flex-direction:column;
    .grade-block {
      margin-right: $gap-sm;
    }
    .recommendation-indicator {
      //width: 320px;
      padding: $gap-xs 0;
      + .point {

      }
    }
  }

}

.recommendation-heading {
  color:white;
  display:flex;
  padding-top:$gap-lg * 3;
  padding-bottom:$gap-lg;
  .icon {
    margin-right:$gap-md;
    color:white;
    svg,img {
      width:90px;
      height:90px;
      @include drop-shadow(0 2px 4px $text-color);
    }
    svg {
      stroke:currentColor;
    }
  }
  h3 {
    font-size:3rem;
    //text-transform: uppercase;
    margin:0 0 $gap-sm;
    padding:0;
    @include font-bold;
    @include drop-shadow(0 2px 2px $text-color);
  }
  .description {
    font-size:2rem;
    line-height:26px;
    max-width:600px;
    text-shadow: 0 1px 2px $text-color;
  }
}


.recommendation-list {
  .item {
    display: flex;
    padding: $gap-sm;
    border: 1px solid #eee;
    margin-bottom: $gap-sm;
  }
  .provider-photo {
    width: 40px;
    height: 40px;
    margin-right: $gap-sm;
  }
  .details {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
  }
  .name {
    font-size: 0.9rem;
    @include font-bold;
  }
  .icon-group {
    display: flex;
    align-items: center;
    .icon {
      opacity: 0.5;
      margin: 0;
      &:hover {
        opacity: 1;
      }
      &:first-of-type {
        margin-right: $gap-sm;
      }
    }
  }
}
