.loader {
  display: inline-block;
  position: fixed;
  width: 64px;
  height: 64px;
  left: 47%;
  top: 50%;
  z-index: 10000;
}
@media (max-width: 576px) {
  .loader {
    display: inline-block;
    position: fixed;
    width: 64px;
    height: 64px;
    left: 40%;
    top: 50%;
    z-index: 10000;
  }
}
.loader:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); 
}
// .loader {
// // overflow: hidden;
// height: inherit;
// width: inherit;
// }
.loader .circle {
width: 24px;
height: 24px;
position: absolute;
background: #134570;
border-radius: 50%;
margin: -12px;
-webkit-animation: mesh 3s  ease-in-out infinite -1.5s;
animation: mesh 3s ease-in-out infinite -1.5s;
}

.loader > div .circle:last-child {
-webkit-animation-delay: 0s;
animation-delay: 0s;
}

.loader > div {
position: absolute;
top: 50%;
left: 50%;
}

.loader > div:last-child {
-webkit-transform: rotate(90deg);
-ms-transform: rotate(90deg);
transform: rotate(90deg);
}


@-webkit-keyframes mesh {
0% {
  -webkit-transform-origin: 50% -100%;
  transform-origin: 50% -100%;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
50% {
  -webkit-transform-origin: 50% -100%;
  transform-origin: 50% -100%;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
50.1% {
  -webkit-transform-origin: 50% 200%;
  transform-origin: 50% 200%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform-origin: 50% 200%;
  transform-origin: 50% 200%;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
}

@keyframes mesh {
0% {
  -webkit-transform-origin: 50% -100%;
  transform-origin: 50% -100%;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
50% {
  -webkit-transform-origin: 50% -100%;
  transform-origin: 50% -100%;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
50.1% {
  -webkit-transform-origin: 50% 200%;
  transform-origin: 50% 200%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform-origin: 50% 200%;
  transform-origin: 50% 200%;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
}

@media only screen and (max-width: 968px) {
.flexbox > div {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333%;
  flex: 0 0 33.3333333%;
}
}

@media only screen and (max-width: 768px) {
.flexbox > div {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}
}

@media only screen and (max-width: 568px) {
.flexbox > div {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}
}