.breadcrumb{
    background-color: #ffffff!important;
    font-size: 14px!important;
    display: flex;
    flex-wrap: wrap;
    padding: 8px 15px!important;
    box-shadow: 0 0px 1px rgba(51, 51, 51, 0.12), 0 0px 1px rgba(51, 51, 51, 0.24);
}
.individual-breadcrumb{
    margin: 0 5px;
    a{
        color:#008fbc!important;
    }
    a:active{
        color:#0E4876!important;
    }
}
