html {
	position:relative;
	min-height:100%;
	font-size:14px;
	@include screen-tv {
		font-size:16px;
	}
	@include screen-tv-sm {
		font-size:16px;
	}
	@media (max-width:$screen-md) {
		font-size:13px;
	}
	@media (max-width:$screen-sm-min) {
		font-size:12px;
	}
}

body {
	@include font-smoothing;
	font-size:inherit;
	margin:0;
	color:$text-color;
	min-height:100vh;
	display:flex;
	flex-direction:column;
	font-family:$font-regular;
	background-color:$body-bg;
	background-image:url('/assets/icon_physioage.svg');
  background-size: 50vw;
  background-position: center right;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

a {
	cursor:pointer;
	transition:all 0.15s ease;
	text-decoration:none;
	color:$link-color;
	&:hover,&:focus,&:active {
		text-decoration:underline;
		color:$link-active;
	}
}

hr {
	height:2px;
	padding:0;
	margin:$gap 0;
	background-color:rgba($blue-lt,0.7);
	border:0;
}
