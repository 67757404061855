@import '../../styles/variables.scss';

.signup-children {
    .side-panel {
        padding-right: 1%;
    }
}
.signup-form {
    // left: 15%;
    .form-group {
        display: flex;
    }
    label {
        min-width: 160px;
    }
    legend {
        color: #333333;
        border-bottom: 1px solid #dddddd;
        margin: 5% 0;
    }

    .form-check {
        margin: 15px;
        margin-top: 0px;
    }

    .form-check-label.col-lg-10 {
        text-align: initial;
        padding: 0;
    }
    .toggle-switch {
        display: flex;
        align-items: center;
        .help-block {
            display: block;
            color: #737373;
            padding-left: 10px !important;
        }
    }
    .switch {
        width: 50px;
        height: 25px;
        background: #ccc;
        border-radius: 25px;
        position: relative;
        cursor: pointer;
        transition: background 0.3s;
    }
    
    .switch.toggled {
        background: #026dec;
    }
    
    .switch-handle {
        width: 23px;
        height: 23px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        top: 1px;
        left: 1px;
        transition: left 0.3s;
    }
    
    .switch.toggled .switch-handle {
        left: 26px;
    }
}

.termsAndConditionsStyles>div{
    margin-left: 15px;
    margin-right: 10px;
   
}

.termsAndConditionsLabel a:hover{   
    text-decoration: underline !important;
}

.termsAndConditionsStyles input{
    width: 20px;
    height: 20px;
}

.termsAndConditionsLabel{
    cursor: pointer;
}


// .signupchildren{
//     .side-panel {
//         padding-right: 1%;
//     }
// }

.site-logo-preview {
    //width: 200px;
    //height: 200px;
    max-width: 80%;
    max-height: 120px;
    padding-right: 0.8rem;
}

.next-form {
    left: 15%;
}

.signup-form-info{
    pre .form-control {
        background: #f5f5f5;
        border: 1px solid #ccc;
    }
    &.alert {
        b {
            font-weight: 700;
        }
        p {
            font-size: 14px;
            margin: 0 0 10px;
            margin-bottom: 0;
        }
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
        &.alert-info {
            color: #31708f;
            background-color: #d9edf7;
            border-color: #bce8f1;
            label {
                color: #31708f;
            }
        }
    }
    .onborading-video{
        a{
            color: #31708f;
        }
    }
}

.tooltiptext {
    visibility: hidden;
    width: 320px;
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 120%;
    left: 26%;
    margin-left: -60px;
  }

  .showToolTip:disabled.showToolTip:hover+.tooltiptext {
    visibility: visible;
} 

.addMargin{
    margin: 15px;
}

@media (max-width: 992px) {
    .signup-form {
        left: 0%;
    }
}

@media (max-width: 769px){
    .signup-intro{
        margin-left: 20px;
    }
}
.onboarding-video video{
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    height: 90vh;
}    

@media (max-width: 992px) {
    .onboarding-video video{
        top: 30%;
        height: 50vh;
        width: 90%;
    }    
}