// Sidebar left compensation
@mixin sidebar-compensation {
  .max-container {

  }
}
.dropdown.has-current-data::before {
  left: $gap-sm;
  width: $gap-xs;
  height: $gap-xs;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar-left {
  box-shadow: 0 0 4px #ccc;
  background: white;
  display:flex;
  flex-direction:column;
  height:100vh;
  overflow:hidden;
  opacity:0;
  position:absolute;
  padding:$gap 0;
  justify-content:flex-start;
  top:0;
  width:$sidebar-width;
  z-index:8; // so it's above content in BOA
  @media (max-width:$screen-sm-ls) {
    display: none!important;
  }
  // @media only screen and (max-width: 1027px) {
  //   body {
  //     display:none;
  //   }
  // }
  .section-title {
    border-bottom:1px solid #eee;
    font-size:32px;
    margin:0;
    padding:0 $gap $gap;
  }
  nav {
    display:flex;
    flex-direction:column;
    a {
      font-size:1.15rem;
      line-height:1.2;
      padding:$gap-sm $gap;
      @include font-semibold;
      //@include ripple;
      &:hover,&:focus {
        color:$blue-dk;
        background-color:rgba($blue2,0.1);
        text-decoration:none;
      }
      &:hover {
        cursor:pointer;
      }
      &.active {
        background-color: rgba(41, 128, 185, 0.05);
        box-shadow: inset 6px 0px 0 0 #2980b9;
        font-weight:bold;
        ~ .nav-stacked {
          background-color: rgba(41, 128, 185, 0.05);
          box-shadow: inset 6px 0px 0 0 #2980b9, 0 2px rgba(41,128,185,0.1);
        }
      }
    }
    li.dropdown {
      position: relative;

      .section-toggle {
        position: absolute;
        right: 3px;
        top: 16px;

        svg {
          width: 15px;
          height: 15px;
          display: inline-block;
          margin-right: 0.6rem;
          vertical-align: text-top;
          color: #57b9e8;
          stroke: #57b9e8;
          fill: #57b9e8;
          transform: rotate(180deg);
          transition: transform 0.15s;
        }
      }
    }
  }
  .nav-stacked>li+li {
    margin: 0;
  }
  .dropdown {
    position: relative;

  }
  .subnav {
    list-style:none;
    max-height: 30rem;
    overflow: hidden;
    padding: 0;
    transition: all 0.15s;
    a {
      display: block;
      font-size: 1rem;
      line-height: 1;
      padding: 0.75rem 0.25rem 0.75rem 2.25rem;
    }
  }
}

.menu-nav.sidebar-left {
  @media (max-width:$screen-sm-ls) {
    opacity: 1;
    position: fixed;
    animation: fadein 0.9s;
      .active-sidebar{
            display: flex !important;
            right: 0;
    }
    
  }
}
.sidebar-content {
  //this doesn't work in IE
  //height:calc(100vh - 6rem);
  height: 97%;
  //changed overflow from auto to scroll
  overflow:auto;
  //changed position from absolute to relative for supporting in IE
  //position:absolute;
  position: relative;
  //changed top from 80 to 50 as the position have changed from absolute to relative
  top: 50px;
}

@keyframes fadein {
  from {opacity: 0; transform: scale(1,1)}
  to {opacity: 1;}
}

.fade-in-element-sidebar {
  opacity: 1;
  position: fixed;
  animation: fadein 0.9s;
  transition: all 0.5s;
  @media (max-width:$screen-sm-ls) {
    display:none !important;
  }
}

.active-sidebar{
      @media (max-width:$screen-sm-ls) {
          display: flex !important;
          transition: all 0.5s;
          right: 0;
          z-index:49;
      }
}
.nav-overlay{
  @media (max-width:$screen-sm-ls) {
    transition: all 0.5s;
    animation: fadein 0.5s;
    width:100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    position: absolute;
    z-index:49;
    bottom: 0;
    top: 0;
  }
}
// .fade-in-element-sidebar {
//   opacity:1;
//   position: fixed;
// }
.sidebar-right {
  background:white;
  border-left:1px solid #eee;
  min-height:calc(100vh - #{$primary-nav-height});
  position: fixed;
  right: 0px;
  top: $primary-nav-height;
  width: $sidebar-width;
  z-index: 1;
  @include screen-tv-sm {
    display:none;
  }
  .section-title {
    font-size:1.7rem;
    padding:$gap $gap 0;
    margin:0 0 $gap;
  }
  .patient-info {
    .detail-list {
      .item-result {
        font-size: 1.2rem;
        line-height:1;
      }
      .title {
        font-size:0.8rem;
      }
    }
    .user-stats {
      padding-left:$gap;
      padding-right:$gap;
      .detail-list {
        flex:1;
        padding-left:0;
        padding-right:0;
      }
    }
    .user-icon {
      margin-right: 0;
    }
    .user-header {
      border-radius:0;
      display: block;
      padding:$gap-sm $gap;
    }
    .patient-name {
      font-size:1.5rem;
    }
    .patient-age {
      font-size:1.1rem;
    }
  }
}
.health-categories {
  .logout{
  @media (max-width:$screen-sm-ls) {
    display: block;
  }
  
}
.signout{
  @media (max-width:$screen-sm-ls) {
    display: block !important;
  }
}
  li.dropdown {
    > .nav {
      background: #f9f9f9;
      max-height: 0;
      overflow: hidden;
      transition: all 0.15s;
      a {
        color: #333;
        font-size: 1rem;
        padding: $gap-xs ($gap + $gap-xs);
      }
      > li:first-of-type > a {
        padding-top: $gap-sm;
      }
      > li:last-of-type > a {
        padding-bottom: $gap-sm;
      }
    }
  }
}
.sidebar-a li{
  width: 100%;
}
.sidebar-a a{
  display: block;
  color: #007DBC !important ;
 
}
.sidebar-a #link{
  display: block;
  color: #007DBC;
 
}
.sidebar-a a:hover{
  color:#103756;
  background-color:rgba(41, 128, 185, 0.1);
  text-decoration:none;
}
.active, .scrollable:hover{
  color:#103756;
  // background-color:rgba(41, 128, 185, 0.1);
  text-decoration:none;
}
#link{
  color: #007DBC;
}
#link.active{
  color:#103756;
}
.logout{
  display: none;
  width:100%;
}
.signout{
  display: none !important;
  width:100%;
}

