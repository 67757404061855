@import '../../styles/variables.scss';
.otp-screen {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .email-container{
    margin-left: 28px;
    margin-right: 28px;
  }
  .otp-container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
    box-shadow: none;
    border-radius: 8px;
    text-align: center;
    .form-group{
      margin-bottom: 0.5rem!important;
    }
    .alert.alert-warning{
      display: flex;
      padding-left: 0px;
      padding-right: 0px;
      .fa.fa-warning{
        font-size: 30px;
        margin: 5px 15px;
        pointer-events: none;
      }
    }
    .label-1{
      font-size: 1.4em;
      font-weight: 600;
      color: black;
    }
    .label-2{
      font-size: 1em;
      font-weight: 600;
      color: #337ab7;
    }
    .label-3{
      font-size: .9em;
      font-weight: 400;
      color: gray;
    }
    .label-4{
      font-size: .9em;
      font-weight: 600;
    }
    .btn.link{
      text-decoration: auto;
      color: #337ab7;
    }
    .btn.link:hover{
        color: #286090;
        text-decoration: auto;
    }
    .btn.link:focus{
      box-shadow: none !important; 
    }
    .notification{
      margin-bottom: 0px !important;
      &.alert {
        padding: 0px;
        ul {
          margin-bottom: 0px !important;
          padding: 0 !important;
          margin-bottom: 5px !important;
          li{
            justify-content: flex-start;
            display: flex;
          }
        }
      }
      &.alert.alert-error{
            background-color: transparent !important; 
            border-color: transparent !important;
      }
    }  
  }
  .css-ahj2mt-MuiTypography-root{
    margin: 0;
    font-family: inherit;
    font-weight: 400 !important;
    font-size: 0.9rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: black !important;
  }  
} 
.card:has(.otp-screen) {
  .helptext-section .text-center{
    font-size: 14px !important;
  }
}

@media(max-width:1140px){
  .otp-screen {
    width: 100%;
  }
}

@media (max-width: 820px) {
  .email-container{
    margin-left: 0px;
    margin-right: 0px;
  }
}  

@media (max-width: 768px) {
  .otp-container {
    max-width: 90%;
    padding: 15px;
    padding: 0px;
  }
  .email-container{
    margin-left: 0px;
    margin-right: 0px;
  }
  .otp-screen {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .otp-container {
    max-width: 100%;
    // padding: 10px;
    padding: 0px;
  }  
  .email-container{
    margin-left: 0px;
    margin-right: 0px;
  }
}

