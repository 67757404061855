#loading {
  z-index: 10000;
  color: white;
  // padding: $gap;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background-color: #09416f;
  // background-image: url("../../../assets//images/icon_physioage.svg");
  // background-image: url("/assets/icon_physioage.svg");
  background-size: 80%;
  background-position: center right;
  background-repeat: no-repeat;
  background-attachment: fixed;

  opacity: 1;

  transition: z-index 0.5s step-start, opacity 0.5s linear;
  -moz-transition: z-index 0.5s step-start, opacity 0.5s linear;
  -webkit-transition: z-index 0.5s step-start, opacity 0.5s linear;
  // display: flex;
  // align-items: flex-start;
  // flex-direction: column;
  // justify-content: center;
}

.header-nav-position{
  z-index: 10000;
  color: white;
  position: static;
}

//Uncomment below code if you want to disable the scroll bar

// .body-class{
//   position: fixed;
//   overflow-y: scroll !important;
// }

.loading-data-text{
  font-size: 1.5rem;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: -75px;
}

div.loading-data-text-black{
  color: black !important;
}

.loading-row {
  font-size:1.5rem;
  margin-bottom:$gap-xs;
}

.pa-loading-container {
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background:rgba(white,0.9);
  display:flex;
  justify-content:center;
  align-items:center;
  z-index:200;
  display:none;
}
.pa-loading {
  margin: 100px auto;
  width: $gap-lg * 1.5;
  height: $gap-lg * 1.5;
  position: relative;
}
.pa-loading .pa-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.pa-loading .pa-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: $blue-dk;
  border-radius: 100%;
  -webkit-animation: pa-loadingBounceDelay 1.2s infinite ease-in-out both;
          animation: pa-loadingBounceDelay 1.2s infinite ease-in-out both;
}
.pa-loading .pa-loading2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.pa-loading .pa-loading3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.pa-loading .pa-loading4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.pa-loading .pa-loading5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.pa-loading .pa-loading6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.pa-loading .pa-loading7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.pa-loading .pa-loading8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.pa-loading .pa-loading9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.pa-loading .pa-loading10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.pa-loading .pa-loading11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg);
}
.pa-loading .pa-loading12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg);
}
.pa-loading .pa-loading2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.pa-loading .pa-loading3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.pa-loading .pa-loading4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.pa-loading .pa-loading5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.pa-loading .pa-loading6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.pa-loading .pa-loading7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.pa-loading .pa-loading8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.pa-loading .pa-loading9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.pa-loading .pa-loading10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.pa-loading .pa-loading11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.pa-loading .pa-loading12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes pa-loadingBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pa-loadingBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
