@import "../../../styles/variables.scss";
.providers-component {
    &.parent-component {
        .data-section {
            @media (max-width: 1024px) {
                margin-left: 15px;
            }
            @media (max-width: 768px) {
                margin-left: 0;
            }
        }
    }
}
.providers-list {
    .react-bootstrap-table-pagination-list {
        display: none !important;
    }
    button {
        width: inherit;
    }
    .side-panel {
        padding-right: 15px;
    }
    // margin: 0px -15px;
}

.provider-bio {
    margin: auto;
    justify-content: space-between;
}
.provider-avatar {
    border: 1px solid #eeeeee;
    box-shadow: 1px 1px #eeeeee;
    min-height: 100px;
    max-height: 100px;
    min-width: 100px;
    max-width: 100px;
    cursor: pointer;
}
.providers-list-grid {
    td {
        vertical-align: inherit !important;
    }
    .btn {
        white-space: nowrap;
    }
    th,
    td {
        min-width: 15% !important;
    }
    @media (max-width: 500px) {
        th:first-child,
        td:first-child {
            display: none;
        }
        th:nth-child(2),
        td:nth-child(2) {
            word-break: break-all;
            min-width: 40%;
            max-width: 40%;
        }
        th:nth-child(3),
        td:nth-child(3),
        th:nth-child(4),
        td:nth-child(4),
        th:nth-child(5),
        td:nth-child(5) {
            word-break: break-all;
            min-width: 20%;
            max-width: 20%;
        }
    }
}
.provider-form {
    // left: 25%;
    // @media(max-width:1024px){
    //     left: 10%;
    // }
    // @media(max-width:992px){
    //     left: 0%;
    // }
}
.image-upload > input {
    display: none;
}

.image-upload img {
    cursor: pointer;
}
.profile-pic-section {
    img {
        height: 100px;
        width: 100px;
    }
}

.image-input:hover .overlay {
    opacity: 1;
    height: fit-content;
    i.fa.fa-times-circle {
        cursor: pointer;
        color: #d35106;
        font-size: 20px;
        background-color: #ffffff;
        border-radius: 25px;
        &:hover {
            font-size: 30px;
        }
    }
}
.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: transparent;
    text-align: center;
    font-size: 20px;
    top: 50px;
}
.sites-section {
    width: 100%;
}
.provider-details-image {
    height: 200px;
    width: 200px;
    margin: 20px;
}
.provider-details {
    button {
        margin-bottom: 5%;
        width: inherit;
    }

    .card {
        flex-direction: row;
    }
    a {
        color: #008fbc !important;
    }
    a.page-link {
        color: #008fbc !important;
    }
    a:active {
        color: #0e4876 !important;
    }
    @media (max-width: 768px) {
        display: block;
    }
}
.provider-details-side-panel {
    @media (max-width: 768px) {
        padding: 0 !important;
    }
}
@media (max-width: 992px) {
    // .providers-list-grid{
    //     left: 0%;
    // }
}
@media (max-width: 768px) {
    .providers-list {
        .react-bootstrap-table {
            thead {
                // display: none !important;
                white-space: nowrap;
            }
            tr {
                flex-wrap: wrap !important;
            }
        }
    }
    .provider-bio.row {
        margin: auto;
        .row {
            margin: auto;
        }
    }
    .profile-pic-sec {
        left: 25%;
    }
}
.providers-children {
    .side-panel {
        padding-right: 15px;
    }
}
@media (max-width: 500px) {
    .providers-list-grid {
        tr {
            display: flex !important;
        }
    }
}
